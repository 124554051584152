import React, { useState } from "react";
import { useDoubleClick } from "@zattoo/use-double-click";
import { useSelector } from "react-redux";
import { CatalogCardProps } from "../types/common";
import DownloadIcon from "../assets/img/download-card-footer.svg";
import ExpandIcon from "../assets/img/expand-card-footer.svg";
import ShareIcon from "../assets/img/share-card-footer.svg";
import noFillHeartPic from "../assets/img/heart-no-fill.svg";
import fillHeartPic from "../assets/img/heart-fill.svg";
import { useAppDispatch, useAppSelector } from "../store";
import {
  assetId,
  assetUrl,
  assetType,
  setIsFavorite,
  setAssetInfo,
} from "../pages/assetCardSlice";
import { RootState } from "../rootReducer";
import { toggleChoice, toggleIsOpen } from "../pages/shareSlice";
import { toggleDownloadChoice } from "../pages/downloadSlice";
import SecureLS from "../utils/SecureStorage";
import { removeAssetsFromFavorite } from "../utils/catalog";
import { FavoriteInfoInterface, setFavorite } from "../pages/newFavoriteSlice";

const CatalogCard = ({
  id,
  title,
  downloadLink,
  shareLink,
  src,
  type,
  filename,
  fullpath,
  fullpathExtandFont,
  fullpathExtandLandscapeImage,
  fullpathExtandPortraitImage,
  fullpathThumbnailFont,
  fullpathThumbnailImageDownload,
  fullpathThumbnailLandscapeImage,
  fullpathThumbnailPortraitImage,
  youTubeSDThumbnailPath,
  youTubeSDPath,
  customMetadataId,
  creationDate,
  fileSize,
  additionalClasses,
  selectCallback,
  toggleAssetDownloadModal,
  toggleAssetDetailModal,
  toggleAssetFavoriteModal,
  isFavorite = false,
  isNew = false,
  dpiAsset,
}: CatalogCardProps) => {
  const dispatch = useAppDispatch();
  const [isFavoriteChecked, setIsFavoriteChecked] =
    useState<boolean>(isFavorite);

  const secureLS = SecureLS.getItem("user");
  const dataValue = secureLS ? JSON.parse(secureLS) : null;
  const fileExtension = filename?.split(".")?.slice(-1)?.pop()?.toUpperCase();

  const currentFolderId = useAppSelector(
    (state) => state.newFavorite.currentFolderId
  );

  const favorite = useAppSelector((state) => state.newFavorite.favorite);

  // update user favorite folder pimcore
  // retrieve the state that track the object with useSelector hook
  const userId = useSelector((state: RootState) => state.user?.id);

  function findFolderId(
    json: Record<string, any>,
    assetID: string
  ): string | null {
    const folderIds = Object.keys(json);
    const folderId = folderIds.find((ID) => {
      const folder = json[ID];
      return folder.assets.some(
        (asset: { id: string }) => asset.id === assetID
      );
    });
    return folderId || null;
  }

  const handleHeartIcon = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (isFavoriteChecked) {
      const folderId = findFolderId(favorite, id);
      if (folderId) {
        removeAssetsFromFavorite(
          userId,
          [id],
          folderId,
          folderId,
          (value: FavoriteInfoInterface) => dispatch(setFavorite(value))
        );
      }
    } else {
      if (toggleAssetFavoriteModal) {
        if (selectCallback) {
          selectCallback(
            id,
            event as unknown as React.MouseEvent<HTMLDivElement>
          );
        }
        dispatch(assetId(id));
        dispatch(assetUrl(src));
        dispatch(assetType(type));
        dispatch(
          setAssetInfo({
            id: id !== undefined ? id : null,
            filename: filename !== undefined ? filename : null,
            fullpath: fullpath !== undefined ? fullpath : null,
            fullpathExtandFont:
              fullpathExtandFont !== undefined ? fullpathExtandFont : null,
            fullpathExtandLandscapeImage:
              fullpathExtandLandscapeImage !== undefined
                ? fullpathExtandLandscapeImage
                : null,
            fullpathExtandPortraitImage:
              fullpathExtandPortraitImage !== undefined
                ? fullpathExtandPortraitImage
                : null,
            fullpathThumbnailFont:
              fullpathThumbnailFont !== undefined
                ? fullpathThumbnailFont
                : null,
            fullpathThumbnailImageDownload:
              fullpathThumbnailImageDownload !== undefined
                ? fullpathThumbnailImageDownload
                : null,
            fullpathThumbnailLandscapeImage:
              fullpathThumbnailLandscapeImage !== undefined
                ? fullpathThumbnailLandscapeImage
                : null,
            fullpathThumbnailPortraitImage:
              fullpathThumbnailPortraitImage !== undefined
                ? fullpathThumbnailPortraitImage
                : null,
            youTubeSDThumbnailPath:
              youTubeSDThumbnailPath !== undefined
                ? youTubeSDThumbnailPath
                : null,
            youTubeSDPath: youTubeSDPath !== undefined ? youTubeSDPath : null,
            type: type !== undefined ? type : null,
            customMetadataId:
              customMetadataId !== undefined ? customMetadataId : null,
            creationDate: creationDate !== undefined ? creationDate : null,
            fileSize: fileSize !== undefined ? fileSize : 0,
            dpiAsset: dpiAsset !== undefined ? dpiAsset : null,
          })
        );
        toggleAssetFavoriteModal();
      }
    }
  };

  const clickEvent = useDoubleClick(
    (event) => {
      if (selectCallback) {
        selectCallback(
          id,
          event as unknown as React.MouseEvent<HTMLDivElement>
        );
      }
      if (toggleAssetDetailModal && dataValue) {
        dispatch(assetId(id));
        dispatch(assetUrl(src));
        dispatch(assetType(type));
        dispatch(setIsFavorite(isFavoriteChecked));
        dispatch(
          setAssetInfo({
            id: id !== undefined ? id : null,
            filename: filename !== undefined ? filename : null,
            fullpath: fullpath !== undefined ? fullpath : null,
            fullpathExtandFont:
              fullpathExtandFont !== undefined ? fullpathExtandFont : null,
            fullpathExtandLandscapeImage:
              fullpathExtandLandscapeImage !== undefined
                ? fullpathExtandLandscapeImage
                : null,
            fullpathExtandPortraitImage:
              fullpathExtandPortraitImage !== undefined
                ? fullpathExtandPortraitImage
                : null,
            fullpathThumbnailFont:
              fullpathThumbnailFont !== undefined
                ? fullpathThumbnailFont
                : null,
            fullpathThumbnailImageDownload:
              fullpathThumbnailImageDownload !== undefined
                ? fullpathThumbnailImageDownload
                : null,
            fullpathThumbnailLandscapeImage:
              fullpathThumbnailLandscapeImage !== undefined
                ? fullpathThumbnailLandscapeImage
                : null,
            fullpathThumbnailPortraitImage:
              fullpathThumbnailPortraitImage !== undefined
                ? fullpathThumbnailPortraitImage
                : null,
            youTubeSDThumbnailPath:
              youTubeSDThumbnailPath !== undefined
                ? youTubeSDThumbnailPath
                : null,
            youTubeSDPath: youTubeSDPath !== undefined ? youTubeSDPath : null,
            type: type !== undefined ? type : null,
            customMetadataId:
              customMetadataId !== undefined ? customMetadataId : null,
            creationDate: creationDate !== undefined ? creationDate : null,
            fileSize: fileSize !== undefined ? fileSize : 0,
            dpiAsset: dpiAsset !== undefined ? dpiAsset : null,
          })
        );
        toggleAssetDetailModal();
      }
    },
    (event) => {
      if (selectCallback)
        selectCallback(id, event as React.MouseEvent<HTMLDivElement>);
    }
  );

  return (
    <div
      className={`content-card ${additionalClasses || additionalClasses}`}
      onClick={clickEvent}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {}}
    >
      <div className="asset-tag-container">
        <p className="asset-tag">{fileExtension}</p>
        {isNew && <p className="asset-tag new-asset">NEW</p>}
      </div>
      <div className="image-container">
        <picture>{src && <img src={src} alt={title} />}</picture>
      </div>
      <div className="content-card-footer">
        <div className="file-name">{title}</div>
        {dataValue && (
          <ul>
            <li>
              <a
                href={downloadLink}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (toggleAssetDownloadModal) {
                    if (selectCallback)
                      selectCallback(
                        id,
                        event as unknown as React.MouseEvent<HTMLDivElement>
                      );
                    dispatch(assetId(id));
                    dispatch(assetUrl(src));
                    dispatch(assetType(type));
                    dispatch(
                      setAssetInfo({
                        id: id !== undefined ? id : null,
                        filename: filename !== undefined ? filename : null,
                        fullpath: fullpath !== undefined ? fullpath : null,
                        fullpathExtandFont:
                          fullpathExtandFont !== undefined
                            ? fullpathExtandFont
                            : null,
                        fullpathExtandLandscapeImage:
                          fullpathExtandLandscapeImage !== undefined
                            ? fullpathExtandLandscapeImage
                            : null,
                        fullpathExtandPortraitImage:
                          fullpathExtandPortraitImage !== undefined
                            ? fullpathExtandPortraitImage
                            : null,
                        fullpathThumbnailFont:
                          fullpathThumbnailFont !== undefined
                            ? fullpathThumbnailFont
                            : null,
                        fullpathThumbnailImageDownload:
                          fullpathThumbnailImageDownload !== undefined
                            ? fullpathThumbnailImageDownload
                            : null,
                        fullpathThumbnailLandscapeImage:
                          fullpathThumbnailLandscapeImage !== undefined
                            ? fullpathThumbnailLandscapeImage
                            : null,
                        fullpathThumbnailPortraitImage:
                          fullpathThumbnailPortraitImage !== undefined
                            ? fullpathThumbnailPortraitImage
                            : null,
                        youTubeSDThumbnailPath:
                          youTubeSDThumbnailPath !== undefined
                            ? youTubeSDThumbnailPath
                            : null,
                        youTubeSDPath:
                          youTubeSDPath !== undefined ? youTubeSDPath : null,
                        type: type !== undefined ? type : null,
                        customMetadataId:
                          customMetadataId !== undefined
                            ? customMetadataId
                            : null,
                        creationDate:
                          creationDate !== undefined ? creationDate : null,
                        fileSize: fileSize !== undefined ? fileSize : 0,
                        dpiAsset: dpiAsset !== undefined ? dpiAsset : null,
                      })
                    );
                    dispatch(toggleDownloadChoice(1));
                    toggleAssetDownloadModal();
                  }
                }}
              >
                <img src={DownloadIcon} alt="Download" />
              </a>
            </li>
            <li>
              <div className="content-card-separator">&nbsp;</div>
            </li>
            <li>
              <a
                href="content-card-icon-link"
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (selectCallback) {
                    selectCallback(
                      id,
                      event as unknown as React.MouseEvent<HTMLDivElement>
                    );
                  }
                  if (toggleAssetDetailModal) {
                    dispatch(assetId(id));
                    dispatch(assetUrl(src));
                    dispatch(assetType(type));
                    dispatch(setIsFavorite(isFavoriteChecked));
                    dispatch(
                      setAssetInfo({
                        id: id !== undefined ? id : null,
                        filename: filename !== undefined ? filename : null,
                        fullpath: fullpath !== undefined ? fullpath : null,
                        fullpathExtandFont:
                          fullpathExtandFont !== undefined
                            ? fullpathExtandFont
                            : null,
                        fullpathExtandLandscapeImage:
                          fullpathExtandLandscapeImage !== undefined
                            ? fullpathExtandLandscapeImage
                            : null,
                        fullpathExtandPortraitImage:
                          fullpathExtandPortraitImage !== undefined
                            ? fullpathExtandPortraitImage
                            : null,
                        fullpathThumbnailFont:
                          fullpathThumbnailFont !== undefined
                            ? fullpathThumbnailFont
                            : null,
                        fullpathThumbnailImageDownload:
                          fullpathThumbnailImageDownload !== undefined
                            ? fullpathThumbnailImageDownload
                            : null,
                        fullpathThumbnailLandscapeImage:
                          fullpathThumbnailLandscapeImage !== undefined
                            ? fullpathThumbnailLandscapeImage
                            : null,
                        fullpathThumbnailPortraitImage:
                          fullpathThumbnailPortraitImage !== undefined
                            ? fullpathThumbnailPortraitImage
                            : null,
                        youTubeSDThumbnailPath:
                          youTubeSDThumbnailPath !== undefined
                            ? youTubeSDThumbnailPath
                            : null,
                        youTubeSDPath:
                          youTubeSDPath !== undefined ? youTubeSDPath : null,
                        type: type !== undefined ? type : null,
                        customMetadataId:
                          customMetadataId !== undefined
                            ? customMetadataId
                            : null,
                        creationDate:
                          creationDate !== undefined ? creationDate : null,
                        fileSize: fileSize !== undefined ? fileSize : 0,
                        dpiAsset: dpiAsset !== undefined ? dpiAsset : null,
                      })
                    );
                    toggleAssetDetailModal();
                  }
                }}
              >
                <img src={ExpandIcon} alt="Expand" />
              </a>
            </li>
            <li>
              <div className="content-card-separator">&nbsp;</div>
            </li>
            <li>
              <a
                href={shareLink}
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(assetId(id));
                  dispatch(
                    setAssetInfo({
                      id: id !== undefined ? id : null,
                      filename: filename !== undefined ? filename : null,
                      fullpath: fullpath !== undefined ? fullpath : null,
                      fullpathExtandFont:
                        fullpathExtandFont !== undefined
                          ? fullpathExtandFont
                          : null,
                      fullpathExtandLandscapeImage:
                        fullpathExtandLandscapeImage !== undefined
                          ? fullpathExtandLandscapeImage
                          : null,
                      fullpathExtandPortraitImage:
                        fullpathExtandPortraitImage !== undefined
                          ? fullpathExtandPortraitImage
                          : null,
                      fullpathThumbnailFont:
                        fullpathThumbnailFont !== undefined
                          ? fullpathThumbnailFont
                          : null,
                      fullpathThumbnailImageDownload:
                        fullpathThumbnailImageDownload !== undefined
                          ? fullpathThumbnailImageDownload
                          : null,
                      fullpathThumbnailLandscapeImage:
                        fullpathThumbnailLandscapeImage !== undefined
                          ? fullpathThumbnailLandscapeImage
                          : null,
                      fullpathThumbnailPortraitImage:
                        fullpathThumbnailPortraitImage !== undefined
                          ? fullpathThumbnailPortraitImage
                          : null,
                      youTubeSDThumbnailPath:
                        youTubeSDThumbnailPath !== undefined
                          ? youTubeSDThumbnailPath
                          : null,
                      youTubeSDPath:
                        youTubeSDPath !== undefined ? youTubeSDPath : null,
                      type: type !== undefined ? type : null,
                      customMetadataId:
                        customMetadataId !== undefined
                          ? customMetadataId
                          : null,
                      creationDate:
                        creationDate !== undefined ? creationDate : null,
                      fileSize: fileSize !== undefined ? fileSize : 0,
                      dpiAsset: dpiAsset !== undefined ? dpiAsset : null,
                    })
                  );
                  dispatch(toggleChoice(1));
                  dispatch(toggleIsOpen());
                }}
              >
                <img src={ShareIcon} alt="Share" />
              </a>
            </li>
            <li>
              <div className="content-card-separator">&nbsp;</div>
            </li>
            <li>
              <a href="content-card-icon-link" onClick={handleHeartIcon}>
                <img
                  width="18px"
                  src={isFavorite ? fillHeartPic : noFillHeartPic}
                  alt="Favorite"
                />
              </a>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default CatalogCard;
