import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sidebarItemProps } from "../utils/sidebar";

interface SideBarState {
  shareExpirationdate: string | null;
  hash: SideBarHash | null;
}

export interface SideBarHash {
  [details: string]: sidebarItemProps;
}

const initialState: SideBarState = {
  shareExpirationdate: "",
  hash: null,
};

const sideBar = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    shareExpirationDate(state, { payload }: PayloadAction<string | null>) {
      if (payload) {
        state.shareExpirationdate = payload;
      }
    },
    setMainSideBarItems(state, { payload }: PayloadAction<SideBarHash>) {
      if (payload) {
        state.hash = { ...payload };
      }
    },
  },
});

export const { shareExpirationDate, setMainSideBarItems } = sideBar.actions;

export default sideBar.reducer;
