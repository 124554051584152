import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import {
  useNavigate,
  useLocation,
  useMatch,
  Link,
  useParams,
} from "react-router-dom";
import { useAppDispatch } from "../../store";
import { setBreadcrumbState } from "../../components/breadcrumbs/breadcrumbSlice";
import UserProfile from "../../components/account/UserProfile";
import Region from "../../components/account/Region";
import CompanyInformation from "../../components/account/CompanyInformation";
import TermsAndConditions from "../../components/account/TermsAndConditions";
import { IAccountMatchParams } from "../../models/interfaces/common";
import { setInPageNavShow } from "../../components/inPageNavSlice";

interface IAccountPage {
  [key: string]: {
    title: string;
    component: React.ReactElement;
  };
}

export default function AccountPage() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const dispatch = useAppDispatch();
  dispatch(setBreadcrumbState(false));
  dispatch(setInPageNavShow(false));
  const [cookies] = useCookies(["okta-oauth-state"]);

  const { userID, accountPage } = useParams();

  const accountPages: IAccountPage = {
    region: {
      title: t("Region Selection & Time"),
      component: <Region />,
    },
    terms: {
      title: t("Terms & Condition"),
      component: <TermsAndConditions />,
    },
    company: {
      title: t("Company Information"),
      component: <CompanyInformation />,
    },
  };

  if (!cookies["okta-oauth-state"]) {
    accountPages.profile = {
      title: t("Account Information"),
      component: <UserProfile />,
    };
  }

  if (!(accountPage && accountPage in accountPages)) {
    navigate("/account/company");
    return null;
  }

  return (
    <>
      <div className="breadcrumbs d-flex justify-content-start align-items-center">
        <h2 className="mb-0 me-2">{accountPages[accountPage].title}</h2>
      </div>
      <div className="cp container-fluid">
        {accountPages[accountPage].component}
      </div>
    </>
  );
}
