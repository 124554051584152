import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import { ILocale } from "../../models/interfaces/locale";

import { useAppDispatch } from "../../store";
import { saveToken, setAuthState } from "./authSlice";
import { setUser } from "./userSlice";
import { setLocale } from "../localeSlice";
import SecureLS from "../../utils/SecureStorage";

export default function RedirectPage() {
  const { authState, oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const locale: ILocale = {
    region: "USA",
    language: "en_US",
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          oktaAuth.tokenManager.getTokens().then(({ accessToken }) => {
            if (accessToken) {
              dispatch(saveToken(accessToken.accessToken));
              dispatch(setLocale(locale));
              dispatch(setAuthState(true));
              const user = {
                id: info.sub,
                email: info.email,
                username: info.preferred_username,
                firstName: info.given_name,
                lastName: info.family_name,
                token: accessToken.accessToken,
                companyName: "Gildan ActiveWear Inc.",
                companyUrl: "www.gildancorp.com",
                companyType: "335",
                purpose: "I am a Gildan employee",
                country: "BB",
                isConfirmed: true,
                primaryRegion: "76",
                preferredLanguage: "en_US",
              };

              dispatch(setUser(user));
              const currentUser = {
                data: user,
                token: accessToken.accessToken,
                employee: true,
              };
              SecureLS.setItem("user", JSON.stringify(currentUser));
            }
          });
        })
        .then(() => {
          navigate(`/`);
        });
    }
  }, [authState, oktaAuth]);
  return <> </>;
}
