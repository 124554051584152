import React, { useState } from "react";
import { useQuery } from "@apollo/client";
// @ts-ignore
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";
import loadingAnimation from "../../loadingAnimation2.json";
import loadingAnimation2 from "../../loading_spinner_editted_noCanvas2.svg";

import { USER_TERMS_AND_CONDITIONS } from "../../services/graphql/pimcoreDam";
import { RootState } from "../../rootReducer";
// import i18n from "../i18n";

export default function TermsAndConditions() {
  const UserInfoSlice = useSelector((state: RootState) => state.user);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // retrieve the current terms & conditions content
  const currentTermsAndConditions = () => {
    const { loading, error, data } = useQuery(USER_TERMS_AND_CONDITIONS, {
      variables: {
        id: Number(UserInfoSlice?.id),
      },
      fetchPolicy: "network-only",
    });
    if (loading) {
      return (
        <object
          type="image/svg+xml"
          width={1500}
          height={1500}
          data={loadingAnimation2}
        >
          svg-animation
        </object>
      );
    }
    if (error) return [];
    const termsAndConditionsContent = DOMPurify.sanitize(
      data.getUser.termsAndConditions.termsConditionsContent
    );
    return (
      /* eslint-disable react/no-danger */
      <div dangerouslySetInnerHTML={{ __html: termsAndConditionsContent }} />
    );
  };

  return (
    <form action="">
      <div className="row form-row">
        <div className="col-12">{currentTermsAndConditions()}</div>
      </div>
    </form>
  );
}
