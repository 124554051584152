/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { BeatLoader } from "react-spinners";

// Redux
import { useAppDispatch, useAppSelector } from "../store";
import { selectAsset } from "../pages/navBarSlice";
import {
  assetIdNav,
  assetTypeNav,
  assetUrlNav,
} from "../pages/assetSliceNavBar";
import { RootState } from "../rootReducer";
import { setAssets } from "../pages/assetsSlice";
import { initialState, setInPageNavState } from "./inPageNavSlice";

// Interfaces
import {
  AssetInfo,
  DocumentThumbnail,
  VideoThumbnail,
} from "../models/interfaces/common";

// Utils
import {
  getDocumentThumbnails,
  getAssetThumbnailUrl,
  checkIfHasNext,
  getVideoThumbnails,
} from "../utils/catalog";

// Components
import CatalogCard from "./CatalogCard";

// Custom Hooks
import useMultiSelection from "../hooks/useMultiSelection";

// SVG
import loadingAnimation3 from "../loading_spinner_editted_noCanvas5.svg";

interface CatalogCardsProps {
  assetLength: number;
  loading: boolean;
  restApiAssets: AssetInfo[] | null;
  pageNumber: number;
  hasNext: boolean;
  setHasNext: (hasNext: boolean) => void;
  toggleDownloadModal: () => void;
  toggleAssetDetail: () => void;
  toggleManageFavoriteModal: () => void;
  setPageNumber: any;
}

const SmallLoader = () => (
  <div className="loaderContainer">
    <BeatLoader size={10} color="#0C2658" />
  </div>
);

export default function CatalogCards(props: CatalogCardsProps) {
  const {
    assetLength,
    loading,
    restApiAssets,
    pageNumber,
    hasNext,
    setHasNext,
    toggleDownloadModal,
    toggleAssetDetail,
    toggleManageFavoriteModal,
    setPageNumber,
  } = props;

  const dispatch = useAppDispatch();
  const Favorites = useAppSelector(
    (state: RootState) => state.newFavorite.favorite
  );

  const initialSelection: string[] = [];
  const [highlightedAsset, setHighlightedAsset] = useState<AssetInfo>();
  const [videoThumbnails, setVideoThumbnails] = useState<VideoThumbnail>({});
  const [documentThumbnails, setDocumentThumbnails] =
    useState<DocumentThumbnail>({});

  const { setAssetData, handleCardClick, selectedList } = useMultiSelection(
    "getAssetListing",
    "image",
    initialSelection
  );

  const allFavoriteIds: string[] = [];
  Object.values(Favorites).forEach((folder) => {
    folder.assets.forEach((asset) => {
      allFavoriteIds.push(String(asset.id));
    });
  });

  const observer = useRef<IntersectionObserver>();
  const lastCardElementRef = useCallback(
    (node: any) => {
      if (!loading && assetLength) {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (
            entries[0].isIntersecting &&
            checkIfHasNext(assetLength, pageNumber + 1)
          ) {
            setPageNumber((pn: number) => pn + 1);
          }
        });
        if (node) observer.current.observe(node);
      }
    },
    [loading, hasNext]
  );

  useEffect(() => {
    if (selectedList[0] && restApiAssets) {
      const asset = restApiAssets.find(
        (assets: AssetInfo) => assets.id === selectedList[0]
      );
      setHighlightedAsset(asset);
    }

    dispatch(selectAsset(selectedList));
  }, [selectedList]);

  useEffect(() => {
    let navInitialState = {
      ...initialState,
      enableDownload: false,
      enableShare: false,
      enableFavorite: false,
    };
    if (selectedList.length > 0) {
      navInitialState = {
        ...initialState,
        enableDownload: true,
        enableShare: true,
        enableFavorite: true,
      };
    }
    dispatch(setInPageNavState(navInitialState));
  }, [selectedList]);

  useEffect(() => {
    if (highlightedAsset) {
      const filename: string = highlightedAsset.filename.toString();
      const ext =
        filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
        filename;
      const Url = // eslint-disable-next-line no-nested-ternary
        highlightedAsset.type !== "video"
          ? highlightedAsset.type === "document" && ext.toUpperCase() === "PDF"
            ? documentThumbnails[highlightedAsset.id]
            : getAssetThumbnailUrl(highlightedAsset)
          : highlightedAsset.youTubeSDThumbnailPath;
      dispatch(assetIdNav(highlightedAsset.id as string));
      dispatch(assetUrlNav(Url));
      dispatch(assetTypeNav(highlightedAsset.type as string));
    }
  }, [highlightedAsset]);

  useEffect(() => {
    if (restApiAssets) {
      setAssetData(restApiAssets);
      dispatch(setAssets(restApiAssets));
      const videoAssetIds = restApiAssets
        .filter((searchListItem) => searchListItem.type.toString() === "video")
        .map((item) => item.id);
      getVideoThumbnails(videoAssetIds.toString(), setVideoThumbnails);
      const documentAssetIds = restApiAssets
        .filter(
          (searchListItem) => searchListItem.type.toString() === "document"
        )
        .map((item) => item.id);
      getDocumentThumbnails(documentAssetIds.toString(), setDocumentThumbnails);
    }
  }, [restApiAssets]);

  useEffect(() => {
    if (assetLength) setHasNext(checkIfHasNext(assetLength, pageNumber + 1));
  }, [assetLength, pageNumber]);

  return (
    <>
      {restApiAssets &&
        restApiAssets.map((asset: AssetInfo, assetIndex: number) => {
          const additionalClasses = selectedList.includes(asset.id)
            ? "selected"
            : "";
          const filename: string = asset.filename.toString();
          let fullPath = asset.fullpath;
          if (asset.fullpath.slice(0, 5) !== "https") {
            fullPath = `https://gildan.blob.core.windows.net/gildan/assets${asset.fullpath}${asset.filename}`;
          }
          const ext =
            filename.substring(
              filename.lastIndexOf(".") + 1,
              filename.length
            ) || filename;
          if (restApiAssets.length === assetIndex + 1) {
            return (
              <div
                className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-12"
                key={`${asset.id}-${asset.filename}`}
                ref={lastCardElementRef}
              >
                <CatalogCard
                  id={asset.id}
                  title={asset.filename}
                  downloadLink={fullPath}
                  shareLink={fullPath}
                  src={
                    // eslint-disable-next-line no-nested-ternary
                    asset.type !== "video"
                      ? asset.type === "document" && ext.toUpperCase() === "PDF"
                        ? documentThumbnails[asset.id]
                        : getAssetThumbnailUrl(asset)
                      : asset.youTubeSDThumbnailPath
                  }
                  type={asset.type}
                  filename={asset.filename}
                  fullpath={fullPath}
                  fullpathExtandFont={asset.fullpathExtandFont}
                  fullpathExtandLandscapeImage={
                    asset.fullpathExtandLandscapeImage
                  }
                  fullpathExtandPortraitImage={
                    asset.fullpathExtandPortraitImage
                  }
                  fullpathThumbnailFont={asset.fullpathThumbnailFont}
                  fullpathThumbnailImageDownload={
                    asset.fullpathThumbnailImageDownload
                  }
                  fullpathThumbnailLandscapeImage={
                    asset.fullpathThumbnailLandscapeImage
                  }
                  fullpathThumbnailPortraitImage={
                    asset.fullpathThumbnailPortraitImage
                  }
                  youTubeSDThumbnailPath={asset.youTubeSDThumbnailPath}
                  youTubeSDPath={asset.youTubeSDPath}
                  customMetadataId={asset.customMetadataId}
                  creationDate={asset.creationDate}
                  fileSize={asset.fileSize}
                  additionalClasses={additionalClasses}
                  selectCallback={handleCardClick}
                  toggleAssetDownloadModal={toggleDownloadModal}
                  toggleAssetDetailModal={toggleAssetDetail}
                  toggleAssetFavoriteModal={toggleManageFavoriteModal}
                  isFavorite={allFavoriteIds.includes(String(asset.id))}
                  isNew={asset.assetTag === 1}
                  dpiAsset={asset.dpiAsset}
                />
              </div>
            );
          }
          return (
            <div
              className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-12"
              key={`${asset.id}-${asset.filename}`}
            >
              <CatalogCard
                id={asset.id}
                title={asset.filename}
                downloadLink={fullPath}
                shareLink={fullPath}
                src={
                  // eslint-disable-next-line no-nested-ternary
                  asset.type !== "video"
                    ? asset.type === "document" && ext.toUpperCase() === "PDF"
                      ? documentThumbnails[asset.id]
                      : getAssetThumbnailUrl(asset)
                    : asset.youTubeSDThumbnailPath
                }
                type={asset.type}
                filename={asset.filename}
                fullpath={fullPath}
                fullpathExtandFont={asset.fullpathExtandFont}
                fullpathExtandLandscapeImage={
                  asset.fullpathExtandLandscapeImage
                }
                fullpathExtandPortraitImage={asset.fullpathExtandPortraitImage}
                fullpathThumbnailFont={asset.fullpathThumbnailFont}
                fullpathThumbnailImageDownload={
                  asset.fullpathThumbnailImageDownload
                }
                fullpathThumbnailLandscapeImage={
                  asset.fullpathThumbnailLandscapeImage
                }
                fullpathThumbnailPortraitImage={
                  asset.fullpathThumbnailPortraitImage
                }
                youTubeSDThumbnailPath={asset.youTubeSDThumbnailPath}
                youTubeSDPath={asset.youTubeSDPath}
                customMetadataId={asset.customMetadataId}
                creationDate={asset.creationDate}
                fileSize={asset.fileSize}
                additionalClasses={additionalClasses}
                selectCallback={handleCardClick}
                toggleAssetDownloadModal={toggleDownloadModal}
                toggleAssetDetailModal={toggleAssetDetail}
                toggleAssetFavoriteModal={toggleManageFavoriteModal}
                isFavorite={allFavoriteIds.includes(String(asset.id))}
                isNew={asset.assetTag === 1}
                dpiAsset={asset.dpiAsset}
              />
            </div>
          );
        })}
      {loading && pageNumber > 1 && <SmallLoader />}
    </>
  );
}
