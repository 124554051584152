import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import {
  useNavigate,
  useLocation,
  useMatch,
  Link,
  useParams,
} from "react-router-dom";
import { useAppDispatch } from "../../store";
import { setBreadcrumbState } from "../../components/breadcrumbs/breadcrumbSlice";
import { IHowToMatchParams } from "../../models/interfaces/common";
import { setInPageNavShow } from "../../components/inPageNavSlice";
import HowToRegister from "../../components/howto/HowToRegister";
import HowToSearch from "../../components/howto/HowToSearch";
import HowToFavorites from "../../components/howto/HowToFavorites";
import HowToDownload from "../../components/howto/HowToDownload";
import HowToEditDownload from "../../components/howto/HowToEditDownload";
import HowToShare from "../../components/howto/HowToShare";
import HowToResetPassword from "../../components/howto/HowToResetPassword";

interface IHowToPage {
  [key: string]: {
    title: string;
    component: React.ReactElement;
  };
}

export default function HowToPage() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const dispatch = useAppDispatch();
  dispatch(setBreadcrumbState(false));
  dispatch(setInPageNavShow(false));

  const { userID, howToPage } = useParams();

  const howToPages: IHowToPage = {
    register: {
      title: t("How To Register?"),
      component: <HowToRegister />,
    },
    search: {
      title: t("How To Search?"),
      component: <HowToSearch />,
    },
    favorites: {
      title: t("How To Add Favorites?"),
      component: <HowToFavorites />,
    },
    download: {
      title: t("How To Download?"),
      component: <HowToDownload />,
    },
    editDownload: {
      title: t("How To Edit Download Presets?"),
      component: <HowToEditDownload />,
    },
    share: {
      title: t("How To Share?"),
      component: <HowToShare />,
    },
    resetPassword: {
      title: t("How To Reset Password?"),
      component: <HowToResetPassword />,
    },
  };

  if (!(howToPage && howToPage in howToPages)) {
    navigate("/howto/register");
    return null;
  }

  return (
    <>
      <div className="breadcrumbs d-flex justify-content-start align-items-center">
        <h2 className="mb-0 me-2">{howToPages[howToPage].title}</h2>
      </div>
      <div className="cp container-fluid">
        {howToPages[howToPage].component}
      </div>
    </>
  );
}
