import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isExpired, decodeToken } from "react-jwt";
import Popup from "reactjs-popup";
import * as crypto from "crypto-js";
import "reactjs-popup/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { useOktaAuth } from "@okta/okta-react";
import { UserClaims } from "@okta/okta-auth-js";
import Swal from "sweetalert2";
import { RootState } from "../../rootReducer";
import LoginSchema from "./Login.schema";
import { ILoginForm } from "../../models/interfaces/login";
import { ILocale } from "../../models/interfaces/locale";
import { useAppDispatch } from "../../store";
import http from "../../services/api";
import { AuthResponse } from "../../services/mirage/routes/user";
import { saveToken, setAuthState } from "./authSlice";
import { setUser } from "./userSlice";
import { setLocale } from "../localeSlice";
import SecureLS from "../../utils/SecureStorage";
import EmailConfirmModal from "../../components/modals/EmailConfirmModal";
import emailConfirmSlice, { toggleOpenEmail } from "../emailConfirmSlice";
import { setOktaId } from "./idForOktaSlice";
import { AuthCheckResponse } from "../../components/layouts/MainLayout";
import "bootstrap-icons/font/bootstrap-icons.css";
import Tooltip from "../../components/tooltip/Tooltip";
// import { setCookie, getCookie } from "../../utils/set-cookie";

export default function Login() {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserClaims | null>(null);
  const OktaId = useSelector((state: RootState) => state.okta);
  const navigate = useNavigate();
  // navigate("/maintenance");
  const dispatch = useAppDispatch();
  const toggleEmailModal = () => dispatch(toggleOpenEmail());
  const secureLS = SecureLS.getItem("user");
  const [showEmailConfirmNotification, setShowEmailConfirmNotification] =
    useState(false);
  const dataValue = secureLS ? JSON.parse(secureLS) : null;
  const [cookies, setCookie, removeCookie] = useCookies([
    "rememberMeEmail",
    "rememberMePassword",
  ]);
  const cookieMaxDay = 7;
  const maxRestrict = "Pimcore-Year-Gildan!";
  // redirect user to main page if already authenticated
  interface apiResponse {
    code: number;
    message: string;
  }

  const initialValues: ILoginForm = {
    username: "",
    email: "",
    password: "",
    rememberMe: !!cookies.rememberMePassword,
  };

  const locale: ILocale = {
    region: "USA",
    language: "en_US",
  };

  const toggleEmailConfirmNotification = () =>
    setShowEmailConfirmNotification(!showEmailConfirmNotification);

  const createRememberMeCookies = (email: string, password: string) => {
    setCookie("rememberMeEmail", email, {
      expires: new Date(
        new Date().setTime(
          new Date().getTime() + cookieMaxDay * 24 * 60 * 60 * 1000
        )
      ),
      // domain: "/",
      // secure: true,
      // sameSite: "lax",
    });
    setCookie("rememberMePassword", password, {
      expires: new Date(
        new Date().setTime(
          new Date().getTime() + cookieMaxDay * 24 * 60 * 60 * 1000
        )
      ),
      // domain: "/",
      // secure: true,
      // sameSite: "lax",
    });
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
        oktaAuth.tokenManager.getTokens().then(({ accessToken }) => {
          if (accessToken) {
            dispatch(saveToken(accessToken.accessToken));
            dispatch(setLocale(locale));
            dispatch(setAuthState(true));
            const user = {
              id: info.sub,
              email: info.email,
              username: info.preferred_username,
              firstName: info.given_name,
              lastName: info.family_name,
              token: accessToken.accessToken,
              companyName: "Gildan ActiveWear Inc.",
              companyUrl: "www.gildancorp.com",
              companyType: "335",
              purpose: "I am a Gildan employee",
              country: "BB",
              isConfirmed: true,
              primaryRegion: "76",
              preferredLanguage: "en_US",
            };

            dispatch(setUser(user));
            const currentUser = {
              data: user,
              token: accessToken.accessToken,
              employee: true,
            };
            SecureLS.setItem("user", JSON.stringify(currentUser));
          }
        });
        navigate(`/`, { state: initialValues });
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const [isFailedLogin, setIsFailedLogin] = useState(false);

  const handleLogin = (
    data: ILoginForm,
    _setSubmitting: any,
    isEmployee: boolean
  ) => {
    if (isEmployee) {
      oktaAuth.signInWithRedirect({ originalUri: "/oktaredirect" });
    } else {
      setIsFailedLogin(false);
      const path = "/api/login";

      // extracting the username from the first part of the email
      const email = JSON.stringify(data.email);
      const nameMatch = String(email.split("@")[0]);
      data.username = nameMatch.replace('"', "");

      http
        .post<ILoginForm, AuthResponse>(path, data)
        .then((res) => {
          if (res) {
            const { user, token } = res;
            // get user's data
            // console.log("response :", res);
            locale.region = user.primaryRegion;
            locale.language = user.preferredLanguage;
            const encryptCaller = crypto.AES.encrypt(
              data.email,
              maxRestrict
            ).toString();
            const encryptCallerData = crypto.AES.encrypt(
              data.password,
              maxRestrict
            ).toString();
            dispatch(saveToken(token));
            dispatch(setUser(user));
            dispatch(setLocale(locale));
            dispatch(setAuthState(true));
            const currentUser = {
              data: user,
              token,
              employee: false,
            };
            SecureLS.setItem("user", JSON.stringify(currentUser));
            if (data.rememberMe === true && !cookies.rememberMePassword) {
              createRememberMeCookies(encryptCaller, encryptCallerData);
            }
            // if the email is not confirmed yet
            if (!res.user.isConfirmed) {
              setIsFailedLogin(true);
              toggleEmailConfirmNotification();

              // Swal.fire({
              //   position: "top-end",
              //   background: "#00ba4d",
              //   html: `<h6 style=color:white><br>Please confirm your email</h6>`,
              //   width: 300,
              //   showConfirmButton: false,
              //   timer: 5000,
              //   heightAuto: false,
              // });
            } else {
              navigate(`/`, { state: data });
            }
          } else {
            setIsFailedLogin(true);
          }
        })
        .catch((error) => {
          _setSubmitting(false);
          setIsFailedLogin(true);
        })
        .finally(() => {
          _setSubmitting(false);
        });
    }
  };

  const LoginForm = () => {
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
      // Check for autofill changes
      const passwordInput = document.getElementById("password");
      if (passwordInput) {
        passwordInput.addEventListener("input", () => {
          passwordInput.dispatchEvent(new Event("change", { bubbles: true }));
        });
      }
    }, []);

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={(
          values: ILoginForm,
          formikHelpers: FormikHelpers<ILoginForm>
        ) => {
          handleLogin(values, formikHelpers.setSubmitting, false);
        }}
      >
        {(props: FormikProps<ILoginForm>) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isSubmitting,
          } = props;

          return (
            <Form>
              {/* {isFailedLogin && (
                <div className="mb-2">
                  <p className="text-danger">
                    Wrong credentials or confirm your email!
                  </p>
                </div>
              )} */}
              <div className="mb-4">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    touched.email && errors.email ? "is-invalid" : ""
                  }`}
                  id="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {touched.email && errors.email ? (
                  <div className="text-danger mt-1 fs-13 fw-bold">
                    {errors.email}
                  </div>
                ) : null}
              </div>
              <div className="mb-2">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                    id="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <Tooltip
                    content={showPassword ? "Hide Password" : "Show Password"}
                    direction="bottom"
                    delay={0}
                  >
                    <button
                      type="button"
                      className="btn showHide btn-outline-secondary"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <i className="bi bi-eye-slash" />
                      ) : (
                        <i className="bi bi-eye" />
                      )}
                    </button>
                  </Tooltip>
                </div>
                {touched.password && errors.password ? (
                  <div className="text-danger mt-1 fs-13 fw-bold">
                    {errors.password}
                  </div>
                ) : null}
                {isFailedLogin && (
                  <div className="text-danger mt-1 fs-13 fw-bold">
                    Invalid email address or password
                  </div>
                )}
              </div>
              <div className="form-check mb-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.rememberMe}
                />
                <label className="form-check-label" htmlFor="rememberMe">
                  Remember Me
                </label>
                <Link
                  to="/reset-password"
                  className="float-end forgot-password-link fs-13 pt-1"
                >
                  Forgot your password?
                </Link>
              </div>
              <div className="mb-3">
                <button
                  className="btn btn-lg btn-blue"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Login
                </button>
              </div>
              <div className="mb-4">
                <p className="fs-13">
                  Don’t have an account?{" "}
                  <Link to="/register">Register here</Link>
                  {" | "}
                  <Link to="/howto/register">How to Register?</Link>
                </p>
              </div>
              <div className="mb-4 position-relative d-flex justify-content-center align-items-center or-section">
                <div className="or-line">&nbsp;</div>
                <div className="or-text">or</div>
              </div>
              <div className="mb-0 mt-4">
                <button
                  className="btn btn-lg btn-turq"
                  type="button"
                  onClick={() => handleLogin(initialValues, null, true)}
                >
                  Gildan Employee Sign-In
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <div className="registration">
        <div className="container-fluid">
          <div className="row">
            <div className="col-left col-md-5 col-12">
              <h2>
                Welcome to the
                <br className="d-none d-lg-block" /> Gildan Digital Asset
                Portal.
              </h2>
              <p>
                Our revamped Digital Asset Portal, offers a new and improved
                experience.
              </p>
              <p>
                This user-friendly interface makes it easy to search, save,
                share, and download any asset you need.
              </p>
              <p>
                Our swatches, studio, lifestyle, web images and more are just a
                click away!
              </p>
            </div>
            <div className="col-right col-md-7 col-12">
              <LoginForm />
            </div>
          </div>
        </div>
      </div>

      <EmailConfirmModal
        isOpen={showEmailConfirmNotification}
        toggle={toggleEmailConfirmNotification}
        modalClass="confirmationModal"
        centered
        backdrop="static"
        keyboard
      />
    </>
  );
}
