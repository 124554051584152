import { getIn, FormikProps, FormikValues } from "formik";

export const getInputProps = (
  name: string,
  formik: FormikProps<FormikValues>,
  classes: string = "form-control",
  isDropdown = false
) => {
  const value: any = getIn(formik.values, name);
  const error = getIn(formik.errors, name) && getIn(formik.touched, name);
  let options = {};
  if (isDropdown) {
    options = {
      onChange: (opt: any, e: any) => {
        formik.setFieldValue(name, opt);
      },
      onBlur: formik.setFieldTouched,
    };
  } else {
    options = {
      onChange: formik.handleChange,
      onBlur: formik.handleBlur,
    };
  }

  const props = {
    id: name,
    name,
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    value: value || "",
    className: `${classes} ${
      formik.touched[name] && formik.errors[name] ? "is-invalid" : ""
    }`,
    error,
    ...options,
    // helperText: getIn(formik.errors, name)
  };

  return props;
};

export const test = "test";
