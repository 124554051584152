/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CHANGE_USER_INFO = gql`
  mutation ($id: Int!, $userInfo: UpdateUserInput) {
    updateUser(id: $id, input: $userInfo) {
      ... on UpdateUserResult {
        success
        message
      }
    }
  }
`;
