/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import useFavorites from "../hooks/useFavorites";
import { useAppDispatch, useAppSelector } from "../store";
import { RootState } from "../rootReducer";
import CheckIcon from "../assets/img/check.svg";
import { clearAllPossibleMultiSelectionState } from "../hooks/useMultiSelectionSlice";
import { emptyExcludedAssets } from "../pages/assetExclusionSlice";
import { setCurrentFolderId } from "../pages/newFavoriteSlice";
import { scrollToTop } from "../util";

interface getUser {
  getUser: collectionFolderFavAsset[];
}
interface collectionFolderFavAsset {
  name: string; // folder name
  assetsListFav: assetListFav[]; // folder.length
}
interface assetListFav {
  id: string;
  filename: string;
  fullpath: string;
  type: string;
  filesize: number;
  creationDate: number;
}

interface PropValueForFolder {
  selectedFolder: string | null;
}

// retrieve a list of favorite asset folder from the server
// define interface
// retrieve list of collection
export const FavoriteFolder = ({ selectedFolder }: PropValueForFolder) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation("common");
  // const FavoriteSlice = useSelector((state: RootState) => state.favorite);
  // const { getFavorites, favoriteList, data, error, loading } = useFavorites();

  const Favorites = useAppSelector((state) => state.newFavorite.favorite);
  const currentFolderId = useAppSelector(
    (state) => state.newFavorite.currentFolderId
  );
  const defaultFolderId = useAppSelector(
    (state) => state.newFavorite.defaultFolderId
  );

  // const emptyExcludeAsset = () => dispatch(emptyExcludedAssets());
  const setCurrentFolderById = (id: string) => dispatch(setCurrentFolderId(id));

  // retrieve data from session storage to populate the userprofile information

  const FavFolderList = () => (
    <>
      {Object.keys(Favorites).map((key) => (
        <li key={`${Favorites[key].name}`}>
          <Link
            onClick={() => {
              setCurrentFolderById(key);
              scrollToTop();
            }}
            to={{
              pathname: "/favorites",
              search: `?favoriteFolder=${encodeURIComponent(
                Favorites[key].name
              )}`,
            }}
            className={`category-link ${
              currentFolderId === key ? "active" : ""
            }`}
          >
            <div className="text">{t(Favorites[key].name)}</div>
            <div className="pill">{Favorites[key].assets.length}</div>
          </Link>
        </li>
      ))}
    </>
  );

  return <FavFolderList />;
};
