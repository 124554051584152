import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NavState {
  assets: string[];
}

const initialState: NavState = {
  assets: [],
};

const nav = createSlice({
  name: "share",
  initialState,
  reducers: {
    selectAsset(state, { payload }: PayloadAction<string[]>) {
      if (payload) {
        state.assets = payload;
      }
    },
  },
});

export const { selectAsset } = nav.actions;

export default nav.reducer;
