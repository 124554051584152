import { ApolloClient, InMemoryCache } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/pimcore-graphql-webservices/Initial?apikey=${process.env.REACT_APP_API_KEY}`,

  cache: new InMemoryCache({
    typePolicies: {
      asset_metadata_item: {
        keyFields: ["name", "data"],
      },
    },
  }),
});
