/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import { isExpired, decodeToken } from "react-jwt";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import {
  useNavigate,
  useLocation,
  useMatch,
  Link,
  useParams,
} from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
// import http from "../services/api";
import { isNullableType } from "graphql";
import {
  ASSETS_CATEGORIES,
  FAV_COLLECTION_LIST,
} from "../services/graphql/pimcoreDam";
// import { IAssetCard } from "../models/interfaces/common";
import BackArrow from "../assets/img/back-arrow.svg";
import FolderCreate from "../assets/img/folder-add.svg";
import {
  sidebarList,
  getType,
  getValues,
  sidebarItemProps,
} from "../utils/sidebar";
import { GraphqlApi } from "../models/interfaces/graphApi";
import AccountPage from "../pages/account/Account";
import {
  CatalogInterface,
  FullMetadataValues,
  IAccountMatchParams,
  IHowToMatchParams,
  SideBarDataInfo,
  SideBarResponse,
} from "../models/interfaces/common";
import { useAppSelector, useAppDispatch } from "../store";
import { client } from "../services/ApolloClient/pimcoreData";
import http from "../services/api";
import { FavoriteFolder } from "./SidebarFavoriteFolders";
import { CHANGE_USER_INFO } from "../services/graphql/migrations";
import { RootState } from "../rootReducer";
import { updateCreateFavFolder } from "../pages/favoriteSlice";
import ManageFavoriteModal from "./modals/ManageFavoriteModal";
import { setMainSideBarItems } from "../pages/sidebarSlice";
import SecureLS from "../utils/SecureStorage";
import { setUser } from "../pages/auth/userSlice";
import { saveToken, setAuthState } from "../pages/auth/authSlice";
import { removeAllFilters } from "../pages/filterSlice";
import { setCatalogs } from "../pages/catalogSlice";
import { getCatalogs } from "../utils/catalog";
import { scrollToTop } from "../util";
import { setAssetIdList } from "../pages/assetsSlice";
import { removeSelectedFilters } from "../pages/filterOptionsSlice";
import { removeProcesses } from "../pages/multipleAssetDownloadSlice";

interface CategoryDataInterface {
  name: String;
  count: number;
}

interface SideBarHash {
  [details: string]: sidebarItemProps;
}

export default function Sidebar() {
  const { t, i18n } = useTranslation("common");
  const dispatch = useAppDispatch();
  const [showManageFavorite, setShowManageFavorite] = useState(false);
  const { accountPage } = useParams();
  const { howToPage } = useParams();
  const [selectedFolder, setSelectedFolder] = useState<string | null>(
    "My Favorites"
  );

  const primaryRegion = useAppSelector(
    (state) => state.user?.primaryRegion as string
  );

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  // const MainSideBarReference = [
  //   "Imagery",
  //   "Videos",
  //   "Logos & Guidelines",
  //   "Swatchcards & Catalogs",
  //   "Social Media",
  //   "Email Ads",
  //   "Web Banners",
  //   "Ads & Sell Sheets",
  //   "Posters & Signage",
  //   "Hangtags",
  // ];

  const [mainSideBarRender, setMainSideBarRender] = useState<
    sidebarItemProps[]
  >([]);
  const navigate = useNavigate();
  const location = useLocation();
  const isSharedPage = useMatch("/shared/:token");
  const isFavoritesPage = useMatch("/favorites");
  const isAccountPage = useMatch("/account/:accountPage");
  const isHowToPage = useMatch("/howto/:howToPage");
  const isDownloadsPage = useMatch("/downloads");
  const userId = useAppSelector((state) => state.user?.id) as string;
  const [cookies] = useCookies(["okta-oauth-state"]);
  const mainSideBar = useAppSelector((state) => state.sidebar.hash);
  const shareExpirationdate = useAppSelector(
    (state) => state.sidebar.shareExpirationdate
  );
  const catalogs = useAppSelector((state) => state.catalog.catalogs);

  const OktaValidation = cookies["okta-oauth-state"] === undefined;

  const insertCatalogs = (value: CatalogInterface[]) =>
    dispatch(setCatalogs(value));

  const toggleManageFavoriteModal = () =>
    setShowManageFavorite(!showManageFavorite);

  const apiCall = useCallback(
    (regionId: string, isCancelled: (arg0: any) => boolean) => {
      const path = "/api/categories";

      if (userId) {
        http
          .post<SideBarResponse>(path, {
            cancelToken: source.token,
            regionId,
            userId,
          })
          .then((res) => {
            const response = res
              ? (res?.data as unknown as SideBarDataInfo[])
              : [];
            const hashTemp: SideBarHash = {};
            for (let i = 0; i < response.length; i++) {
              const itemValues = getValues(response[i].brandPortalCategoryName);
              const temp: sidebarItemProps = {
                text: response[i].brandPortalCategoryName,
                count: Number(response[i].assetsCount),
                path: itemValues ? itemValues.path : "",
                size: response[i].categoryAssetsSize / 1024 ** 3,
              };
              if (!hashTemp[temp.text]) hashTemp[temp.text] = temp;
            }
            dispatch(setMainSideBarItems(hashTemp));
          })
          .catch((error) => {});
      }
    },
    [userId]
  );

  useEffect(() => {
    if (!(catalogs.length > 0)) getCatalogs(insertCatalogs, source);

    return () => {
      source.cancel("");
    };
  }, []);

  useEffect(() => {
    const folderName = new URLSearchParams(location.search).get(
      "favoriteFolder"
    );
    if (folderName) setSelectedFolder(decodeURIComponent(folderName));
  });

  useEffect(() => {
    const secureData = SecureLS.getItem("user")
      ? JSON.parse(SecureLS.getItem("user"))
      : {};
    if (secureData?.data) dispatch(setUser(secureData.data));
    if (secureData?.token) {
      dispatch(saveToken(secureData.token));
      dispatch(setAuthState(true));
    }
  }, []);

  useEffect(() => {
    const tempArray: sidebarItemProps[] = [];
    for (let i = 0; i < catalogs.length; i++) {
      if (mainSideBar) tempArray.push(mainSideBar[catalogs[i].catalog]);
    }
    setMainSideBarRender([...tempArray]);
  }, [mainSideBar, catalogs]);

  useEffect(() => {
    const secureLS = SecureLS.getItem("user");
    const dataValue = secureLS ? JSON.parse(secureLS) : null;
    if (dataValue)
      apiCall(primaryRegion, (value: any) => axios.isCancel(value));

    return () => {
      source.cancel("Component was destroyed and unmounted!!!");
    };
  }, [primaryRegion, userId]);

  interface BrandValues {
    node: SampleNode;
  }

  interface SampleNode {
    id: number;
    name: string;
  }

  const removeFilters = () => dispatch(removeAllFilters());
  const removeActiveFilters = () => dispatch(removeSelectedFilters());
  const setAssetIds = (ids: string[]) => dispatch(setAssetIdList(ids));

  const MainDashboardSidebarItems = React.memo(() => (
    <>
      {mainSideBarRender.map((sidebaritem) =>
        sidebaritem?.count > 0 ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <li
            key={`${sidebaritem?.text}-${sidebaritem?.count}-${sidebaritem?.path}`}
            onClick={() => {
              removeFilters();
              removeActiveFilters();
              setAssetIds([]);
            }}
          >
            <Link
              to={sidebaritem?.path}
              className={`category-link ${
                location.pathname === sidebaritem?.path ? "active" : ""
              }`}
              onClick={() => {
                scrollToTop();
              }}
            >
              <div className="text">{t(sidebaritem?.text)}</div>
              <div className="pill">{sidebaritem?.count}</div>
            </Link>
          </li>
        ) : null
      )}
    </>
  ));

  const FavoriteSidebarContent = React.memo(() => (
    <>
      <a
        className="library-return-link"
        href="#asd"
        onClick={(event) => {
          event.preventDefault();
          navigate("/");
          scrollToTop();
        }}
      >
        <img className="me-2" src={BackArrow} alt="Return to Asset Library" />
        {t("Return to Asset Library")}
      </a>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="mb-0">{t("Favorites")}</h2>
        <a
          className="Folder-create"
          href="#asd"
          onClick={(event) => {
            event.preventDefault();
            toggleManageFavoriteModal();
          }}
        >
          <img src={FolderCreate} alt="Create Folder" />
        </a>
      </div>
      <div className="ps-4 d-block">
        <hr />
      </div>

      <ul className="category-nav">
        <FavoriteFolder selectedFolder={selectedFolder} />
      </ul>
    </>
  ));

  const SharedSidebarContent = React.memo(() => {
    const [expirationDate, setExpirationDate] = useState<string>("");
    const secureLS = SecureLS.getItem("user");
    const dataValue = secureLS ? JSON.parse(secureLS) : null;

    useEffect(() => {
      const mydate = new Date(shareExpirationdate as string);
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ][mydate.getMonth()];

      setExpirationDate(
        month && mydate
          ? `${month} ${
              shareExpirationdate
                ? (shareExpirationdate as string).split("-")[2]
                : ""
            }, ${mydate.getFullYear()}`
          : ""
      );
    }, []);

    return (
      <>
        {(dataValue || !isExpired(dataValue?.token)) && (
          <a
            className="library-return-link"
            href="#asd"
            onClick={(event) => {
              event.preventDefault();
              navigate("/");
            }}
          >
            <img
              className="me-2"
              src={BackArrow}
              alt="Return to Asset Library"
            />
            {t("Return to Asset Library")}
          </a>
        )}
        <h2>{t("Shared Items")}</h2>
        <div className="ps-4 d-block">
          <hr />
        </div>
        <div className="files-shared">
          <p>{`${t("These files are shared until")} ${expirationDate}`}</p>
        </div>
      </>
    );
  });

  const UserSidebarContent = React.memo(() => (
    <>
      <a
        className="library-return-link"
        href="#asd"
        onClick={(event) => {
          event.preventDefault();
          navigate("/");
        }}
      >
        <img className="me-2" src={BackArrow} alt="Return to Asset Library" />
        {t("Return to Asset Library")}
      </a>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="mb-0">{t("My Account")}</h2>
      </div>
      <div className="ps-4 d-block">
        <hr />
      </div>

      <ul className="category-nav">
        {OktaValidation && (
          <li>
            <Link
              to="/account/profile"
              className={`category-link ${
                accountPage === "profile" ? "active" : ""
              }`}
            >
              <div className="text">{t("User Profile")}</div>
            </Link>
          </li>
        )}
        <li>
          <Link
            to="/account/company"
            className={`category-link ${
              accountPage === "company" ? "active" : ""
            }`}
          >
            <div className="text">{t("Company Information")}</div>
          </Link>
        </li>
        <li>
          <Link
            to="/account/region"
            className={`category-link ${
              accountPage === "region" ? "active" : ""
            }`}
          >
            <div className="text">{t("Region Selection & Time")}</div>
          </Link>
        </li>
        <li>
          <Link
            to="/account/terms"
            className={`category-link ${
              accountPage === "terms" ? "active" : ""
            }`}
          >
            <div className="text">{t("Terms and Conditions")}</div>
          </Link>
        </li>
      </ul>
    </>
  ));

  const HowToSidebarContent = React.memo(() => {
    const secureLS = SecureLS.getItem("user");
    const dataValue = secureLS ? JSON.parse(secureLS) : null;
    return (
      <>
        {(dataValue || !isExpired(dataValue?.token)) && (
          <a
            className="library-return-link"
            href="#asd"
            onClick={(event) => {
              event.preventDefault();
              navigate("/");
            }}
          >
            <img
              className="me-2"
              src={BackArrow}
              alt="Return to Asset Library"
            />
            {t("Return to Asset Library")}
          </a>
        )}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="mb-0">{t("HowTo")}</h2>
        </div>
        <div className="ps-4 d-block">
          <hr />
        </div>

        <ul className="category-nav">
          <li>
            <Link
              to="/howto/register"
              className={`category-link ${
                howToPage === "register" ? "active" : ""
              }`}
              onClick={() => scrollToTop()}
            >
              <div className="text">{t("Register")}</div>
            </Link>
          </li>
          <li>
            <Link
              to="/howto/search"
              className={`category-link ${
                howToPage === "search" ? "active" : ""
              }`}
              onClick={() => scrollToTop()}
            >
              <div className="text">{t("Search")}</div>
            </Link>
          </li>
          <li>
            <Link
              to="/howto/favorites"
              className={`category-link ${
                howToPage === "favorites" ? "active" : ""
              }`}
              onClick={() => scrollToTop()}
            >
              <div className="text">{t("Add Favorites")}</div>
            </Link>
          </li>
          <li>
            <Link
              to="/howto/download"
              className={`category-link ${
                howToPage === "download" ? "active" : ""
              }`}
              onClick={() => scrollToTop()}
            >
              <div className="text">{t("Download")}</div>
            </Link>
          </li>
          <li>
            <Link
              to="/howto/editDownload"
              className={`category-link ${
                howToPage === "editDownload" ? "active" : ""
              }`}
              onClick={() => scrollToTop()}
            >
              <div className="text">{t("Edit Download Presets")}</div>
            </Link>
          </li>
          <li>
            <Link
              to="/howto/share"
              className={`category-link ${
                howToPage === "share" ? "active" : ""
              }`}
              onClick={() => scrollToTop()}
            >
              <div className="text">{t("Share")}</div>
            </Link>
          </li>
          <li>
            <Link
              to="/howto/resetPassword"
              className={`category-link ${
                howToPage === "resetPassword" ? "active" : ""
              }`}
              onClick={() => scrollToTop()}
            >
              <div className="text">{t("Reset Password")}</div>
            </Link>
          </li>
        </ul>
      </>
    );
  });

  const DownloadsSidebarContent = React.memo(() => (
    <>
      <a
        className="library-return-link"
        href="#asd"
        onClick={(event) => {
          event.preventDefault();
          navigate("/");
          dispatch(removeProcesses());
        }}
      >
        <img className="me-2" src={BackArrow} alt="Return to Asset Library" />
        {t("Return to Asset Library")}
      </a>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="mb-0">{t("Downloads")}</h2>
      </div>
      <div className="ps-4 d-block">
        <hr />
      </div>
    </>
  ));

  const MainDashboardSidebar = () => (
    <>
      <h2>{t("Asset Library")}</h2>
      <div className="ps-4">
        <hr />
      </div>
      <ul className="category-nav">
        <MainDashboardSidebarItems />
      </ul>
    </>
  );

  const SidebarContent = () => {
    if (isFavoritesPage) {
      return <FavoriteSidebarContent />;
    }
    if (isSharedPage) {
      return <SharedSidebarContent />;
    }
    if (isAccountPage) {
      return <UserSidebarContent />;
    }
    if (isHowToPage) {
      return <HowToSidebarContent />;
    }
    if (isDownloadsPage) {
      return <DownloadsSidebarContent />;
    }
    return <MainDashboardSidebar />;
  };

  return (
    <aside>
      <SidebarContent />
      <ManageFavoriteModal
        isOpen={showManageFavorite}
        toggle={toggleManageFavoriteModal}
        modalClass="favoritesModal"
        centered
        backdrop="static"
        keyboard
        isAddToFavorite={false}
      />
    </aside>
  );
}
