// Types
import { Step } from "../../types/registration";
// Components & schema
import UserProfileRegistration from "./userProfile/UserProfile";
import UserProfileRegistrationSchema from "./userProfile/UserProfile.schema";
import CompanyInformationRegistration from "./companyInformation/CompanyInformation";
import CompanyInformationRegistrationSchema from "./companyInformation/CompanyInformation.schema";
import RegionRegistration from "./region/Region";
import RegionRegistrationSchema from "./region/Region.schema";
import TermsAndConditionRegistration from "./termsAndCondition/TermsAndCondition";
import TermsAndConditionRegistrationchema from "./termsAndCondition/TermsAndCondition.schema";

const baseSteps: Step[] = [
  {
    name: "userProfile",
    component: UserProfileRegistration,
    validationSchema: UserProfileRegistrationSchema,
  },
  {
    name: "companyInformation",
    component: CompanyInformationRegistration,
    validationSchema: CompanyInformationRegistrationSchema,
  },
  {
    name: "region",
    component: RegionRegistration,
    validationSchema: RegionRegistrationSchema,
  },
  {
    name: "termsAndCondition",
    component: TermsAndConditionRegistration,
    validationSchema: TermsAndConditionRegistrationchema,
  },
];

// Depending on the type of user, and the requirements for the form that are needed -- you can perform filtering logic here to omit steps that aren't
// necessary for each case
export const generateSteps = (): Step[] => baseSteps;

export const generateInitialValues = (filteredSteps: Step[]) => {
  // These initial values are assumed to be `null`.
  // In more complex cases you may want to populate these values w/ data from an API, so you could do what you need to do in here
  const initialValues = filteredSteps.reduce(
    (values, step) => ({ ...values, [step.name]: null }),
    {}
  );

  return initialValues;
};

export const getStepSchema = (currentIndex: number, steps: Step[]) =>
  steps[currentIndex].validationSchema;
