/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import "react-multi-email/dist/style.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  NavBarResponse,
  NavBarData,
  ShareResponse,
} from "../../models/interfaces/common";
import http from "../../services/api";
import { AuthResponse, RegResponse } from "../../services/mirage/routes/user";
import { useAppSelector } from "../../store";
import { RootState } from "../../rootReducer";
import { ISignupForm } from "../../models/interfaces/signup";
import { ModalProps } from "../../types/common";
import userSlice from "../../pages/auth/userSlice";

const EmailConfirmModal = ({
  isOpen,
  toggle,
  modalClass,
  keyboard = true,
  backdrop = "static",
  centered = true,
}: ModalProps) => {
  const { t, i18n } = useTranslation("common");
  const UserInfoSlice = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState<boolean>(false);
  interface resendEmailInterface {
    email: string;
  }
  interface resendEmailResponse {
    success: boolean;
    message: string;
    code: number;
  }

  const ResendEmail = () => {
    toggle();
    // console.log("email entered: ", UserInfoSlice?.email);
    setSubmitting(true);
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 2000);
    }) // beginning api call:
      .then(() => {
        // variable that contain the path where the sign up data be sent to
        const path = "/api/resendconfirmemail";
        const valuesToSubmit = {
          email: UserInfoSlice?.email,
        };
        // console.log("valuesToSubmit - ", valuesToSubmit);
        http
          .post<resendEmailInterface, resendEmailResponse>(path, valuesToSubmit)
          // .then((res) => {
          //   // if (res?.code === 200) {
          //   //   toggle();
          //   // }
          //   console.log("res: ", res);
          // })
          .then((res) => {
            if (res?.code === 200) {
              setSubmitting(false);
              // toggle();
            }
          })
          .catch((error) => {
            // console.log(error);
          });
      });
  };
  return (
    <Modal
      show={isOpen}
      onHide={toggle}
      className={`${modalClass}`}
      backdrop={backdrop}
      centered={centered}
      keyboard={keyboard}
    >
      <Modal.Body>
        <h2>{t("Account Activation Required")}</h2>
        <p>
          {t(
            "Your account has not been activated, please check your inbox/junk/spam for the activation email. If you are not seeing the activation email, click the link below to have it resent to you."
          )}
        </p>
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="btn btn-blue w-100 me-1"
            type="button"
            onClick={ResendEmail}
            disabled={submitting}
          >
            {t("Resend email")}
          </button>
          <button
            className="btn btn-reversed w-100 ms-1"
            type="button"
            onClick={toggle}
          >
            {t("Cancel")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EmailConfirmModal;
