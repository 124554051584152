/* eslint-disable no-shadow */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MultiAssetDownloadData } from "../models/interfaces/common";

/**
 * Would need tochange tje
 */
interface DownloadInfoInterface {
  completed: boolean;
  presetSetting: MultiAssetDownloadData;
}

// interface DownloadProcessInterface {
//   [fileCount: number]: DownloadInfoInterface;
// }

export enum DownloadStatus {
  notStarted = "notStarted",
  inProgress = "inProgress",
  ready = "ready",
  completed = "completed",
  failed = "failed",
}

export interface ChangeStatusInterface {
  zipID: number;
  status: string;
  downloadUrl?: string;
  zipFileName?: string;
  expirationDate?: string;
}

export interface ChangeProgressInterface {
  downloadId: string;
  progress: Number;
}

export interface DownloadProcessInterface {
  status: string;
  zipID: number;
  zipFileName?: string;
  expirationDate?: string;
  retryDownloadId?: number;
  downloadUrl?: string;
  fileCount: Number;
  downloadProgress: Number;
  presetSetting: MultiAssetDownloadData;
}
interface MultipleAssetsDownloadInterface {
  isOpen: boolean;
  cancelAllProcesses: boolean;
  // true -> return number
  // false -> return infinity
  downloadProcesses: DownloadProcessInterface[];
  unreadMessages: number;
  zipIdToCancel: number | null;
}

const initialState: MultipleAssetsDownloadInterface = {
  isOpen: false,
  cancelAllProcesses: false,
  downloadProcesses: [],
  unreadMessages: 0,
  zipIdToCancel: null,
};

const multipleAssetsDownload = createSlice({
  name: "assetsDownload",
  initialState,
  reducers: {
    setDownloadStatusIsOpen(state, { payload }: PayloadAction<boolean>) {
      state.isOpen = payload;
    },
    addMultiDownloadProcess(
      state,
      { payload }: PayloadAction<DownloadProcessInterface>
    ) {
      state.downloadProcesses = [...state.downloadProcesses, payload];
      state.isOpen = true;
    },
    updateDownloadProcessStatusById(
      state,
      { payload }: PayloadAction<ChangeStatusInterface>
    ) {
      const processIndex = state.downloadProcesses.findIndex(
        (process) => process.zipID === payload.zipID
      );
      state.downloadProcesses[processIndex].status = payload.status;
      state.downloadProcesses[processIndex].downloadUrl = payload.downloadUrl;
      state.downloadProcesses[processIndex].zipFileName = payload.zipFileName;
      state.downloadProcesses[processIndex].expirationDate =
        payload.expirationDate;
    },
    setUnreadMessages(state, { payload }: PayloadAction<number>) {
      if (payload < 0) {
        state.unreadMessages = 0;
        return;
      }
      state.unreadMessages = payload;
    },
    setZipIdToCancel(state, { payload }: PayloadAction<number>) {
      state.zipIdToCancel = payload;
    },
    removeProcessById(state, { payload }: PayloadAction<number>) {
      state.downloadProcesses = state.downloadProcesses.filter(
        (process) => process.zipID !== payload
      );
    },
    removeProcesses(state) {
      state.downloadProcesses = [];
    },
    setCancelAllProcesses(state, { payload }: PayloadAction<boolean>) {
      state.cancelAllProcesses = payload;
    },
  },
});

export const {
  setDownloadStatusIsOpen,
  addMultiDownloadProcess,
  updateDownloadProcessStatusById,
  setUnreadMessages,
  setZipIdToCancel,
  removeProcessById,
  removeProcesses,
  setCancelAllProcesses,
} = multipleAssetsDownload.actions;

export default multipleAssetsDownload.reducer;
