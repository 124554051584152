import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ResetTokenState {
  token: string | null;
}

const initialState: ResetTokenState = {
  token: null,
};

const resetToken = createSlice({
  name: "resetToken",
  initialState,
  reducers: {
    saveResetToken(state: ResetTokenState, { payload }: PayloadAction<string>) {
      if (payload) {
        // eslint-disable-next-line no-param-reassign
        state.token = payload;
      }
    },
    clearResetToken(state) {
      // eslint-disable-next-line no-param-reassign
      state.token = null;
    },
  },
});

export const { saveResetToken, clearResetToken } = resetToken.actions;

export default resetToken.reducer;
