import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import SecureLS from "../utils/SecureStorage";
// import { showAlert } from "../util";

const http: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// fixing the CORS error throwing
http.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
http.defaults.headers.post["Access-Control-Allow-Credentials"] = "true";
http.defaults.headers.post["Content-Type"] = "application/json";

http.interceptors.request.use((config) => {
  const secureLS = SecureLS.getItem("user");
  const dataValue = secureLS ? JSON.parse(secureLS) : {};
  const { data, token, employee } = dataValue;
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.authType = employee === true ? "okta" : "jwt";
  return config;
});

http.interceptors.response.use(
  async (response: AxiosResponse): Promise<any> => {
    if (response.status >= 200 && response.status < 300) {
      if (response.data instanceof Blob) {
        return response;
      }
      return response.data;
    }
    return null;
  },
  (error: AxiosError) => {
    const {
      response,
      request,
    }: { response?: AxiosResponse; request?: XMLHttpRequest } = error;
    if (response) {
      if (response?.status === 401 || response?.status === 403) {
        if (
          !request?.responseURL.includes("/api/login") &&
          !window.location.pathname.toLocaleLowerCase().startsWith("/shared/")
        ) {
          // alert("There was an error");
          window.location.href = "/login";
        }
      } else if (response.status >= 400 && response.status < 500) {
        // showAlert(response.data?.data?.message, "error");
        return null;
      }
    } else if (request) {
      // showAlert("Request failed. Please try again.", "error");
      return null;
    }
    return Promise.reject(error);
  }
);

export default http;
