import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import commonEnUS from "./translations/en_US/common.json";
import commonEs from "./translations/es/common.json";
import commonItIT from "./translations/it_IT/common.json";
import commonfrCA from "./translations/fr_CA/common.json";
import commondeDE from "./translations/de_DE/common.json";
import commonzh from "./translations/zh_CH/common.json";
import commones from "./translations/es_US/common.json";
import commonenAU from "./translations/en_AU/common.json";
import commonenCA from "./translations/en_CA/common.json";
import commonenCO from "./translations/en_CO/common.json";
import commonenGB from "./translations/en_GB/common.json";
import commonenMX from "./translations/en_MX/common.json";
import commonenMY from "./translations/en_MY/common.json";
import commonenNZ from "./translations/en_NZ/common.json";
import commonesCO from "./translations/es_CO/common.json";
import commonesES from "./translations/es_ES/common.json";
import commonesMX from "./translations/es_MX/common.json";
import commonfrFR from "./translations/fr_FR/common.json";

i18n.use(LanguageDetector).init({
  resources: {
    en_US: {
      common: commonEnUS,
    },
    en_AU: {
      common: commonenAU,
    },
    en_CA: {
      common: commonenCA,
    },
    en_CO: {
      common: commonenCO,
    },
    en_GB: {
      common: commonenGB,
    },
    en_MX: {
      common: commonenMX,
    },
    en_MY: {
      common: commonenMY,
    },
    en_NZ: {
      common: commonenNZ,
    },
    es: {
      common: commonEs,
    },
    es_CO: {
      common: commonesCO,
    },
    es_ES: {
      common: commonesES,
    },
    es_MX: {
      common: commonesMX,
    },
    it_IT: {
      common: commonItIT,
    },
    fr_CA: {
      common: commonfrCA,
    },
    fr_FR: {
      common: commonfrFR,
    },
    de_DE: {
      common: commondeDE,
    },
    // zh_Hans_CN: {
    //   common: commonzh,
    // },
    zh: {
      common: commonzh,
    },
    es_US: {
      common: commones,
    },
  },
  fallbackLng: "en",
  debug: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    useSuspense: true,
  },
});

export default i18n;
