import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { ApolloProvider } from "@apollo/client";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import i18n from "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./main.scss";
import * as serviceWorker from "./serviceWorker";
// TODO: comment the next row to switch to the real API endpoint
// import { setupServer } from "./services/mirage/server";
import store from "./store";

// import { client } from "./services/ApolloClient/client";
import { client } from "./services/ApolloClient/pimcoreData";

if (process.env.NODE_ENV === "development") {
  // TODO: comment the next row to switch to the real API endpoint
  // setupServer();
}

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

// Use createRoot instead of ReactDOM.render
const root = document.getElementById("root");
const rootElement = root ? createRoot(root) : null;

rootElement?.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <App />
        </Provider>
      </ApolloProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
