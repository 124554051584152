import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AssetCardProps } from "../types/common";
import { useAppDispatch } from "../store";
import { removeAllFilters } from "../pages/filterSlice";
import { setAssetIdList } from "../pages/assetsSlice";
import { removeSelectedFilters } from "../pages/filterOptionsSlice";

const AssetCard = ({ title, count, size, src, path }: AssetCardProps) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation("common");

  const removeFilters = () => dispatch(removeAllFilters());
  const removeActiveFilters = () => dispatch(removeSelectedFilters());
  const setAssetIds = (ids: string[]) => dispatch(setAssetIdList(ids));

  if (count > 0) {
    return (
      <div className="content-card">
        <div className="image-container">
          <img src={src} alt={title} className="img-fluid" />
        </div>
        <div className="text-container">
          <div
            className="card-title"
            onClick={() => {
              removeFilters();
              removeActiveFilters();
              setAssetIds([]);
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <Link to={path} className="content-card-link stretched-link">
              {t(title)}
            </Link>
          </div>
          <div className="card-subtitle">
            {count} {t("files")} | {size.toFixed(2)} GB
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default AssetCard;
