import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import http from "../../services/api";

interface confirmEmailInterface {
  confirmEmailToken: string;
}
interface confirmEmailRequest {
  token: string;
}
interface confirmEmailResponseInterface {
  success: boolean;
  message: string;
}

export default function emailConfirmation() {
  const [confirmEmailMessage, setConfirmEmailMessage] = useState<string>("");
  const [confirmEmailSuccess, setConfirmEmailSuccess] =
    useState<boolean>(false);
  const { confirmEmailToken } = useParams();
  const callingConfirmToken = useCallback((tokenInput: string) => {
    const confirmEmail: confirmEmailRequest = {
      token: tokenInput,
    };
    const path = "/api/confirmemail";
    http
      .post<
        confirmEmailRequest,
        confirmEmailResponseInterface
      >(path, confirmEmail)
      .then((res) => {
        if (res) {
          setConfirmEmailSuccess(res.success);
          setConfirmEmailMessage(res.message);
        }
      });
  }, []);

  useEffect(() => {
    if (confirmEmailToken) {
      callingConfirmToken(confirmEmailToken);
    }
  }, []);

  const navigate = useNavigate();
  return (
    <div className="registration registration-sm">
      <div className="container-fluid">
        <div className="row">
          <div className="px-5 py-3 pb-1 single-col h-auto col-12 overflow-hidden">
            <h2>Account Activation Successful</h2>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Thank you for registering! Your account has been successfully
                activated. Please click the button below to login and get
                started.
              </label>
            </div>
            <div className="mb-3">
              <button
                className="btn btn-lg btn-blue"
                onClick={() => {
                  navigate("/login");
                }}
                type="submit"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
