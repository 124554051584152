import React, { useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import { IRouteProps } from "../../models/interfaces/common";
import { ReactDefaultProps } from "../../types/common";
import SecureLS from "../../utils/SecureStorage";

export const AuthLayout = ({ children }: ReactDefaultProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    const secureLS = SecureLS.getItem("user");
    const dataValue = secureLS ? JSON.parse(secureLS) : null;
    if (dataValue) {
      if (!isExpired(dataValue.token)) {
        navigate(`/`);
      } else {
        SecureLS.removeItem("user");
      }
    }
  }, []);

  return (
    <div className="registration-bg registration-page min-vh-100">
      {children}
    </div>
  );
};

export const AuthLayoutRoute = ({ component: Comp }: IRouteProps) => (
  <AuthLayout>
    <Comp />
  </AuthLayout>
);
