import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GraphqlApi } from "../models/interfaces/graphApi";
import {
  FAV_COLLECTION_LIST_ID_TYPE,
  FAV_COLLECTION_LIST,
} from "../services/graphql/pimcoreDam";
import { RootState } from "../rootReducer";
import { FavPayloadType, FavoriteAsset } from "../types/common";
import { setRawFavoriteState, setFavoriteState } from "../pages/favoriteSlice";
import { useAppDispatch } from "../store";

/*
This is a Custom React Hook that handles card selections
*/

const useFavorites = () => {
  const dispatch = useAppDispatch();
  const [refetchNeeded, setRefetchNeeded] = useState<boolean>(false);
  const initializeFavList: any[] = [];
  const [favoriteList, setFavoriteList] = useState(initializeFavList);
  const [favoritesListError, setFavoritesListError] = useState<any>(null);
  const UserInfoSlice = useSelector((state: RootState) => state.user);
  // const [getFavorites, { loading, error, data }] = useLazyQuery(
  //   FAV_COLLECTION_LIST_ID_TYPE,
  //   {
  //     variables: {
  //       id: Number(UserInfoSlice?.id),
  //     },
  //     fetchPolicy: "cache-first",
  //   }
  // );
  const [getFavorites, { loading, error, data, refetch, called }] =
    useLazyQuery(FAV_COLLECTION_LIST, {
      variables: {
        id: Number(UserInfoSlice?.id),
      },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    getFavorites();
  }, []);

  useEffect(() => {
    if (refetchNeeded) {
      if (refetch) refetch();
      setRefetchNeeded(false);
    }
  }, [refetchNeeded]);

  useEffect(() => {
    if (data) {
      const receivedFavList = data?.getUser?.collectionFolderFavAsset;
      if (receivedFavList) {
        const favList = receivedFavList.map((fav: any) => {
          const favAssetList = fav.assetsListFav;
          return {
            name: fav.name,
            favs: favAssetList
              ? (favAssetList as any[]).map((assetFav: FavoriteAsset) => ({
                  id: Number(assetFav.id),
                  type: "asset",
                }))
              : [],
          };
        });
        // console.log("favList = ", favList);
        dispatch(setRawFavoriteState(receivedFavList));
        // setFavoriteList(favList);
        dispatch(setFavoriteState(favList));
      }
    }
  }, [data]);

  // const handleCardClick = (
  //   id: any,
  //   event: React.MouseEvent<HTMLDivElement>,
  //   callBackEvent?: () => void
  // ) => {
  //   console.log("from useSelection id: ", id);
  // };

  return {
    getFavorites,
    favoriteList,
    loading,
    error,
    data,
    setRefetchNeeded,
  };
};

export default useFavorites;
