import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Value } from "aws-sdk/clients/cloudformation";
import CompanyInformationSchema from "../registrationSteps/companyInformation/CompanyInformation.schema";
import { ICompanyInformation } from "../../models/interfaces/common";
import SecureLS from "../../utils/SecureStorage";
import {
  COMPANYTYPE_LIST,
  REGION_LANGUAGES,
} from "../../services/graphql/pimcoreDam";
import { CHANGE_USER_INFO } from "../../services/graphql/migrations";
import { setUser } from "../../pages/auth/userSlice";
import { useAppDispatch } from "../../store";
import { RootState } from "../../rootReducer";
import {
  CountriesResponse,
  CountryListInterface,
  Data,
} from "../../models/interfaces/countries";
import http from "../../services/api";

export default function CompanyInformation() {
  const { t, i18n } = useTranslation("common");
  const UserInfoSlice = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const [id, setID] = useState<number | null>(null);
  // set up for dropdown
  const [selectedCompanyType, setSelectedCompanyType] = useState<any>(null);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);

  // retrieve data from session storage to populate the userprofile information
  useEffect(() => {
    const secureLS = SecureLS.getItem("user");
    if (secureLS) {
      const dataValue = JSON.parse(secureLS) || {};
      const { data } = dataValue || {};
      if (data && data.id) {
        setID(data.id);
      }
    }
  }, []);

  interface GraphqlApi {
    node: companyTypeInterface | region;
  }

  interface companyTypeInterface {
    id: number;
    name: string;
  }

  interface region {
    id: number;
    name: string;
    languages: string;
  }

  // retrieve list of company type
  const CompanyTypeList = () => {
    const { loading, error, data } = useQuery(COMPANYTYPE_LIST, {
      fetchPolicy: "cache-first",
      // pollInterval: 500,
    });
    if (loading) return [];
    if (error) return [];
    // got to edges
    const valueArr: GraphqlApi[] = data.getCompanyTypeListing.edges;

    return valueArr.map((option) => ({
      value: option.node.id,
      label: option.node.name,
    }));
  };

  // retrieve list of countries
  const [countryListState, setCountryListState] = useState<
    CountryListInterface[]
  >([]);

  const getCountryList = () => {
    const path = "/api/countries";
    http.get<CountriesResponse>(path).then((res) => {
      const tempCountryList: CountryListInterface[] = [];
      if (res.data) {
        const dataRes: any = res.data;
        Object.keys(res.data).forEach((key) => {
          const value = key;
          const label = dataRes[key];
          tempCountryList.push({ label, value });
        });
        setCountryListState([...tempCountryList]);
      }
    });
  };

  useEffect(() => {
    getCountryList();
  }, []);
  // end of countries retrieving

  const handleDCompanyTypeChange = (selectedOption: any) => {
    setSelectedCompanyType(selectedOption);
  };
  const handleCountryChange = (selectedOption: any) => {
    setSelectedRegion(selectedOption);
  };

  // useMutation hook to update value
  const [updateUserInfo] = useMutation(CHANGE_USER_INFO);
  const handleUpdate = (data: ICompanyInformation, _setSubmitting: any) => {
    updateUserInfo({
      variables: {
        // eslint-disable-next-line object-shorthand
        id: Number(id),
        userInfo: {
          companyName: data.companyName,
          companyUrl: data.companyUrl,
          // eslint-disable-next-line object-shorthand
          companyType: (data.companyType as any).value,
          // eslint-disable-next-line object-shorthand
          country: (data.country as any).value,
          // eslint-disable-next-line object-shorthand
          purposeOfUse: data.purpose,
        },
      },
    });
    const userInfo = {
      ...UserInfoSlice,
      companyName: data.companyName,
      companyType: (data.companyType as any).value,
      companyUrl: data.companyUrl,
      country: (data.country as any).value,
      purpose: data.purpose,
    };
    dispatch(setUser(userInfo));
    _setSubmitting(false);
  };

  const secureLS = SecureLS.getItem("user");
  const dataValue = JSON.parse(secureLS) || {};
  const companyTypeList = CompanyTypeList();
  // const countryList = getCountryList();

  const defaultCompanyType = companyTypeList.find(
    (option: any) => option.value.toString() === dataValue.data.companyType
  );

  const defaultCountry = countryListState.find(
    (option) => option.value.toString() === dataValue.data.country
  );

  const initialValues: ICompanyInformation = {
    companyName: dataValue.data.companyName || "",
    companyUrl: dataValue.data.companyUrl || "",
    companyType: defaultCompanyType || "",
    country: defaultCountry || "",
    purpose: dataValue.data.purpose,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={CompanyInformationSchema}
      onSubmit={(
        values: ICompanyInformation,
        formikHelpers: FormikHelpers<ICompanyInformation>
      ) => {
        handleUpdate(values, formikHelpers.setSubmitting);
      }}
    >
      {(props: FormikProps<ICompanyInformation>) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
        } = props;

        return (
          <Form>
            <div className="row form-row">
              <div className="col-md-6 col-12">
                <div className="mb-4">
                  <label htmlFor="companyName" className="form-label">
                    {t("Company Name")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      touched.companyName && errors.companyName
                        ? "is-invalid"
                        : ""
                    }`}
                    id="companyName"
                    name="companyName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                  />
                  {touched.companyName && errors.companyName ? (
                    <div className="text-danger mt-1 fs-13 fw-bold">
                      {errors.companyName}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="mb-4">
                  <label htmlFor="companyUrl" className="form-label">
                    {t("Company URL")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      touched.companyUrl && errors.companyUrl
                        ? "is-invalid"
                        : ""
                    }`}
                    id="companyUrl"
                    name="companyUrl"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyUrl}
                  />
                  {touched.companyUrl && errors.companyUrl ? (
                    <div className="text-danger mt-1 fs-13 fw-bold">
                      {errors.companyUrl}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="mb-4">
                  <label htmlFor="companyType" className="form-label">
                    {t("Company Type")}
                  </label>
                  <Select
                    classNamePrefix="regular-looking-dropdown"
                    className="w-100"
                    options={companyTypeList}
                    isMulti={false}
                    name="companyType"
                    id="companyType"
                    onChange={(option: any, e: any) => {
                      setFieldValue("companyType", option);
                      handleDCompanyTypeChange(option);
                    }}
                    onBlur={() => setFieldTouched("companyType", true)}
                    value={values.companyType}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="mb-4">
                  <label htmlFor="country" className="form-label">
                    {t("Country")}
                  </label>
                  <Select
                    classNamePrefix="regular-looking-dropdown"
                    className="w-100"
                    name="country"
                    id="country"
                    options={countryListState}
                    isMulti={false}
                    onChange={(option: any, e: any) => {
                      setFieldValue("country", option);
                      handleCountryChange(option);
                    }}
                    onBlur={() => setFieldTouched("country", true)}
                    value={values.country}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-4">
                  <label htmlFor="purpose" className="form-label">
                    {t("Purpose of Use")}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      touched.purpose && errors.purpose ? "is-invalid" : ""
                    }`}
                    id="purpose"
                    name="purpose"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.purpose}
                  />
                  {touched.purpose && errors.purpose ? (
                    <div className="text-danger mt-1 fs-13 fw-bold">
                      {errors.purpose}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="my-account-buttons">
              <button
                className="btn btn-lg btn-blue"
                type="submit"
                disabled={
                  isSubmitting ||
                  !!(errors.companyType && touched.companyType) ||
                  !!(errors.companyType && touched.companyType) ||
                  !!(errors.country && touched.country) ||
                  !!(errors.purpose && touched.purpose)
                }
              >
                {t("Update")}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
