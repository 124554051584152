import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import Select from "react-select";
import { useSelector } from "react-redux";
import RegionRegistrationSchema from "../registrationSteps/region/Region.schema";
import { IRegionSelection } from "../../models/interfaces/common";
import { getRegionAndLanguageData } from "../../utils/regionAndLanguagesGraphQL";
import { CHANGE_USER_INFO } from "../../services/graphql/migrations";
import { useAppDispatch } from "../../store";
import { RootState } from "../../rootReducer";
import { setUser } from "../../pages/auth/userSlice";

export default function Region() {
  const { t, i18n } = useTranslation("common");
  const dispatch = useAppDispatch();
  const UserInfoSlice = useSelector((state: RootState) => state.user);
  const [languageOptions, setLanguageOptions] = useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<any>(null);
  const [isSelectedLanguageChanged, setIsSelectedLanguageChanged] =
    useState<boolean>(false);
  const selectRef = useRef<any>(null);

  useEffect(() => {}, []);

  const {
    allRegionList,
    allLanguageList,
    loading,
    error,
    data,
    defaultRegion,
    defaultLanguage,
  } = getRegionAndLanguageData(languageOptions);

  const changeSelectOptionHandler = (selectedLang: any) => {
    // setSelectedRegion(selectedLang);
    const selectedNode = data.getRegionListing.edges.find(
      (x: { node: { id: number } }) => x.node.id === selectedLang.value
    );
    setLanguageOptions(selectedNode.node.languages);
  };

  const handleChangeLanguage = (selectedLanguge: any) => {
    const newLanguage = selectedLanguge.value;
    // setSelectedLanguageCode(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  // useMutation hook to update value
  const [updateUserInfo] = useMutation(CHANGE_USER_INFO);
  const handleUpdate = (values: IRegionSelection, _setSubmitting: any) => {
    updateUserInfo({
      variables: {
        // eslint-disable-next-line object-shorthand
        id: Number(UserInfoSlice?.id),
        userInfo: {
          preferredLanguage: (values.language as any).value,
          // eslint-disable-next-line object-shorthand
          primaryRegion: (values.primaryRegion as any).value,
        },
      },
    });
    const userInfo = {
      ...UserInfoSlice,
      preferredLanguage: (values.language as any).value,
      primaryRegion: (values.primaryRegion as any).value,
    };
    dispatch(setUser(userInfo));
    handleChangeLanguage(values.language);
    _setSubmitting(false);
  };

  const initialValues: IRegionSelection = {
    primaryRegion: selectedRegion || defaultRegion,
    language: !isSelectedLanguageChanged ? defaultLanguage : null,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={RegionRegistrationSchema}
      onSubmit={(
        values: IRegionSelection,
        formikHelpers: FormikHelpers<IRegionSelection>
      ) => {
        handleUpdate(values, formikHelpers.setSubmitting);
      }}
    >
      {(props: FormikProps<IRegionSelection>) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
          validateField,
          validateForm,
          setFieldError,
          isValid,
        } = props;
        return (
          <Form>
            <div className="row form-row">
              <div className="col-md-6 col-12">
                <div className="mb-4">
                  <label htmlFor="primaryRegion" className="form-label">
                    {t("Primary Region")}
                  </label>
                  <Select
                    classNamePrefix="regular-looking-dropdown"
                    className="w-100"
                    options={allRegionList}
                    isMulti={false}
                    name="primaryRegion"
                    placeholder="- Select Region -"
                    id="primaryRegion"
                    onChange={async (option: any, e: any) => {
                      setSelectedRegion(option);
                      setSelectedLanguage(null);
                      setFieldValue("language", undefined, true);
                      await setFieldTouched("language", false, true);
                      setFieldValue("primaryRegion", option);
                      setIsSelectedLanguageChanged(true);
                      changeSelectOptionHandler(option);
                    }}
                    onBlur={() => setFieldTouched("primaryRegion", true)}
                    value={values.primaryRegion}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="mb-4">
                  <label htmlFor="language" className="form-label">
                    {t("Language")}
                  </label>
                  <Select
                    classNamePrefix="regular-looking-dropdown"
                    className="w-100"
                    options={allLanguageList}
                    isMulti={false}
                    name="language"
                    placeholder="- Select Language -"
                    id="language"
                    ref={selectRef}
                    onChange={(option: any, e: any) => {
                      setIsSelectedLanguageChanged(false);
                      setFieldValue("language", option);
                    }}
                    onBlur={() => {
                      if (values.language) setFieldTouched("language", true);
                    }}
                    value={selectedLanguage || values.language}
                  />
                </div>
              </div>
            </div>
            <div className="my-account-buttons">
              <button
                className="btn btn-lg btn-blue"
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                {t("Update")}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
