/* eslint-disable no-plusplus */
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import loadingAnimation from "../../loadingAnimation2.json";
import loadingAnimation2 from "../../loading_spinner_editted_noCanvas2.svg";
import { useAppDispatch, useAppSelector } from "../../store";
import { RootState } from "../../rootReducer";
import HeartCardFooterFill from "../../assets/img/heart-card-footer-fill.svg";
import ShareCardFooter from "../../assets/img/share-card-footer.svg";
import ExpandCardFooter from "../../assets/img/expand-card-footer.svg";
import DownloadCardFooter from "../../assets/img/download-card-footer.svg";
import EditIcon from "../../assets/img/pencil.png";
import TrashCanIcon from "../../assets/img/trash.svg";
import CatalogCard from "../../components/CatalogCard";
import ManageFavoriteModal from "../../components/modals/ManageFavoriteModal";
import {
  ASSETS_FILESIZE_SORT,
  SHARED_ASSETS,
} from "../../services/graphql/pimcoreDam";
import { setBreadcrumbState } from "../../components/breadcrumbs/breadcrumbSlice";
import {
  initialState,
  setInPageNavState,
  resetInPageNav,
} from "../../components/inPageNavSlice";
import {
  checkForLandscapeOrPortrait,
  getAssetLists,
  getAssetSizes,
  getAssetThumbnailUrl,
  getDocumentThumbnails,
  getFilterVariables,
  getMetaDataAttributes,
  getResolutionsByAssetIds,
} from "../../utils/catalog";
import useMultiSelection from "../../hooks/useMultiSelection";
import { GraphqlApi, Node } from "../../models/interfaces/graphApi";
import AssetDownloadModal from "../../components/modals/AssetDownloadModal";
import AssetDetailModal from "../../components/modals/AssetDetailModal";
import { client } from "../../services/ApolloClient/pimcoreData";
import {
  AssetInfo,
  AssetResolutionData,
  DocumentThumbnail,
  ShareLinkAssetInfo,
  ShareLinkResponse,
} from "../../models/interfaces/common";
import http from "../../services/api";
import { shareExpirationDate } from "../sidebarSlice";
import { FavoriteType, FavoriteAsset } from "../../types/common";
import { selectAsset } from "../navBarSlice";
import { toggleDownloadIsOpen } from "../downloadSlice";
import { setUseMultiSelectionPossibleState } from "../../hooks/useMultiSelectionSlice";
import { getSortDirection, getSortOrder } from "../../utils/InPageNav";
import { sortOrder } from "../sortSlice";
import { assetIdNav, assetTypeNav, assetUrlNav } from "../assetSliceNavBar";
import { setAssets } from "../assetsSlice";

interface MatchParams {
  token: string;
}

interface ShareRequestInterface {
  token: string;
  sortingOrder: string;
  sortingDirection: string;
}

export default function Shared() {
  const { t, i18n } = useTranslation("common");
  const Favorites = useAppSelector(
    (state: RootState) => state.newFavorite.favorite
  );
  const downloadModalState = useAppSelector((state) => state.download.isOpen);
  const SelectionSlice = useSelector((state: RootState) => state.useSelections);

  const [restApiAssets, setRestApiAssets] = useState<AssetInfo[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [highlightedAsset, setHighlightedAsset] = useState<AssetInfo>();

  const [documentThumbnails, setDocumentThumbnails] =
    useState<DocumentThumbnail>({});

  const [assetResolutions, setAssetResolutions] = useState<AssetResolutionData>(
    {}
  );

  const [showManageFavorite, setShowManageFavorite] = useState(false);
  const [isAddToFavoriteModalTrigger, setIsAddToFavoriteModalTrigger] =
    useState(false);

  const sortingOrder = useAppSelector((state) => state.sort.order);
  const sortingDirection = useAppSelector((state) => state.sort.direction);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const dispatch = useAppDispatch();
  const toggleDownloadModal = () => dispatch(toggleDownloadIsOpen());
  const navigate = useNavigate();
  const location = useLocation();

  const tokenObj = useMatch("/shared/:token");
  const token = tokenObj?.params.token;

  const path = "/api/sharelink";

  const toggleManageFavoriteModal = () => {
    setIsAddToFavoriteModalTrigger(true);
    setShowManageFavorite(!showManageFavorite);
  };

  const sharedAssets = useCallback(
    async (stOrder: string, stDirection: boolean) => {
      setLoading(true);
      await http
        .post<ShareRequestInterface, ShareLinkResponse>(path, {
          token,
          sortingOrder: stOrder,
          sortingDirection: stDirection ? "ASC" : "DESC",
        })
        .then((res: ShareLinkResponse) => {
          const response: AssetInfo[] | null = res.assetInfo
            ? (res.assetInfo as unknown as AssetInfo[])
            : null;

          if (response) {
            setRestApiAssets(response);
            dispatch(shareExpirationDate(res.expirationDate));
          }
        })
        .catch((err) => {})
        .finally(() => setLoading(false));
    },
    []
  );

  useEffect(() => {
    sharedAssets(sortingOrder, sortingDirection);
  }, [sortingOrder, sortingDirection]);

  const initialSelection: string[] = [];

  // initial state for download and asset detail modals
  const [showDownload, setShowDownload] = useState(false);
  const [showAssetDetail, setShowAssetDetail] = useState(false);

  dispatch(setBreadcrumbState(false));

  const resetSharedNavbar = () => {
    dispatch(
      setInPageNavState({
        ...initialState,
        showDownload: true,
        enableDownload: false,
        showLeftDownloadAll: true,
        enableLeftDownloadAll: true,
        showShare: false,
        enableShare: false,
        showFavorite: false,
        showFilter: false,
        showSpacerLine: false,
        showName: true,
        showRemoveFromFavorite: false,
        enableRemoveFromFavorite: false,
        showRightDownloadAll: false,
        enableRightDownloadAll: false,
        showRightShareAll: false,
        enableRightShareAll: false,
        showRightClearAll: false,
        enableRightClearAll: true,
      })
    );
  };

  const updateSharedNavbar = () => {
    dispatch(
      setInPageNavState({
        ...initialState,
        showDownload: true,
        enableDownload: true,
        showLeftDownloadAll: true,
        enableLeftDownloadAll: true,
        showShare: false,
        enableShare: false,
        showFavorite: false,
        showFilter: false,
        showSpacerLine: false,
        showName: true,
        showRemoveFromFavorite: false,
        enableRemoveFromFavorite: false,
        showRightDownloadAll: false,
        enableRightDownloadAll: false,
        showRightShareAll: false,
        enableRightShareAll: false,
        showRightClearAll: false,
        enableRightClearAll: true,
      })
    );
  };

  useEffect(() => {});

  // use the multiple selection hook on cards
  const {
    setAssetData,
    handleCardClick,
    selectedList,
    updateSelectedListFromSlice,
  } = useMultiSelection("getAssetListing", "image", initialSelection);

  useEffect(() => {
    updateSelectedListFromSlice();
  }, [SelectionSlice.allSections]);

  useEffect(() => {
    if (selectedList.length > 0) {
      updateSharedNavbar();
    } else {
      resetSharedNavbar();
    }
    dispatch(selectAsset(selectedList));
  }, [selectedList]);

  useEffect(() => {
    if (restApiAssets) {
      setAssetData(restApiAssets);
      dispatch(
        setUseMultiSelectionPossibleState(
          restApiAssets.map((asset) => String(asset.id))
        )
      );
      getResolutionsByAssetIds(
        [...restApiAssets.map((asset) => Number(asset.id))],
        setAssetResolutions,
        source
      );
    }

    return () => {
      source.cancel("");
    };
  }, [restApiAssets]);

  // download and asset detail modal controller
  const toggleAssetDetail = () => setShowAssetDetail(!showAssetDetail);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (selectedList[0] && restApiAssets) {
      const asset = restApiAssets.find(
        (assetCondition) => assetCondition.id === selectedList[0]
      );
      setHighlightedAsset(asset);
    }
  }, [selectedList]);

  useEffect(() => {
    if (highlightedAsset) {
      const { filename } = highlightedAsset;
      const ext =
        filename?.substring(filename?.lastIndexOf(".") + 1, filename.length) ||
        filename;
      const Url = getAssetThumbnailUrl(highlightedAsset);
      dispatch(assetIdNav(highlightedAsset.id as string));
      dispatch(assetUrlNav(Url));
      dispatch(assetTypeNav(highlightedAsset.type as string));
    }
  }, [highlightedAsset]);

  useEffect(() => {
    if (restApiAssets) {
      dispatch(setAssets(restApiAssets));
      const documentAssetIds = restApiAssets
        .filter((assetItem) => assetItem.type.toString() === "document")
        .map((item) => item.id);
      getDocumentThumbnails(documentAssetIds.toString(), setDocumentThumbnails);
    }
  }, [restApiAssets]);

  // get list of shared assets
  const SharedAssets = () => {
    if (loading)
      return (
        <object
          type="image/svg+xml"
          width={1500}
          height={1500}
          data={loadingAnimation2}
        >
          svg-animation
        </object>
      );
    // if (sharedAssetsData.error) return <p>{t("Error...")}</p>;

    const allFavoriteIds: string[] = [];
    Object.values(Favorites).forEach((folder) => {
      folder.assets.forEach((asset) => {
        allFavoriteIds.push(String(asset.id));
      });
    });

    return (
      <>
        {restApiAssets &&
          restApiAssets.map((asset) => {
            const additionalClasses = selectedList.includes(asset.id)
              ? "selected"
              : "";
            const filename: string = asset.filename.toString();
            let fullPath = asset.fullpath;
            if (asset.fullpath.slice(0, 5) !== "https") {
              fullPath = `https://gildan.blob.core.windows.net/gildan/assets${asset.fullpath}${asset.filename}`;
            }
            // console.log("Asssset: ", asset.fullpath);
            const ext =
              filename.substring(
                filename.lastIndexOf(".") + 1,
                filename.length
              ) || filename;
            return (
              <div
                className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-12"
                key={`${asset.id}-${asset.filename}`}
              >
                <CatalogCard
                  id={asset.id}
                  title={asset.filename}
                  downloadLink={asset.fullpath}
                  shareLink={asset.fullpath}
                  src={
                    // getAssetThumbnailUrl(asset)
                    // eslint-disable-next-line no-nested-ternary
                    asset.type !== "video"
                      ? asset.type === "document" && ext.toUpperCase() === "PDF"
                        ? documentThumbnails[asset.id]
                        : checkForLandscapeOrPortrait(
                            assetResolutions[asset.id],
                            asset.fullpathThumbnailPortraitImage,
                            asset.fullpathThumbnailLandscapeImage
                          )
                      : asset.youTubeSDThumbnailPath
                  }
                  type={asset.type}
                  filename={asset.filename}
                  fullpath={asset.fullpath}
                  fullpathExtandFont={asset.fullpathExtandFont}
                  fullpathExtandLandscapeImage={
                    asset.fullpathExtandLandscapeImage
                  }
                  fullpathExtandPortraitImage={
                    asset.fullpathExtandPortraitImage
                  }
                  fullpathThumbnailFont={asset.fullpathThumbnailFont}
                  // fullpathThumbnailImageDownload={
                  //   asset.node.fullpathThumbnailImageDownload
                  // }
                  fullpathThumbnailLandscapeImage={
                    asset.fullpathThumbnailLandscapeImage
                  }
                  fullpathThumbnailPortraitImage={
                    asset.fullpathThumbnailPortraitImage
                  }
                  youTubeSDThumbnailPath={asset.youTubeSDThumbnailPath}
                  youTubeSDPath={asset.youTubeSDPath}
                  customMetadataId={asset.customMetadataId}
                  creationDate={asset.creationDate}
                  fileSize={asset.fileSize}
                  additionalClasses={additionalClasses}
                  selectCallback={handleCardClick}
                  toggleAssetDownloadModal={toggleDownloadModal}
                  toggleAssetDetailModal={toggleAssetDetail}
                  toggleAssetFavoriteModal={toggleManageFavoriteModal}
                  isFavorite={allFavoriteIds.includes(String(asset.id))}
                  isNew={asset.assetTag === 1}
                  dpiAsset={asset.dpiAsset}
                />
              </div>
            );
          })}
        .{" "}
      </>
    );
  };

  return (
    <div className="cp container-fluid">
      <div className="cards">
        <div className="row">
          <SharedAssets />
          <AssetDownloadModal
            isOpen={downloadModalState}
            toggle={toggleDownloadModal}
            selectedList={selectedList}
            modalClass={
              selectedList.length > 1
                ? "downloadMultipleModal"
                : "downloadModal"
            }
            centered
            backdrop="static"
            keyboard
            isMultipleDownload={false}
            useMultiSelectionData={false}
          />
          <AssetDetailModal
            isOpen={showAssetDetail}
            toggle={toggleAssetDetail}
            modalClass="assetDetailsModal"
            centered
            backdrop="static"
            keyboard
            fullscreen
          />
          <ManageFavoriteModal
            isOpen={showManageFavorite}
            toggle={toggleManageFavoriteModal}
            modalClass="favoritesModal"
            centered
            backdrop="static"
            keyboard
            isAddToFavorite={isAddToFavoriteModalTrigger}
          />
        </div>
      </div>
    </div>
  );
}
