/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useNavigate, Link, useMatch, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Select from "react-select";
import { useOktaAuth } from "@okta/okta-react";
import companyLogo from "../assets/img/logo2.png";
import globePic from "../assets/img/globe.svg";
import userPic from "../assets/img/user.svg";
import HowToIcon from "../assets/img/HowToIcon.svg";
import noFillHeartPic from "../assets/img/heart-no-fill.svg";
import { CHANGE_USER_INFO } from "../services/graphql/migrations";
import fillHeartPic from "../assets/img/heart-fill.svg";
import signOutPic from "../assets/img/sign-out.svg";
import downloadIcon from "../assets/img/downloads/downloadIcon.svg";
import { useAppDispatch, useAppSelector } from "../store";
import { RootState } from "../rootReducer";
import { getRegionAndLanguageData } from "../utils/regionAndLanguagesGraphQL";
import { setUser } from "../pages/auth/userSlice";
import SecureLS from "../utils/SecureStorage";
import http from "../services/api";
import {
  FavoriteVideoThumbnailData,
  FavoriteVideoThumbnailRequest,
  FavoriteVideoThumbnailResponse,
  VideoThumbNails,
  FilterOptionsInfo,
  Options,
} from "../models/interfaces/common";
import { addVideoThumbNails } from "../pages/favoriteSlice";
import { getDownloadsCounter, getFavoritesApiCall } from "../utils/catalog";
import {
  FavoriteInfoInterface,
  setCurrentFolderId,
  setDefaultFolderId,
  setFavorite,
} from "../pages/newFavoriteSlice";
import { scrollToTop } from "../util";
import {
  assignSelectedAge,
  assignSelectedAssetType,
  assignSelectedAssetTag,
  assignSelectedBrand,
  assignSelectedColorCode,
  assignSelectedColorName,
  assignSelectedFabricContent,
  assignSelectedFabricName,
  assignSelectedFileType,
  assignSelectedFilters,
  assignSelectedGender,
  assignSelectedLabelName,
  assignSelectedImageResolution,
  assignSelectedSilhouette,
  assignSelectedStyle,
  assignSelectedTotalModel,
  assignLastSelectedOption,
  removeSelectedFilters,
} from "../pages/filterOptionsSlice";
import { removeAllFilters } from "../pages/filterSlice";
import { setAssetIdList } from "../pages/assetsSlice";
import user from "../services/mirage/routes/user";
import {
  removeProcesses,
  setUnreadMessages,
} from "../pages/multipleAssetDownloadSlice";

export default function Header() {
  const UserInfoSlice = useSelector((state: RootState) => state.user);
  const userId = useSelector((state: RootState) => state.user?.id);
  const FavoriteSlice = useSelector((state: RootState) => state.favorite);
  const isHowToPage = useMatch("/howto/:howToPage");
  const isSharedPage = useMatch("/shared/:token");
  let regionDropDown = useRef<HTMLDivElement>(null);
  const [cookies, setCookie, removeCookie] = useCookies([
    "rememberMeEmail",
    "rememberMePassword",
    "JSESSIONID",
    "sid",
    "ln",
    "srefresh",
    "enduser_version",
    "proximity_cc91527febee5e661206adb5f3f102b3",
    "okta-oauth-nonce",
    "t",
    "Okta_Verify_Autopush_978541678",
    "DT",
    "okta-oauth-state",
    "okta-oauth-redirect-params",
  ]);
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showRegionLanguage, setshowRegionLanguage] = useState<Boolean>(false);
  const [languageOptions, setLanguageOptions] = useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<any>(null);
  const [isSelectedRegionChanged, setIsSelectedRegionChanged] = useState(false);
  const [selectedRegionDeisplay, setSelectedRegionDeisplay] =
    useState<string>("");

  const source = axios.CancelToken.source();

  const [counter, setCounter] = useState<number>(0);
  const unreadMessages = useAppSelector(
    (state) => state.multipleAssetsDownload.unreadMessages
  );

  const [updateUserInfo] = useMutation(CHANGE_USER_INFO);

  const [favoriteAssetIds, setFavoriteAssetIds] = useState<string[] | null>(
    null
  );

  const setSelectedAssetType = (value: Options[]) =>
    dispatch(assignSelectedAssetType(value));
  const setSelectedAssetTag = (value: Options[]) =>
    dispatch(assignSelectedAssetTag(value));
  const setSelectedGender = (value: Options[]) =>
    dispatch(assignSelectedGender(value));
  const setSelectedStyle = (value: Options[]) =>
    dispatch(assignSelectedStyle(value));
  const setSelectedAge = (value: Options[]) =>
    dispatch(assignSelectedAge(value));
  const setSelectedBrand = (value: Options[]) =>
    dispatch(assignSelectedBrand(value));
  const setSelectedColorCode = (value: Options[]) =>
    dispatch(assignSelectedColorCode(value));
  const setSelectedColorName = (value: Options[]) =>
    dispatch(assignSelectedColorName(value));
  const setSelectedFabricContent = (value: Options[]) =>
    dispatch(assignSelectedFabricContent(value));
  const setSelectedFabricName = (value: Options[]) =>
    dispatch(assignSelectedFabricName(value));
  const setSelectedFileType = (value: Options[]) =>
    dispatch(assignSelectedFileType(value));
  const setSelectedLabelName = (value: Options[]) =>
    dispatch(assignSelectedLabelName(value));
  const setSelectedImageResolution = (value: Options[]) =>
    dispatch(assignSelectedImageResolution(value));
  const setSelectedSilhouette = (value: Options[]) =>
    dispatch(assignSelectedSilhouette(value));
  const setSelectedTotalModel = (value: Options[]) =>
    dispatch(assignSelectedTotalModel(value));
  const setSelectedFilters = (value: FilterOptionsInfo[]) =>
    dispatch(assignSelectedFilters(value));
  const setLastSelectedOption = (item: string) =>
    dispatch(assignLastSelectedOption(item));

  // Remove all filters
  const removeFilters = () => dispatch(removeAllFilters());
  const removeActiveFilters = () => dispatch(removeSelectedFilters());
  const setAssetIds = (ids: string[]) => dispatch(setAssetIdList(ids));

  const clearAllCookies = () => {
    document.cookie =
      "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

  const deleteRememberMeCookies = () => {
    removeCookie("rememberMeEmail");
    removeCookie("rememberMePassword");
  };

  const deleteOktaCookies = () => {
    removeCookie("JSESSIONID");
    removeCookie("sid");
    removeCookie("ln");
    removeCookie("srefresh");
    removeCookie("enduser_version");
    removeCookie("proximity_cc91527febee5e661206adb5f3f102b3");
    removeCookie("okta-oauth-nonce");
    removeCookie("t");
    removeCookie("Okta_Verify_Autopush_978541678");
    removeCookie("DT");
    removeCookie("okta-oauth-state");
    removeCookie("okta-oauth-redirect-params");
  };

  const {
    getUpdatedLanguageOptions,
    allRegionList,
    allLanguageList,
    loading,
    error,
    data,
    defaultRegion,
    defaultLanguage,
    userID,
  } = getRegionAndLanguageData(languageOptions);

  const getCounterForDownloads = async () => {
    if (userId === undefined) return;
    const response = await getDownloadsCounter(
      Number(userId),
      "expirationDate",
      "asc",
      source
    );

    if (response && typeof response === "number") {
      dispatch(setUnreadMessages(response));
    }
  };

  useEffect(() => {
    setSelectedRegionDeisplay(defaultRegion?.label);
  }, [defaultRegion, selectedRegionDeisplay]);

  useEffect(() => {
    if (FavoriteSlice.rawFavorites) {
      const temp: string[] = [];
      FavoriteSlice.rawFavorites.forEach((item) => {
        if (item.assetsListFav)
          item.assetsListFav.forEach((asset: any) => {
            if (asset.type === "video") temp.push(asset.id);
          });
      });
      setFavoriteAssetIds([...temp]);
    }
  }, [FavoriteSlice.rawFavorites]);

  useEffect(() => {
    if (favoriteAssetIds) {
      const path = "/api/favoritevideothumbnails";

      http
        .post<
          FavoriteVideoThumbnailRequest,
          FavoriteVideoThumbnailResponse
        >(path, { videoIds: favoriteAssetIds })
        .then((res) => {
          const response: FavoriteVideoThumbnailData[] = res
            ? (res.data as unknown as FavoriteVideoThumbnailData[])
            : [];
          const temp: VideoThumbNails = {};
          response.forEach((asset) => {
            temp[asset.assetId] = asset.videoThumbnailPath;
          });
          dispatch(addVideoThumbNails(temp));
        });
    }
  }, [favoriteAssetIds]);

  // Get the search value from the URL
  const location = useLocation();
  const currentSearch = new URLSearchParams(location.search).get("search");
  const { t, i18n } = useTranslation("common");
  const [searchValue, setSetSearchValue] = useState(currentSearch ?? "");

  const updateRegionChanges = () => {
    setSelectedRegionDeisplay(selectedRegion.label);
    updateUserInfo({
      variables: {
        // eslint-disable-next-line object-shorthand
        id: Number(userID),
        userInfo: {
          preferredLanguage: selectedLanguage.value,
          // eslint-disable-next-line object-shorthand
          primaryRegion: selectedRegion.value,
        },
      },
    });
    const userInfo = {
      ...UserInfoSlice,
      preferredLanguage: selectedLanguage.value,
      primaryRegion: selectedRegion.value,
    };
    dispatch(setUser(userInfo));
    i18n.changeLanguage(selectedLanguage.value);
    const element: HTMLElement = document.getElementById(
      "headerAccountRegion"
    ) as HTMLElement;
    element.click();
    // if (regionDropDown) {
    //   regionDropDown.click();
    // }
    // console.log("This is the selected language value", selectedLanguage.value);
  };

  const emptyFilterAttributes = () => {
    setSelectedAssetType([]);
    setSelectedAssetTag([]);
    setSelectedBrand([]);
    setSelectedImageResolution([]);
    setSelectedFileType([]);
    setSelectedStyle([]);
    setSelectedSilhouette([]);
    setSelectedColorName([]);
    setSelectedColorCode([]);
    setSelectedGender([]);
    setSelectedAge([]);
    setSelectedFabricName([]);
    setSelectedFabricContent([]);
    setSelectedTotalModel([]);
    setSelectedLabelName([]);
    setSelectedFilters([]);
    setLastSelectedOption("");
  };

  const handleRegionChange = (region: any) => {
    setIsSelectedRegionChanged(true);
    setSelectedRegion(region);
    setSelectedLanguage(null);
    emptyFilterAttributes();
  };

  const handleLanguageChange = (lang: any) => {
    if (!selectedRegion && defaultRegion) {
      setSelectedRegion(defaultRegion);
      setIsSelectedRegionChanged(true);
    }
    setSelectedLanguage(lang);
  };

  useEffect(() => {
    if (selectedRegion && getUpdatedLanguageOptions) {
      setSelectedLanguage(getUpdatedLanguageOptions(selectedRegion)[0]);
    }
  }, [selectedRegion]);

  const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    removeFilters();
    removeActiveFilters();
    setAssetIds([]);
    navigate(`/search?search=${searchValue}`, {
      state: { detail: searchValue },
    });
    scrollToTop();
  };

  const onSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSetSearchValue(event.target.value);
  };

  // sign out okta
  const oktaLogout = async () => {
    // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`\
    if (oktaAuth) {
      // console.log("okta auth: ", oktaAuth);
      // await oktaAuth.signOut();
    }
    navigate(`/login`);
  };
  const oktaDeleteSessions = async () => {
    oktaAuth.tokenManager.clear();
  };

  const englishLanguage = new Array(1).fill({
    value: "en_US",
    label: "English",
  });

  // useEffect(() => {
  //   console.log("language list: ", allLanguageList, " vs ", englishLanguage);
  // }, [allLanguageList]);

  // get list of regions
  const RegionLanguages = () => (
    <>
      <Select
        classNamePrefix="regular-looking-dropdown"
        className="w-100"
        name="region"
        onChange={handleRegionChange}
        options={allRegionList}
        isMulti={false}
        value={selectedRegion || defaultRegion}
      />
      <Select
        classNamePrefix="regular-looking-dropdown"
        className="w-100 mt-3 mb-2"
        name="language"
        onChange={handleLanguageChange}
        // temporary hide other language options
        options={
          getUpdatedLanguageOptions && selectedRegion
            ? getUpdatedLanguageOptions(selectedRegion)
            : allLanguageList
        }
        // options={allLanguageList}
        isMulti={false}
        value={isSelectedRegionChanged ? selectedLanguage : defaultLanguage}
      />
    </>
  );

  const defaultFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.defaultFolderId
  );
  const currentFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.currentFolderId
  );
  const favorites = useAppSelector((state) => state.newFavorite.favorite);

  useEffect(() => {
    let tempCounter = 0;
    Object.values(favorites).forEach((item) => {
      tempCounter += item.assets.length;
    });
    if (tempCounter !== counter) setCounter(tempCounter);
  }, [favorites]);

  useEffect(() => {
    getCounterForDownloads();
  }, [userId]);

  useEffect(() => {
    if (userId && userId !== 0) {
      getFavoritesApiCall(
        userId as number,
        defaultFavoriteFolderId,
        currentFavoriteFolderId,
        (value: FavoriteInfoInterface) => dispatch(setFavorite(value)),
        (id: string) => dispatch(setDefaultFolderId(id)),
        (id: string) => dispatch(setCurrentFolderId(id))
      );
    }
  }, [userId]);

  const secureLS = SecureLS.getItem("user");
  const dataValue = secureLS ? JSON.parse(secureLS) : null;

  const renderChangeRegionIcon = () => (
    <span>
      <img width="22" src={globePic} alt="User" />{" "}
      <b>{`${selectedRegionDeisplay || ""}`}</b>
    </span>
  );

  const renderChangeRegion = () => (
    <DropdownButton
      show={showRegionLanguage as boolean}
      onToggle={() => setshowRegionLanguage(!showRegionLanguage)}
      id="headerAccountRegion"
      title={renderChangeRegionIcon()}
      autoClose="outside"
      ref={(dropdownRef: any) => {
        regionDropDown = dropdownRef;
      }}
    >
      <h5 className="mb-3">{t("Change Region")}</h5>
      <RegionLanguages />
      <p>{t("Not all styles or brands will be available in every region.")}</p>
      <button
        className="btn btn-blue"
        type="button"
        disabled={!(!!selectedRegion && !!selectedLanguage)}
        onClick={updateRegionChanges}
      >
        {t("Continue")}
      </button>
    </DropdownButton>
  );

  const renderHeaderUserTools = () => {
    if ((isSharedPage || isHowToPage) && !dataValue) {
      return (
        <div className="shared-profile-links">
          <button
            type="button"
            className="btn btn-lg btn-blue"
            onClick={() => navigate("/login")}
          >
            Log In
          </button>
          <button
            className="btn btn-lg shared-btn-reversed btn-blue"
            type="button"
            onClick={() => navigate("/register")}
          >
            Sign Up
          </button>
        </div>
      );
    }
    return (
      <>
        <div className="search">
          <form action="" onSubmit={handleSearch}>
            <input
              type="text"
              className="search-input form-control"
              id="exampleFormControlInput1"
              placeholder={t("Search...")}
              value={searchValue}
              onChange={onSearchValueChange}
            />
          </form>
        </div>
        <div className="account-links">
          <Link to="/howto/register" className="px-1 me-3">
            <img className="howto" src={HowToIcon} alt="HowToIcon" />
          </Link>
          <Link to="/account/profile" className="user px-1 me-3">
            <img src={userPic} alt="User" />
          </Link>
          {/* <img className="" width="22" src={globePic} alt="User" /> */}
          {/* {renderChangeRegionIcon()} */}

          <div className="region">{renderChangeRegion()}</div>
          <Link
            to="/downloads"
            className="user like-toggle position-relative ms-2 me-3"
          >
            <img
              width="26"
              className="img-toggle d-block"
              src={downloadIcon}
              alt="Like"
            />
            <div className="circle-count"> {unreadMessages} </div>
          </Link>
          <Link
            to="/favorites"
            className="user like-toggle position-relative ms-3 me-3"
          >
            <img
              width="26"
              className="img-toggle d-block"
              src={counter > 0 ? fillHeartPic : noFillHeartPic}
              alt="Like"
            />
            <div className="circle-count"> {counter} </div>
          </Link>
          <Link
            to="/login"
            className="user ms-3"
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
              event.preventDefault();
              event.stopPropagation();
              deleteRememberMeCookies();
              deleteOktaCookies();
              SecureLS.removeItem("user");
              SecureLS.removeItem("okta-cache-storage");
              SecureLS.removeItem("okta-token-storage");
              oktaLogout();
              clearAllCookies();
              dispatch(removeProcesses());
            }}
          >
            <img src={signOutPic} alt="Sign-Out" />
          </Link>
        </div>
      </>
    );
  };

  return (
    <header className="d-flex flex-column flex-md-row justify-content-between align-items-center">
      <div
        className="logo mb-md-0 mb-3"
        onClick={() => {
          scrollToTop();
          if (window.location.pathname === "/downloads") {
            dispatch(removeProcesses());
          }
          navigate("/");
        }}
        onKeyPress={() => {}}
        role="button"
        tabIndex={-1}
      >
        <img src={companyLogo} alt="Gildan Brands" />
      </div>
      {renderHeaderUserTools()}
    </header>
  );
}
