import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterOptionsInfo, Options } from "../models/interfaces/common";

interface FilterOptionsInterface {
  assetTypeOptions: Options[];
  assetTagOptions: Options[];
  styleOptions: Options[];
  genderOptions: Options[];
  ageOptions: Options[];
  brandOptions: Options[];
  colorCodeOptions: Options[];
  colorNameOptions: Options[];
  fabricContentOptions: Options[];
  fabricNameOptions: Options[];
  fileTypeOptions: Options[];
  labelNameOptions: Options[];
  imageResolutionOptions: Options[];
  silhouetteOptions: Options[];
  totalModelOptions: Options[];
  selectedAssetType: Options[];
  selectedAssetTag: Options[];
  selectedGender: Options[];
  selectedStyle: Options[];
  selectedAge: Options[];
  selectedBrand: Options[];
  selectedColorCode: Options[];
  selectedColorName: Options[];
  selectedFabricContent: Options[];
  selectedFabricName: Options[];
  selectedFileType: Options[];
  selectedLabelName: Options[];
  selectedImageResolution: Options[];
  selectedSilhouette: Options[];
  selectedTotalModel: Options[];
  selectedFilters: FilterOptionsInfo[];
  areFiltersSelected: boolean;
  lastSelectedOption: string;
}
const initialState: FilterOptionsInterface = {
  assetTypeOptions: [],
  assetTagOptions: [],
  styleOptions: [],
  genderOptions: [],
  ageOptions: [],
  brandOptions: [],
  colorCodeOptions: [],
  colorNameOptions: [],
  fabricContentOptions: [],
  fabricNameOptions: [],
  fileTypeOptions: [],
  labelNameOptions: [],
  imageResolutionOptions: [],
  silhouetteOptions: [],
  totalModelOptions: [],
  selectedAssetType: [],
  selectedAssetTag: [],
  selectedGender: [],
  selectedStyle: [],
  selectedAge: [],
  selectedBrand: [],
  selectedColorCode: [],
  selectedColorName: [],
  selectedFabricContent: [],
  selectedFabricName: [],
  selectedFileType: [],
  selectedLabelName: [],
  selectedImageResolution: [],
  selectedSilhouette: [],
  selectedTotalModel: [],
  selectedFilters: [],
  areFiltersSelected: false,
  lastSelectedOption: "",
};

const filterOptions = createSlice({
  name: "filterOptions",
  initialState,
  reducers: {
    assignAssetTypeOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.assetTypeOptions = payload;
      }
    },
    assignAssetTagOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.assetTagOptions = payload;
      }
    },
    assignStyleOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.styleOptions = payload;
      }
    },
    assignGenderOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.genderOptions = payload;
      }
    },
    assignAgeOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.ageOptions = payload;
      }
    },
    assignBrandOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.brandOptions = payload;
      }
    },
    assignColorCodeOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.colorCodeOptions = payload;
      }
    },
    assignColorNameOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.colorNameOptions = payload;
      }
    },
    assignFabricContentOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.fabricContentOptions = payload;
      }
    },
    assignFabricNameOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.fabricNameOptions = payload;
      }
    },
    assignFileTypeOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.fileTypeOptions = payload;
      }
    },
    assignLabelNameOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.labelNameOptions = payload;
      }
    },
    assignImageResolutionOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.imageResolutionOptions = payload;
      }
    },
    assignSilhouetteOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.silhouetteOptions = payload;
      }
    },
    assignTotalModelOptions(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.totalModelOptions = payload;
      }
    },
    assignSelectedAssetType(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedAssetType = payload;
      }
    },
    assignSelectedAssetTag(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedAssetTag = payload;
      }
    },
    assignSelectedGender(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedGender = payload;
      }
    },
    assignSelectedStyle(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedStyle = payload;
      }
    },
    assignSelectedAge(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedAge = payload;
      }
    },
    assignSelectedBrand(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedBrand = payload;
      }
    },
    assignSelectedColorCode(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedColorCode = payload;
      }
    },
    assignSelectedColorName(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedColorName = payload;
      }
    },
    assignSelectedFabricContent(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedFabricContent = payload;
      }
    },
    assignSelectedFabricName(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedFabricName = payload;
      }
    },
    assignSelectedFileType(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedFileType = payload;
      }
    },
    assignSelectedLabelName(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedLabelName = payload;
      }
    },
    assignSelectedImageResolution(
      state,
      { payload }: PayloadAction<Options[]>
    ) {
      if (payload) {
        state.selectedImageResolution = payload;
      }
    },
    assignSelectedSilhouette(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedSilhouette = payload;
      }
    },
    assignSelectedTotalModel(state, { payload }: PayloadAction<Options[]>) {
      if (payload) {
        state.selectedTotalModel = payload;
      }
    },
    assignSelectedFilters(
      state,
      { payload }: PayloadAction<FilterOptionsInfo[]>
    ) {
      if (payload) {
        state.selectedFilters = payload;
      }
    },
    removeSelectedFilters(state) {
      state.selectedAssetType = [];
      state.selectedAssetTag = [];
      state.selectedGender = [];
      state.selectedStyle = [];
      state.selectedAge = [];
      state.selectedBrand = [];
      state.selectedColorCode = [];
      state.selectedColorName = [];
      state.selectedFabricContent = [];
      state.selectedFabricName = [];
      state.selectedFileType = [];
      state.selectedLabelName = [];
      state.selectedImageResolution = [];
      state.selectedSilhouette = [];
      state.selectedTotalModel = [];
      state.selectedFilters = [];
    },
    setAreFiltersSelected(state, { payload }: PayloadAction<boolean>) {
      state.areFiltersSelected = payload;
    },
    assignLastSelectedOption(state, { payload }: PayloadAction<string>) {
      state.lastSelectedOption = payload;
    },
  },
});

export const {
  assignAssetTypeOptions,
  assignAssetTagOptions,
  assignStyleOptions,
  assignGenderOptions,
  assignAgeOptions,
  assignBrandOptions,
  assignColorCodeOptions,
  assignColorNameOptions,
  assignFabricContentOptions,
  assignFabricNameOptions,
  assignFileTypeOptions,
  assignLabelNameOptions,
  assignImageResolutionOptions,
  assignSilhouetteOptions,
  assignTotalModelOptions,
  assignSelectedAssetType,
  assignSelectedAssetTag,
  assignSelectedGender,
  assignSelectedStyle,
  assignSelectedAge,
  assignSelectedBrand,
  assignSelectedColorCode,
  assignSelectedColorName,
  assignSelectedFabricContent,
  assignSelectedFabricName,
  assignSelectedFileType,
  assignSelectedLabelName,
  assignSelectedImageResolution,
  assignSelectedSilhouette,
  assignSelectedTotalModel,
  assignSelectedFilters,
  removeSelectedFilters,
  setAreFiltersSelected,
  assignLastSelectedOption,
} = filterOptions.actions;

export default filterOptions.reducer;
