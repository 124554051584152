import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../rootReducer";
import { ModalProps } from "../../types/common";

const HelpModal = ({
  isOpen,
  toggle,
  modalClass = "helpModal",
  keyboard = true,
  backdrop = "static",
  centered = true,
}: ModalProps) => {
  const { t, i18n } = useTranslation("common");
  const UserInfoSlice = useSelector((state: RootState) => state.user);
  const LocaleSlice = useSelector((state: RootState) => state.locale);
  useEffect(() => {
    i18n.changeLanguage(LocaleSlice?.language);
    // console.log("after translation: ", LocaleSlice?.language);
  }, [LocaleSlice?.language]);
  return (
    <Modal
      show={isOpen}
      onHide={toggle}
      className={`${modalClass}`}
      backdrop={backdrop}
      centered={centered}
      keyboard={keyboard}
    >
      <Modal.Header closeButton>
        <h4 className="modal-title">{t("Help")}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6 className="m-0">{t("Continuous Select")}</h6>
          <p className="m-0">{t("Shift + Click")}</p>
        </div>
        <hr />
        <div className="exampleSelectOne exampleSelect">
          <div className="exampleSelectCard blue" />
          <div className="exampleSelectCard blue" />
          <div className="exampleSelectCard blue" />
          <div className="exampleSelectCard blue" />
          <div className="exampleSelectCard blue" />
          <div className="exampleSelectCard blue" />
          <div className="exampleSelectCard gray" />
          <div className="exampleSelectCard gray" />
        </div>
        <p className="small mb-5">{t("helptext1")}</p>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6 className="m-0">{t("Multi-Select")}</h6>
          <p className="m-0">{t("Cmd/Ctrl + Click")}</p>
        </div>
        <hr />
        <div className="exampleSelectOne exampleSelect">
          <div className="exampleSelectCard blue" />
          <div className="exampleSelectCard gray" />
          <div className="exampleSelectCard gray" />
          <div className="exampleSelectCard blue" />
          <div className="exampleSelectCard blue" />
          <div className="exampleSelectCard gray" />
          <div className="exampleSelectCard blue" />
          <div className="exampleSelectCard gray" />
        </div>
        <p className="small mb-5">{t("helptext2")}</p>
      </Modal.Body>
    </Modal>
  );
};

export default HelpModal;
