import React, { useState } from "react";
// Types
import { useQuery } from "@apollo/client";
// @ts-ignore
import DOMPurify from "dompurify";
import { RegistrationFormProps } from "../../../types/registration";
// Util
import { getInputProps } from "../../../utils/forms";
import { LATEST_TERMS_AND_CONDITIONS } from "../../../services/graphql/pimcoreDam";

const TermsAndConditionRegistration = ({ form }: RegistrationFormProps) => {
  const { id, name, onBlur, value, className, onChange } = getInputProps(
    "termsAndCondition",
    form,
    "form-check-input"
  );

  // retrieve the current terms & conditions content
  const currentTermsAndConditions = () => {
    const { loading, error, data } = useQuery(LATEST_TERMS_AND_CONDITIONS, {
      fetchPolicy: "network-only",
    });
    if (loading) {
      return "loading...";
    }
    if (error) return "error occurred...";
    // got to edges
    const termsAndConditions: any = data.getTermsConditionsListing.edges[0];
    const termsAndConditionsContent = DOMPurify.sanitize(
      termsAndConditions.node.termsConditionsContent
    );
    return (
      <>
        <div>
          <h1>Terms &#38; Conditions</h1>
        </div>
        <div className="row form-row mb-3">
          <div className="col-12">
            <div className="mb-4 terms-and-conditions" />
          </div>
          <div className="col-12">
            {/* eslint-disable react/no-danger */}
            <div
              dangerouslySetInnerHTML={{ __html: termsAndConditionsContent }}
              className="registration-dynamic-terms-height"
            />
            <div className="form-check mb-4 mt-3">
              <input
                type="checkbox"
                checked={value}
                id={id}
                name={name}
                onChange={async (event) => {
                  form.setFieldValue(
                    "termsAndConditionsId",
                    termsAndConditions.node.id
                  );
                  await form.setFieldValue(
                    "termsAndCondition",
                    event.target.checked
                  );
                }}
                onBlur={onBlur}
              />
              <label
                className="form-check-label terms-and-conditions-agreement"
                htmlFor="termsAndCondition"
              >
                <strong>I agree to the Terms and Conditions</strong>
              </label>
            </div>
          </div>
        </div>
      </>
    );
  };

  return <>{currentTermsAndConditions()}</>;
};

export default TermsAndConditionRegistration;
