/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { ErrorMessage } from "formik";
// Types
import type { ReactI18NextChild } from "react-i18next";
import { RegistrationFormProps } from "../../../types/registration";
// Util
import { getInputProps } from "../../../utils/forms";
import useDebounce from "../../../hooks/useDebounce";
import http from "../../../services/api";

const UserProfileRegistration = ({
  form,
  updateAllowProceed,
}: RegistrationFormProps) => {
  const delay = 500;
  const debouncedUserName = useDebounce(form.values.userName, delay);
  const debouncedEmail = useDebounce(form.values.email, delay);
  const [isUsernameExists, setIsUsernameExists] = useState<boolean>(true);
  const [isEmailExists, setIsEmailExists] = useState<boolean>(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(
    null
  );
  const [usernameErrorMessage, setUsernameErrorMessage] = useState<
    string | null
  >(null);
  const [lastModifiedForUsernameOrEmail, setLastModifiedForUsernameOrEmail] =
    useState<string | null>(null);

  const renderUserNameError = () => {
    if (isUsernameExists && usernameErrorMessage) {
      return (
        <div className="text-danger mt-1 fs-13 fw-bold">
          {usernameErrorMessage}
        </div>
      );
    }
    if (form.touched.userName && form.errors.userName) {
      return (
        <div className="text-danger mt-1 fs-13 fw-bold">
          {form.errors.userName as ReactI18NextChild}
        </div>
      );
    }
    return null;
  };

  const renderEmailError = () => {
    if (isEmailExists && emailErrorMessage) {
      return (
        <div className="text-danger mt-1 fs-13 fw-bold">
          {emailErrorMessage}
        </div>
      );
    }
    if (form.touched.email && form.errors.email) {
      return (
        <div className="text-danger mt-1 fs-13 fw-bold">
          {form.errors.email as ReactI18NextChild}
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    const path = "/api/isemailexist";
    if (debouncedEmail) {
      http
        .post(path, {
          email: debouncedEmail,
        })
        .then(async (res: any) => {
          if (res) {
            setIsEmailExists(!res.success);
            setEmailErrorMessage(res.message);
            if (res.code !== 200) {
              if (updateAllowProceed) updateAllowProceed(false);
            } else {
              if (updateAllowProceed && !isUsernameExists)
                updateAllowProceed(true);
            }
          }
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  }, [debouncedEmail]);

  useEffect(() => {
    const path = "/api/isusernameexist";
    if (debouncedUserName) {
      http
        .post(path, {
          username: debouncedUserName,
        })
        .then(async (res: any) => {
          if (res) {
            setIsUsernameExists(!res.success);
            setUsernameErrorMessage(res.message);
            if (res.code !== 200) {
              if (updateAllowProceed) updateAllowProceed(false);
            } else {
              if (updateAllowProceed && !isEmailExists)
                updateAllowProceed(true);
            }
          }
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  }, [debouncedUserName]);

  const focusHandler = async (event: React.FocusEvent<HTMLInputElement>) => {
    if (["userName", "email"].includes(event.target.name))
      setLastModifiedForUsernameOrEmail(event.target.name);
  };

  return (
    <>
      <div>
        <h1>User Profile</h1>
      </div>
      <div className="row form-row mb-3">
        <div className="col-md-6 col-12">
          <div className="mb-4">
            <label htmlFor="firstName" className="form-label">
              First Name
            </label>
            <input type="text" {...getInputProps("firstName", form)} />
            {form.touched.firstName && form.errors.firstName ? (
              <div className="text-danger mt-1 fs-13 fw-bold">
                {form.errors.firstName as ReactI18NextChild}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="mb-4">
            <label htmlFor="lastName" className="form-label">
              Last Name
            </label>
            <input type="text" {...getInputProps("lastName", form)} />
            {form.touched.lastName && form.errors.lastName ? (
              <div className="text-danger mt-1 fs-13 fw-bold">
                {form.errors.lastName as ReactI18NextChild}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="mb-4">
            <label htmlFor="userName" className="form-label">
              Username
            </label>
            <input
              type="text"
              {...getInputProps("userName", form)}
              onFocus={focusHandler}
            />
            {renderUserNameError()}
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="mb-4">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="text"
              {...getInputProps("email", form)}
              onFocus={focusHandler}
            />
            {renderEmailError()}
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="mb-4">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input type="password" {...getInputProps("password", form)} />
            {form.touched.password && form.errors.password ? (
              <div className="text-danger mt-1 fs-13 fw-bold">
                {form.errors.password as ReactI18NextChild}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="mb-4">
            <label htmlFor="confirmPassword" className="form-label">
              Verify Password
            </label>
            <input
              type="password"
              {...getInputProps("confirmPassword", form)}
            />
            {form.touched.confirmPassword && form.errors.confirmPassword ? (
              <div className="text-danger mt-1 fs-13 fw-bold">
                {form.errors.confirmPassword as ReactI18NextChild}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileRegistration;
