import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

export default function HowToRegister() {
  const { t, i18n } = useTranslation("common");

  return (
    <main className="how-to-container">
      <div className="steps-container">
        <div className="steps-column">
          <div className="step">
            <h4 className="step-title">{t("Step 1")}</h4>
            <p className="step-description">
              {t("Register-Step1")}{" "}
              <a
                className="step-description"
                href="https://dp.gildan.com/login"
              >
                https://dp.gildan.com/login
              </a>
              .
            </p>
          </div>
          <div className="step">
            <h4 className="step-title">{t("Step 2")}</h4>
            <p className="step-description">{t("Register-Step2")}</p>
          </div>
        </div>
        <div className="steps-column">
          <div className="step">
            <h4 className="step-title">{t("Step 3")}</h4>
            <p className="step-description">{t("Register-Step3")}</p>
          </div>
          <div className="step">
            <h4 className="step-title">{t("Step 4")}</h4>
            <p className="step-description">{t("Register-Step4")}</p>
          </div>
        </div>
      </div>
      <div className="how-to-video">
        <h4 className="video-title">{t("InstructionalVideo")}</h4>
        <ReactPlayer
          height="480px"
          width="100%"
          url="https://youtu.be/p0j1CmfbMSk"
        />
      </div>
    </main>
  );
}
