/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store";
import AssetCard from "../../components/AssetCard";
import CatalogSlider from "../../components/CatalogSlider";
import { FullMetadataValues, IAssetCard } from "../../models/interfaces/common";
import { setInPageNavShow } from "../../components/inPageNavSlice";
import { sidebarList, sidebarItemProps, getType } from "../../utils/sidebar";
import { setBreadcrumbState } from "../../components/breadcrumbs/breadcrumbSlice";
import { SideBarHash } from "../sidebarSlice";
import { scrollToTop } from "../../util";
import { removeAllFilters } from "../filterSlice";
import { removeSelectedFilters } from "../filterOptionsSlice";
import { setAssetIdList } from "../assetsSlice";

interface Hash {
  [details: string]: Props;
}

interface Props {
  title: string;
  count: number;
  size: number;
  src: string | undefined;
  path: string;
}

export default function Catalogs() {
  const dispatch = useAppDispatch();
  const mainSideBarItems = useAppSelector((state) => state.sidebar.hash);
  const [dataObj, setDataObj] = useState<Hash | null>(null);
  const [mainSideBarRender, setMainSideBarRender] = useState<
    sidebarItemProps[]
  >([]);
  const { t, i18n } = useTranslation("common");
  dispatch(setBreadcrumbState(false));

  const removeFilters = () => dispatch(removeAllFilters());
  const removeActiveFilters = () => dispatch(removeSelectedFilters());
  const setAssetIds = (ids: string[]) => dispatch(setAssetIdList(ids));

  useEffect(() => {
    dispatch(setInPageNavShow(false));
  });

  const sampleFunction = useCallback((sidebarItems: SideBarHash | null) => {
    if (sidebarItems && !dataObj) {
      const tempData: Hash = {};
      sidebarList.forEach((item: sidebarItemProps) => {
        if (sidebarItems[item.text]?.count > 0) {
          tempData[item.text] = {
            title: item.text,
            count: sidebarItems[item.text] ? sidebarItems[item.text]?.count : 0,
            size: sidebarItems[item.text]
              ? (sidebarItems[item.text].size as number)
              : 0,
            src: item.src,
            path: item.path,
          };
        }
      });
      setDataObj({ ...tempData });
    }
  }, []);

  useEffect(() => {
    sampleFunction(mainSideBarItems);
  }, [mainSideBarItems]);

  const AssetsCards = () => (
    <>
      {dataObj &&
        Object.keys(dataObj).map((key) => (
          <div
            key={dataObj[key].title}
            className="col-xl-4 col-lg-4 col-md-6 col-12"
          >
            <AssetCard {...dataObj[key]} />
          </div>
        ))}
    </>
  );

  return (
    <>
      <div className="cp container-fluid">
        <div className="row">
          <AssetsCards />
        </div>
      </div>
      <div className="recently-added">
        <div className="ra-header">
          <h3>{t("Recently Added")}</h3>
          <Link
            to="/viewall"
            className="view-all"
            onClick={() => {
              scrollToTop();
              removeFilters();
              removeActiveFilters();
              setAssetIds([]);
            }}
          >
            {t("View All")}
          </Link>
        </div>
        <CatalogSlider />
      </div>
    </>
  );
}
