import AssetImageIcon from "../assets/img/GB-Pimcore-Icons-Images.svg";
import AssetVideoIcon from "../assets/img/GB-Pimcore-Icons-Videos.svg";
import AssetLogoGuidelinesIcon from "../assets/img/GB-Pimcore-Icons-Logos-Guidelines.svg";
import AssetSwatchcardsIcon from "../assets/img/GB-Pimcore-Icons-Swatchcards.svg";
import AssetSocialMediaIcon from "../assets/img/GB-Pimcore-Icons-Social-Media.svg";
import AssetEmailIcon from "../assets/img/GB-Pimcore-Icons-Emails.svg";
import AssetWebBannersIcon from "../assets/img/GB-Pimcore-Icons-Web-Banners.svg";
import AssetAdsSellsheetsIcon from "../assets/img/GB-Pimcore-Icons-Ads-Sellsheets.svg";
import AssetHangtagsIcon from "../assets/img/GB-Pimcore-Icons-Hangtags.svg";
import AssetSignageIcon from "../assets/img/GB-Pimcore-Icons-Signage.svg";
import { SideBarResponse } from "../models/interfaces/common";
import http from "../services/api";

export type sidebarItemProps = {
  id?: number;
  text: string;
  count: number;
  path: string;
  size?: number;
  src?: string;
};

export const sidebarList: sidebarItemProps[] = [
  {
    text: "Imagery",
    count: 30,
    path: "/catalogs/imagery",
    src: AssetImageIcon,
  },
  {
    text: "Videos",
    count: 30,
    path: "/catalogs/videos",
    src: AssetVideoIcon,
  },
  {
    text: "Logos & Guidelines",
    count: 30,
    path: "/catalogs/logosguidelines",
    src: AssetLogoGuidelinesIcon,
  },
  {
    text: "Swatchcards & Catalogs",
    count: 30,
    path: "/catalogs/swatch-cards-catalogs",
    src: AssetSwatchcardsIcon,
  },
  {
    text: "Social Media",
    count: 30,
    path: "/catalogs/socialmedia",
    src: AssetSocialMediaIcon,
  },
  {
    text: "Email Ads",
    count: 30,
    path: "/catalogs/emails",
    src: AssetEmailIcon,
  },
  {
    text: "Web Banners",
    count: 30,
    path: "/catalogs/webbanners",
    src: AssetWebBannersIcon,
  },
  {
    text: "Ads & Sell Sheets",
    count: 30,
    path: "/catalogs/sellsheets",
    src: AssetAdsSellsheetsIcon,
  },
  {
    text: "Posters & Signage",
    count: 30,
    path: "/catalogs/posterssignage",
    src: AssetSignageIcon,
  },
  {
    text: "Hangtags",
    count: 30,
    path: "/catalogs/hangtags",
    src: AssetHangtagsIcon,
  },
];

export const getValues = (name: string) => {
  switch (name) {
    case "Imagery":
      return { path: "/catalogs/imagery", src: AssetImageIcon, name: "image" };
    case "Videos":
      return { path: "/catalogs/videos", src: AssetVideoIcon, name: "video" };
    case "Logos & Guidelines":
      return {
        path: "/catalogs/logosguidelines",
        src: AssetLogoGuidelinesIcon,
        name: "guidelines",
      };
    case "Swatchcards & Catalogs":
      return {
        path: "/catalogs/swatch-cards-catalogs",
        src: AssetSwatchcardsIcon,
        name: "swatchcards",
      };
    case "Social Media":
      return {
        path: "/catalogs/socialmedia",
        src: AssetSocialMediaIcon,
        name: "socialmedia",
      };
    case "Email Ads":
      return {
        path: "/catalogs/emails",
        src: AssetEmailIcon,
        name: "email",
      };
    case "Web Banners":
      return {
        path: "/catalogs/webbanners",
        src: AssetWebBannersIcon,
        name: "webbanner",
      };
    case "Ads & Sell Sheets":
      return {
        path: "/catalogs/sellsheets",
        src: AssetAdsSellsheetsIcon,
        name: "sellsheets",
      };
    case "Posters & Signage":
      return {
        path: "/catalogs/posterssignage",
        src: AssetAdsSellsheetsIcon,
        name: "postersignage",
      };
    case "Hangtags":
      return {
        path: "/catalogs/hangtags",
        src: AssetAdsSellsheetsIcon,
        name: "hangtags",
      };
    default:
      return null;
  }
};

export const getType = (name: string) => {
  switch (name) {
    case "Imagery":
      return "image";
    case "Videos":
      return "video";
    case "Guidelines":
      return "guidelines";
    case "Swatchcards & Catalogs":
      return "swatchcards";
    case "Social Media":
      return "socialMedia";
    case "Email Ads":
      return "email";
    case "Web Banner":
      return "webbanner";
    case "Sell Sheets":
      return "sellsheets";
    case "Posters & Signage":
      return "posterssignage";
    case "Hangtags":
      return "hangtags";
    default:
      return name;
  }
};

export const categoryMapping = (value: string) => {
  switch (value) {
    case "/catalogs/imagery":
      return "Imagery";
    case "/catalogs/videos":
      return "Videos";
    case "/catalogs/logosguidelines":
      return "Logos & Guidelines";
    case "/catalogs/swatch-cards-catalogs":
      return "Swatchcards & Catalogs";
    case "/catalogs/socialmedia":
      return "Social Media";
    case "/catalogs/emails":
      return "Email Ads";
    case "/catalogs/webbanners":
      return "Web Banners";
    case "/catalogs/sellsheets":
      return "Ads & Sell Sheets";
    case "/catalogs/posterssignage":
      return "Posters & Signage";
    case "/catalogs/hangtags":
      return "Hangtags";
    default:
      return "Imagery";
  }
};
