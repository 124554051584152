/* eslint-disable no-unreachable */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { Collapse } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { useLocation } from "react-router-dom";
import http from "../../services/api";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  FilterOptionsInfo,
  Options,
  AssetInfo,
} from "../../models/interfaces/common";
import { setAssetIdList, setLoaderValue } from "../../pages/assetsSlice";
import { categoryMapping } from "../../utils/sidebar";
import {
  assignAgeOptions,
  assignAssetTypeOptions,
  assignAssetTagOptions,
  assignBrandOptions,
  assignColorCodeOptions,
  assignColorNameOptions,
  assignFabricContentOptions,
  assignFabricNameOptions,
  assignFileTypeOptions,
  assignGenderOptions,
  assignLabelNameOptions,
  assignLastSelectedOption,
  assignImageResolutionOptions,
  assignSelectedAge,
  assignSelectedAssetType,
  assignSelectedAssetTag,
  assignSelectedBrand,
  assignSelectedColorCode,
  assignSelectedColorName,
  assignSelectedFabricContent,
  assignSelectedFabricName,
  assignSelectedFileType,
  assignSelectedFilters,
  assignSelectedGender,
  assignSelectedLabelName,
  assignSelectedImageResolution,
  assignSelectedSilhouette,
  assignSelectedStyle,
  assignSelectedTotalModel,
  assignSilhouetteOptions,
  assignStyleOptions,
  assignTotalModelOptions,
  setAreFiltersSelected,
} from "../../pages/filterOptionsSlice";
import { setFilterDropdownStatus } from "../../pages/filterSlice";
import {
  isArrayEqual,
  quickSortOptions,
  getSearchResultAssetIds,
} from "../../utils/catalog";

interface FiltersDataInterface {
  [detailed: string]: string[];
}

const sampleFunctionApi = async (
  userId: number,
  region: string,
  categoryId: string,
  filtersData: FiltersDataInterface,
  lastSelectedOption: string,
  globalAssetIds: string[],
  location: string,
  selectedAssetType: Options[],
  selectedAssetTag: Options[],
  selectedStyle: Options[],
  selectedGender: Options[],
  selectedAge: Options[],
  selectedBrand: Options[],
  selectedColorCode: Options[],
  selectedColorName: Options[],
  selectedFabricContent: Options[],
  selectedFabricName: Options[],
  selectedFileType: Options[],
  selectedLabelName: Options[],
  selectedImageResolution: Options[],
  selectedSilhouette: Options[],
  selectedTotalModel: Options[],
  setAssetTypeOptions: (item: Options[]) => void,
  setAssetTagOptions: (item: Options[]) => void,
  setStyleOptions: (item: Options[]) => void,
  setGenderOptions: (item: Options[]) => void,
  setAgeOptions: (item: Options[]) => void,
  setBrandOptions: (item: Options[]) => void,
  setColorCodeOptions: (item: Options[]) => void,
  setColorNameOptions: (item: Options[]) => void,
  setFabricContentOptions: (item: Options[]) => void,
  setFabricNameOptions: (item: Options[]) => void,
  setFileTypeOptions: (item: Options[]) => void,
  setLabelNameOptions: (item: Options[]) => void,
  setImageResolutionOptions: (item: Options[]) => void,
  setSilhouetteOptions: (item: Options[]) => void,
  setTotalModelOptions: (item: Options[]) => void,
  setAssets: (item: string[]) => void,
  setAssetIds: (item: string[]) => void,
  setLoader: (item: boolean) => void,
  setIfFiltersAreSelected: (item: boolean) => void,
  // setLastSelectedOption: (item: string) => void,
  source: CancelTokenSource,
  isCancelled: (arg0: any) => boolean,
  searchValue?: string | null,
  primaryRegion?: string
) => {
  const path =
    // eslint-disable-next-line no-nested-ternary
    location === "/viewall"
      ? "/api/recentlyaddedfilterattributesmodified"
      : location === "/search"
        ? "/api/searchfilter"
        : "/api/filterattributesmodified";

  interface FilterRequestInterface {
    userId: number;
    region: string;
    categoryId: string;
    filtersData: {
      [detailed: string]: string[];
    };
  }

  interface SearchFilterRequestInterface {
    userId: number;
    region: string;
    assetIds: string[];
    filtersData: {
      [detailed: string]: string[];
    };
  }

  interface responseData {
    asset: string[];
    region: string[];
    assetType: string[];
    assetTag: string[];
    style: string[];
    gender: string[];
    age: string[];
    brand: string[];
    colorCode: string[];
    colorName: string[];
    fabricContent: string[];
    fabricName: string[];
    fileType: string[];
    labelName: string[];
    imageResolution: string[];
    silhouette: string[];
    totalModel: string[];
    brandPortalCategoryId: string[];
  }

  interface FilterResponseInterface {
    success: boolean;
    message: string;
    data: responseData[];
    code: number;
  }

  const filterRequest: FilterRequestInterface = {
    userId,
    region,
    categoryId,
    filtersData,
  };

  let assetIds: string[] = [];
  if (location === "/search" && searchValue !== null) {
    const searchResultAssetIds = await getSearchResultAssetIds(
      (searchValue as string).toLowerCase(),
      Number(primaryRegion)
    );

    if (Array.isArray(searchResultAssetIds)) {
      assetIds = searchResultAssetIds;
    }
  }

  const searchFilterRequest: SearchFilterRequestInterface = {
    userId,
    region,
    assetIds,
    filtersData,
  };

  const sampleArray: string[] = [];

  Object.values(filtersData).forEach((item) => {
    item.forEach((value) => {
      sampleArray.push(value);
    });
  });

  setIfFiltersAreSelected(sampleArray.length !== 0);

  setLoader(true);

  await http
    .post<FilterRequestInterface, FilterResponseInterface>(
      path,
      location === "/search" ? searchFilterRequest : filterRequest,
      { cancelToken: source.token }
    )
    .then((res) => {
      if (res) {
        const response = res.data as unknown as responseData;

        const assetTypeOptions = new Set<string>();
        const assetTagOptions = new Set<string>();
        const styleOptions = new Set<string>();
        const genderOptions = new Set<string>();
        const ageOptions = new Set<string>();
        const brandOptions = new Set<string>();
        const colorCodeOptions = new Set<string>();
        const colorNameOptions = new Set<string>();
        const fabricContentOptions = new Set<string>();
        const fabricNameOptions = new Set<string>();
        const fileTypeOptions = new Set<string>();
        const labelNameOptions = new Set<string>();
        const imageResolutionOptions = new Set<string>();
        const silhouetteOptions = new Set<string>();
        const totalModelOptions = new Set<string>();
        const assetOptions = new Set<string>();

        if (response.assetType)
          response.assetType.forEach((item) => assetTypeOptions.add(item));
        if (response.assetTag)
          response.assetTag.forEach((item) => assetTagOptions.add(item));
        if (response.style)
          response.style.forEach((item) => styleOptions.add(item));
        if (response.gender)
          response.gender.forEach((item) => genderOptions.add(item));
        if (response.age) response.age.forEach((item) => ageOptions.add(item));
        if (response.brand)
          response.brand.forEach((item) => brandOptions.add(item));
        if (response.colorCode)
          response.colorCode.forEach((item) => colorCodeOptions.add(item));
        if (response.colorName)
          response.colorName.forEach((item) => colorNameOptions.add(item));
        if (response.fabricContent)
          response.fabricContent.forEach((item) =>
            fabricContentOptions.add(item)
          );
        if (response.fabricName)
          response.fabricName.forEach((item) => fabricNameOptions.add(item));
        if (response.fileType)
          response.fileType.forEach((item) => fileTypeOptions.add(item));
        if (response.labelName)
          response.labelName.forEach((item) => labelNameOptions.add(item));
        if (response.imageResolution)
          response.imageResolution.forEach((item) =>
            imageResolutionOptions.add(item)
          );
        if (response.silhouette)
          response.silhouette.forEach((item) => silhouetteOptions.add(item));
        if (response.totalModel)
          response.totalModel.forEach((item) => totalModelOptions.add(item));
        if (response.asset)
          response.asset.forEach((item) => assetOptions.add(item));

        if (
          lastSelectedOption !== "assetType" ||
          (lastSelectedOption === "assetType" &&
            assetTypeOptions.size > selectedAssetType.length)
        ) {
          const tempArray = selectedAssetType
            .filter((item) => !assetTypeOptions.has(item.value))
            .map((value, index) => value);
          setAssetTypeOptions([
            ...Array.from(assetTypeOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "assetTag" ||
          (lastSelectedOption === "assetTag" &&
            assetTagOptions.size > selectedAssetTag.length)
        ) {
          const tempArray = selectedAssetTag
            .filter((item) => !assetTagOptions.has(item.value))
            .map((value, index) => value);
          setAssetTagOptions([
            ...Array.from(assetTagOptions).map((val, index) => ({
              id: index,
              label: val === "1" ? "New" : val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "style" ||
          (lastSelectedOption === "style" &&
            styleOptions.size > selectedStyle.length)
        ) {
          const tempArray = selectedStyle
            .filter((item) => !styleOptions.has(item.value))
            .map((value, index) => value);
          setStyleOptions([
            ...Array.from(styleOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "gender" ||
          (lastSelectedOption === "gender" &&
            genderOptions.size > selectedGender.length)
        ) {
          const tempArray = selectedGender
            .filter((item) => !genderOptions.has(item.value))
            .map((value, index) => value);
          setGenderOptions([
            ...Array.from(genderOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "age" ||
          (lastSelectedOption === "age" && ageOptions.size > selectedAge.length)
        ) {
          const tempArray = selectedAge
            .filter((item) => !ageOptions.has(item.value))
            .map((value, index) => value);
          setAgeOptions([
            ...Array.from(ageOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "brand" ||
          (lastSelectedOption === "brand" &&
            brandOptions.size > selectedBrand.length)
        ) {
          const tempArray = selectedBrand
            .filter((item) => !brandOptions.has(item.value))
            .map((value, index) => value);
          setBrandOptions([
            ...Array.from(brandOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "colorCode" ||
          (lastSelectedOption === "colorCode" &&
            colorCodeOptions.size > selectedColorCode.length)
        ) {
          const tempArray = selectedColorCode
            .filter((item) => !colorCodeOptions.has(item.value))
            .map((value, index) => value);
          setColorCodeOptions([
            ...Array.from(colorCodeOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "colorName" ||
          (lastSelectedOption === "colorName" &&
            colorNameOptions.size > selectedColorName.length)
        ) {
          const tempArray = selectedColorName
            .filter((item) => !colorNameOptions.has(item.value))
            .map((value, index) => value);
          setColorNameOptions([
            ...Array.from(colorNameOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "fabricContent" ||
          (lastSelectedOption === "fabricContent" &&
            fabricContentOptions.size > selectedFabricContent.length)
        ) {
          const tempArray = selectedFabricContent
            .filter((item) => !fabricContentOptions.has(item.value))
            .map((value, index) => value);
          setFabricContentOptions([
            ...Array.from(fabricContentOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "fabricName" ||
          (lastSelectedOption === "fabricName" &&
            fabricNameOptions.size > selectedFabricName.length)
        ) {
          const tempArray = selectedFabricName
            .filter((item) => !fabricNameOptions.has(item.value))
            .map((value, index) => value);
          setFabricNameOptions([
            ...Array.from(fabricNameOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "fileType" ||
          (lastSelectedOption === "fileType" &&
            fileTypeOptions.size > selectedFileType.length)
        ) {
          const tempArray = selectedFileType
            .filter((item) => !fileTypeOptions.has(item.value))
            .map((value, index) => value);
          setFileTypeOptions([
            ...Array.from(fileTypeOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "labelName" ||
          (lastSelectedOption === "labelName" &&
            fileTypeOptions.size > selectedFileType.length)
        ) {
          const tempArray = selectedLabelName
            .filter((item) => !labelNameOptions.has(item.value))
            .map((value, index) => value);
          setLabelNameOptions([
            ...Array.from(labelNameOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "imageResolution" ||
          (lastSelectedOption === "imageResolution" &&
            imageResolutionOptions.size > selectedImageResolution.length)
        ) {
          const tempArray = selectedImageResolution
            .filter((item) => !imageResolutionOptions.has(item.value))
            .map((value, index) => value);
          setImageResolutionOptions([
            ...Array.from(imageResolutionOptions).map((val, index) => ({
              id: index,
              label: `${val} dpi`,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "silhouette" ||
          (lastSelectedOption === "silhouette" &&
            silhouetteOptions.size > selectedSilhouette.length)
        ) {
          const tempArray = selectedSilhouette
            .filter((item) => !silhouetteOptions.has(item.value))
            .map((value, index) => value);
          setSilhouetteOptions([
            ...Array.from(silhouetteOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        if (
          lastSelectedOption !== "totalModel" ||
          (lastSelectedOption === "totalModel" &&
            totalModelOptions.size > selectedTotalModel.length)
        ) {
          const tempArray = selectedTotalModel
            .filter((item) => !totalModelOptions.has(item.value))
            .map((value, index) => value);
          setTotalModelOptions([
            ...Array.from(totalModelOptions).map((val, index) => ({
              id: index,
              label: val,
              value: val,
            })),
            ...tempArray,
          ]);
        }

        const tempAssetIds: string[] = Array.from(assetOptions);

        setAssets(tempAssetIds);
        if (!isArrayEqual(globalAssetIds, tempAssetIds))
          setAssetIds(tempAssetIds);
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-useless-return
      if (isCancelled(error)) return;
    })
    .finally(() => {
      setLoader(false);
    });
};

const InPageFilter = () => {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const searchValue = new URLSearchParams(location.search).get("search");

  const userId = useAppSelector((state) => state.user?.id) as number | null;
  const globalAssetIds = useAppSelector((state) => state.assets.assetIdList);

  // API Parameters
  const primaryRegion = useAppSelector(
    (state) => state.user?.primaryRegion
  ) as string;
  const [restApiAssets, setRestApiAssets] = useState<AssetInfo[] | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const assetTypeOptions = useAppSelector(
    (state) => state.filterOptions.assetTypeOptions
  );
  const assetTagOptions = useAppSelector(
    (state) => state.filterOptions.assetTagOptions
  );
  const styleOptions = useAppSelector(
    (state) => state.filterOptions.styleOptions
  );
  const genderOptions = useAppSelector(
    (state) => state.filterOptions.genderOptions
  );
  const ageOptions = useAppSelector((state) => state.filterOptions.ageOptions);
  const brandOptions = useAppSelector(
    (state) => state.filterOptions.brandOptions
  );
  const colorCodeOptions = useAppSelector(
    (state) => state.filterOptions.colorCodeOptions
  );
  const colorNameOptions = useAppSelector(
    (state) => state.filterOptions.colorNameOptions
  );
  const fabricContentOptions = useAppSelector(
    (state) => state.filterOptions.fabricContentOptions
  );
  const fabricNameOptions = useAppSelector(
    (state) => state.filterOptions.fabricNameOptions
  );
  const fileTypeOptions = useAppSelector(
    (state) => state.filterOptions.fileTypeOptions
  );
  const labelNameOptions = useAppSelector(
    (state) => state.filterOptions.labelNameOptions
  );
  const imageResolutionOptions = useAppSelector(
    (state) => state.filterOptions.imageResolutionOptions
  );
  const silhouetteOptions = useAppSelector(
    (state) => state.filterOptions.silhouetteOptions
  );
  const totalModelOptions = useAppSelector(
    (state) => state.filterOptions.totalModelOptions
  );

  const selectedAssetType = useAppSelector(
    (state) => state.filterOptions.selectedAssetType
  );
  const selectedAssetTag = useAppSelector(
    (state) => state.filterOptions.selectedAssetTag
  );
  const selectedGender = useAppSelector(
    (state) => state.filterOptions.selectedGender
  );
  const selectedStyle = useAppSelector(
    (state) => state.filterOptions.selectedStyle
  );
  const selectedAge = useAppSelector(
    (state) => state.filterOptions.selectedAge
  );
  const selectedBrand = useAppSelector(
    (state) => state.filterOptions.selectedBrand
  );
  const selectedColorCode = useAppSelector(
    (state) => state.filterOptions.selectedColorCode
  );
  const selectedColorName = useAppSelector(
    (state) => state.filterOptions.selectedColorName
  );
  const selectedFabricContent = useAppSelector(
    (state) => state.filterOptions.selectedFabricContent
  );
  const selectedFabricName = useAppSelector(
    (state) => state.filterOptions.selectedFabricName
  );
  const selectedFileType = useAppSelector(
    (state) => state.filterOptions.selectedFileType
  );
  const selectedLabelName = useAppSelector(
    (state) => state.filterOptions.selectedLabelName
  );
  const selectedImageResolution = useAppSelector(
    (state) => state.filterOptions.selectedImageResolution
  );
  const selectedSilhouette = useAppSelector(
    (state) => state.filterOptions.selectedSilhouette
  );
  const selectedTotalModel = useAppSelector(
    (state) => state.filterOptions.selectedTotalModel
  );
  const selectedFilters = useAppSelector(
    (state) => state.filterOptions.selectedFilters
  );

  const lastSelectedOption = useAppSelector(
    (state) => state.filterOptions.lastSelectedOption
  );

  const setLastSelectedOption = (item: string) =>
    dispatch(assignLastSelectedOption(item));

  const [assets, setAssets] = useState<string[]>([]);

  // const [lastSelectedOption, setLastSelectedOption] = useState<string>("");

  const setSelectedAssetType = (value: Options[]) =>
    dispatch(assignSelectedAssetType(value));
  const setSelectedAssetTag = (value: Options[]) =>
    dispatch(assignSelectedAssetTag(value));
  const setSelectedGender = (value: Options[]) =>
    dispatch(assignSelectedGender(value));
  const setSelectedStyle = (value: Options[]) =>
    dispatch(assignSelectedStyle(value));
  const setSelectedAge = (value: Options[]) =>
    dispatch(assignSelectedAge(value));
  const setSelectedBrand = (value: Options[]) =>
    dispatch(assignSelectedBrand(value));
  const setSelectedColorCode = (value: Options[]) =>
    dispatch(assignSelectedColorCode(value));
  const setSelectedColorName = (value: Options[]) =>
    dispatch(assignSelectedColorName(value));
  const setSelectedFabricContent = (value: Options[]) =>
    dispatch(assignSelectedFabricContent(value));
  const setSelectedFabricName = (value: Options[]) =>
    dispatch(assignSelectedFabricName(value));
  const setSelectedFileType = (value: Options[]) =>
    dispatch(assignSelectedFileType(value));
  const setSelectedLabelName = (value: Options[]) =>
    dispatch(assignSelectedLabelName(value));
  const setSelectedImageResolution = (value: Options[]) =>
    dispatch(assignSelectedImageResolution(value));
  const setSelectedSilhouette = (value: Options[]) =>
    dispatch(assignSelectedSilhouette(value));
  const setSelectedTotalModel = (value: Options[]) =>
    dispatch(assignSelectedTotalModel(value));

  const setSelectedFilters = (value: FilterOptionsInfo[]) =>
    dispatch(assignSelectedFilters(value));

  // const [selectedFilters, setSelectedFilters] = useState<FilterOptionsInfo[]>(
  //   []
  // );

  const setAssetTypeOptions = (value: Options[]) =>
    dispatch(
      assignAssetTypeOptions(quickSortOptions(value, 0, value.length - 1))
    );
  const setAssetTagOptions = (value: Options[]) =>
    dispatch(
      assignAssetTagOptions(quickSortOptions(value, 0, value.length - 1))
    );
  const setStyleOptions = (value: Options[]) =>
    dispatch(assignStyleOptions(quickSortOptions(value, 0, value.length - 1)));
  const setGenderOptions = (value: Options[]) =>
    dispatch(assignGenderOptions(quickSortOptions(value, 0, value.length - 1)));
  const setAgeOptions = (value: Options[]) =>
    dispatch(assignAgeOptions(quickSortOptions(value, 0, value.length - 1)));
  const setBrandOptions = (value: Options[]) =>
    dispatch(assignBrandOptions(quickSortOptions(value, 0, value.length - 1)));
  const setColorCodeOptions = (value: Options[]) =>
    dispatch(
      assignColorCodeOptions(quickSortOptions(value, 0, value.length - 1))
    );
  const setColorNameOptions = (value: Options[]) =>
    dispatch(
      assignColorNameOptions(quickSortOptions(value, 0, value.length - 1))
    );
  const setFabricContentOptions = (value: Options[]) =>
    dispatch(
      assignFabricContentOptions(quickSortOptions(value, 0, value.length - 1))
    );
  const setFabricNameOptions = (value: Options[]) =>
    dispatch(
      assignFabricNameOptions(quickSortOptions(value, 0, value.length - 1))
    );
  const setFileTypeOptions = (value: Options[]) =>
    dispatch(
      assignFileTypeOptions(quickSortOptions(value, 0, value.length - 1))
    );
  const setLabelNameOptions = (value: Options[]) =>
    dispatch(
      assignLabelNameOptions(quickSortOptions(value, 0, value.length - 1))
    );
  const setImageResolutionOptions = (value: Options[]) =>
    dispatch(assignImageResolutionOptions(value));
  const setSilhouetteOptions = (value: Options[]) =>
    dispatch(
      assignSilhouetteOptions(quickSortOptions(value, 0, value.length - 1))
    );
  const setTotalModelOptions = (value: Options[]) =>
    dispatch(
      assignTotalModelOptions(quickSortOptions(value, 0, value.length - 1))
    );

  const setIfFiltersAreSelected = (value: boolean) =>
    dispatch(setAreFiltersSelected(value));

  const catalogs = useAppSelector((state) => state.catalog.catalogs);

  interface CatalogHashInterface {
    [detailed: string]: string;
  }

  const [catalogHash, setCatalogHash] = useState<CatalogHashInterface>({});

  const defaultRegion = useAppSelector((state) => state.user?.primaryRegion);
  const selectedRegion = useAppSelector((state) => state.region.regionId);

  const showFilter = useAppSelector((state) => state.filter.showFilter);

  const setAssetIds = (assetIds: string[]) =>
    dispatch(setAssetIdList(assetIds));

  const setLoader = (loader: boolean) => dispatch(setLoaderValue(loader));

  async function getFilterAttributes() {
    if (userId) {
      await sampleFunctionApi(
        userId,
        selectedRegion === "" ? defaultRegion : selectedRegion,
        catalogHash[categoryMapping(location.pathname)],
        {
          assetType: selectedAssetType.map((a) => a.value),
          assetTag: selectedAssetTag.map((a) => a.value),
          gender: selectedGender.map((a) => a.value),
          style: selectedStyle.map((a) => a.value),
          age: selectedAge.map((a) => a.value),
          brand: selectedBrand.map((a) => a.value),
          colorCode: selectedColorCode.map((a) => a.value),
          colorName: selectedColorName.map((a) => a.value),
          fabricContent: selectedFabricContent.map((a) => a.value),
          fabricName: selectedFabricName.map((a) => a.value),
          fileType: selectedFileType.map((a) => a.value),
          labelName: selectedLabelName.map((a) => a.value),
          imageResolution: selectedImageResolution.map((a) => a.value),
          silhouette: selectedSilhouette.map((a) => a.value),
          totalModel: selectedTotalModel.map((a) => a.value),
        },
        lastSelectedOption,
        globalAssetIds,
        location.pathname,
        selectedAssetType,
        selectedAssetTag,
        selectedStyle,
        selectedGender,
        selectedAge,
        selectedBrand,
        selectedColorCode,
        selectedColorName,
        selectedFabricContent,
        selectedFabricName,
        selectedFileType,
        selectedLabelName,
        selectedImageResolution,
        selectedSilhouette,
        selectedTotalModel,
        setAssetTypeOptions,
        setAssetTagOptions,
        setStyleOptions,
        setGenderOptions,
        setAgeOptions,
        setBrandOptions,
        setColorCodeOptions,
        setColorNameOptions,
        setFabricContentOptions,
        setFabricNameOptions,
        setFileTypeOptions,
        setLabelNameOptions,
        setImageResolutionOptions,
        setSilhouetteOptions,
        setTotalModelOptions,
        setAssets,
        setAssetIds,
        setLoader,
        setIfFiltersAreSelected,
        source,
        (value: any) => axios.isCancel(value),
        searchValue,
        primaryRegion
      );
    }
  }

  useEffect(() => {
    const tempCatalogHash: CatalogHashInterface = {};
    catalogs.forEach((value) => {
      tempCatalogHash[value.catalog] = value.id;
    });
    setCatalogHash(tempCatalogHash);
  }, [catalogs]);

  const emptyFilterAttributes = () => {
    setSelectedAssetType([]);
    setSelectedAssetTag([]);
    setSelectedBrand([]);
    setSelectedImageResolution([]);
    setSelectedFileType([]);
    setSelectedStyle([]);
    setSelectedSilhouette([]);
    setSelectedColorName([]);
    setSelectedColorCode([]);
    setSelectedGender([]);
    setSelectedAge([]);
    setSelectedFabricName([]);
    setSelectedFabricContent([]);
    setSelectedTotalModel([]);
    setSelectedLabelName([]);
    setSelectedFilters([]);
  };

  useEffect(() => {
    if (
      location.pathname.substring(0, 10) === "/favorites" ||
      location.pathname === "/"
    ) {
      emptyFilterAttributes();
      dispatch(setFilterDropdownStatus(false));
    }
  }, [location.pathname]);

  useEffect(() => {
    getFilterAttributes();
    return () => {
      source.cancel("Cancelled api call");
    };
  }, [
    selectedAssetType,
    selectedAssetTag,
    selectedStyle,
    selectedGender,
    selectedAge,
    selectedBrand,
    selectedColorName,
    selectedColorCode,
    selectedFabricName,
    selectedFabricContent,
    selectedFileType,
    selectedLabelName,
    selectedImageResolution,
    selectedSilhouette,
    selectedTotalModel,
    selectedRegion,
    catalogHash,
    userId,
    selectedFilters,
  ]);

  useEffect(() => {
    setSelectedFilters([
      ...selectedAssetType.map((item) => ({
        filterOption: "assetType",
        ...item,
      })),
      ...selectedAssetTag.map((item) => ({
        filterOption: "assetTag",
        ...item,
      })),
      ...selectedGender.map((item) => ({
        filterOption: "gender",
        ...item,
      })),
      ...selectedStyle.map((item) => ({
        filterOption: "style",
        ...item,
      })),
      ...selectedAge.map((item) => ({
        filterOption: "age",
        ...item,
      })),
      ...selectedBrand.map((item) => ({
        filterOption: "brand",
        ...item,
      })),
      ...selectedColorName.map((item) => ({
        filterOption: "colorName",
        ...item,
      })),
      ...selectedColorCode.map((item) => ({
        filterOption: "colorCode",
        ...item,
      })),
      ...selectedFabricName.map((item) => ({
        filterOption: "fabricName",
        ...item,
      })),
      ...selectedFabricContent.map((item) => ({
        filterOption: "fabricContent",
        ...item,
      })),
      ...selectedFileType.map((item) => ({
        filterOption: "fileType",
        ...item,
      })),
      ...selectedLabelName.map((item) => ({
        filterOption: "labelName",
        ...item,
      })),
      ...selectedImageResolution.map((item) => ({
        filterOption: "imageResolution",
        ...item,
      })),
      ...selectedSilhouette.map((item) => ({
        filterOption: "silhouette",
        ...item,
      })),
      ...selectedTotalModel.map((item) => ({
        filterOption: "totalModel",
        ...item,
      })),
    ]);
  }, [
    selectedAssetType,
    selectedAssetTag,
    selectedStyle,
    selectedGender,
    selectedAge,
    selectedBrand,
    selectedColorName,
    selectedColorCode,
    selectedFabricName,
    selectedFabricContent,
    selectedFileType,
    selectedLabelName,
    selectedImageResolution,
    selectedSilhouette,
    selectedTotalModel,
    searchValue,
  ]);

  return (
    <>
      {/* Filter Collapsible Box */}
      <Collapse in={showFilter}>
        <div className="collapse filters-collapse" id="filter-dropdown">
          <div className="filters-container">
            <form>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 d-flex justify-content-start align-items-center filter-list">
                    <button
                      type="button"
                      className="btn btn-filter-list clear-all"
                      style={{
                        backgroundColor:
                          selectedFilters.length > 0 ? "#0d6efd" : "",
                      }}
                      onClick={() => {
                        // dispatch(removeAllFilters());
                        emptyFilterAttributes();
                      }}
                    >
                      Clear All
                    </button>
                    {selectedFilters.map((item, index) => (
                      <button
                        type="button"
                        className="btn btn-filter-list"
                        key={index}
                      >
                        {item.label}
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                        <span
                          role="button"
                          onClick={() => {
                            if (item.filterOption === "assetType") {
                              setSelectedAssetType(
                                selectedAssetType
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "assetTag") {
                              setSelectedAssetTag(
                                selectedAssetTag
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "gender") {
                              setSelectedGender(
                                selectedGender
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "style") {
                              setSelectedStyle(
                                selectedStyle
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "age") {
                              setSelectedAge(
                                selectedAge
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "brand") {
                              setSelectedBrand(
                                selectedBrand
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "colorName") {
                              setSelectedColorName(
                                selectedColorName
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "colorCode") {
                              setSelectedColorCode(
                                selectedColorCode
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "fabricName") {
                              setSelectedFabricName(
                                selectedFabricName
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "fabricContent") {
                              setSelectedFabricContent(
                                selectedFabricContent
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "fileType") {
                              setSelectedFileType(
                                selectedFileType
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "labelName") {
                              setSelectedLabelName(
                                selectedLabelName
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "imageResolution") {
                              setSelectedImageResolution(
                                selectedImageResolution
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "silhouette") {
                              setSelectedSilhouette(
                                selectedSilhouette
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                            if (item.filterOption === "totalModel") {
                              setSelectedTotalModel(
                                selectedTotalModel
                                  .filter((a) => a.value !== item.value)
                                  .map((b) => b)
                              );
                              setSelectedFilters(
                                selectedFilters
                                  .filter((c) => c.value !== item.value)
                                  .map((d) => d)
                              );
                            }
                          }}
                          tabIndex={0}
                          className="clear-x"
                        >
                          x
                        </span>
                      </button>
                    ))}
                  </div>
                </div>
                <div className="row">
                  {assetTypeOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedAssetType.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={assetTypeOptions}
                        value={selectedAssetType}
                        onChange={(data: Options[]) => {
                          setSelectedAssetType(data);
                          // if (data.length > 0)
                          setLastSelectedOption("assetType");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Asset Type"
                        valueRenderer={() => "Asset Type"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Asset Type",
                          search: "Search Asset Type",
                        }}
                      />
                    </div>
                  ) : null}
                  {assetTagOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedAssetTag.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={assetTagOptions}
                        value={selectedAssetTag}
                        onChange={(data: Options[]) => {
                          setSelectedAssetTag(data);
                          // if (data.length > 0)
                          setLastSelectedOption("assetTag");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Asset Tag"
                        valueRenderer={() => "Asset Tag"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Asset Tag",
                          search: "Search Asset Tag",
                        }}
                      />
                    </div>
                  ) : null}
                  {styleOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedStyle.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={styleOptions}
                        value={selectedStyle}
                        onChange={(data: Options[]) => {
                          setSelectedStyle(data);
                          // if (data.length > 0)
                          setLastSelectedOption("style");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Style"
                        valueRenderer={() => "Style"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Style",
                          search: "Search Style",
                        }}
                      />
                    </div>
                  ) : null}
                  {genderOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedGender.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={genderOptions}
                        value={selectedGender}
                        onChange={(data: Options[]) => {
                          setSelectedGender(data);
                          // if (data.length > 0)
                          setLastSelectedOption("gender");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Gender"
                        valueRenderer={() => "Gender"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Gender",
                          search: "Search Gender",
                        }}
                      />
                    </div>
                  ) : null}
                  {ageOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedAge.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={ageOptions}
                        value={selectedAge}
                        onChange={(data: Options[]) => {
                          setSelectedAge(data);
                          // if (data.length > 0)
                          setLastSelectedOption("age");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Age"
                        valueRenderer={() => "Age"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Age",
                          search: "Search Age",
                        }}
                      />
                    </div>
                  ) : null}
                  {brandOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedBrand.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={brandOptions}
                        value={selectedBrand}
                        onChange={(data: Options[]) => {
                          setSelectedBrand(data);
                          // if (data.length > 0)
                          setLastSelectedOption("brand");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Brand"
                        valueRenderer={() => "Brand"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Brand",
                          search: "Search Brand",
                        }}
                      />
                    </div>
                  ) : null}
                  {colorCodeOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedColorCode.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={colorCodeOptions}
                        value={selectedColorCode}
                        onChange={(data: Options[]) => {
                          setSelectedColorCode(data);
                          // if (data.length > 0)
                          setLastSelectedOption("colorCode");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Color Code"
                        valueRenderer={() => "Color Code"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Color Code",
                          search: "Search Color Code",
                        }}
                      />
                    </div>
                  ) : null}
                  {colorNameOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedColorName.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={colorNameOptions}
                        value={selectedColorName}
                        onChange={(data: Options[]) => {
                          setSelectedColorName(data);
                          // if (data.length > 0)
                          setLastSelectedOption("colorName");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Color Name"
                        valueRenderer={() => "Color Name"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Color Name",
                          search: "Search Color Name",
                        }}
                      />
                    </div>
                  ) : null}
                  {fabricContentOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedFabricContent.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={fabricContentOptions}
                        value={selectedFabricContent}
                        onChange={(data: Options[]) => {
                          setSelectedFabricContent(data);
                          // if (data.length > 0)
                          setLastSelectedOption("fabricContent");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Fabric Content"
                        valueRenderer={() => "Fabric Content"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Fabric Content",
                          search: "Search Fabric Content",
                        }}
                      />
                    </div>
                  ) : null}
                  {fabricNameOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedFabricName.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={fabricNameOptions}
                        value={selectedFabricName}
                        onChange={(data: Options[]) => {
                          setSelectedFabricName(data);
                          // if (data.length > 0)
                          setLastSelectedOption("fabricName");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Fabric Name"
                        valueRenderer={() => "Fabric Name"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Fabric Name",
                          search: "Search Fabric Name",
                        }}
                      />
                    </div>
                  ) : null}
                  {fileTypeOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedFileType.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={fileTypeOptions}
                        value={selectedFileType}
                        onChange={(data: Options[]) => {
                          setSelectedFileType(data);
                          // if (data.length > 0)
                          setLastSelectedOption("fileType");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="File Type"
                        valueRenderer={() => "File Type"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "File Type",
                          search: "Search File Type",
                        }}
                      />
                    </div>
                  ) : null}
                  {labelNameOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedLabelName.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={labelNameOptions}
                        value={selectedLabelName}
                        onChange={(data: Options[]) => {
                          setSelectedLabelName(data);
                          // if (data.length > 0)
                          setLastSelectedOption("labelName");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Label Name"
                        valueRenderer={() => "Label Name"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Label Name",
                          search: "Search Label Name",
                        }}
                      />
                    </div>
                  ) : null}
                  {imageResolutionOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedImageResolution.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={imageResolutionOptions}
                        value={selectedImageResolution}
                        onChange={(data: Options[]) => {
                          setSelectedImageResolution(data);
                          // if (data.length > 0)
                          setLastSelectedOption("imageResolution");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Image Resolution"
                        valueRenderer={() => "Image Resolution"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Image Resolution",
                          search: "Search Image Resolution",
                        }}
                      />
                    </div>
                  ) : null}
                  {totalModelOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedTotalModel.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={totalModelOptions}
                        value={selectedTotalModel}
                        onChange={(data: Options[]) => {
                          setSelectedTotalModel(data);
                          // if (data.length > 0)
                          setLastSelectedOption("totalModel");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Total Model"
                        valueRenderer={() => "Total Model"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Total Model",
                          search: "Search Total Model",
                        }}
                      />
                    </div>
                  ) : null}
                  {silhouetteOptions.length > 0 ? (
                    <div className="col-lg-3 col-12">
                      <MultiSelect
                        className={`${
                          selectedSilhouette.length > 0 ? "rmsc2" : ""
                        } multi-select`}
                        options={silhouetteOptions}
                        value={selectedSilhouette}
                        onChange={(data: Options[]) => {
                          setSelectedSilhouette(data);
                          // if (data.length > 0)
                          setLastSelectedOption("silhouette");
                          // else setLastSelectedOption("");
                        }}
                        labelledBy="Silhouette"
                        valueRenderer={() => "Silhouette"}
                        ClearSelectedIcon={null}
                        // hasSelectAll={!selectHiddenStates.assetTypes.isHidden}
                        overrideStrings={{
                          selectSomeItems: "Silhouette",
                          search: "Search Silhouette",
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default InPageFilter;
