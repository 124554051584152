import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import UAParser from "ua-parser-js";
import { useSelector } from "react-redux";
import { useLazyQuery, useQuery } from "@apollo/client";
// import loadingAnimation from "../loadingAnimation2.json";
import { RootState } from "../rootReducer";
import CatalogCard from "./CatalogCard";
import {
  ALL_ASSETS,
  RECENTLY_ADDED_ASSETS,
} from "../services/graphql/pimcoreDam";
import { GraphqlApi } from "../models/interfaces/graphApi";
import useMultiSelection from "../hooks/useMultiSelection";
import { FavoriteAsset, FavoriteType } from "../types/common";
import AssetDownloadModal from "./modals/AssetDownloadModal";
import AssetDetailModal from "./modals/AssetDetailModal";
import ManageFavoriteModal from "./modals/ManageFavoriteModal";
import {
  IOwlCarousel,
  IOwlCarouselResponsiveness,
  VideoThumbnail,
  DocumentThumbnail,
  ViewAllData,
  AssetInfo,
} from "../models/interfaces/common";
import "react-multi-carousel/lib/styles.css";
import DownloadIcon from "../assets/img/download-card-footer.svg";
import ExpandIcon from "../assets/img/expand-card-footer.svg";
import {
  getAssetsAvailableInDamPortal,
  getAssetThumbnailUrl,
  getVideoThumbnails,
  getDocumentThumbnails,
  getCatalogSliderAssets,
} from "../utils/catalog";
import http from "../services/api";
import { useAppDispatch, useAppSelector } from "../store";
import { toggleDownloadIsOpen } from "../pages/downloadSlice";
import { setAssets } from "../pages/assetsSlice";

export default function CatalogSlider() {
  const { t, i18n } = useTranslation("common");
  const initialSelection: string[] = [];
  const [showDownload, setShowDownload] = useState(false);
  const [showAssetDetail, setShowAssetDetail] = useState(false);
  const Favorites = useAppSelector(
    (state: RootState) => state.newFavorite.favorite
  );
  const [videoThumbnails, setVideoThumbnails] = useState<VideoThumbnail>({});
  const [documentThumbnails, setDocumentThumbnails] =
    useState<DocumentThumbnail>({});
  const [assetIds, setAssetIds] = useState<string | null>(null);
  const [assetState, setAssetState] = useState<GraphqlApi[]>([]);
  const [sortedAssetInfo, setSortedAssetInfo] = useState<ViewAllData[]>([]);
  const [restApiAssets, setRestApiAssets] = useState<AssetInfo[] | null>(null);

  const [showManageFavorite, setShowManageFavorite] = useState(false);
  const [isAddToFavoriteModalTrigger, setIsAddToFavoriteModalTrigger] =
    useState(false);

  const downloadModalState = useAppSelector((state) => state.download.isOpen);

  const defaultRegion = useAppSelector((state) => state.user?.primaryRegion);
  const selectedRegion = useAppSelector((state) => state.region.regionId);

  const dispatch = useAppDispatch();
  const toggleDownloadModal = () => dispatch(toggleDownloadIsOpen());
  const toggleManageFavoriteModal = () => {
    setIsAddToFavoriteModalTrigger(true);
    setShowManageFavorite(!showManageFavorite);
  };

  const primaryRegion = useAppSelector(
    (state) => state.user?.primaryRegion
  ) as string;

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const responsives = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  useEffect(() => {
    getCatalogSliderAssets(defaultRegion, selectedRegion, setRestApiAssets);
  }, [defaultRegion, selectedRegion]);

  const { setAssetData, handleCardClick, clearMultiSelections, selectedList } =
    useMultiSelection("getAssetListing", "image", initialSelection);

  useEffect(() => {
    if (restApiAssets) {
      setAssetData(restApiAssets);
      dispatch(setAssets(restApiAssets));
    }
  }, [restApiAssets]);

  useEffect(() => {
    if (selectedList.length > 1) {
      // selectedList.shift();
      selectedList.splice(0, selectedList.length - 1);
    }
    // console.log("selected list: ", selectedList);
  }, [selectedList]);

  useEffect(() => {
    if (restApiAssets) {
      const videoAssetIds = restApiAssets
        .filter((favItem) => favItem.type.toString() === "video")
        .map((item) => item.id);
      getVideoThumbnails(videoAssetIds.toString(), setVideoThumbnails);
      const documentAssetIds = restApiAssets
        .filter((favItem) => favItem.type.toString() === "document")
        .map((item) => item.id);
      getDocumentThumbnails(documentAssetIds.toString(), setDocumentThumbnails);
    }
  }, [restApiAssets]);

  // const toggleDownloadModal = () => setShowDownload(!showDownload);
  const toggleAssetDetail = () => setShowAssetDetail(!showAssetDetail);

  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: loadingAnimation,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  const CatalogCards = () => {
    // if (recentlyAddedQuery.loading)
    //   return (
    //     <div className="cards">
    //       <object
    //         type="image/svg+xml"
    //         width={1500}
    //         height={1500}
    //         data={loadingAnimation2}
    //         // className="ob"
    //       >
    //         svg-animation
    //       </object>
    //     </div>
    //   );

    // if (recentlyAddedQuery.error) return <p>{t("Error...")}</p>;

    const allFavoriteIds: string[] = [];
    Object.values(Favorites).forEach((folder) => {
      folder.assets.forEach((asset) => {
        allFavoriteIds.push(String(asset.id));
      });
    });

    const { userAgent } = navigator;
    const parser = new UAParser();
    parser.setUA(userAgent);
    const result = parser.getResult();
    const deviceType = (result.device && result.device.type) || "desktop";

    return (
      <div className="cards">
        {restApiAssets && (
          <Carousel
            ssr
            partialVisible
            deviceType={deviceType}
            itemClass="slider-item"
            responsive={responsives}
          >
            {restApiAssets.map((asset: AssetInfo, assetIndex: number) => {
              const additionalClasses = selectedList.includes(asset.id)
                ? "selected"
                : "";
              const filename: string = asset.filename.toString();
              let fullPath = asset.fullpath;
              if (asset.fullpath.slice(0, 5) !== "https") {
                fullPath = `https://gildan.blob.core.windows.net/gildan/assets${asset.fullpath}${asset.filename}`;
              }
              const ext =
                filename.substring(
                  filename.lastIndexOf(".") + 1,
                  filename.length
                ) || filename;

              return (
                <CatalogCard
                  key={asset.id}
                  id={asset.id}
                  title={asset.filename}
                  downloadLink={fullPath}
                  shareLink={fullPath}
                  src={
                    // eslint-disable-next-line no-nested-ternary
                    asset.type !== "video"
                      ? asset.type === "document" && ext.toUpperCase() === "PDF"
                        ? documentThumbnails[asset.id]
                        : getAssetThumbnailUrl(asset)
                      : asset.youTubeSDThumbnailPath
                  }
                  type={asset.type}
                  filename={asset.filename}
                  fullpath={fullPath}
                  fullpathExtandFont={asset.fullpathExtandFont}
                  fullpathExtandLandscapeImage={
                    asset.fullpathExtandLandscapeImage
                  }
                  fullpathExtandPortraitImage={
                    asset.fullpathExtandPortraitImage
                  }
                  fullpathThumbnailFont={asset.fullpathThumbnailFont}
                  fullpathThumbnailImageDownload={
                    asset.fullpathThumbnailImageDownload
                  }
                  fullpathThumbnailLandscapeImage={
                    asset.fullpathThumbnailLandscapeImage
                  }
                  fullpathThumbnailPortraitImage={
                    asset.fullpathThumbnailPortraitImage
                  }
                  youTubeSDThumbnailPath={asset.youTubeSDThumbnailPath}
                  youTubeSDPath={asset.youTubeSDPath}
                  customMetadataId={asset.customMetadataId}
                  creationDate={asset.creationDate}
                  fileSize={asset.fileSize}
                  additionalClasses={additionalClasses}
                  toggleAssetDownloadModal={toggleDownloadModal}
                  toggleAssetDetailModal={toggleAssetDetail}
                  toggleAssetFavoriteModal={toggleManageFavoriteModal}
                  isFavorite={allFavoriteIds.includes(String(asset.id))}
                  isNew={asset.assetTag === 1}
                  dpiAsset={asset.dpiAsset}
                />
              );
            })}
          </Carousel>
        )}
      </div>
    );
  };

  return (
    <div>
      <CatalogCards />
      <AssetDownloadModal
        isOpen={downloadModalState}
        toggle={toggleDownloadModal}
        selectedList={selectedList}
        useMultiSelectionData={false}
        modalClass={
          selectedList.length > 1 ? "downloadMultipleModal" : "downloadModal"
        }
        centered
        backdrop="static"
        keyboard
        isMultipleDownload={false}
      />
      <AssetDetailModal
        isOpen={showAssetDetail}
        toggle={toggleAssetDetail}
        modalClass="assetDetailsModal"
        centered
        backdrop="static"
        keyboard
        fullscreen
      />
      <ManageFavoriteModal
        isOpen={showManageFavorite}
        toggle={toggleManageFavoriteModal}
        modalClass="favoritesModal"
        centered
        backdrop="static"
        keyboard
        isAddToFavorite={isAddToFavoriteModalTrigger}
      />
    </div>
  );
}
