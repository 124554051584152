/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { EventSourcePolyfill } from "event-source-polyfill";
import axios from "axios";
import {
  DownloadStatusProps,
  ModalProps,
  MultiDownloadProps,
} from "../../types/common";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setDownloadStatusIsOpen,
  removeProcesses,
  setCancelAllProcesses,
  DownloadStatus,
  setUnreadMessages,
  addMultiDownloadProcess,
  updateDownloadProcessStatusById,
  setZipIdToCancel,
} from "../../pages/multipleAssetDownloadSlice";
import http from "../../services/api";
import { type RootState } from "../../rootReducer";

import DynamicButton from "../../pages/downloads/DynamicButton";
import Tooltip from "../tooltip/Tooltip";

// Icons
import zippingIcon from "../../assets/img/downloads/zippingIcon.svg";
import closeButtonXS from "../../assets/img/downloads/closeButtonXS.svg";
import progressBar from "../../assets/img/downloads/progressBar.gif";
import readyButton from "../../assets/img/downloads/readyIcon.svg";
import failedButton from "../../assets/img/downloads/retryIcon.svg";
import closeButton from "../../assets/img/downloads/closeIcon.svg";
import {
  downloadFileOnBrowser,
  downloadZip,
  markDownloadAsRead,
  postDownloadedOnce,
} from "../../utils/catalog";

interface ToastMessageStatusProps {
  status: string;
  message: string;
  zipID: number;
  zipFile?: string;
  notificationCounter: number;
  zipFilename?: string;
}

const DownloadStatusItem = ({
  request,
  status,
  zipID,
  fileCount,
  presetSetting,
  toggleCancelDownloadProcesses,
}: MultiDownloadProps) => {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(true);
  const [downloadedOnce, setDownloadedOnce] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const unreadMessages = useAppSelector(
    (state) => state.multipleAssetsDownload.unreadMessages
  );

  // Mercure
  const [toastMessageStatus, setToastMessageStatus] =
    useState<ToastMessageStatusProps>({
      status: "inProgress",
      message: "",
      zipID,
      notificationCounter: 0,
    });
  const updateEventSourceRef = useRef<EventSourcePolyfill | null>(null);
  const defaultTopic = `${process.env.REACT_APP_MERCURE_TOPIC}${user?.id}`;

  const mercureHandler = () => {
    // transfor this into a single string
    const url = new URL(process.env.REACT_APP_MERCURE_URL ?? "");
    url.searchParams.append("topic", defaultTopic);
    const urlAsString = url.toString();

    updateEventSourceRef.current = new EventSourcePolyfill(urlAsString, {
      headers: {
        Authorization: `Bearer ${user?.mercureToken}`,
      },
    });

    updateEventSourceRef.current.onmessage = (e: any) => {
      const data = JSON.parse(e.data);
      if (data.zipID === zipID) {
        setToastMessageStatus(data);
      }

      if (data.status === "completed") {
        markDownloadAsRead(data.zipID);
        dispatch(
          updateDownloadProcessStatusById({
            status: DownloadStatus.completed,
            zipID: data.zipID,
            downloadUrl: data.zipFile,
            zipFileName: data.zipFilename,
            expirationDate: data.expirationDate,
          })
        );
      }

      if (data.status === "failed") {
        dispatch(
          updateDownloadProcessStatusById({
            status: DownloadStatus.failed,
            zipID: data.zipID,
          })
        );
      }

      if (data.status === "failed" || data.status === "completed") {
        // close connection
        if (updateEventSourceRef.current) {
          updateEventSourceRef.current.close();
          updateEventSourceRef.current = null;
        }
      }

      if (data.notificationCounter) {
        dispatch(setUnreadMessages(data.notificationCounter));
      }
    };

    updateEventSourceRef.current.onerror = (e: any) => {
      console.log("EventSource error:", e);
    };
  };

  // --- END OF MERCURE ---

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const retryDownload = async () => {
    setToastMessageStatus({
      status: DownloadStatus.inProgress,
      message: "",
      zipID,
      notificationCounter: 0,
    });
    if (presetSetting) {
      const zipSettings = JSON.parse(presetSetting);
      const response = await downloadZip(zipSettings);
      if (response?.zipTaskId) {
        dispatch(
          addMultiDownloadProcess({
            status: DownloadStatus.inProgress,
            zipID: response?.zipTaskId,
            fileCount: zipSettings.imageAssetIds.length,
            presetSetting: zipSettings,
            downloadProgress: 0,
          })
        );
      }
    }
  };

  function showTextByStatus(downloadStatus: string | undefined) {
    switch (downloadStatus) {
      case DownloadStatus.inProgress:
        return `Zipping ${fileCount} files`;
      case DownloadStatus.ready:
        return "Download Ready";
      case DownloadStatus.completed:
        return `Download Ready`;
      case DownloadStatus.failed:
        return `Error Zipping ${fileCount} Files`;
      default:
        return `Zipping ${fileCount} files`;
    }
  }

  function getButtonByStatus(downloadStatus: string | undefined) {
    switch (downloadStatus) {
      case DownloadStatus.inProgress:
        return closeButton;
      case DownloadStatus.ready:
        return readyButton;
      case DownloadStatus.completed:
        return readyButton;
      case DownloadStatus.failed:
        return failedButton;
      default:
        return closeButton;
    }
  }

  function getColorByStatus(downloadStatus: string | undefined) {
    switch (downloadStatus) {
      case DownloadStatus.ready:
        return "#009800";
      case DownloadStatus.inProgress:
        return "#003EA5";
      case DownloadStatus.failed:
        return "#FF1010";
      case DownloadStatus.completed:
        return "#4CC321";
      default:
        return "#003EA5";
    }
  }

  useEffect(() => {
    mercureHandler();
  }, [user?.token]);

  if (!show) return null;

  return (
    <div
      className="popper bxshw"
      style={{
        cursor: status === DownloadStatus.ready ? "pointer" : "default",
        display: window.location.pathname === "/downloads" ? "none" : "flex",
      }}
    >
      <div className="popbod d-flex flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-row">
          <img src={zippingIcon} alt="download" className="downloadIcon" />
          <div className="cardMessageContainer">
            <text
              style={{
                textDecoration: "none",
                color: `${getColorByStatus(toastMessageStatus?.status)}`,
              }}
              className="paddingl20 m-0"
            >
              {showTextByStatus(toastMessageStatus?.status)}
            </text>
            {toastMessageStatus?.status === DownloadStatus.inProgress && (
              <img
                src={progressBar}
                alt="progressBar"
                className="paddingl20 m-0"
              />
            )}
            {toastMessageStatus?.status === DownloadStatus.completed && (
              <p className="cardNameMessage m-0 paddingl20">
                {toastMessageStatus?.zipFilename}
              </p>
            )}
          </div>
        </div>
        <Tooltip
          content={
            toastMessageStatus?.status === DownloadStatus.inProgress
              ? "Cancel"
              : toastMessageStatus?.status === DownloadStatus.failed
                ? "Retry"
                : "Download"
          }
          direction="bottom"
          delay={0}
        >
          <DynamicButton
            icon={getButtonByStatus(toastMessageStatus?.status)}
            onClick={() => {
              // Insert Button functionality here
              // console.log("Button: ", status);
              if (toastMessageStatus?.status === DownloadStatus.inProgress) {
                dispatch(setZipIdToCancel(toastMessageStatus?.zipID));
                toggleCancelDownloadProcesses();
              }
              if (toastMessageStatus?.status === DownloadStatus.failed) {
                // Retry download
                retryDownload();
              }
              if (toastMessageStatus?.status === DownloadStatus.completed) {
                // Call Downloaded Once API
                downloadFileOnBrowser(toastMessageStatus?.zipFile ?? "");

                if (!downloadedOnce) {
                  postDownloadedOnce(toastMessageStatus?.zipID);
                  setDownloadedOnce(true);
                  dispatch(setUnreadMessages(unreadMessages - 1));
                  setShow(false);
                }
              }
            }}
            isSmall
          />
        </Tooltip>
        {toastMessageStatus?.status !== DownloadStatus.inProgress && (
          <button
            type="button"
            className="closeButton"
            onClick={() => {
              setShow(false);
            }}
          >
            <img src={closeButtonXS} alt="closeButton" className="width10" />
          </button>
        )}
      </div>
    </div>
  );
};

const DownloadStatusModal = ({
  toggleCancelDownloadProcesses,
}: DownloadStatusProps) => {
  const dispatch = useAppDispatch();
  const multiDownload = useAppSelector(
    (state) => state.multipleAssetsDownload.downloadProcesses
  );
  const cancelAllDownloadProcesses = useAppSelector(
    (state) => state.multipleAssetsDownload.cancelAllProcesses
  );

  useEffect(() => {
    if (cancelAllDownloadProcesses) {
      setTimeout(() => {
        dispatch(removeProcesses());
        dispatch(setCancelAllProcesses(false));
      }, 2000);
    }
  }, [cancelAllDownloadProcesses]);

  return (
    <div
      className="toastDownloadsContainer"
      style={window.location.pathname === "/downloads" ? { top: "42px" } : {}}
    >
      {multiDownload.map((item) => (
        <DownloadStatusItem
          key={item.zipID}
          request={item.presetSetting}
          status="inProgress"
          zipID={item.zipID}
          fileCount={item.fileCount}
          downloadProgress={item.downloadProgress}
          presetSetting={item.presetSetting}
          toggleCancelDownloadProcesses={toggleCancelDownloadProcesses}
        />
      ))}
    </div>
  );
};

export default DownloadStatusModal;
