import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssetInfoSliceInterface } from "../models/interfaces/common";

interface AssetState {
  id: string | null;
  url?: string | undefined;
  type?: string | null;
  isFavorite?: boolean;
  filename?: string | null;
  fullpath?: string | null;
  fullpathExtandFont?: string | null;
  fullpathExtandLandscapeImage?: string | null;
  fullpathExtandPortraitImage?: string | null;
  fullpathThumbnailFont?: string | null;
  fullpathThumbnailImageDownload?: string | null;
  fullpathThumbnailLandscapeImage?: string | null;
  fullpathThumbnailPortraitImage?: string | null;
  youTubeSDThumbnailPath?: string | null;
  youTubeSDPath?: string | null;
  customMetadataId?: string | null;
  creationDate?: string | null;
  fileSize?: number;
  dpiAsset?: number | null;
}

const initialState: AssetState = {
  id: null,
  url: undefined,
  type: undefined,
  isFavorite: false,
  filename: null,
  fullpath: null,
  fullpathExtandFont: null,
  fullpathExtandLandscapeImage: null,
  fullpathExtandPortraitImage: null,
  fullpathThumbnailFont: null,
  fullpathThumbnailImageDownload: null,
  fullpathThumbnailLandscapeImage: null,
  fullpathThumbnailPortraitImage: null,
  youTubeSDThumbnailPath: null,
  youTubeSDPath: null,
  customMetadataId: null,
  creationDate: null,
  fileSize: 0,
  dpiAsset: null,
};

const assetCard = createSlice({
  name: "assetCard",
  initialState,
  reducers: {
    assetId(state, { payload }: PayloadAction<string | null>) {
      state.id = payload;
    },
    assetUrl(state, { payload }: PayloadAction<string>) {
      if (payload) {
        state.url = payload;
      }
    },
    assetType(state, { payload }: PayloadAction<string>) {
      if (payload) {
        state.type = payload;
      }
    },
    setSelectedAssetCard(state, { payload }: PayloadAction<AssetState>) {
      if (payload) {
        return payload;
      }
      return state;
    },
    toggleIsFavorite(state) {
      state.isFavorite = !state.isFavorite;
    },
    setIsFavorite(state, { payload }: PayloadAction<boolean>) {
      state.isFavorite = payload;
    },
    setAssetInfo(state, { payload }: PayloadAction<AssetInfoSliceInterface>) {
      if (payload) {
        state.id = payload.id;
        state.filename = payload.filename;
        state.fullpath = payload.fullpath;
        state.fullpathExtandFont = payload.fullpathExtandFont;
        state.fullpathExtandLandscapeImage =
          payload.fullpathExtandLandscapeImage;
        state.fullpathExtandPortraitImage = payload.fullpathExtandPortraitImage;
        state.fullpathThumbnailFont = payload.fullpathThumbnailFont;
        state.fullpathThumbnailImageDownload =
          payload.fullpathThumbnailImageDownload;
        state.fullpathThumbnailLandscapeImage =
          payload.fullpathThumbnailLandscapeImage;
        state.fullpathThumbnailPortraitImage =
          payload.fullpathThumbnailPortraitImage;
        state.youTubeSDThumbnailPath = payload.youTubeSDThumbnailPath;
        state.youTubeSDPath = payload.youTubeSDPath;
        state.type = payload.type;
        state.customMetadataId = payload.customMetadataId;
        state.creationDate = payload.creationDate;
        state.fileSize = payload.fileSize;
        state.dpiAsset = payload.dpiAsset;
      }
    },
  },
});

export const {
  assetId,
  assetUrl,
  assetType,
  setSelectedAssetCard,
  toggleIsFavorite,
  setIsFavorite,
  setAssetInfo,
} = assetCard.actions;

export default assetCard.reducer;
