/* eslint-disable no-shadow */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ShareInterface {
  choice: number;
}

enum Choice {
  inShare = 1,
  notInShare = 0,
}

/*
 * From Card = 1
 * From Navbar = 0
 */

const initialState: ShareInterface = {
  choice: Choice.notInShare,
};

const inShare = createSlice({
  name: "inShare",
  initialState,
  reducers: {
    toggleChoice(state, { payload }: PayloadAction<number>) {
      state.choice = payload;
    },
  },
});

export const { toggleChoice } = inShare.actions;

export default inShare.reducer;
