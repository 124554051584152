import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AssetDownloadUrl {
  assetDownloadThumbUrl: string | null;
}

const initialState: AssetDownloadUrl = {
  assetDownloadThumbUrl: "",
};

const assetDownload = createSlice({
  name: "assetDownload",
  initialState,
  reducers: {
    setAssetThumbnailUrl(state, { payload }: PayloadAction<string | null>) {
      if (payload) {
        state.assetDownloadThumbUrl = payload;
      }
    },
  },
});

export const { setAssetThumbnailUrl } = assetDownload.actions;

export default assetDownload.reducer;
