/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "react-multi-email/dist/style.css";
import Swal from "sweetalert2";
import {
  NavBarResponse,
  NavBarData,
  ShareResponse,
} from "../../models/interfaces/common";
import http from "../../services/api";
import { AuthResponse } from "../../services/mirage/routes/user";
import { useAppSelector } from "../../store";
import { ModalProps } from "../../types/common";

interface ShareParams {
  emails: string[];
  sharedLink: string;
}

enum Choice {
  fromCard = 1,
  fromNav = 0,
}

const ShareModal = ({
  isOpen,
  toggle,
  modalClass,
  keyboard = true,
  backdrop = "static",
  centered = true,
}: ModalProps) => {
  const { t, i18n } = useTranslation("common");
  const [emails, setEmails] = useState<string[]>([]);
  const [error, setError] = useState<string>(
    t("Please enter a valid email address(es)")
  );

  const [loading, setloading] = useState<boolean>(false);
  const [isError, setIsError] = useState<Boolean>(false);
  const [link, setLink] = useState<string>("");
  const [copied, setCopied] = useState(false);
  const selectedAssets = useAppSelector(
    (state) => state.navBar.assets
  ).toString();
  const shareChoice = useAppSelector((state) => state.share.choice);
  const assetId = useAppSelector((state) => state.assetCard.id);
  const userId = useAppSelector((state) => state.user && state.user.id);
  const generateShareLink = (data: NavBarData) => {
    const path = "/api/share";

    http.post<NavBarData, NavBarResponse>(path, data).then((res) => {
      if (res && res.link) {
        setLink(res.link);
      }
    });
  };

  useEffect(() => {
    if (isOpen && selectedAssets !== "" && Choice.fromNav === shareChoice)
      generateShareLink({ assets: selectedAssets });
    if (isOpen && assetId && Choice.fromCard === shareChoice)
      generateShareLink({ assets: assetId as string });
    return setLink("");
  }, [selectedAssets, assetId, isOpen]);

  const path = "/api/sendsharelink";
  const pathLink = "/api/sharelink";
  const sharedLink = `${window.location.origin}/Shared/${link}`;

  const sharedAssets = async () => {
    setloading(true);
    await http
      .post<ShareParams, ShareResponse>(path, { emails, sharedLink, userId })
      .then((res: ShareResponse) => {
        if (res) {
          Swal.fire({
            position: "top-end",
            background: "#00ba4d",
            html: `<h6 style=color:white><br>${t(res.message)}</h6>`,
            width: 221,
            showConfirmButton: false,
            timer: 5000,
            heightAuto: false,
          });
          toggle();
        }
      })
      .catch((err) => {})
      .finally(() => setloading(false));
  };

  const sharedAssetsLink = async () => {
    await http
      .post<ShareParams, ShareResponse>(pathLink, { emails, sharedLink })
      .then((res: ShareResponse) => {
        Swal.fire({
          position: "top-end",
          background: "#00ba4d",
          html: `<h6 style=color:white><br>Link copied</h6>`,
          width: 170,
          showConfirmButton: false,
          timer: 5000,
          heightAuto: false,
        });
      });
  };

  const ColoredLine = () => (
    <hr
      style={{
        backgroundColor: "grey",
        height: 1,
      }}
    />
  );

  useEffect(() => {
    if (emails.length === 0) {
      setIsError(true);
    } else {
      setIsError(false);
      emails.forEach((email) => {
        if (!isEmail(email)) {
          setIsError(true);
        }
      });
    }
  }, [emails]);

  return (
    <Modal
      show={isOpen}
      onHide={toggle}
      className={`${modalClass}`}
      backdrop={backdrop}
      centered={centered}
      keyboard={keyboard}
    >
      <Modal.Header closeButton>
        <h4 className="modal-title">{t("Share")}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="" />
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6 className="m-0">{t("Share via email")}</h6>
        </div>
        <p className="small mb-2 light-gray">
          {t("Use a comma to add multiple email addresses")}.
        </p>
        <ReactMultiEmail
          placeholder={t("Use a comma to add multiple email addresses")}
          emails={emails}
          onChange={(_emails: string[]) => {
            setEmails(_emails);
          }}
          validateEmail={(email: string) => isEmail(email)}
          getLabel={(
            email: string,
            index: number,
            removeEmail: (tindex: number) => void
          ) => (
            <div data-tag key={index}>
              {email}
              <span
                data-tag-handle
                role="button"
                tabIndex={0}
                onClick={() => removeEmail(index)}
                onKeyDown={() => {}}
              >
                ×
              </span>
            </div>
          )}
        />
        {/* <input
          *  className="mb-3"
          *  type="text"
          *  defaultValue="AYO! THIS NEEDS TO BE CHANGED FOR A REACT EMAIL COMPONENT"
          *  placeholder=""
          > */}
        {isError ? (
          <div className="text-danger mt-1 fs-13 fw-bold">
            {t("Please enter a valid email address(es)")}
          </div>
        ) : null}
        <button
          className="btn btn-lg btn-blue mb-3 share-via-email-button"
          type="button"
          onClick={() => sharedAssets()}
          disabled={emails.length === 0 || loading === true}
        >
          {t("Send Email")}
        </button>
        <ColoredLine />

        <div className="mt-3 mb-3">
          <h6 className="mb-2">{t("Share via link")}</h6>
          <div className="shareLink d-flex justify-content-between align-items-center">
            <input className="mb-0" type="text" value={sharedLink} readOnly />
            <CopyToClipboard text={sharedLink} onCopy={() => setCopied(true)}>
              <button
                className="btn btn-lg btn-blue ms-1 mb-0 nowrap"
                type="button"
                onClick={() => sharedAssetsLink()}
              >
                {t("Copy Link")}
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
