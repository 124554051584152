import React from "react";
import {
  BrowserRouter as Router,
  useNavigate,
  Routes,
  Route,
} from "react-router-dom";
import { Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import config from "./config";
import { AuthLayoutRoute } from "./components/layouts/AuthLayout";
import MainLayoutRoute from "./components/layouts/MainLayout";
import HowTo from "./pages/howto/HowTo";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Catalogs from "./pages/catalogs/Catalogs";
import ExchangeRate from "./pages/exchangeRates/ExchangeRate";
import Videos from "./pages/catalogs/video/Videos";
import Imagery from "./pages/catalogs/imagery/Imagery";
import Favorites from "./pages/favorites/Favorites";
import Shared from "./pages/shared/Shared";
import AccountPage from "./pages/account/Account";
import SocialMedia from "./pages/catalogs/socialmedia/SocialMedia";
import EmailAds from "./pages/catalogs/emailAds/EmailAds";
import LogosGuidelines from "./pages/catalogs/logosGuidlines/LogosGuidelines";
import AdsSellSheets from "./pages/catalogs/adsSellSheets/AdsSellSheets";
import WebBanners from "./pages/catalogs/webBanners/WebBanners";
import SwatchCards from "./pages/catalogs/swatchcards/SwatchCards";
import Search from "./pages/search/Search";
import Downloads from "./pages/downloads/Downloads";
import ViewAll from "./components/ViewAll";
import emailConfirmation from "./pages/auth/EmailConfirmation";
import ResetPassword from "./pages/auth/ResetPassword";
import ResetPasswordConfirmation from "./pages/auth/ResetPasswordConfirmation";
import PostersSignage from "./pages/catalogs/postersSignage/PostersSignage";
import Hangtags from "./pages/catalogs/hangtags/Hangtags";
import Maintenance from "./pages/auth/Maintenance";
import OKTARedirect from "./pages/auth/OKTARedirect";

export default function AppRoutes() {
  const oktaAuth = new OktaAuth(config.oidc);
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  // MAINTENANCE MODE
  // return (
  //   <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
  //     <Routes>
  //       <Route path="*" element={<AuthLayoutRoute component={Maintenance} />} />
  //     </Routes>
  //   </Security>
  // );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/login" element={<AuthLayoutRoute component={Login} />} />
        <Route path="/" element={<MainLayoutRoute component={Catalogs} />} />
        <Route
          path="/reset-password"
          element={<AuthLayoutRoute component={ResetPassword} />}
        />
        <Route
          path="/confirmemail/:confirmEmailToken"
          element={<AuthLayoutRoute component={emailConfirmation} />}
        />
        <Route
          path="/maintenance"
          element={<AuthLayoutRoute component={Maintenance} />}
        />
        <Route
          path="/downloads"
          element={<MainLayoutRoute component={Downloads} />}
        />
        <Route
          path="/forgotpasswordlink/:resetPasswordToken"
          element={<AuthLayoutRoute component={ResetPasswordConfirmation} />}
        />
        <Route
          path="/register"
          element={<AuthLayoutRoute component={Register} />}
        />
        <Route path="/oktaredirect" element={<OKTARedirect />} />
        <Route path="/login/callback" element={<LoginCallback />} />
        <Route
          path="/howto/:howToPage"
          element={<MainLayoutRoute component={HowTo} />}
        />
        <Route
          path="/catalogs"
          element={<MainLayoutRoute component={Catalogs} />}
        />
        <Route
          path="/favorites"
          element={<MainLayoutRoute component={Favorites} />}
        />
        <Route
          path="/catalogs/imagery"
          element={<MainLayoutRoute component={Imagery} />}
        />
        <Route
          path="/catalogs/videos"
          element={<MainLayoutRoute component={Videos} />}
        />
        <Route
          path="/catalogs/emails"
          element={<MainLayoutRoute component={EmailAds} />}
        />
        <Route
          path="/catalogs/swatch-cards-catalogs"
          element={<MainLayoutRoute component={SwatchCards} />}
        />
        <Route
          path="/catalogs/webbanners"
          element={<MainLayoutRoute component={WebBanners} />}
        />
        <Route
          path="/catalogs/sellsheets"
          element={<MainLayoutRoute component={AdsSellSheets} />}
        />
        <Route
          path="/catalogs/logosguidelines"
          element={<MainLayoutRoute component={LogosGuidelines} />}
        />
        <Route
          path="/catalogs/socialmedia"
          element={<MainLayoutRoute component={SocialMedia} />}
        />
        <Route
          path="/catalogs/posterssignage"
          element={<MainLayoutRoute component={PostersSignage} />}
        />
        <Route
          path="/catalogs/hangtags"
          element={<MainLayoutRoute component={Hangtags} />}
        />
        <Route
          path="/shared/:token"
          element={<MainLayoutRoute component={Shared} />}
        />
        <Route
          path="/rates"
          element={<MainLayoutRoute component={ExchangeRate} />}
        />
        <Route
          path="/account/:accountPage"
          element={<MainLayoutRoute component={AccountPage} />}
        />
        <Route
          path="/search"
          element={<MainLayoutRoute component={Search} />}
        />
        <Route
          path="/viewall"
          element={<MainLayoutRoute component={ViewAll} />}
        />
      </Routes>
    </Security>
  );
}
