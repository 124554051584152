/* eslint-disable no-plusplus */
import { useMutation } from "@apollo/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CHANGE_USER_INFO } from "../services/graphql/migrations";
import { FavoriteType, FavPayloadType, FavoriteAsset } from "../types/common";
import { VideoThumbNails } from "../models/interfaces/common";

export type FavoriteState = {
  allFavs: FavoriteType[];
  createFavFolder: boolean;
  rawFavorites: any[];
  currentFavFolder: string;
  videoThumbNails: VideoThumbNails;
};

const initialState: FavoriteState = {
  allFavs: [],
  rawFavorites: [],
  createFavFolder: false,
  currentFavFolder: "My Favorites",
  videoThumbNails: {},
};

const favorite = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    setFavoriteState(state, { payload }: PayloadAction<FavoriteType[]>) {
      state.allFavs = payload;
    },
    setCurrentFavoriteFolder(state, { payload }: PayloadAction<string>) {
      state.currentFavFolder = payload;
    },
    setRawFavoriteState(state, { payload }: PayloadAction<any[]>) {
      state.rawFavorites = payload;
    },
    addVideoThumbNails(state, { payload }: PayloadAction<VideoThumbNails>) {
      if (payload) state.videoThumbNails = payload;
    },
    updateCreateFavFolder(state, { payload }: PayloadAction<boolean>) {
      state.createFavFolder = payload;
    },
    // add asset based on asset id
    addToFavoriteFolder(state, { payload }: PayloadAction<FavPayloadType>) {
      if (payload) {
        state.allFavs[payload.folderIndex].favs.push({
          id: Number(payload.assetID),
          type: "asset",
        });
      }
    },
    removeFavoriteFolder(state, { payload }: PayloadAction<number>) {
      if (payload && state.allFavs.length > payload) {
        state.allFavs.splice(payload, 1);
      }
    },
    removeFavoriteFromFolder(
      state,
      { payload }: PayloadAction<FavPayloadType>
    ) {
      if (payload) {
        const allFavs = state.allFavs.map(
          (item: FavoriteType, itemIndex: number) => {
            if (itemIndex === payload.folderIndex) {
              const itemFavs = item.favs.filter(
                (favItem: FavoriteAsset) =>
                  favItem.id.toString() !== payload.assetID.toString()
              );
              item.favs = itemFavs;
            }
            return item;
          }
        );
        state.allFavs = allFavs;
      }
    },
    removeFavoriteFromRawFavorites(
      state,
      { payload }: PayloadAction<FavPayloadType>
    ) {
      if (payload) {
        const allRawFavorites = state.rawFavorites.map(
          (item: any, itemIndex: number) => {
            if (itemIndex === payload.folderIndex) {
              const itemFavList = item.assetsListFav || [];
              const itemFavs = itemFavList.filter(
                (favItem: any) =>
                  favItem.id.toString() !== payload.assetID.toString()
              );
              item.assetsListFav = itemFavs;
            }
            return item;
          }
        );
        state.rawFavorites = allRawFavorites;
      }
    },
    removeSelectonsFromFavorite(state, { payload }: PayloadAction<string[]>) {
      if (payload && payload.length > 0) {
        const allRawFavorites = state.rawFavorites.map(
          (item: any, itemIndex: number) => {
            const itemFavList = item.assetsListFav || [];
            const itemFavs = itemFavList.filter(
              (favItem: any) => !payload.includes(favItem.id.toString())
            );
            item.assetsListFav = itemFavs;
            return item;
          }
        );

        const allFavs = state.allFavs.map(
          (item: FavoriteType, itemIndex: number) => {
            const itemFavs = item.favs.filter(
              (favItem: FavoriteAsset) =>
                !payload.includes(favItem.id.toString())
            );
            item.favs = itemFavs;
            return item;
          }
        );
        state.allFavs = allFavs;
      }
    },
    addMultipleTOfav(state, { payload }: PayloadAction<any[]>) {
      if (payload) {
        // const temp: AssetInFavFolder[] = [];
        // for (let i = 0; i < payload.length; i++) {
        //   let status = false;
        //   for (let j = 0; j < state.folder.length; j++) {
        //     if (Number(payload[i].id) === state.folder[j].id) status = true;
        //   }
        //   if (!status) temp.push({ id: Number(payload[i].id), type: "asset" });
        // }
        // state.folder = [...state.folder, ...temp];
      }
    },
    // remove asset base on the asset id

    removeFromFav(state, { payload }: PayloadAction<number>) {
      //   //   state.folder = [zv  v v vv  v v vvv  ...state.folder, { id: payload, type: "asset" }];
      if (payload) {
        // if (state.folder.length > 0) {
        //   for (let i = 0; i < state.folder.length; i += 1) {
        //     if (state.folder[i].id === payload) {
        //       state.folder = state.folder.filter(
        //         (object) => object.id !== payload
        //       );
        //       i -= 1;
        //     }
        //   }
        // }
      }
    },
  },
});

export const {
  addToFavoriteFolder,
  removeFavoriteFromFolder,
  removeFavoriteFromRawFavorites,
  removeFavoriteFolder,
  removeSelectonsFromFavorite,
  addMultipleTOfav,
  setFavoriteState,
  updateCreateFavFolder,
  setRawFavoriteState,
  setCurrentFavoriteFolder,
  addVideoThumbNails,
} = favorite.actions;

export default favorite.reducer;
