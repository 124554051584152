import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InPageNavState {
  showNav: boolean;
  showDownload: boolean;
  showRightDownloadAll: boolean;
  showLeftDownloadAll: boolean;
  enableDownload: boolean;
  enableRightDownloadAll: boolean;
  enableLeftDownloadAll: boolean;
  // clickDownload?: () => void;
  showShare: boolean;
  showRightShareAll: boolean;
  showRightClearAll: boolean;
  enableShare: boolean;
  enableRightShareAll: boolean;
  enableRightClearAll: boolean;
  // clickShare?: () => void;
  showFavorite: boolean;
  showRemoveFromFavorite: boolean;
  showSpacerLine: boolean;
  enableFavorite: boolean;
  enableRemoveFromFavorite: boolean;
  // clickFavorite?: () => void;
  showFilter: boolean;
  showName: boolean;
  showSort: boolean;
}

export const initialState: InPageNavState = {
  showNav: true,
  showDownload: true,
  showRightDownloadAll: false,
  enableDownload: true,
  enableRightDownloadAll: false,
  showLeftDownloadAll: false,
  enableLeftDownloadAll: false,
  showShare: true,
  showRightShareAll: false,
  showRightClearAll: false,
  enableShare: true,
  enableRightShareAll: false,
  enableRightClearAll: false,
  showFavorite: true,
  showRemoveFromFavorite: false,
  showSpacerLine: false,
  enableFavorite: true,
  enableRemoveFromFavorite: false,
  showFilter: true,
  showName: true,
  showSort: true,
};

export const searchState: InPageNavState = {
  showNav: true,
  showDownload: true,
  showRightDownloadAll: false,
  enableDownload: true,
  enableRightDownloadAll: false,
  showLeftDownloadAll: false,
  enableLeftDownloadAll: false,
  showShare: true,
  showRightShareAll: false,
  showRightClearAll: false,
  enableShare: true,
  enableRightShareAll: false,
  enableRightClearAll: false,
  showFavorite: true,
  showRemoveFromFavorite: false,
  showSpacerLine: true,
  enableFavorite: true,
  enableRemoveFromFavorite: false,
  showFilter: false,
  showName: false,
  showSort: false,
};

const inPageNav = createSlice({
  name: "inPageNav",
  initialState,
  reducers: {
    setInPageNavState(state, { payload }: PayloadAction<InPageNavState>) {
      return payload != null ? payload : state;
    },
    setInPageNavShow(state, { payload }: PayloadAction<boolean>) {
      state.showNav = payload && true;
    },
    setInPageNavDownload(state, { payload }: PayloadAction<boolean>) {
      state.showDownload = payload && true;
    },
    enableInPageNavDownload(state, { payload }: PayloadAction<boolean>) {
      state.enableDownload = payload && true;
    },
    setInPageNavRightDownloadAll(state, { payload }: PayloadAction<boolean>) {
      state.showRightDownloadAll = payload && true;
    },
    enableInPageNavRightDownloadAll(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.enableRightDownloadAll = payload && true;
    },
    setInPageNavLeftDownloadAll(state, { payload }: PayloadAction<boolean>) {
      state.showLeftDownloadAll = payload && true;
    },
    enableInPageNavLeftDownloadAll(state, { payload }: PayloadAction<boolean>) {
      state.enableLeftDownloadAll = payload && true;
    },
    setInPageNavShare(state, { payload }: PayloadAction<boolean>) {
      state.showShare = payload && true;
    },
    enableInPageNavShare(state, { payload }: PayloadAction<boolean>) {
      state.enableShare = payload && true;
    },
    setInPageNavRightShareAll(state, { payload }: PayloadAction<boolean>) {
      state.showRightShareAll = payload && true;
    },
    enableInPageNavRightShareAll(state, { payload }: PayloadAction<boolean>) {
      state.enableRightShareAll = payload && true;
    },
    setInPageNavRightClearAll(state, { payload }: PayloadAction<boolean>) {
      state.showRightClearAll = payload && true;
    },
    enableInPageNavRightClearAll(state, { payload }: PayloadAction<boolean>) {
      state.enableRightClearAll = payload && true;
    },
    setInPageNavFavorite(state, { payload }: PayloadAction<boolean>) {
      state.showFavorite = payload && true;
    },
    setInPageNavRemoveFromFavorite(state, { payload }: PayloadAction<boolean>) {
      state.showRemoveFromFavorite = payload && true;
    },
    setInPageNavSpacerLine(state, { payload }: PayloadAction<boolean>) {
      state.showSpacerLine = payload && true;
    },
    enableInPageNavFavorite(state, { payload }: PayloadAction<boolean>) {
      state.enableFavorite = payload && true;
    },
    enableInPageNavRemoveFromFavorite(
      state,
      { payload }: PayloadAction<boolean>
    ) {
      state.enableRemoveFromFavorite = payload && true;
    },
    setInPageNavFilter(state, { payload }: PayloadAction<boolean>) {
      state.showFilter = payload && true;
    },
    setInPageNavName(state, { payload }: PayloadAction<boolean>) {
      state.showName = payload && true;
    },
    setInPageNavSort(state, { payload }: PayloadAction<boolean>) {
      state.showSort = payload && true;
    },
    searchPageNav(state) {
      return searchState;
    },
    resetInPageNav(state) {
      return initialState;
    },
  },
});

export const {
  setInPageNavState,
  setInPageNavShow,
  setInPageNavDownload,
  enableInPageNavDownload,
  setInPageNavRightDownloadAll,
  enableInPageNavRightDownloadAll,
  setInPageNavLeftDownloadAll,
  enableInPageNavLeftDownloadAll,
  setInPageNavShare,
  enableInPageNavShare,
  setInPageNavRightShareAll,
  enableInPageNavRightShareAll,
  setInPageNavRightClearAll,
  enableInPageNavRightClearAll,
  setInPageNavFavorite,
  setInPageNavRemoveFromFavorite,
  enableInPageNavFavorite,
  enableInPageNavRemoveFromFavorite,
  setInPageNavSpacerLine,
  setInPageNavFilter,
  setInPageNavName,
  setInPageNavSort,
  resetInPageNav,
  searchPageNav,
} = inPageNav.actions;
export default inPageNav.reducer;
