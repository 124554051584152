import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IBreadcrumbState {
  show: boolean;
}

export const initialState: IBreadcrumbState = {
  show: true,
};

const breadcrumbState = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    setBreadcrumbState(state, { payload }: PayloadAction<boolean>) {
      state.show = payload && true;
    },
  },
});

export const { setBreadcrumbState } = breadcrumbState.actions;
export default breadcrumbState.reducer;
