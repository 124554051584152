import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import http from "../../services/api";
import companyLogo from "../../assets/img/logo_maintenance.png";
import fixIt from "../../assets/img/fix_it.png";

export default function Maintenance() {
  const navigate = useNavigate();
  return (
    <div className="registration-page">
      <div className="registration maxwth850px">
        <div className="container-fluid">
          <div className="row hitfit">
            <div
              className="single-col col-12 minhitfit txtalic"
              style={{ padding: "35px 83px" }}
            >
              <div className="flex-column flex-md-row justify-content-between align-items-center mb-4">
                <div className="vstack">
                  <div className="txtalic">
                    <img className="wth105px" alt="logo" src={companyLogo} />
                  </div>
                  <br />
                  <div className="txtalic">
                    <img className="wth55px" alt="fix_it" src={fixIt} />
                  </div>
                </div>
              </div>

              <div className="">
                <div className="">
                  <p className="fntblu fnt25px fntweit500">
                    <b>We will be back in a bit</b>
                  </p>
                  <br />
                  <p className="fntweit500 fnt17px">
                    The Gildan Digital Asset Portal will undergo an upgrade
                    starting from Friday, February 9th, 2024, at 10:00 PM AST.
                    This upgrade is aimed at enhancing your user experience. We
                    anticipate the system to be fully operational by Tuesday,
                    February 13th, 2024.
                  </p>
                  <p className="fntweit500 fnt17px">
                    We apologize for any inconvenience caused and appreciate
                    your patience during this time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
