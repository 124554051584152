import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import FilterIcon from "../../assets/img/filter.png";

export default function HowToSearch() {
  const { t, i18n } = useTranslation("common");

  return (
    <main className="how-to-container">
      <div className="steps-container">
        <div className="steps-column">
          <div className="step">
            <h4 className="step-title">{t("Step 1")}</h4>
            <p className="step-description">{t("Search-Step1")}</p>
          </div>
          <div className="step">
            <h4 className="step-title">{t("Step 2")}</h4>
            <p className="step-description">{t("Search-Step2")}</p>
          </div>
        </div>
        <div className="steps-column">
          <div className="step">
            <h4 className="step-title">{t("Step 3")}</h4>
            <p className="step-description">
              {t("Search-Step3")}
              <img className="how-to-icon" src={FilterIcon} alt="Filter icon" />
              {t("Search-Step3.2")}
            </p>
          </div>
        </div>
      </div>
      <div className="how-to-video">
        <h4 className="video-title">{t("InstructionalVideo")}</h4>
        <ReactPlayer
          height="480px"
          width="100%"
          url="https://youtu.be/AtPMlga_mZg"
        />
      </div>
    </main>
  );
}
