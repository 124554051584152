/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useMemo } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate, useLocation, useMatch, Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { RootState } from "../../rootReducer";
import HeartCardFooterFill from "../../assets/img/heart-card-footer-fill.svg";
import ShareCardFooter from "../../assets/img/share-card-footer.svg";
import ExpandCardFooter from "../../assets/img/expand-card-footer.svg";
import DownloadCardFooter from "../../assets/img/download-card-footer.svg";
import EditIcon from "../../assets/img/pencil.png";
import TrashCanIcon from "../../assets/img/trash.svg";
import DeleteFavoriteConfirmationModal from "../../components/modals/DeleteFavoriteConfirmationModal";
import CatalogCard from "../../components/CatalogCard";
import {
  ASSETS_CATALOG,
  FAV_COLLECTION_LIST,
} from "../../services/graphql/pimcoreDam";
import {
  initialState,
  setInPageNavState,
  enableInPageNavRightClearAll,
  enableInPageNavRightShareAll,
  enableInPageNavRightDownloadAll,
} from "../../components/inPageNavSlice";
import { setBreadcrumbState } from "../../components/breadcrumbs/breadcrumbSlice";
import useMultiSelection from "../../hooks/useMultiSelection";
import { GraphqlApi } from "../../models/interfaces/graphApi";
import AssetDownloadModal from "../../components/modals/AssetDownloadModal";
import AssetDetailModal from "../../components/modals/AssetDetailModal";
import { CHANGE_USER_INFO } from "../../services/graphql/migrations";
import useFavorites from "../../hooks/useFavorites";
import { FavoriteAsset, FavoriteType } from "../../types/common";
import { setCurrentFavoriteFolder } from "../favoriteSlice";
import {
  getAssetThumbnailUrl,
  getVideoThumbnails,
  getDocumentThumbnails,
  getAssetsByIds,
  getResolutionsByAssetIds,
  checkForLandscapeOrPortrait,
} from "../../utils/catalog";
import {
  VideoThumbnail,
  DocumentThumbnail,
  AssetInfo,
  AssetResolutionData,
} from "../../models/interfaces/common";
import { setUseMultiSelectionPossibleState } from "../../hooks/useMultiSelectionSlice";
import { selectAsset } from "../navBarSlice";
import EditFavoriteFolderModal from "../../components/modals/EditFavoriteFolderModal";
import DownloadStatusModal from "../../components/modals/DownloadStatusModal";
import CancelDownloadConfirmationModal from "../../components/modals/CancelDownloadModal";
import { setAssets } from "../assetsSlice";
import { toggleDownloadIsOpen } from "../downloadSlice";
import { emptyExcludedAssets } from "../assetExclusionSlice";

interface getUser {
  getUser: collectionFolderFavAsset[];
}
interface collectionFolderFavAsset {
  name: string; // folder name
  assetListFav: assetListFav[]; // folder.length
}
interface assetListFav {
  id: string;
  filename: string;
  fullpath: string;
  fullpathThumbnailPortraitImage?: string;
  fullpathThumbnailLandscapeImage?: string;
  fullpathExtandPortraitImage?: string;
  fullpathExtandLandscapeImage?: string;
  fullpathThumbnailFont?: string;
  fullpathExtandFont?: string;
  type: string;
  filesize: number;
  creationDate: number;
  metadata: { data: string; name: string }[];
}
interface PropValue {
  selectedFolder: string | null;
}

export default function Favorites() {
  const { t, i18n } = useTranslation("common");
  const { getFavorites, setRefetchNeeded, data } = useFavorites();
  const SelectionSlice = useSelector((state: RootState) => state.useSelections);
  const FavoriteSlice = useSelector((state: RootState) => state.favorite);
  const Favorite = useAppSelector((state) => state.newFavorite.favorite);
  const currentFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.currentFolderId
  );
  const defaultFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.defaultFolderId
  );
  const UserInfoSlice = useSelector((state: RootState) => state.user);
  const [videoThumbnails, setVideoThumbnails] = useState<VideoThumbnail>({});
  const [documentThumbnails, setDocumentThumbnails] =
    useState<DocumentThumbnail>({});
  const dispatch = useAppDispatch();

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  const downloadStatusIsOpen = useAppSelector(
    (state) => state.multipleAssetsDownload.isOpen
  );

  const excludedAssets = useAppSelector((state) => state.assetExclusion.assets);

  // update user favorite folder pimcore
  const [updateUserInfo] = useMutation(CHANGE_USER_INFO);
  const navigate = useNavigate();
  const location = useLocation();
  const initialSelection: string[] = [];
  // initial state for favorite folder delete confirmation modal
  const [
    showDeleteFavoriteConfirmationModal,
    setDeleteFavoriteConfirmationModal,
  ] = useState(false);

  // edit folder confirmation modal
  const [showEditFavoriteFolderModal, setEditFavoriteFolderModal] =
    useState(false);

  // initial state for download and asset detail modals
  const [showDownload, setShowDownload] = useState(false);
  const downloadModalState = useAppSelector((state) => state.download.isOpen);
  const [showRightControls, setShowRightControls] = useState(true);
  const [showAssetDetail, setShowAssetDetail] = useState(false);

  const [restApiAssets, setRestApiAssets] = useState<AssetInfo[]>([]);

  const [assetResolutions, setAssetResolutions] = useState<AssetResolutionData>(
    {}
  );

  // state for currently selected favorite folder
  const [selectedFolder, setSelectedFolder] = useState<string | null>(
    "My Favorites"
  );

  dispatch(setBreadcrumbState(true));

  const resetFavoriteNavbar = () => {
    dispatch(
      setInPageNavState({
        ...initialState,
        showDownload: true,
        enableDownload: false,
        showShare: true,
        enableShare: false,
        showFavorite: false,
        showFilter: false,
        showSpacerLine: true,
        showName: false,
        showRemoveFromFavorite: true,
        enableRemoveFromFavorite: false,
        showRightDownloadAll: true,
        enableRightDownloadAll: showRightControls,
        showRightShareAll: true,
        enableRightShareAll: showRightControls,
        showRightClearAll: true,
        enableRightClearAll: showRightControls,
      })
    );
  };

  const updateFavoriteNavbar = () => {
    dispatch(
      setInPageNavState({
        ...initialState,
        showDownload: true,
        enableDownload: true,
        showShare: true,
        enableShare: true,
        showFavorite: false,
        showFilter: false,
        showSpacerLine: true,
        showName: false,
        showRemoveFromFavorite: true,
        enableRemoveFromFavorite: true,
        showRightDownloadAll: true,
        enableRightDownloadAll: showRightControls,
        showRightShareAll: true,
        enableRightShareAll: showRightControls,
        showRightClearAll: true,
        enableRightClearAll: showRightControls,
      })
    );
  };

  const goToFavoritePage = () => {
    navigate({
      pathname: "/favorites",
      search: "?favoriteFolder=My Favorites",
    });
  };

  // get currently selected folder from url or set default
  useEffect(() => {
    const folderName = new URLSearchParams(location.search).get(
      "favoriteFolder"
    );
    if (folderName) {
      setSelectedFolder(decodeURIComponent(folderName));
      dispatch(setCurrentFavoriteFolder(folderName));
      // configure the In-Page navigation bar buttons base of the initial state
      // resetFavoriteNavbar();
    } else {
      goToFavoritePage();
    }
  });

  useEffect(() => {
    if (defaultFavoriteFolderId) {
      getResolutionsByAssetIds(
        Favorite[
          currentFavoriteFolderId || defaultFavoriteFolderId
        ]?.assets.map((asset) => Number(asset.id)) || [],
        setAssetResolutions,
        source
      );
    }

    return () => {
      // source.cancel("");
    };
  }, [
    currentFavoriteFolderId,
    Favorite[currentFavoriteFolderId || defaultFavoriteFolderId]?.assets,
  ]);

  // use the multiple selection hook on cards
  const {
    handleCardClick,
    selectedList,
    clearMultiSelections,
    setAssetData,
    updateSelectedListFromSlice,
  } = useMultiSelection("getUser", "asset", initialSelection, false, true);

  // selectedlist
  useEffect(() => {
    dispatch(selectAsset(selectedList));
  }, [selectedList]);

  useEffect(() => {
    updateSelectedListFromSlice();
  }, [SelectionSlice.possibleSelectionsListener]);

  const setInPageNavBarVisibility = () => {
    if (selectedList.length > 0) {
      updateFavoriteNavbar();
    } else {
      resetFavoriteNavbar();
    }
  };

  useEffect(() => {
    setInPageNavBarVisibility();
  }, [showDownload, showAssetDetail, selectedList]);

  const updateTopRightControls = () => {
    dispatch(enableInPageNavRightClearAll(true));
    dispatch(enableInPageNavRightShareAll(true));
    dispatch(enableInPageNavRightDownloadAll(true));
  };

  useEffect(() => {
    const videoAssetIds = Favorite[
      currentFavoriteFolderId || defaultFavoriteFolderId
    ]?.assets
      .filter((asset) => asset.type.toString() === "video")
      .map((item) => item.id);
    if (videoAssetIds)
      getVideoThumbnails(videoAssetIds.toString(), setVideoThumbnails);
    const documentAssetIds = Favorite[
      currentFavoriteFolderId || defaultFavoriteFolderId
    ]?.assets
      .filter((asset) => asset.type.toString() === "document")
      .map((item) => item.id);
    if (documentAssetIds)
      getDocumentThumbnails(documentAssetIds.toString(), setDocumentThumbnails);

    const selectedFolderData = Favorite[
      currentFavoriteFolderId || defaultFavoriteFolderId
    ]?.assets.map((asset) => asset.id);
    if (!selectedFolderData || selectedFolderData.length === 0) {
      setShowRightControls(false);
      dispatch(setUseMultiSelectionPossibleState([]));
    } else {
      setShowRightControls(true);
      dispatch(setUseMultiSelectionPossibleState(selectedFolderData));
      updateTopRightControls();
    }
  }, [currentFavoriteFolderId]);

  useEffect(() => {
    if (defaultFavoriteFolderId) {
      setAssetData(
        Favorite[currentFavoriteFolderId || defaultFavoriteFolderId]
          ?.assets as AssetInfo[]
      );
      dispatch(
        setAssets(
          Favorite[currentFavoriteFolderId || defaultFavoriteFolderId]
            ?.assets as AssetInfo[]
        )
      );
    }
  }, [selectedFolder, Favorite]);

  useEffect(() => {
    clearMultiSelections();
    // dispatch(setUseMultiSelectionPossibleState([]));
    if (selectedFolder) {
      const selectedFolderData = Favorite[
        currentFavoriteFolderId || defaultFavoriteFolderId
      ]?.assets.map((asset) => asset.id);
      if (!selectedFolderData) goToFavoritePage();
      if (!selectedFolderData || selectedFolderData.length === 0) {
        setShowRightControls(false);
        dispatch(setUseMultiSelectionPossibleState([]));
      } else {
        setShowRightControls(true);
        // updateTopRightControls();
        dispatch(setUseMultiSelectionPossibleState(selectedFolderData));
      }
      // resetFavoriteNavbar();
    }
  }, [selectedFolder, Favorite]);

  // favorite modal controller
  const toggleDeleteFavoriteConfirmationModal = () =>
    setDeleteFavoriteConfirmationModal(!showDeleteFavoriteConfirmationModal);

  // edit modal controller
  const toggleEditFavoriteFolderModal = () =>
    setEditFavoriteFolderModal(!showEditFavoriteFolderModal);

  // download and asset detail modal contrroller
  const toggleDownloadModal = () => setShowDownload(!showDownload);
  const toggleDownloadModal2 = () => dispatch(toggleDownloadIsOpen());
  const toggleAssetDetail = () => setShowAssetDetail(!showAssetDetail);

  const DeleteButton = () => (
    <button
      className="trash-link me-3"
      type="button"
      onClick={toggleDeleteFavoriteConfirmationModal}
      style={{
        visibility:
          Favorite[currentFavoriteFolderId || defaultFavoriteFolderId]?.name !==
          "My Favorites"
            ? "visible"
            : "hidden",
      }}
    >
      <img src={TrashCanIcon} alt="Remove" />
    </button>
  );

  const EditButton = () => (
    <a
      className=" ms-3"
      type="button"
      href="#asdfaasd"
      onClick={() => {
        toggleEditFavoriteFolderModal();
      }}
    >
      <img src={EditIcon} alt="edit" />
    </a>
  );

  // get list of favorite modals
  const FavoriteAssets = () => {
    const selectedFolderData = FavoriteSlice.rawFavorites.find(
      (item: any) => item.name === selectedFolder
    );
    return (
      <>
        {defaultFavoriteFolderId in Favorite &&
          Favorite[
            currentFavoriteFolderId || defaultFavoriteFolderId
          ]?.assets.map((item: AssetInfo) => {
            const additionalClasses = selectedList.includes(item.id)
              ? "selected"
              : "";
            const filename: string = item.filename.toString();
            const ext =
              filename.substring(
                filename.lastIndexOf(".") + 1,
                filename.length
              ) || filename;
            return (
              <div
                className="col-xl-3 col-lg-6 col-md-4 col-sm-6 col-12"
                key={`${item.id}`}
              >
                <CatalogCard
                  id={item.id}
                  title={item.filename}
                  downloadLink={item.fullpath}
                  shareLink={item.fullpath}
                  src={
                    // eslint-disable-next-line no-nested-ternary
                    item.type !== "video"
                      ? item.type === "document" && ext.toUpperCase() === "PDF"
                        ? documentThumbnails[item.id]
                        : checkForLandscapeOrPortrait(
                            assetResolutions[item.id],
                            item.fullpathThumbnailPortraitImage,
                            item.fullpathThumbnailLandscapeImage
                          )
                      : item.youTubeSDThumbnailPath
                  }
                  type={item.type}
                  filename={item.filename}
                  fullpath={item.fullpath}
                  fullpathExtandFont={item.fullpathExtandFont}
                  fullpathExtandLandscapeImage={
                    item.fullpathExtandLandscapeImage
                  }
                  fullpathExtandPortraitImage={item.fullpathExtandPortraitImage}
                  fullpathThumbnailFont={item.fullpathThumbnailFont}
                  fullpathThumbnailLandscapeImage={
                    item.fullpathThumbnailLandscapeImage
                  }
                  fullpathThumbnailPortraitImage={
                    item.fullpathThumbnailPortraitImage
                  }
                  youTubeSDThumbnailPath={item.youTubeSDThumbnailPath}
                  youTubeSDPath={item.youTubeSDPath}
                  customMetadataId={item.customMetadataId}
                  creationDate={item.creationDate.toString()}
                  fileSize={item.fileSize}
                  additionalClasses={additionalClasses}
                  selectCallback={handleCardClick}
                  toggleAssetDownloadModal={toggleDownloadModal2}
                  toggleAssetDetailModal={toggleAssetDetail}
                  // toggleAssetFavoriteModal={toggleAssetFavorite}
                  isFavorite
                  isNew={item.assetTag === 1}
                  dpiAsset={item.dpiAsset}
                />
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <div className="breadcrumbs d-flex justify-content-start align-items-center">
        {Favorite[currentFavoriteFolderId || defaultFavoriteFolderId]?.name !==
        "My Favorites" ? (
          <DeleteButton />
        ) : null}
        <h2 className="mb-0 me-0">
          {t(
            Favorite[currentFavoriteFolderId || defaultFavoriteFolderId]?.name
          )}
        </h2>
        {/* <img
          src={EditIcon}
          alt="edit"
          style={{
            visibility:
              selectedFolder !== "My Favorites" ? "visible" : "hidden",
          }}
        /> */}
        {Favorite[currentFavoriteFolderId || defaultFavoriteFolderId]?.name !==
        "My Favorites" ? (
          <EditButton />
        ) : null}
      </div>
      <div className="cp container-fluid">
        <div className="cards">
          <div className="row">
            <FavoriteAssets />
          </div>
        </div>
      </div>
      <EditFavoriteFolderModal
        isOpen={showEditFavoriteFolderModal}
        toggle={toggleEditFavoriteFolderModal}
        modalClass="confirmationModal"
        centered
        backdrop="static"
        keyboard
        selectedFolder={selectedFolder}
      />
      <DeleteFavoriteConfirmationModal
        isOpen={showDeleteFavoriteConfirmationModal}
        toggle={toggleDeleteFavoriteConfirmationModal}
        modalClass="confirmationModal"
        centered
        backdrop="static"
        keyboard
        selectedFolder={currentFavoriteFolderId}
      />
      <AssetDownloadModal
        isOpen={downloadModalState}
        toggle={toggleDownloadModal2}
        selectedList={selectedList}
        modalClass={
          selectedList.length > 1 ? "downloadMultipleModal" : "downloadModal"
        }
        centered
        backdrop="static"
        keyboard
        isMultipleDownload={false}
        useMultiSelectionData={false}
      />
      <AssetDetailModal
        isOpen={showAssetDetail}
        toggle={toggleAssetDetail}
        modalClass="assetDetailsModal"
        centered
        backdrop="static"
        keyboard
        fullscreen
      />
    </>
  );
}
