import React, { useEffect, useState } from "react";
import { useMatch, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../rootReducer";

interface IProps {
  location: any;
}

const Breadcrumbs = ({ location }: IProps) => {
  const breadcrumbState = useSelector((state: RootState) => state.breadcrumb);
  const { search } = useLocation();
  const navigate = useNavigate();
  // const [pathnames, setPathNames] = useState<striing[]>(location.pathname.split("/").filter((x) => x));

  const isSearchPage = useMatch("/search");
  const pathnames = location.pathname.split("/").filter((x: any) => x);
  if (isSearchPage && pathnames.length === 1) {
    const val = new URLSearchParams(search).get("search");
    pathnames.push(`Search result from “${val || ""}”`);
  }

  const { t, i18n } = useTranslation("common");
  const breadcrumbsLabel: { [key: string]: string } = {
    imagery: t("Imagery"),
    videos: t("Videos"),
    catalogs: t("Asset Library"),
    "swatch-cards-catalogs": t("Swatchcards & Catalogs"),
    socialmedia: t("Social Media"),
    emails: t("Email Ads"),
    webbanners: t("Web Banners"),
    sellsheets: t("Ads & Sell Sheets"),
    logosguidelines: t("Logos & Guidelines"),
    posterssignage: t("Posters & Signage"),
  };

  if (!breadcrumbState.show) return null;

  const AllBreadcrumbs = () => (
    <div className="breadcrumbs">
      {pathnames.map((name: string, index: number) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <strong className="text-capitalize" key={name}>
            {breadcrumbsLabel[name] || name}
          </strong>
        ) : (
          <React.Fragment key={name}>
            <span
              className="opacity-40 text-capitalize"
              role="button"
              onClick={() => navigate(routeTo)}
              onKeyDown={() => {}}
              tabIndex={0}
              key={name}
            >
              {breadcrumbsLabel[name] || name}
            </span>
            <span className="opacity-40" key={`${name}-${routeTo}`}>
              {" "}
              &gt;{" "}
            </span>
          </React.Fragment>
        );
      })}
    </div>
  );

  if (pathnames.length > 1) {
    return <AllBreadcrumbs />;
  }
  return null;
};
export default Breadcrumbs;
