import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../store";
import { RootState } from "../../rootReducer";
import { ModalProps, FavoriteAsset, FavoriteType } from "../../types/common";
import { deleteFavoriteFolder } from "../../utils/catalog";
import {
  FavoriteInfoInterface,
  setCurrentFolderId,
  setFavorite,
} from "../../pages/newFavoriteSlice";

type DeleteFavoriteConfirmationModalType = ModalProps & {
  selectedFolder: string | null;
};

const DeleteFavoriteConfirmationModal = ({
  isOpen,
  toggle,
  modalClass,
  selectedFolder,
  keyboard = true,
  backdrop = "static",
  centered = true,
}: DeleteFavoriteConfirmationModalType) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");

  const dispatch = useAppDispatch();

  const userId = useSelector((state: RootState) => state.user?.id);
  const defaultFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.defaultFolderId
  );

  const goToDefaultFavoritePage = () => {
    if (defaultFavoriteFolderId) {
      navigate({
        pathname: "/favorites",
        search: "?favoriteFolder=My Favorites",
      });
      dispatch(setCurrentFolderId(defaultFavoriteFolderId));
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={toggle}
      className={`${modalClass}`}
      backdrop={backdrop}
      centered={centered}
      keyboard={keyboard}
    >
      <Modal.Body>
        <h2>{t("Confirmation")}</h2>
        <p>{t("Are you sure that you would like to delete this folder?")}</p>
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="btn btn-red w-100 me-1"
            type="button"
            disabled={!selectedFolder}
            onClick={() => {
              if (userId) {
                deleteFavoriteFolder(
                  userId as number,
                  selectedFolder,
                  null,
                  (value: FavoriteInfoInterface) => dispatch(setFavorite(value))
                );
                goToDefaultFavoritePage();
                toggle();
              }
            }}
          >
            {t("Delete")}
          </button>
          <button
            className="btn btn-reversed w-100 ms-1"
            type="button"
            onClick={toggle}
          >
            {t("Cancel")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteFavoriteConfirmationModal;
