/* eslint-disable no-shadow */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface EmailConfirmInterface {
  isOpen: boolean;
}

/*
 * From Card = 1
 * From Navbar = 0
 */

const initialState: EmailConfirmInterface = {
  isOpen: false,
};

const emailConfirm = createSlice({
  name: "emailConfirm",
  initialState,
  reducers: {
    toggleOpenEmail(state) {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleOpenEmail } = emailConfirm.actions;

export default emailConfirm.reducer;
