import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { useNavigate, useMatch } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { RootState } from "../../rootReducer";
import { ModalProps, FavoriteAsset, FavoriteType } from "../../types/common";
import { CHANGE_USER_INFO } from "../../services/graphql/migrations";
import {
  enableInPageNavRightClearAll,
  enableInPageNavRightDownloadAll,
  enableInPageNavRightShareAll,
} from "../inPageNavSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  clearAllPossibleMultiSelectionState,
  setUseMultiSelectionState,
} from "../../hooks/useMultiSelectionSlice";
import { addExcludedAssets } from "../../pages/assetExclusionSlice";
import { removeAssetsFromFavorite } from "../../utils/catalog";
import {
  FavoriteInfoInterface,
  setFavorite,
} from "../../pages/newFavoriteSlice";

type DeleteAllFavoriteConfirmationModalType = ModalProps;

const DeleteAllFavoriteConfirmationModal = ({
  isOpen,
  toggle,
  modalClass,
  //   selectedFolder,
  keyboard = true,
  backdrop = "static",
  centered = true,
}: DeleteAllFavoriteConfirmationModalType) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const dispatch = useAppDispatch();
  const UserInfoSlice = useSelector((state: RootState) => state.user);
  const userId = useSelector((state: RootState) => state.user?.id);
  const FavoriteSlice = useSelector((state: RootState) => state.favorite);
  const Favorite = useAppSelector((state) => state.newFavorite.favorite);
  const currentFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.currentFolderId
  );
  const defaultFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.defaultFolderId
  );
  const isFavoritesPage = useMatch("/favorites");

  // following function is for deselecting all asset
  const clearMultiSelections = () => {
    dispatch(setUseMultiSelectionState([]));
  };

  // update user favorite folder pimcore
  const [updateUserInfo] = useMutation(CHANGE_USER_INFO, {
    onCompleted({ updateUser: { success } }) {
      if (success) {
        if (isFavoritesPage) {
          const folderName = new URLSearchParams(window.location.search).get(
            "favoriteFolder"
          );
          navigate({
            pathname: "/favorites",
            search: `?favoriteFolder=${
              folderName ? encodeURIComponent(folderName) : ""
            }`,
          });
        }
        // dispatch(removeSelectonsFromFavorite(UseSelectionsSlice.allSections));
      }
    },
  });

  const excludeAssets = (assetIds: string[]) =>
    dispatch(addExcludedAssets(assetIds));

  // function that remove all asset from current favorite folder
  const clearAllFavorites = () => {
    if (userId && defaultFavoriteFolderId) {
      removeAssetsFromFavorite(
        userId,
        Favorite[currentFavoriteFolderId || defaultFavoriteFolderId].assets.map(
          (assetItem) => assetItem.id
        ),
        defaultFavoriteFolderId,
        currentFavoriteFolderId,
        (value: FavoriteInfoInterface) => dispatch(setFavorite(value))
      );
      dispatch(enableInPageNavRightClearAll(false));
      dispatch(enableInPageNavRightShareAll(false));
      dispatch(enableInPageNavRightDownloadAll(false));
      navigate({
        pathname: "/favorites",
        search: `?favoriteFolder=${
          currentFavoriteFolderId
            ? encodeURIComponent(Favorite[currentFavoriteFolderId].name)
            : encodeURIComponent(Favorite[defaultFavoriteFolderId].name)
        }`,
      });
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={toggle}
      className={`${modalClass}`}
      backdrop={backdrop}
      centered={centered}
      keyboard={keyboard}
    >
      <Modal.Body>
        <h2>{t("Confirmation")}</h2>
        <p>
          {t(
            "Are you sure that you would like to remove all items from this folder?"
          )}
        </p>
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="btn btn-red w-100 me-1"
            type="button"
            //   disabled={!selectedFolder || selectedFolder.length === 0}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              clearMultiSelections();
              dispatch(clearAllPossibleMultiSelectionState());
              clearAllFavorites();
              toggle();
            }}
          >
            {t("Remove")}
          </button>
          <button
            className="btn btn-reversed w-100 ms-1"
            type="button"
            onClick={toggle}
          >
            {t("Cancel")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteAllFavoriteConfirmationModal;
