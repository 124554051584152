/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { setInPageNavShow } from "../../components/inPageNavSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import DynamicButton from "./DynamicButton";

import {
  downloadFileOnBrowser,
  downloadZip,
  getDownloads,
  postDownloadedOnce,
  removeDownloads,
} from "../../utils/catalog";
import {
  setUnreadMessages,
  DownloadStatus,
  addMultiDownloadProcess,
  removeProcesses,
} from "../multipleAssetDownloadSlice";
import { type RootState } from "../../rootReducer";
import Tooltip from "../../components/tooltip/Tooltip";

// Icons
import zippingIcon from "../../assets/img/downloads/zippingIcon.svg";
import closeButtonXS from "../../assets/img/downloads/closeButtonXS.svg";
import readyButton from "../../assets/img/downloads/readyIcon.svg";
import failedButton from "../../assets/img/downloads/retryIcon.svg";
import closeButton from "../../assets/img/downloads/closeIcon.svg";
import progressBar from "../../assets/img/downloads/progressBar.gif";

const BigLoader = () => (
  <div className="loaderContainer">
    <BeatLoader size={50} color="#0C2658" />
    <h1>Loading...</h1>
  </div>
);

interface DownloadsCardProps {
  id: number;
  name: string;
  expirationDate: string;
  downloadedOnce: boolean;
  notifiedOnce: boolean | null;
  downloadUrl?: string;
  presetSetting?: string | null;
  removeById: (id: number, isDownloadedOnce: boolean) => void;
}

interface DownloadsContainerProps {
  downloadsArray: { date: string; items: DownloadsCardProps[] };
  removeById: (id: number, isDownloadedOnce: boolean) => void;
}

interface DownloadsNavBarProps {
  sortDirection: boolean;
  setSortDirection: (direction: boolean) => void;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  removeAll: () => void;
  areDownloadsEmpty: boolean;
}

const DownloadsNavBar = ({
  sortDirection,
  setSortDirection,
  sortBy,
  setSortBy,
  removeAll,
  areDownloadsEmpty,
}: DownloadsNavBarProps) => (
  <div className="container-fluid filter">
    <div className="row">
      <div className="col-12 d-flex flex-md-row flex-column justify-content-between align-items-center">
        <div className="mb-2 mb-md-0" />
        <div className="d-flex justify-content-between align-items-center">
          <a
            href="#ass"
            className="active"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (!areDownloadsEmpty) {
                removeAll();
              }
            }}
            style={{
              color: areDownloadsEmpty ? "#3F527A" : "#ffffff",
              cursor: areDownloadsEmpty ? "not-allowed" : "pointer",
            }}
          >
            Remove All
          </a>
          {!areDownloadsEmpty && (
            <>
              <div className="mb-2 mb-md-0">
                <button
                  className="btn btn-secondary btn-name dropdown-toggle"
                  type="button"
                  id="inPageNavNameFilter"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {sortBy === "expirationDate"
                    ? "Expiration"
                    : sortBy === "zipFileSize"
                      ? "File Size"
                      : "Date"}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="inPageNavNameFilter"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href="#asdf"
                      onClick={() => setSortBy("expirationDate")}
                    >
                      Expiration
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#asdf"
                      onClick={() => setSortBy("zipFileSize")}
                    >
                      File Size
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#asdf"
                      onClick={() => setSortBy("creationDate")}
                    >
                      Date
                    </a>
                  </li>
                </ul>
              </div>
              <div className="ms-2">
                <button
                  className={`btn btn-secondary dropdown-toggle ${
                    sortDirection ? "btn-order-desc" : "btn-order"
                  }`}
                  type="button"
                  id="dropdownMenuButton1"
                  // data-bs-toggle="dropdown"
                  aria-expanded="false"
                  aria-label="Sorter"
                  onClick={() => setSortDirection(!sortDirection)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
);

const DownloadsCard = ({
  id,
  name,
  expirationDate,
  downloadedOnce,
  notifiedOnce,
  downloadUrl,
  presetSetting,
  removeById,
}: DownloadsCardProps) => {
  const [status, setStatus] = useState(presetSetting ? "failed" : "completed");
  const [isDownloadedOnce, setIsDownloadedOnce] = useState(downloadedOnce);
  const [fileName, setFileName] = useState(name);
  const [url, setUrl] = useState(downloadUrl);
  const [expDate, setExpDate] = useState(expirationDate);
  const dispatch = useAppDispatch();
  const multiDownload = useAppSelector(
    (state) => state.multipleAssetsDownload.downloadProcesses
  );
  const unreadMessages = useAppSelector(
    (state) => state.multipleAssetsDownload.unreadMessages
  );

  function getButtonByStatus() {
    switch (status) {
      case "ready":
        return readyButton;
      case "inProgress":
        return closeButton;
      case "failed":
        return failedButton;
      case "completed":
        return readyButton;
      default:
        return closeButton;
    }
  }

  function getColorByStatus(downloadStatus: string | undefined) {
    switch (status) {
      case "ready":
        return "#009800";
      case "inProgress":
        return "#003EA5";
      case "failed":
        return "#FF1010";
      case "completed":
        return "#009800";
      default:
        return "#003EA5";
    }
  }

  function showTextByStatus(downloadStatus: string | undefined) {
    switch (downloadStatus) {
      case DownloadStatus.inProgress:
        return `Zipping files`;
      case DownloadStatus.ready:
        return "Ready to Download";
      case DownloadStatus.completed:
        return `Ready to Download`;
      case DownloadStatus.failed:
        return `Error Zipping Files`;
      default:
        return `Zipping files`;
    }
  }

  const retryDownload = async () => {
    setStatus("inProgress");
    if (presetSetting) {
      const zipSettings = JSON.parse(presetSetting);
      const response = await downloadZip(zipSettings);

      if (response?.zipTaskId) {
        dispatch(
          addMultiDownloadProcess({
            status: DownloadStatus.inProgress,
            zipID: response?.zipTaskId,
            retryDownloadId: id,
            fileCount: zipSettings.imageAssetIds.length,
            presetSetting: zipSettings,
            downloadProgress: 0,
          })
        );
      }
    }
  };

  const checkIfDownloadIsCompleted = () => {
    const downloadItem = multiDownload.find((dwnld) => dwnld.zipID === id);
    if (downloadItem?.status === "completed") {
      setStatus("completed");
      setUrl(downloadItem.downloadUrl);
      setFileName(downloadItem.zipFileName ?? "");
      setExpDate(downloadItem.expirationDate ?? "");
    }
  };

  useEffect(() => {
    // search for object inside multiDownload that matches the id
    const downloadItem = multiDownload.find(
      (dwnld) => dwnld.retryDownloadId === id
    );
    if (downloadItem) {
      if (status === "inProgress" && downloadItem?.status === "completed") {
        setStatus("completed");
        setUrl(downloadItem.downloadUrl);
        setFileName(downloadItem.zipFileName ?? "");
      }
      if (status === "inProgress" && downloadItem?.status === "failed") {
        setStatus("failed");
      }
    }
  }, [multiDownload]);

  useEffect(() => {
    if (!name || expirationDate === "Invalid date") {
      setStatus("inProgress");
    }
  }, []);

  useEffect(() => {
    if (status === "inProgress") {
      checkIfDownloadIsCompleted();
    }
  }, [multiDownload]);

  return (
    <div className="downloadCard">
      <img src={zippingIcon} alt="documentIcon" className="documentIcon" />
      <div className="downloadCardBody">
        <div className="downloadCardInfo">
          {!isDownloadedOnce && (
            <h5
              className="cardTitle"
              style={{ color: `${getColorByStatus(status)}` }}
            >
              {showTextByStatus(status)}
            </h5>
          )}
          <h6 className="cardSubtitle">{fileName}</h6>
          {status === "completed" && (
            <span className="cardExpiration">Expires: {expDate}</span>
          )}
          {status === "inProgress" && (
            <img src={progressBar} alt="progressBar" className="m-0" />
          )}
        </div>
        <div className="downloadButtonContainer">
          <Tooltip
            content={
              status === "failed"
                ? "Retry download"
                : status === "completed"
                  ? "Download"
                  : "Cancel"
            }
            direction="bottom"
            delay={0}
          >
            <DynamicButton
              icon={getButtonByStatus()}
              onClick={() => {
                if (status === "failed") {
                  retryDownload();
                }
                if (status === "completed") {
                  if (!isDownloadedOnce) {
                    postDownloadedOnce(id);
                    setIsDownloadedOnce(true);
                    dispatch(setUnreadMessages(unreadMessages - 1));
                  }
                  downloadFileOnBrowser(url ?? "");
                }
                if (status === "inProgress") {
                  removeById(id, isDownloadedOnce);
                }
              }}
            />
          </Tooltip>
        </div>
      </div>
      {status !== "inProgress" && (
        <button
          type="button"
          className="downloadCloseButton"
          onClick={() => {
            removeById(id, isDownloadedOnce);
          }}
        >
          <Tooltip content="Remove" direction="bottom" delay={0}>
            <img src={closeButtonXS} alt="closeButton" className="w-100" />
          </Tooltip>
        </button>
      )}
    </div>
  );
};

const DownloadsContainer = ({
  downloadsArray,
  removeById,
}: DownloadsContainerProps) => {
  const { date, items } = downloadsArray;
  const todaysDate = new Date().toISOString().split("T")[0];
  const itemDate = new Date(`${date}T00:00:00Z`).toDateString();

  function formatDate(dateString: string) {
    const dateToFormat = new Date(`${dateString}T00:00:00Z`); // Ensures UTC time
    const day = dateToFormat.getUTCDate();
    const month = dateToFormat.getUTCMonth() + 1; // getUTCMonth is zero-based
    const year = dateToFormat.getUTCFullYear();
    return `${month}/${day}/${year}`;
  }

  if (items.length === 0) return null;

  return (
    <div className="downloadContainer">
      <div className="downloadContainerTopbar">
        <h3>{date === todaysDate ? "Today" : formatDate(date)}</h3>
      </div>
      <div className="cardsContainer">
        {items.map((item, index) => (
          <DownloadsCard key={index} {...item} removeById={removeById} />
        ))}
      </div>
    </div>
  );
};

export default function Downloads() {
  const dispatch = useAppDispatch();

  const [downloadsArray, setDownloadsArray] = useState<Array<any>>([]);
  const [sortDirection, setSortDirection] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string>("creationDate");
  const [loading, setLoading] = useState<boolean>(true);

  const multiDownload = useAppSelector(
    (state) => state.multipleAssetsDownload.downloadProcesses
  );
  const unreadMessages = useAppSelector(
    (state) => state.multipleAssetsDownload.unreadMessages
  );

  const userId = useSelector((state: RootState) => state.user?.id);
  const source = axios.CancelToken.source();

  const updateUnreadMessageCounter = (count: number) => {
    dispatch(setUnreadMessages(count));
  };

  const removeAll = async () => {
    setLoading(true);
    const response = await removeDownloads(userId, [], true);
    if (response) {
      setDownloadsArray([]);
      updateUnreadMessageCounter(0);
    } else {
      console.log("Failed to remove downloads");
    }
    setLoading(false);
  };

  const removeById = async (id: number, isDownloadedOnce: boolean) => {
    setLoading(true);
    const response = await removeDownloads(userId, [id], false);
    if (response) {
      setDownloadsArray(
        downloadsArray.map((downloads) => ({
          ...downloads,
          items: downloads.items.filter((item: any) => item.id !== id),
        }))
      );

      if (!isDownloadedOnce) {
        dispatch(setUnreadMessages(unreadMessages - 1));
      }
    } else {
      console.log("Failed to remove download");
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(setInPageNavShow(false));
  });

  useEffect(() => {
    if (userId && userId !== 0) {
      getDownloads(
        Number(userId),
        sortBy,
        sortDirection ? "desc" : "asc",
        loading,
        setLoading,
        setDownloadsArray,
        updateUnreadMessageCounter,
        source
      );
    }
  }, [userId, sortDirection, sortBy]);

  const areDownloadsEmpty =
    downloadsArray.length === 0 ||
    (downloadsArray.length === 1 && downloadsArray[0]?.items?.length === 0);

  return (
    <>
      <DownloadsNavBar
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        sortBy={sortBy}
        setSortBy={setSortBy}
        removeAll={removeAll}
        areDownloadsEmpty={areDownloadsEmpty}
      />
      {!loading && areDownloadsEmpty ? (
        <div className="nr-wrap">
          <div className="no-results">
            <h1>No downloads were found.</h1>
            <p>
              Don’t give up! Start downloding assets and they will appear here.
            </p>
          </div>
        </div>
      ) : !loading && downloadsArray.length > 0 ? (
        downloadsArray.map((downloads, index) => (
          <DownloadsContainer
            key={index}
            downloadsArray={downloads}
            removeById={removeById}
          />
        ))
      ) : (
        <div className="loaderContainer">
          <BigLoader />
        </div>
      )}
    </>
  );
}
