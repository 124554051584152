import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useNavigate, redirect, useMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { isExpired, decodeToken } from "react-jwt";
import { RootState } from "../../rootReducer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { IRouteProps } from "../../models/interfaces/common";
import { ReactDefaultProps } from "../../types/common";
import InPageNavBar from "../InPageNav";
import Breadcrumb from "../breadcrumbs/Breadcrumb";
import HelpModal from "../modals/HelpModal";
import KeyboardIcon from "../../assets/img/icon-keyboard.svg";
import SecureLS from "../../utils/SecureStorage";
import http from "../../services/api";
import { AuthResponse } from "../../services/mirage/routes/user";
import { saveToken, setAuthState } from "../../pages/auth/authSlice";
import { setUser } from "../../pages/auth/userSlice";
import { setLocale } from "../../pages/localeSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import { ILocale } from "../../models/interfaces/locale";
import { setOktaId } from "../../pages/auth/idForOktaSlice";
import DownloadStatusModal from "../modals/DownloadStatusModal";
import CancelDownloadConfirmationModal from "../modals/CancelDownloadModal";
import { toggleIsOpen } from "../../pages/shareSlice";
import ShareModal from "../modals/ShareModal";

export interface AuthCheckResponse {
  success: boolean;
  code: number;
  message?: string;
  mercureToken: string;
  id?: number | string;
}

const MainLayout = ({ children }: ReactDefaultProps) => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const toggleHelpModal = () => setShowHelpModal(!showHelpModal);
  const isAccountPage = useMatch("/account/:accountPage");

  const dispatch = useAppDispatch();

  const downloadStatusIsOpen = useAppSelector(
    (state) => state.multipleAssetsDownload.isOpen
  );
  const shareIsOpen = useSelector((state: RootState) => state.share.isOpen);

  const [showCancelDownloadModal, setShowCancelDownloadModal] = useState(false);

  const toggleCancelDownloadModal = () =>
    setShowCancelDownloadModal(!showCancelDownloadModal);
  const toggleShareModal = () => dispatch(toggleIsOpen());

  return (
    <>
      <div className="portal portal-page min-vh-100">
        <Header />
        <div className="portal-content container-fluid m-0 p-0">
          <div className="row g-0">
            <div className="left-col col-xl-3 col-lg-4 col-12">
              <Sidebar />
            </div>
            <div className="right-col col-xl-9 col-lg-8 col-12 scroll-container">
              <main className={`${isAccountPage ? "" : "main-bg"}`}>
                <InPageNavBar />
                <div className="portal-content-white">
                  {downloadStatusIsOpen && (
                    <DownloadStatusModal
                      toggleCancelDownloadProcesses={toggleCancelDownloadModal}
                    />
                  )}
                  <Breadcrumb location={window.location} />
                  {children}
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
      {/* floating button */}
      <a className="keyboard-button" href="#asdf" onClick={toggleHelpModal}>
        <img src={KeyboardIcon} alt="Keyboard" />
      </a>
      <HelpModal
        isOpen={showHelpModal}
        toggle={toggleHelpModal}
        modalClass="shareModal"
        centered
        backdrop="static"
        keyboard
      />
      <CancelDownloadConfirmationModal
        isOpen={showCancelDownloadModal}
        toggle={toggleCancelDownloadModal}
        modalClass="confirmationModal"
        centered
        backdrop="static"
        keyboard
      />
      <ShareModal
        isOpen={shareIsOpen}
        toggle={toggleShareModal}
        modalClass="shareModal"
        centered
        backdrop="static"
        keyboard
      />
    </>
  );
};

const MainLayoutRoute = ({ component: Comp, ...rest }: IRouteProps) => {
  const { i18n } = useTranslation("common");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const secureLS = SecureLS.getItem("user");
  const userInfo = secureLS ? JSON.parse(secureLS) : null;
  const { data, token, employee } = userInfo || {};
  const dispatch = useAppDispatch();
  const OktaId = useSelector((state: RootState) => state.okta);
  const user = useSelector((state: RootState) => state.user);
  const isSharedPage = useMatch("/shared/:token");
  const isHowToPage = useMatch("/howto/:howToPage");

  const navigate = useNavigate();

  const authCheck = () => {
    const path = "/api/authCheck";
    const axiosConfig = {
      headers: {
        Accept: "application/json",
        "Content-type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
        authType: employee === true ? "okta" : "jwt",
      },
    };

    if (token) {
      http
        .post<AuthResponse, AuthCheckResponse>(path, token, axiosConfig)
        .then((res) => {
          if (res) {
            dispatch(setOktaId(String(res.id)));

            const { success, code } = res;
            if (success === true && code === 200) {
              setIsAuthenticated(true);

              if (user?.email && !user?.mercureToken) {
                const userUpdated = {
                  ...user,
                  mercureToken: res.mercureToken,
                };
                if (userUpdated !== user) {
                  dispatch(setUser(userUpdated));
                }
              }
            }
          }
        })
        .catch((error) => {
          // console.log(error);
        })
        .finally(() => {
          // setLoading(false);
        });
    }
  };

  useEffect(() => {
    // const user = useSelector((state: RootState) => state.user);
    if (userInfo) {
      const userUpdated = {
        // id: info.sub,
        id: Number(OktaId.id),
        email: userInfo.data.email,
        username: userInfo.data.username,
        firstName: userInfo.data.firstName,
        lastName: userInfo.data.lastName,
        primaryRegion: userInfo.data.primaryRegion,
        preferredLanguage: userInfo.data.preferredLanguage,
        companyName: userInfo.data.companyName,
        companyType: userInfo.data.companyType,
        companyUrl: userInfo.data.companyUrl,
        purpose: userInfo.data.purpose,
        country: userInfo.data.country,
        favCollection: userInfo.data.favCollection,
        isConfirmed: userInfo.data.isConfirmed,
        token: userInfo.token,
      };
      dispatch(setUser(userUpdated));
    }
  }, [OktaId.id]);

  // navigate("/maintenance");

  if (i18n.language !== "en_US" && isHowToPage && !userInfo) {
    i18n.changeLanguage("en_US");
    dispatch(
      setLocale({
        region: "76",
        language: "en_US",
      })
    );
  }

  useEffect(() => {
    if (
      !isSharedPage &&
      !isHowToPage &&
      (!userInfo || isExpired(userInfo?.token))
    ) {
      navigate("/login");
    }
  }, [userInfo]);

  const locale: ILocale = {
    region: "76",
    language: "en_US",
  };

  const handleAuthCheck = () => {
    if (data && token) {
      dispatch(saveToken(token));
      dispatch(setUser(data));
      dispatch(setLocale(locale));
      dispatch(setAuthState(true));
    }
  };

  useEffect(() => {
    authCheck();
  });

  useEffect(() => {
    if (!user?.mercureToken) {
      authCheck();
    }
  });

  return (
    <MainLayout>
      <Comp />
    </MainLayout>
  );
};

export default MainLayoutRoute;
