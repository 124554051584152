/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { RootState } from "../rootReducer";
import AssetDownloadModal from "./modals/AssetDownloadModal";
import ShareModal from "./modals/ShareModal";
import { useAppDispatch, useAppSelector } from "../store";
import { sortOrder, sortDirection } from "../pages/sortSlice";
import ManageFavoriteModal from "./modals/ManageFavoriteModal";
import favoriteSlice, {
  removeSelectonsFromFavorite,
} from "../pages/favoriteSlice";
import { CHANGE_USER_INFO } from "../services/graphql/migrations";
import { FavoriteType, FavoriteAsset } from "../types/common";
import useMultiSelectionSlice, {
  setUseMultiSelectionState,
  selectAllPossibleMultiSelectionState,
  clearAllPossibleMultiSelectionState,
  togglePossibleSelectionsListener,
} from "../hooks/useMultiSelectionSlice";
import asset from "../services/mirage/routes/asset";
import { toggleChoice, toggleIsOpen } from "../pages/shareSlice";
import {
  enableInPageNavRightClearAll,
  enableInPageNavRightShareAll,
  enableInPageNavRightDownloadAll,
} from "./inPageNavSlice";
import DeleteAllFavoriteConfirmationModal from "./modals/DeleteAllFavoriteConfirmationModal";
import { toggleDownloadChoice } from "../pages/downloadSlice";
import {
  assetId,
  assetType,
  assetUrl,
  setAssetInfo,
} from "../pages/assetCardSlice";
import { AssetInfo, FilterOptionsInfo } from "../models/interfaces/common";
import { showFilterDropdown } from "../pages/filterSlice";
import InPageFilter from "./inPageNav/InPageFilter";
import { addExcludedAssets } from "../pages/assetExclusionSlice";
import { removeAssetsFromFavorite } from "../utils/catalog";
import { FavoriteInfoInterface, setFavorite } from "../pages/newFavoriteSlice";

const InPageNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isFavoritesPage = useMatch("/favorites");
  // const [showShare, setShowShare] = useState(false);
  const [showSort, setShowSort] = useState(true);
  const [showManageFavorite, setShowManageFavorite] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isAddToFavoriteModalTrigger, setIsAddToFavoriteModalTrigger] =
    useState(false);
  const inPageNavConfig = useSelector((state: RootState) => state.inPageNav);
  const UseSelectionsSlice = useSelector(
    (state: RootState) => state.useSelections
  );
  const sortingOrder = useSelector((state: RootState) => state.sort.order);
  const showFilter = useAppSelector((state) => state.filter.showFilter);
  const dispatch = useAppDispatch();
  const UserInfoSlice = useSelector((state: RootState) => state.user);
  const userId = useSelector((state: RootState) => state.user?.id);
  const FavoriteSlice = useSelector((state: RootState) => state.favorite);
  const Favorite = useAppSelector(
    (state: RootState) => state.newFavorite.favorite
  );
  const currentFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.currentFolderId
  );
  const defaultFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.defaultFolderId
  );
  const SortSlice = useSelector((state: RootState) => state.sort);
  const areFiltersSelected = useAppSelector(
    (state) => state.filterOptions.areFiltersSelected
  );

  const excludeAssets = (assetIds: string[]) =>
    dispatch(addExcludedAssets(assetIds));

  const toggleShareModal = () => dispatch(toggleIsOpen());
  const toggleManageFavoriteModal = () =>
    setShowManageFavorite(!showManageFavorite);
  const toggleDownloadModal = () => setShowDownloadModal(!showDownloadModal);
  const { t, i18n } = useTranslation("common");

  // begginning of collecting all assets for multiselect
  const [selectedFavsArr, setselectedFavsArr] = useState<any[]>([]);
  const [folderNameForMultiselect, setfolderNameForMultiselect] =
    useState<string>("");

  // inital state for delete all confirmation modal
  const [
    showDeleteAllFavoriteConfirmationModal,
    setDeleteAllFavoriteConfirmationModal,
  ] = useState(false);
  // delete all modal controller
  const toggleDeleteAllFavoriteConfirmationModal = () =>
    setDeleteAllFavoriteConfirmationModal(
      !showDeleteAllFavoriteConfirmationModal
    );

  // update folder change right away
  // when FavoriteSlice.currentFavFolder state updated
  useEffect(() => {
    setfolderNameForMultiselect(FavoriteSlice.currentFavFolder);
  }, [FavoriteSlice.currentFavFolder]);

  // add all assets in the current folder
  // to UseSelectionsSlice.allSections
  useEffect(() => {
    // look above for current foldername
    const tempArr: string[] = [];
    const foldersFromFavSlice: FavoriteType[] = FavoriteSlice.allFavs;
    // loop, select folder, assign the value to temporary arr
    // assign to folder based on index
    // push value to the matched folder
    foldersFromFavSlice.forEach((folder) => {
      if (folder.name === folderNameForMultiselect) {
        folder.favs.forEach((object) => {
          const id: string = String(object.id) as string;
          tempArr.push(id);
        });
      }
    });
    // assign array to state then send it to usemultiselection
    setselectedFavsArr([...tempArr]);
    dispatch(setUseMultiSelectionState([...tempArr]));
  }, [folderNameForMultiselect]);

  // end of collecting all assets for multiselect

  // following function is for deselecting all asset
  const clearMultiSelections = () => {
    dispatch(setUseMultiSelectionState([]));
  };

  useEffect(() => {
    if (location.pathname === "/viewall") setShowSort(false);
    else setShowSort(true);
  }, [location.pathname]);

  // update user favorite folder pimcore
  const [updateUserInfo] = useMutation(CHANGE_USER_INFO, {
    onCompleted({ updateUser: { success } }) {
      if (success) {
        if (isFavoritesPage) {
          const folderName = new URLSearchParams(location.search).get(
            "favoriteFolder"
          );
          navigate({
            pathname: "/favorites",
            search: `?favoriteFolder=${
              folderName ? encodeURIComponent(folderName) : ""
            }`,
          });
        }
        // dispatch(removeSelectonsFromFavorite(UseSelectionsSlice.allSections));
      }
    },
  });

  // const addAssetToAssetCardSlice = useCallback(
  //   (s: string[], y: AssetInfo[]) => {
  //     if (s.length === 1) {
  //       const temp = s[0];
  //       const temp2 = y.filter((a) => a.id === temp);
  //       dispatch(
  //         setAssetInfo({
  //           filename: temp2[0].filename,
  //           fullpath: temp2[0].fullpath,
  //           fullpathExtandFont: temp2[0].fullpathExtandFont,
  //           fullpathExtandLandscapeImage: temp2[0].fullpathExtandLandscapeImage,
  //           fullpathExtandPortraitImage: temp2[0].fullpathExtandPortraitImage,
  //           fullpathThumbnailFont: temp2[0].fullpathThumbnailFont,
  //           fullpathThumbnailImageDownload:
  //             temp2[0].fullpathThumbnailImageDownload,
  //           fullpathThumbnailLandscapeImage:
  //             temp2[0].fullpathThumbnailLandscapeImage,
  //           fullpathThumbnailPortraitImage:
  //             temp2[0].fullpathThumbnailPortraitImage,
  //           youTubeSDThumbnailPath: temp2[0].youTubeSDThumbnailPath,
  //           youTubeSDPath: temp2[0].youTubeSDPath,
  //           customMetadataId: temp2[0].customMetadataId,
  //           creationDate: temp2[0].creationDate,
  //           fileSize: temp2[0].fileSize,
  //         })
  //       );
  //     }
  //   },
  //   []
  // );

  const DownloadButton = () => (
    <a
      className={`${
        inPageNavConfig.enableDownload ? "active" : "inactive disabled"
      }`}
      href="#asdf"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        toggleDownloadModal();
        dispatch(toggleDownloadChoice(0));
        // addAssetToAssetCardSlice(selections, assetsLists);
        // if (UseSelectionsSlice.allSections.length === 1) {
        //   console.log(
        //     "UseSelectionsSlice.allSections data: ",
        //     UseSelectionsSlice.allSections
        //   );
        //   dispatch(assetId(UseSelectionsSlice.allSections[0].id));
        //   dispatch(assetUrl(UseSelectionsSlice.allSections[0].url));
        //   dispatch(assetType(UseSelectionsSlice.allSections[0].type));
        // }
      }}
    >
      {t("Download")}
    </a>
  );

  const ShareButton = () => (
    <a
      className={`${
        inPageNavConfig.enableShare ? "active" : "inactive disabled"
      }`}
      href="#asdf"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(toggleChoice(0));
        toggleShareModal();
      }}
    >
      {t("Share")}
    </a>
  );

  const FavoriteButton = () => (
    <a
      className={`${
        inPageNavConfig.enableFavorite ? "active" : "inactive disabled"
      }`}
      href="#asdf"
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        event.stopPropagation();
        toggleManageFavoriteModal();
        setIsAddToFavoriteModalTrigger(true);
      }}
    >
      {t("Add To Favorites")}
    </a>
  );

  const removeSelectionsFromFavorite = () => {
    if (userId && defaultFavoriteFolderId) {
      removeAssetsFromFavorite(
        userId,
        Favorite[currentFavoriteFolderId || defaultFavoriteFolderId].assets
          .filter((assetItem) =>
            UseSelectionsSlice.allSections.includes(assetItem.id)
          )
          .map((assetItem) => assetItem.id),
        defaultFavoriteFolderId,
        currentFavoriteFolderId,
        (value: FavoriteInfoInterface) => dispatch(setFavorite(value))
      );
    }
  };

  const RemoveFromFavoriteButton = () => (
    <a
      href="#asdf"
      className={`${
        inPageNavConfig.enableRemoveFromFavorite
          ? "active"
          : "inactive disabled"
      }`}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        event.stopPropagation();
        removeSelectionsFromFavorite();
        setIsAddToFavoriteModalTrigger(false);
      }}
    >
      {t("Remove From Favorites")}
    </a>
  );

  useEffect(() => {
    const container = document.querySelector(".scroll-container");
    const whiteContainer = document.querySelector(".portal-content-white");
    const filtersContainer = document.querySelector(".filters-container");
    const desiredHeight = filtersContainer
      ? (filtersContainer as HTMLElement).offsetHeight + 69 + 42
      : 0;
    if (showFilter) {
      (container as HTMLElement).style.overflowY = "hidden";
      (whiteContainer as HTMLElement).style.height =
        `calc(100vh - ${desiredHeight}px)`;
    } else {
      (container as HTMLElement).style.overflowY = "auto";
      (whiteContainer as HTMLElement).style.height = "calc(100vh - 111px)";
    }
  }, [showFilter]);

  const FiltersButton = () => (
    <div className=" ">
      <button
        className={`btn btn-secondary btn-filter dropdown-toggle filter-dropdown-btn ms-2 ${
          showFilter || areFiltersSelected ? "active" : ""
        }`}
        type="button"
        aria-expanded={showFilter}
        aria-controls="filter-dropdown"
        onClick={() => {
          dispatch(showFilterDropdown());
        }}
      >
        {t("Filters")}
      </button>
    </div>
  );

  const SpacerLineView = () => <div className="spacer" />;

  const NameView = () => (
    <>
      <div className="dropdown">
        <button
          className="btn btn-secondary btn-name dropdown-toggle"
          type="button"
          id="inPageNavNameFilter"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t(sortingOrder)}
        </button>
        {/* Name Dropdown Box */}
        <ul className="dropdown-menu" aria-labelledby="inPageNavNameFilter">
          <li>
            <a
              className="dropdown-item"
              href="#asdf"
              onClick={() => dispatch(sortOrder("Name"))}
            >
              {t("Name")}
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#asdf"
              onClick={() => dispatch(sortOrder("File Size"))}
            >
              {t("File Size")}
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#asdf"
              onClick={() => dispatch(sortOrder("Date Uploaded"))}
            >
              {t("Date Uploaded")}
            </a>
          </li>
        </ul>
      </div>
      <div className="ms-2">
        <button
          className={`btn btn-secondary dropdown-toggle ${
            SortSlice.direction ? "btn-order-desc" : "btn-order"
          }`}
          type="button"
          id="dropdownMenuButton1"
          // data-bs-toggle="dropdown"
          aria-expanded="false"
          aria-label="Sorter"
          onClick={() => dispatch(sortDirection())}
        />
      </div>
    </>
  );

  const RightDownloadAll = () => (
    <a
      href="#asd"
      className={`${
        inPageNavConfig.enableRightDownloadAll ? "active" : "inactive disabled"
      }`}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(selectAllPossibleMultiSelectionState());
        toggleDownloadModal();
      }}
    >
      {t("Download All")}
    </a>
  );

  const LeftDownloadAll = () => (
    <a
      href="#asd"
      className={`${
        inPageNavConfig.enableLeftDownloadAll ? "active" : "inactive disabled"
      }`}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(selectAllPossibleMultiSelectionState());
        toggleDownloadModal();
      }}
    >
      {t("Download All")}
    </a>
  );

  const RightShareAll = () => (
    <a
      href="#ass"
      className={`${
        inPageNavConfig.enableRightShareAll ? "active" : "inactive disabled"
      }`}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(selectAllPossibleMultiSelectionState());
        toggleShareModal();
      }}
    >
      {t("Share All")}
    </a>
  );

  const RightClearAll = () => (
    <a
      href="#asd"
      className={`${
        inPageNavConfig.enableRightClearAll ? "active" : "inactive disabled"
      }`}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        // clearMultiSelections();
        toggleDeleteAllFavoriteConfirmationModal();
        // dispatch(clearAllPossibleMultiSelectionState());
        // clearAllFavorites();
      }}
      // onClick={toggleDeleteAllFavoriteConfirmationModal}
    >
      {t("Remove All")}
    </a>
  );

  const InPageNavBarContent = () => (
    <>
      <div className="container-fluid filter">
        <div className="row">
          <div className="col-12 d-flex flex-md-row flex-column justify-content-between align-items-center">
            <div className="mb-2 mb-md-0">
              {inPageNavConfig.showDownload && <DownloadButton />}
              {inPageNavConfig.showLeftDownloadAll && <LeftDownloadAll />}
              {inPageNavConfig.showShare && <ShareButton />}
              {inPageNavConfig.showFavorite && <FavoriteButton />}
              {inPageNavConfig.showRemoveFromFavorite && (
                <RemoveFromFavoriteButton />
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              {inPageNavConfig.showFilter && <FiltersButton />}
              {/* {inPageNavConfig.showSpacerLine && <SpacerLineView />} */}
              {inPageNavConfig.showSpacerLine ? null : (
                <div className="spacer" />
              )}
              {inPageNavConfig.showName && showSort && <NameView />}
              <div className="mb-2 mb-md-0">
                {inPageNavConfig.showRightDownloadAll && <RightDownloadAll />}
                {inPageNavConfig.showRightShareAll && <RightShareAll />}
                {inPageNavConfig.showRightClearAll && <RightClearAll />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <InPageFilter />

      <AssetDownloadModal
        isOpen={showDownloadModal}
        toggle={toggleDownloadModal}
        centered
        selectedList={UseSelectionsSlice.allSections}
        modalClass={
          UseSelectionsSlice.allSections.length > 1
            ? "downloadMultipleModal"
            : "downloadModal"
        }
        backdrop="static"
        keyboard
        isMultipleDownload={UseSelectionsSlice.allSections.length > 1}
        useMultiSelectionData
      />
      <ManageFavoriteModal
        isOpen={showManageFavorite}
        toggle={toggleManageFavoriteModal}
        modalClass="favoritesModal"
        centered
        backdrop="static"
        keyboard
        isAddToFavorite={isAddToFavoriteModalTrigger}
      />
      <DeleteAllFavoriteConfirmationModal
        isOpen={showDeleteAllFavoriteConfirmationModal}
        toggle={toggleDeleteAllFavoriteConfirmationModal}
        modalClass="confirmationModal"
        centered
        backdrop="static"
        keyboard
      />
    </>
  );

  if (inPageNavConfig.showNav) {
    return <InPageNavBarContent />;
  }
  return null;
};

export default InPageNavBar;
