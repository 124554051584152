import * as Yup from "yup";

export default Yup.object({
  password: Yup.string()
    .notOneOf([null])
    .min(8, "Password must contain least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_])[A-Za-z\d!@#$%^&*()_]{8,20}$/,
      "Password must contain at least one uppercase and lowercase letters, one number and one special character."
    )
    .required("Please enter your password"),
  confirmPassword: Yup.string()
    .required("Please re-enter your password")
    .test(
      "password-match",
      "Password must match",
      function matchingPasswords(value) {
        return this.parent.password === value;
      }
    ),
});
