import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import HeartIcon from "../../assets/img/heartIcon.png";
import FolderIcon from "../../assets/img/folder-add.svg";

export default function HowToFavorites() {
  const { t, i18n } = useTranslation("common");

  return (
    <main className="how-to-container">
      <h4 className="step-option">{t("Favorites-Option1")}</h4>
      <div className="steps-container">
        <div className="steps-column">
          <div className="step">
            <h4 className="step-title">{t("Step 1")}</h4>
            <p className="step-description">
              {t("Favorites-Step1")}
              <img className="heart-icon" src={HeartIcon} alt="Heart icon" />
              {t("Favorites-Step1.2")}
              <img className="how-to-icon" src={FolderIcon} alt="Folder icon" />
              {t("Favorites-Step1.3")}
            </p>
          </div>
          <div className="step">
            <h4 className="step-title">{t("Step 2")}</h4>
            <p className="step-description">{t("Favorites-Step2")}</p>
          </div>
        </div>
        <div className="steps-column">
          <div className="step">
            <h4 className="step-title">{t("Step 3")}</h4>
            <p className="step-description">
              {t("Favorites-Step3")}
              <img className="heart-icon" src={HeartIcon} alt="Heart icon" />
              {t("Favorites-Step3.2")}
            </p>
          </div>
        </div>
      </div>
      <h4 className="step-option">{t("Favorites-Option2")}</h4>
      <div className="steps-container">
        <div className="step">
          <h4 className="step-title">{t("Step 1")}</h4>
          <p className="step-description">{t("Favorites-Step4")}</p>
        </div>
        <div className="step">
          <h4 className="step-title">{t("Step 2")}</h4>
          <p className="step-description">{t("Favorites-Step5")}</p>
        </div>
      </div>
      <div className="how-to-video">
        <h4 className="video-title">{t("InstructionalVideo")}</h4>
        <ReactPlayer
          height="480px"
          width="100%"
          url="https://youtu.be/GUIobqb0WgI"
        />
      </div>
    </main>
  );
}
