import React, { useEffect } from "react";
// Hooks
import { useFormikContext, FormikProps, FormikValues } from "formik";
// Types
import { NavigationProps } from "../../types/registration";

/** Gotcha: Will need to be a child of a Formik component to have access to Formik context */
const RegistrationNavigation = ({
  maxSteps,
  currentIndex,
  onClickNext,
  onClickBack,
  handleSubmit,
  allowProceed,
}: NavigationProps) => {
  const isFirstStep = currentIndex === 0;
  const isLastStep = currentIndex === maxSteps - 1;

  // Grab what we need from formik without prop-drilling
  const { validateForm, isSubmitting, isValid, values } = useFormikContext();

  // Will run form.validateForm() when the currentIndex prop is changed
  useEffect(() => {
    validateForm();
  }, [currentIndex, validateForm]);

  let disableProceed = !isValid;
  if (allowProceed === false) disableProceed = true;

  // Popup that reminds the users to confirm the email before taking them to login page

  return (
    <div className="progress-buttons">
      <br />
      {!isFirstStep && (
        <button
          className="btn btn-lg btn-reversed btn-blue"
          type="button"
          onClick={onClickBack}
        >
          Go Back
        </button>
      )}
      {isLastStep ? (
        <button
          className="btn btn-lg btn-blue"
          type="button"
          disabled={disableProceed}
          onClick={() => handleSubmit(values as FormikValues)}
        >
          {isSubmitting ? "Submitting" : "Proceed"}
        </button>
      ) : (
        <button
          className="btn btn-lg btn-blue"
          type="button"
          disabled={disableProceed}
          onClick={onClickNext}
        >
          Proceed
        </button>
      )}
    </div>
  );
};

export default RegistrationNavigation;
