import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Hash } from "../models/interfaces/common";

interface CustomMetadataInterface {
  hash: Hash;
}

const initialState: CustomMetadataInterface = { hash: {} };

const customMetaData = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    addMetadata(state, { payload }: PayloadAction<Hash>) {
      if (payload) {
        state.hash = payload;
      }
    },
  },
});

export const { addMetadata } = customMetaData.actions;

export default customMetaData.reducer;
