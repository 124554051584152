import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../models/interfaces/user";
import SecureLS from "../../utils/SecureStorage";

const user = createSlice({
  name: "user",
  initialState: null as IUser | null,
  reducers: {
    setUser(state, { payload }: PayloadAction<IUser>) {
      // eslint-disable-next-line no-param-reassign
      state = payload;
      const secureLS = SecureLS.getItem("user");
      const currentUser = secureLS ? JSON.parse(secureLS) : null;
      if (currentUser) {
        currentUser.data = payload;
        SecureLS.setItem("user", JSON.stringify(currentUser));
      }
      return state;
    },
  },
});

export const { setUser } = user.actions;

export default user.reducer;
