import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssetInfoSliceInterface } from "../models/interfaces/common";

interface AssetState {
  id: string | null;
  url?: string | undefined;
  type?: string | undefined;
  isFavorite?: boolean;
  filename?: string | null;
  fullpath?: string | null;
  fullpathExtandFont?: string | null;
  fullpathExtandLandscapeImage?: string | null;
  fullpathExtandPortraitImage?: string | null;
  fullpathThumbnailFont?: string | null;
  fullpathThumbnailImageDownload?: string | null;
  fullpathThumbnailLandscapeImage?: string | null;
  fullpathThumbnailPortraitImage?: string | null;
  youTubeSDThumbnailPath?: string | null;
  youTubeSDPath?: string | null;
  customMetadataId?: string | null;
  creationDate?: string | null;
  fileSize?: number;
}

const initialState: AssetState = {
  id: null,
  url: undefined,
  type: undefined,
  isFavorite: false,
  filename: null,
  fullpath: null,
  fullpathExtandFont: null,
  fullpathExtandLandscapeImage: null,
  fullpathExtandPortraitImage: null,
  fullpathThumbnailFont: null,
  fullpathThumbnailImageDownload: null,
  fullpathThumbnailLandscapeImage: null,
  fullpathThumbnailPortraitImage: null,
  youTubeSDThumbnailPath: null,
  youTubeSDPath: null,
  customMetadataId: null,
  creationDate: null,
  fileSize: 0,
};

const assetCardNav = createSlice({
  name: "assetCardNav",
  initialState,
  reducers: {
    assetIdNav(state, { payload }: PayloadAction<string | null>) {
      state.id = payload;
    },
    assetUrlNav(state, { payload }: PayloadAction<string>) {
      if (payload) {
        state.url = payload;
      }
    },
    assetTypeNav(state, { payload }: PayloadAction<string>) {
      if (payload) {
        state.type = payload;
      }
    },
    setSelectedAssetCardNav(state, { payload }: PayloadAction<AssetState>) {
      if (payload) {
        return payload;
      }
      return state;
    },
    toggleIsFavoriteNav(state) {
      state.isFavorite = !state.isFavorite;
    },
    setIsFavoriteNav(state, { payload }: PayloadAction<boolean>) {
      state.isFavorite = payload;
    },
    setAssetInfoNav(
      state,
      { payload }: PayloadAction<AssetInfoSliceInterface>
    ) {
      if (payload) {
        state.filename = payload.filename;
        state.fullpath = payload.fullpath;
        state.fullpathExtandFont = payload.fullpathExtandFont;
        state.fullpathExtandLandscapeImage =
          payload.fullpathExtandLandscapeImage;
        state.fullpathExtandPortraitImage = payload.fullpathExtandPortraitImage;
        state.fullpathThumbnailFont = payload.fullpathThumbnailFont;
        state.fullpathThumbnailImageDownload =
          payload.fullpathThumbnailImageDownload;
        state.fullpathThumbnailLandscapeImage =
          payload.fullpathThumbnailLandscapeImage;
        state.fullpathThumbnailPortraitImage =
          payload.fullpathThumbnailPortraitImage;
        state.youTubeSDThumbnailPath = payload.youTubeSDThumbnailPath;
        state.youTubeSDPath = payload.youTubeSDPath;
        state.customMetadataId = payload.customMetadataId;
        state.creationDate = payload.creationDate;
        state.fileSize = payload.fileSize;
      }
    },
  },
});

export const {
  assetIdNav,
  assetUrlNav,
  assetTypeNav,
  setSelectedAssetCardNav,
  toggleIsFavoriteNav,
  setIsFavoriteNav,
  setAssetInfoNav,
} = assetCardNav.actions;

export default assetCardNav.reducer;
