import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SortState {
  order: string;
  direction: boolean;
}

const initialState: SortState = {
  order: "Name",
  direction: true,
};

const sort = createSlice({
  name: "sort",
  initialState,
  reducers: {
    sortOrder(state, { payload }: PayloadAction<string>) {
      if (payload) {
        state.order = payload;
      }
    },
    sortDirection(state) {
      state.direction = !state.direction;
    },
    setSortDirection(state, { payload }: PayloadAction<boolean>) {
      state.direction = payload;
    },
  },
});

export const { sortOrder, sortDirection, setSortDirection } = sort.actions;

export default sort.reducer;
