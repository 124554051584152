import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import Select from "react-select";
// Types
import type { ReactI18NextChild } from "react-i18next";
import { RegistrationFormProps } from "../../../types/registration";
// Util
import { getInputProps } from "../../../utils/forms";
import {
  COMPANYTYPE_LIST,
  REGION_LANGUAGES,
} from "../../../services/graphql/pimcoreDam";
import {
  CountriesResponse,
  CountryListInterface,
} from "../../../models/interfaces/countries";
import http from "../../../services/api";

export interface GraphqlApi {
  node: companyType | region;
}

export interface companyType {
  id: number;
  name: string;
}

export interface region {
  id: number;
  name: string;
  languages: string;
}

// retrieve a list of Company type
const companyTypeList = () => {
  const { loading, error, data } = useQuery(COMPANYTYPE_LIST, {
    fetchPolicy: "cache-first",
    // pollInterval: 500,
  });
  if (loading) return [];
  if (error) return [];
  // got to edges
  const valueArr: GraphqlApi[] = data.getCompanyTypeListing.edges;

  return valueArr.map((option) => ({
    value: option.node.id,
    label: option.node.name,
  }));
};

const CompanyInformationRegistration = ({ form }: RegistrationFormProps) => {
  const [countryListState, setCountryListState] = useState<
    CountryListInterface[]
  >([]);

  const getCountryList = () => {
    const path = "/api/countries";
    http.get<CountriesResponse>(path).then((res) => {
      const tempCountryList: CountryListInterface[] = [];
      const dataRes: any = res.data;
      Object.keys(res.data).forEach((key) => {
        const value = key;
        const label = dataRes[key];
        tempCountryList.push({ label, value });
      });
      setCountryListState([...tempCountryList]);
    });
  };

  useEffect(() => {
    getCountryList();
  }, []);

  const [selectedCompanyType, setSelectedCompanyType] =
    useState<any>("country");
  const handleCompanyTypeChange = (selectedOption: any) => {
    setSelectedCompanyType(selectedOption.label);
  };
  const [selectedRegion, setSelectedRegion] = useState<any>("country");
  const handleCountryChange = (selectedOption: any) => {
    setSelectedRegion(selectedOption.label);
  };

  return (
    <>
      <div>
        <h1>Company Information</h1>
      </div>
      <div className="row form-row mb-3">
        <div className="col-md-6 col-12">
          <div className="mb-4">
            <label htmlFor="companyName" className="form-label">
              Company Name
            </label>
            <input type="text" {...getInputProps("companyName", form)} />
            {form.touched.companyName && form.errors.companyName ? (
              <div className="text-danger mt-1 fs-13 fw-bold">
                {form.errors.companyName as ReactI18NextChild}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="mb-4">
            <label htmlFor="companyUrl" className="form-label">
              Company URL
            </label>
            <input type="text" {...getInputProps("companyUrl", form)} />
            {form.touched.companyUrl && form.errors.companyUrl ? (
              <div className="text-danger mt-1 fs-13 fw-bold">
                {form.errors.companyUrl as ReactI18NextChild}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="mb-4">
            <label htmlFor="companyType" className="form-label">
              Company Type
            </label>
            <Select
              {...getInputProps("companyType", form, "form-control", true)}
              classNamePrefix="regular-looking-dropdown"
              className={`w-100 ${
                form.touched.companyType && form.errors.companyType
                  ? "is-invalid"
                  : ""
              }`}
              name="companyType"
              options={companyTypeList()}
              isMulti={false}
            />
            {form.touched.companyType && form.errors.companyType ? (
              <div className="text-danger mt-1 fs-13 fw-bold">
                {form.errors.companyType as ReactI18NextChild}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="mb-4">
            <label htmlFor="country" className="form-label">
              Country
            </label>
            <Select
              {...getInputProps("country", form, "form-control", true)}
              classNamePrefix="regular-looking-dropdown"
              className={`w-100 ${
                form.touched.country && form.errors.country ? "is-invalid" : ""
              }`}
              name="country"
              // options={CountryList()}
              options={countryListState}
              isMulti={false}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="mb-4">
            <label htmlFor="purpose" className="form-label">
              Purpose of Use
            </label>
            <input type="text" {...getInputProps("purpose", form)} />
            {form.touched.purpose && form.errors.purpose ? (
              <div className="text-danger mt-1 fs-13 fw-bold">
                {form.errors.purpose as ReactI18NextChild}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyInformationRegistration;
