/* eslint-disable no-shadow */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AssetDetailInterface {
  isOpen: boolean;
  choice: number;
}

enum Choice {
  fromCard = 1,
  fromNav = 0,
}

const initialState: AssetDetailInterface = {
  isOpen: false,
  choice: Choice.fromNav,
};

const download = createSlice({
  name: "assetDetail",
  initialState,
  reducers: {
    toggleDownloadIsOpen(state) {
      state.isOpen = !state.isOpen;
    },
    toggleDownloadChoice(state, { payload }: PayloadAction<number>) {
      state.choice = payload;
    },
  },
});

export const { toggleDownloadIsOpen, toggleDownloadChoice } = download.actions;

export default download.reducer;
