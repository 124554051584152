import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAssetCard } from "../models/interfaces/common";
import { AssetResponse } from "../services/mirage/routes/asset";
import http from "../services/api";
import { sidebarList } from "../utils/sidebar";

export type AssetState = {
  status: "loading" | "idle";
  error: string | null;
  list: AssetResponse[];
};

const initialState = {
  list: sidebarList.map((item) => {
    const assetResponse = {
      title: item.text,
      count: item.count,
      src: item.src,
      size: 0,
      path: item.path,
    };
    return assetResponse;
  }),
  error: null,
  status: "idle",
} as AssetState;

export const fetchAssets = createAsyncThunk<AssetState>(
  "assets/fetchAssets",
  async () => {
    const response = await http.get("/api/assets");
    const assetStatus = response as unknown as AssetState;
    return assetStatus;
  }
);

const assetSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    loadAssets: (state) => {
      const path = `/api/asset`;
      http.get(path).then((res) => {
        const assets = res as unknown as AssetResponse[];

        assets.forEach((x: AssetResponse) => {
          state.list.forEach((y: IAssetCard) => {
            if (x.type === y.title) {
              // eslint-disable-next-line no-plusplus
              y.count++;
              y.size += x.size;
              y.size.toFixed(2);
            }
          });
        });
      });
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssets.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });

    builder.addCase(fetchAssets.fulfilled, (state, { payload }) => {
      state.list.push(...payload.list);
      state.status = "idle";
    });
  },
});

export const { loadAssets } = assetSlice.actions;

export default assetSlice.reducer;
