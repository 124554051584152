import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { randomBytes } from "crypto";
import { useSelector } from "react-redux";
import { number } from "yup";
import { useAppSelector, useAppDispatch } from "../../store";
import FolderAddIcon from "../../assets/img/folder-add.svg";
import TrashIcon from "../../assets/img/trash.svg";
import { CHANGE_USER_INFO } from "../../services/graphql/migrations";
import { FAV_COLLECTION_LIST } from "../../services/graphql/pimcoreDam";
import { RootState } from "../../rootReducer";
import { FavoriteFolder } from "./FavoriteFolderModal";
import { setFavoriteState } from "../../pages/favoriteSlice";
import { FavoriteAsset, FavoriteType, ModalProps } from "../../types/common";
import useFavorites from "../../hooks/useFavorites";
import { addAssetsToFavorite, createFavoriteFolder } from "../../utils/catalog";
import {
  FavoriteInfoInterface,
  setFavorite,
} from "../../pages/newFavoriteSlice";

type ManageFavoriteModalType = ModalProps & {
  isAddToFavorite: boolean;
};

const ManageFavoriteModal = ({
  isOpen,
  toggle,
  modalClass,
  keyboard = true,
  backdrop = "static",
  centered = true,
  isAddToFavorite = false,
}: ManageFavoriteModalType) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation("common");
  const userId = useSelector((state: RootState) => state.user?.id);
  const Favorite = useAppSelector((state) => state.newFavorite.favorite);
  const currentFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.currentFolderId
  );
  const defaultFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.defaultFolderId
  );
  const UseSelectionsSlice = useSelector(
    (state: RootState) => state.useSelections
  );
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [checkedFavoriteFolder, setCheckedFavoriteFolder] = useState<
    string | null
  >();
  const [newFavFolder, setNewFavFolder] = useState<string | null>();
  const [createNewFavFolder, setCreateNewFavFolder] = useState<boolean>(false);
  const [isNewFavFolderSubmitted, setIsNewFavFolderSubmitted] =
    useState<boolean>(false);

  const closeModal = () => {
    if (isOpen) toggle();
  };

  const handleNewFavFolderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event?.target?.value;
    if (value) setNewFavFolder(value.trim());
  };

  // beginning of create user favorite folder
  const handleUpdate = () => {
    if (userId && newFavFolder) {
      setIsNewFavFolderSubmitted(true);
      createFavoriteFolder(
        userId,
        newFavFolder,
        defaultFavoriteFolderId,
        currentFavoriteFolderId,
        (value: FavoriteInfoInterface) => dispatch(setFavorite(value))
      );
    }
  };

  useEffect(() => {
    if (isNewFavFolderSubmitted) setIsNewFavFolderSubmitted(false);
  }, [newFavFolder]);

  const handleAddToFavoriteFolder = () => {
    if (checkedFavoriteFolder && userId) {
      const assetsNotInFolder: string[] = UseSelectionsSlice.allSections
        .filter(
          (id) =>
            !Favorite[checkedFavoriteFolder].assets
              .map((value) => value.id)
              .includes(id)
        )
        .map((id) => id);
      if (assetsNotInFolder.length > 0) {
        addAssetsToFavorite(
          userId as number,
          assetsNotInFolder,
          defaultFavoriteFolderId,
          checkedFavoriteFolder,
          (value: FavoriteInfoInterface) => dispatch(setFavorite(value)),
          () => closeModal()
        );
      } else closeModal();
    } else closeModal();

    setCheckedFavoriteFolder(null);
    setSubmitting(false);
  };

  return (
    <Modal
      show={isOpen}
      onHide={toggle}
      className={`${modalClass}`}
      backdrop={backdrop}
      centered={centered}
      keyboard={keyboard}
    >
      <Modal.Header closeButton>
        <h4 className="modal-title">{t("Save to Favorites")}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive mb-10">
          <table className="table body-header manageFavorite">
            <tbody>
              <tr>
                <td>
                  <div className="w-100">
                    <a
                      className="d-flex justify-content-start align-items-center "
                      href="#asdfaasd"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setCreateNewFavFolder(!createNewFavFolder);
                      }}
                    >
                      <img
                        className="me-2"
                        src={FolderAddIcon}
                        alt="Create Folder"
                      />
                      <h6 className="mb-0">{t("Create A Folder")}</h6>
                    </a>
                  </div>
                  {createNewFavFolder && (
                    <div className="input-group pt-3">
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Text input with segmented dropdown button"
                        placeholder={`${t("Enter Favorite Folder")}`}
                        onChange={handleNewFavFolderChange}
                      />
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        <button
                          type="button"
                          className="btn btn-blue"
                          disabled={
                            !newFavFolder ||
                            isNewFavFolderSubmitted ||
                            newFavFolder.length < 3 ||
                            !!Object.values(Favorite)
                              .map((folder) => folder.name)
                              .find(
                                (folderName: string) =>
                                  folderName.toLowerCase() ===
                                  newFavFolder.toLowerCase()
                              )
                          }
                          onClick={handleUpdate}
                        >
                          {t("Save")}
                        </button>
                        <button
                          type="button"
                          className="btn ml-1 btn-red"
                          onClick={() =>
                            setCreateNewFavFolder(!createNewFavFolder)
                          }
                        >
                          {t("Cancel")}
                        </button>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
              <FavoriteFolder
                setCheckedFavoriteFolder={setCheckedFavoriteFolder}
              />
            </tbody>
          </table>
        </div>
        <button
          className="btn btn-lg btn-blue mb-3"
          type="button"
          disabled={submitting || !checkedFavoriteFolder}
          onClick={() => {
            setSubmitting(true);
            if (isAddToFavorite) {
              handleAddToFavoriteFolder();
            } else {
              closeModal();
            }
          }}
        >
          {t("Done")}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ManageFavoriteModal;
