/* eslint-disable no-shadow */
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select, { OptionProps } from "react-select";
import { gql, useLazyQuery } from "@apollo/client";
import { v4 as uuid } from "uuid";
import ReactPlayer from "react-player";
import Swal from "sweetalert2";
import { client } from "../../services/ApolloClient/pimcoreData";
import { useAppSelector, useAppDispatch } from "../../store";
import "react-multi-email/dist/style.css";
import { ModalProps } from "../../types/common";
import IconLockIcon from "../../assets/img/icon-lock.svg";
import defaultVideoThumbnail from "../../assets/img/default_video_thumbnail.jpeg";
import defaultDocumentThumbnail from "../../assets/img/default_document_thumbnail.png";
import { RootState } from "../../rootReducer";
import {
  AssetDownloadData,
  NavBarData,
  NavBarResponse,
  VideoThumbnail,
  DocumentThumbnail,
  MultiAssetDownloadData,
  AssetInfo,
} from "../../models/interfaces/common";
import { GraphqlApi } from "../../models/interfaces/graphApi";
import CatalogCard from "../CatalogCard";
import http from "../../services/api";
import assetDownloadSlice from "../../pages/assetDownloadSlice";
import useMultiSelectionSlice, {
  clearAllPossibleMultiSelectionState,
  setUseMultiSelectionState,
} from "../../hooks/useMultiSelectionSlice";
import { setAssetInfo, setSelectedAssetCard } from "../../pages/assetCardSlice";
import {
  addMultiDownloadProcess,
  DownloadStatus,
} from "../../pages/multipleAssetDownloadSlice";
import {
  getAssetThumbnailUrl,
  getVideoThumbnails,
  getVideoPreviewFilePath,
  getDocumentThumbnails,
  getAssetResolution,
  getAssetResolutionScale,
  getAssetColorSpace,
  getVideoPreviewThumbnails,
  downloadSingleAsset,
  downloadFileOnBrowser,
  downloadZip,
} from "../../utils/catalog";
import useMultiSelection from "../../hooks/useMultiSelection";
import playButtonIcon from "../../assets/img/play-button.png";
import thumbnailZIP from "../../assets/img/icons-svg/zip-1127889.svg";
import thumbnailPPTX from "../../assets/img/icons-svg/pptx-extension-775413.svg";
import thumbnailPPT from "../../assets/img/icons-svg/ppt-775405.svg";
import thumbnailDOCX from "../../assets/img/icons-svg/docx-1127984.svg";
import thumbnailDOC from "../../assets/img/icons-svg/doc-1127985.svg";
import thumbnailXLSX from "../../assets/img/icons-svg/xlsx-file-775412.svg";
import thumbnailXLS from "../../assets/img/icons-svg/xls-775407.svg";
import thumbnailINDD from "../../assets/img/icons-svg/ini-1127950.svg";
import thumbnailTXT from "../../assets/img/icons-svg/txt-1127979.svg";
import thumbnailCSV from "../../assets/img/icons-svg/csv-1127906.svg";
import thumbnailPDF from "../../assets/img/icons-svg/pdf-1127982.svg";
import thumbnailAI from "../../assets/img/icons-svg/ai-1127929.svg";
import thumbnailOTF from "../../assets/img/icons-svg/otf-775404.svg";
import thumbnailTTF from "../../assets/img/icons-svg/ttf-775410.svg";

// NOTE: set up formik form validation here

type ModalDataProps = ModalProps & {
  isMultipleDownload: boolean;
  useMultiSelectionData: boolean;
};

const getFileExtension = (fileName: string): string => {
  const tempFileExtension = fileName.split(".");
  return tempFileExtension[tempFileExtension.length - 1].toLowerCase();
};

const getStaticFileThumbNail = (fileExtension: string) => {
  switch (fileExtension) {
    case "pdf":
      return thumbnailPDF;
    case "doc":
      return thumbnailDOC;
    case "docx":
      return thumbnailDOCX;
    case "zip":
      return thumbnailZIP;
    case "pptx":
      return thumbnailPPTX;
    case "ppt":
      return thumbnailPPT;
    case "xlsx":
      return thumbnailXLSX;
    case "xls":
      return thumbnailXLS;
    case "indd":
      return thumbnailINDD;
    case "txt":
      return thumbnailTXT;
    case "csv":
      return thumbnailCSV;
    case "ai":
      return thumbnailAI;
    default:
      return thumbnailPDF;
  }
};

const AssetDownloadModal = ({
  isOpen,
  toggle,
  modalClass,
  selectedList,
  keyboard = true,
  backdrop = "static",
  centered = true,
  isMultipleDownload = false,
  useMultiSelectionData = false,
}: ModalDataProps) => {
  const { t, i18n } = useTranslation("common");
  const dispatch = useAppDispatch();
  const [selectedAssetPreset, setSelectedAssetPreset] = useState<any>({
    value: "original",
    label: t("Original"),
  });
  const [selectedVideoAssetPreset, setSelectedVideoAssetPreset] = useState<any>(
    {
      value: "original",
      label: t("Original"),
    }
  );
  const [selectedAssetFileFormat, setSelectedAssetFileFormat] = useState<any>();
  const [selectedDimensionOption, setSelectedDimensionOption] = useState<any>({
    value: "height",
    label: t("Height"),
  });
  const [assetResolutionScale, setAssetResolutionScale] =
    useState<Number | null>(null);
  const [assetResolution, setAssetResolution] = useState<any>();
  const [assetWidth, setAssetWidth] = useState<any>();
  const [assetHeight, setAssetHeight] = useState<any>();
  const [selectedAssetColorSpace, setSelectedAssetColorSpace] = useState<any>();
  const [showEditPreset, setShowEditPreset] = useState(false);
  const [hideDesiredLabel, setHideDesiredLabel] = useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState<string | null>(
    defaultVideoThumbnail
  );
  const [videoFilePath, setVideoFilePath] = useState("");
  // use assetList state for multi download
  const [assetList, setAssetList] = useState<AssetInfo[]>([]);
  const [imageAssetList, setImageAssetList] = useState<AssetInfo[]>([]);
  const [videoAssetList, setVideoAssetList] = useState<AssetInfo[]>([]);
  const [documentAssetList, setDocumentAssetList] = useState<AssetInfo[]>([]);
  const [imageIds, setImageIds] = useState<string[]>([]);
  const [videoIds, setVideoIds] = useState<string[]>([]);
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [downloadProgress, setDownloadProgress] = useState<any>();
  const [thumbnail, setThumbnail] = useState<any>();
  // end of multi download state asset
  const [documentThumbnails, setDocumentThumbnails] =
    useState<DocumentThumbnail>({});
  const [fileFormat, setFileFormat] = useState<string>("");
  const [fileExtension, setFileExtension] = useState<string>("");
  const [checkFileFormats] = useState<string[]>([
    "zip",
    "indd",
    "docx",
    "pdf",
    "tif",
    "ai",
  ]);

  const userId = useAppSelector((state) => state.user && state.user.id);
  const assetLists = useAppSelector((state) => state.assets.assetList);
  const assetValue = useSelector((state: RootState) => state.assetCard);
  const assetValueNavBar = useSelector((state: RootState) => state.assetNav);

  const downloadChoice = useAppSelector((state) => state.download.choice);
  const UseSelectionsSlice = useSelector(
    (state: RootState) => state.useSelections
  );

  useEffect(() => {
    if (downloadChoice === 0 && UseSelectionsSlice.allSections.length === 1) {
      const selectedAsset = UseSelectionsSlice.allSections[0];
      const tempAsset: AssetInfo[] = assetLists.filter(
        (asset: AssetInfo) => asset.id === selectedAsset
      );
      dispatch(setAssetInfo(tempAsset[0]));
    }
  }, [downloadChoice]);

  const stringToNumberArray = (tempArr: string[]): number[] => {
    if (tempArr.length === 1 && tempArr[0] === "") return [];
    const result: number[] = tempArr.map((item: string) => Number(item));
    return result;
  };

  const multiAssetDownloadData: MultiAssetDownloadData = {
    userId,
    imageAssetIds:
      // eslint-disable-next-line no-nested-ternary
      imageIds && imageIds.length > 0
        ? stringToNumberArray(imageIds)
        : assetValue && assetValue.type === "image"
          ? stringToNumberArray([(assetValue.id as string) || ""])
          : [],
    videoAssetIds:
      // eslint-disable-next-line no-nested-ternary
      videoIds && videoIds.length > 0
        ? stringToNumberArray(videoIds)
        : assetValue && assetValue.type === "video"
          ? stringToNumberArray([(assetValue.id as string) || ""])
          : [],
    otherAssetIds:
      // eslint-disable-next-line no-nested-ternary
      documentIds && documentIds.length > 0
        ? stringToNumberArray(documentIds)
        : assetValue &&
            (assetValue.type === "document" || assetValue.type === "archive")
          ? stringToNumberArray([(assetValue.id as string) || ""])
          : [],
    imageDownloadType:
      assetValue && assetValue.type !== "image" && imageIds.length === 0
        ? ""
        : (selectedAssetPreset.value as string),
    videoDownloadType:
      assetValue && assetValue.type !== "video" && videoIds.length === 0
        ? ""
        : (selectedVideoAssetPreset.value as string),
    width: assetWidth || "",
    height: assetHeight || "",
    format: selectedAssetFileFormat ? selectedAssetFileFormat.value : "",
    resolution: assetResolution || "",
    colorSpace: selectedAssetColorSpace ? selectedAssetColorSpace.value : "",
  };

  const singleAssetDownloadData: MultiAssetDownloadData = {
    userId,
    imageAssetIds:
      assetValue && assetValue.type === "image"
        ? stringToNumberArray([(assetValue.id as string) || ""])
        : [],
    videoAssetIds:
      assetValue && assetValue.type === "video"
        ? stringToNumberArray([(assetValue.id as string) || ""])
        : [],
    otherAssetIds:
      assetValue &&
      (assetValue.type === "document" || assetValue.type === "archive")
        ? stringToNumberArray([(assetValue.id as string) || ""])
        : [],
    imageDownloadType:
      assetValue && assetValue.type !== "image"
        ? ""
        : (selectedAssetPreset.value as string),
    videoDownloadType:
      assetValue && assetValue.type !== "video"
        ? ""
        : (selectedVideoAssetPreset.value as string),
    width: assetWidth || "",
    height: assetHeight || "",
    format: selectedAssetFileFormat ? selectedAssetFileFormat.value : "",
    resolution: assetResolution || "",
    colorSpace: selectedAssetColorSpace ? selectedAssetColorSpace.value : "",
  };

  interface IAsset {
    node: Node;
  }
  interface Node {
    id: string;
    filename: string;
    fullpath: string;
    fullpathThumbnailPortraitImage?: string;
    fullpathThumbnailImageDownload?: string;
    fullpathThumbnailLandscapeImage?: string;
    fullpathExtandPortraitImage?: string;
    fullpathExtandLandscapeImage?: string;
    fullpathThumbnailFont?: string;
    fullpathExtandFont?: string;
    type: string;
  }

  const queryValue = gql`
    query ($ids: String!) {
      getAssetListing(ids: $ids) {
        edges {
          node {
            ... on asset {
              id
              filename
              fullpath
              fullpathThumbnailPortraitImage: fullpath(
                thumbnail: "DAMPortalThumbnailPortraitImage"
              )
              fullpathThumbnailImageDownload: fullpath(
                thumbnail: "DamPortalThumbnailImageDownload"
              )
              fullpathThumbnailLandscapeImage: fullpath(
                thumbnail: "DAMPortalThumbnailLandscapeImage"
              )
              fullpathExtandPortraitImage: fullpath(
                thumbnail: "DAMPortalExtandPortraitImage"
              )
              fullpathExtandLandscapeImage: fullpath(
                thumbnail: "DAMPortalExtandLandscapeImage"
              )
              fullpathThumbnailFont: fullpath(
                thumbnail: "DAMPortalThumbnailFont"
              )
              fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
              type
            }
          }
        }
      }
    }
  `;

  const getAssetIds = () => {
    if (useMultiSelectionData) {
      return selectedList && selectedList.toString();
    }
    return assetValue.id;
  };
  const [getSelectedAssets, { loading, error, data }] = useLazyQuery(
    queryValue,
    {
      variables: {
        ids: getAssetIds(),
      },
    }
  );

  useEffect(() => {
    if (assetValue.id && "filename" in assetValue && assetValue.filename) {
      setFileExtension(
        (
          assetValue?.filename?.split(".").slice(-1).pop() as string
        ).toLowerCase()
      );
    }
  }, [assetValue.id]);

  useEffect(() => {
    if (assetValue.id && isOpen) {
      getAssetResolutionScale(
        { assetId: Number(assetValue.id) },
        setAssetResolutionScale
      );
    }
  }, [assetValue.id, isOpen]);

  useEffect(() => {
    setShowEditPreset(
      selectedAssetPreset && selectedAssetPreset.value === "custom"
    );
  }, [selectedAssetPreset.value]);

  useEffect(() => {
    if (
      assetValue &&
      assetValue.filename &&
      assetValue.filename !== undefined
    ) {
      setFileFormat(
        (assetValue.filename.split(".").slice(-1).pop() as string).toLowerCase()
      );
    }
  }, [assetValue]);

  useEffect(() => {
    if (isOpen) {
      setSelectedAssetPreset({
        value: "original",
        label: t("Original"),
      });
      setSelectedVideoAssetPreset({
        value: "original",
        label: t("Original"),
      });
    } else {
      setSelectedAssetPreset("");
      setSelectedVideoAssetPreset("");
      setSelectedAssetFileFormat("");
      setAssetResolution("");
      setAssetWidth("");
      setAssetHeight("");
      setSelectedAssetColorSpace("");
      setShowEditPreset(false);
    }
  }, [isOpen]);

  const handleAssetPresetChange = (selectedOption: any) => {
    setSelectedAssetPreset(selectedOption);
  };

  const handleVideoAssetPresetChange = (selectedOption: any) => {
    setSelectedVideoAssetPreset(selectedOption);
  };

  const handleAssetFileFormatChange = (selectedOption: any) => {
    setSelectedAssetFileFormat(selectedOption);
  };

  const handleAssetResolutionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAssetResolution(event.target.value);
  };

  const handleAssetDimensionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (assetResolutionScale && selectedDimensionOption) {
      if (selectedDimensionOption.value === "height") {
        setAssetHeight(Math.abs(Number(event.target.value)));
        setAssetWidth(
          Math.abs(Number(event.target.value)) / Number(assetResolutionScale)
        );
      } else {
        setAssetWidth(Math.abs(Number(event.target.value)));
        setAssetHeight(
          Math.abs(Number(event.target.value)) * Number(assetResolutionScale)
        );
      }
    }
  };

  const handleAssetHeightChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAssetHeight(event.target.value);
  };

  const handleAssetResolution = (selectedOption: any) => {
    setAssetResolution(selectedOption);
  };

  const handleAssetColorSpaceChange = (selectedOption: any) => {
    setSelectedAssetColorSpace(selectedOption);
  };

  const handleDimensionOptionChange = (selectedOption: any) => {
    setSelectedDimensionOption(selectedOption);
  };

  const toggleEditPreset = () => setShowEditPreset(!showEditPreset);

  const imageDownloadPreset = [
    { value: "original", label: t("Original") },
    { value: "preview", label: t("Preview") },
    { value: "custom", label: t("Custom") },
  ];

  useEffect(() => {
    if (!error && !loading && data && isOpen) {
      const assetListingTypes: string[] =
        data &&
        data.getAssetListing &&
        data.getAssetListing.edges
          .filter((asset: IAsset, assetIndex: number) =>
            UseSelectionsSlice.allSections.includes(asset.node.id.toString())
          )
          .map((asset: IAsset, assetIndex: number) => asset.node.type);
      if (assetListingTypes.length > 1) {
        setHideDesiredLabel(true);
      } else {
        if (checkFileFormats.includes(fileFormat)) setHideDesiredLabel(true);
        else setHideDesiredLabel(false);
      }
    }
  }, [data, isOpen, fileFormat]);

  const videoDownloadPreset = [
    { value: "original", label: t("Original") },
    { value: "preview", label: t("Preview") },
  ];

  const imageFileFormat = [
    { value: "jpg", label: "jpg" },
    { value: "gif", label: "gif" },
    { value: "png", label: "png" },
    { value: "eps", label: "eps" },
    { value: "tiff", label: "tiff" },
    { value: "bmp", label: "bmp" },
    { value: "pdf", label: "pdf" },
    { value: "jpg", label: "jpeg" },
    // { value: "indd", label: "INDD" },
  ];

  const imageDimensionOptions = [
    { value: "height", label: t("Height") },
    { value: "width", label: t("Width") },
  ];

  const imageColorSpace = [
    // { value: "srgb", label: "SRGB" },
    { value: "rgb", label: "RGB" },
    { value: "cmyk", label: "CMYK" },
    { value: "grey", label: "GRAYSCALE" },
  ];

  const colorCodeOptions = [
    { label: "Color Code 1", value: "colorCode1" },
    { label: "Color Code 2", value: "colorCode2" },
    { label: "Color Code 3", value: "colorCode3" },
  ];

  const MultipleDownloadPresetsCount = () => {
    if (isMultipleDownload) {
      return (
        <>
          <div className="asset-number">
            {selectedList && selectedList.length}{" "}
            {selectedList && selectedList.length > 1 ? "assets" : "asset"}
          </div>
          <div className="arrow-up" />
        </>
      );
    }
    return null;
  };

  const downloadAsset = async () => {
    const counter = UseSelectionsSlice.allSections.length;

    if (counter === 1 || (assetValue.id && counter < 2)) {
      await downloadSingleAsset(singleAssetDownloadData);
    } else {
      const response = await downloadZip(multiAssetDownloadData);

      if (response?.zipTaskId) {
        dispatch(
          addMultiDownloadProcess({
            status: DownloadStatus.inProgress,
            zipID: response?.zipTaskId,
            fileCount: counter,
            presetSetting: multiAssetDownloadData,
            downloadProgress: 0,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      setAssetList(
        assetLists.filter((asset: AssetInfo, assetIndex: number) =>
          UseSelectionsSlice.allSections.includes(asset.id.toString())
        )
      );
    }
  }, [assetLists, isOpen]);

  useEffect(() => {
    setImageAssetList(
      assetList.filter((asset: AssetInfo) => asset.type === "image")
    );
    setVideoAssetList(
      assetList.filter((asset: AssetInfo) => asset.type === "video")
    );
    setDocumentAssetList(
      assetList.filter(
        (asset: AssetInfo) => asset.type !== "image" && asset.type !== "video"
      )
    );
  }, [assetList]);

  useEffect(() => {
    const imageAssetIds: string[] | undefined = [];
    const videoAssetIds: string[] | undefined = [];
    const DocumentAssetIds: string[] | undefined = [];

    if (imageAssetList.length > 0) {
      imageAssetList.forEach((asset: AssetInfo) => {
        imageAssetIds.push(asset.id as string);
      });
      setImageIds(imageAssetIds);
    }

    if (videoAssetList.length > 0) {
      videoAssetList.forEach((asset: AssetInfo) => {
        videoAssetIds.push(asset.id as string);
      });
      setVideoIds(videoAssetIds);
    }

    if (documentAssetList.length > 0) {
      documentAssetList.forEach((asset: AssetInfo) => {
        DocumentAssetIds.push(asset.id as string);
      });
      setDocumentIds(DocumentAssetIds);
    }
  }, [imageAssetList, videoAssetList, documentAssetList]);

  // useEffect(() => {
  //   if (videoIds.length > 0) {
  //     getVideoThumbnails(videoIds.toString(), setVideoThumbnails);
  //   }
  // }, [videoIds, videoThumbnails]);

  useEffect(() => {
    if (documentIds.length > 0) {
      getDocumentThumbnails(documentIds.toString(), setDocumentThumbnails);
    }
  }, [documentIds.length]);

  const DownloadPreview = () => {
    useEffect(() => {
      setVideoThumbnail(defaultVideoThumbnail);
      setThumbnail(null);
      if (assetValue) {
        if (
          assetValue.type === "video" &&
          assetValue.youTubeSDThumbnailPath &&
          assetValue?.youTubeSDThumbnailPath.startsWith("https")
        ) {
          getVideoPreviewFilePath(assetValue.id as string, setVideoFilePath);
          // getVideoPreviewThumbnails(assetValue.id as string, setVideoThumbnail);
          setVideoThumbnail(assetValue.url as string);
          setThumbnail(assetValue.youTubeSDThumbnailPath);
        }
        if (
          assetValue.type === "image" &&
          assetValue.fullpathThumbnailPortraitImage &&
          assetValue?.fullpathThumbnailPortraitImage.startsWith("https")
        ) {
          setThumbnail(assetValue.fullpathThumbnailPortraitImage);
        }

        if (
          assetValue.youTubeSDThumbnailPath &&
          assetValue?.youTubeSDThumbnailPath.startsWith("https")
        ) {
          setThumbnail(assetValue.youTubeSDThumbnailPath);
        }

        if (
          (assetValue.type === "document" || assetValue.type === "archive") &&
          assetValue.filename &&
          (assetValue.fullpathThumbnailPortraitImage === null ||
            assetValue.fullpathThumbnailPortraitImage === "")
        ) {
          setThumbnail(
            getStaticFileThumbNail(getFileExtension(assetValue.filename))
          );
        }
      }
    }, [assetValue]);

    return (
      <div className="img-area">
        {/* <img
          src={AssetCardlice.url}
          className="img-fluid"
          alt="alt-update-this-when-update-src"
        /> */}
        {/* eslint-disable-next-line no-nested-ternary */}
        {assetValue.type === "video" ? (
          videoThumbnail !== defaultVideoThumbnail ? (
            <ReactPlayer
              url={[
                {
                  src: encodeURI(
                    assetValue.youTubeSDPath
                      ? (assetValue.youTubeSDPath as string)
                      : ""
                  ),
                  type: "video/mp4",
                },
              ]}
              width="90%"
              height="90%"
              controls
              playing
              light={encodeURI(thumbnail as string)}
              playIcon={<img src={playButtonIcon} alt="videoPlayer" />}
            />
          ) : (
            <img
              src={defaultVideoThumbnail}
              width="90%"
              height="90%"
              alt="Gildan Thumbnail"
            />
          )
        ) : (
          <img
            src={thumbnail}
            className="img-fluid"
            alt="alt-update-this-when-update-src"
          />
        )}
      </div>
    );
  };

  const DownloadPreviewForNav = () => {
    useEffect(() => {
      setVideoThumbnail(defaultVideoThumbnail);
      if (assetValueNavBar && assetValueNavBar.type === "video") {
        getVideoPreviewFilePath(
          assetValueNavBar.id as string,
          setVideoFilePath
        );
        setVideoThumbnail(assetValueNavBar.url as string);
      }
    }, [assetValueNavBar.id]);

    return (
      <div className="img-area">
        {/* <img
          src={AssetCardlice.url}
          className="img-fluid"
          alt="alt-update-this-when-update-src"
        /> */}
        {/* eslint-disable-next-line no-nested-ternary */}
        {assetValueNavBar.type === "video" ? (
          videoThumbnail !== defaultVideoThumbnail ? (
            <ReactPlayer
              url={[
                {
                  src: encodeURI(
                    (assetValue.youTubeSDPath as string)?.slice(0, 5) !==
                      "https"
                      ? `https://gildan.blob.core.windows.net/gildan/tmp/video-thumbnails${
                          assetValue.youTubeSDPath as string
                        }`
                      : (assetValue.youTubeSDPath as string)
                  ),
                  type: "video/mp4",
                },
              ]}
              width="90%"
              height="90%"
              controls
              playing
              light={encodeURI(videoThumbnail as string)}
              playIcon={<img src={playButtonIcon} alt="videoPlayer" />}
            />
          ) : (
            <img
              src={defaultVideoThumbnail}
              width="90%"
              height="90%"
              alt="Gildan Thumbnail"
            />
          )
        ) : (
          <img
            src={assetValue && (assetValue.youTubeSDThumbnailPath as string)}
            className="img-fluid"
            alt="alt-update-this-when-update-src"
          />
        )}
      </div>
    );
  };

  const ImageDownloadPreview = () => {
    if (imageAssetList && isMultipleDownload) {
      return (
        <>
          {isMultipleDownload && (
            <>
              <div className="asset-number">
                {imageAssetList && imageAssetList.length}{" "}
                {imageAssetList && imageAssetList.length > 1
                  ? t("Images")
                  : t("Image")}
              </div>
              <div className="arrow-up" />
            </>
          )}
          <div className="img-area">
            <div className="exampleSelectOne exampleSelect">
              {imageAssetList
                .filter((asset: AssetInfo, index) => index < 7)
                .map((asset: AssetInfo, assetIndex: number) => {
                  const filename: string = asset.filename.toString();
                  const ext =
                    filename.substring(
                      filename.lastIndexOf(".") + 1,
                      filename.length
                    ) || filename;
                  return (
                    <div className="exampleSelectCard blue" key={asset.id}>
                      <img
                        key={`${asset.id}-${asset.fullpath}`}
                        className="img-fluid"
                        alt="photos/58/72"
                        src={
                          // eslint-disable-next-line no-nested-ternary
                          asset.fullpathThumbnailImageDownload
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  const VideoDownloadPreview = () => {
    if (videoAssetList && isMultipleDownload) {
      return (
        <>
          {isMultipleDownload && (
            <>
              <div className="asset-number">
                {videoAssetList && videoAssetList.length}{" "}
                {videoAssetList && videoAssetList.length > 1
                  ? t("Videos")
                  : t("Video")}
              </div>
              <div className="arrow-up" />
            </>
          )}
          <div className="img-areaVideo">
            <div className="exampleSelectOne exampleSelect">
              {videoAssetList
                .filter((asset: AssetInfo, index) => index < 3)
                .map((asset: AssetInfo, assetIndex: number) => {
                  const filename: string = asset.filename.toString();
                  const ext =
                    filename.substring(
                      filename.lastIndexOf(".") + 1,
                      filename.length
                    ) || filename;
                  return (
                    <div className="exampleSelectCardVideo ">
                      <img
                        key={`${asset.id}-${asset.fullpath}`}
                        className="img-fluid"
                        alt="photos/58/72"
                        src={
                          // eslint-disable-next-line no-nested-ternary
                          asset.youTubeSDThumbnailPath
                            ? asset.youTubeSDThumbnailPath
                            : defaultVideoThumbnail
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  const DocumentDownloadPreview = () => {
    if (documentAssetList && isMultipleDownload) {
      return (
        <>
          {isMultipleDownload && (
            <>
              <div className="asset-number">
                {documentAssetList && documentAssetList.length}{" "}
                {documentAssetList && documentAssetList.length > 1
                  ? t("files")
                  : t("file")}
              </div>
              <div className="arrow-up" />
            </>
          )}
          <div className="img-area">
            <div className="exampleSelectOne exampleSelect">
              {documentAssetList
                .filter((asset: AssetInfo, index) => index < 7)
                .map((asset: AssetInfo, assetIndex: number) => {
                  const filename: string = asset.filename.toString();
                  const ext =
                    filename.substring(
                      filename.lastIndexOf(".") + 1,
                      filename.length
                    ) || filename;
                  return (
                    <div className="exampleSelectCard ">
                      <img
                        key={`${asset.id}-${asset.fullpath}`}
                        className="img-fluid"
                        alt="photos/58/72"
                        src={
                          // eslint-disable-next-line no-nested-ternary
                          getStaticFileThumbNail(
                            getFileExtension(asset.filename)
                          )
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  const EditAssetPreset = () => (
    <table className="table table-borderless body-header ">
      <thead />
      <tbody>
        {!hideDesiredLabel && (
          <tr>
            <td>
              <h6 className="mb-1 mt-3">{t("Desired Preset")}</h6>
            </td>
            <td>
              <div className="d-flex justify-content-between align-items-center ">
                <Select
                  classNamePrefix="regular-looking-dropdown"
                  className="w-100"
                  options={
                    assetValue && assetValue?.type === "image"
                      ? imageDownloadPreset
                      : videoDownloadPreset
                  }
                  isMulti={false}
                  onChange={handleAssetPresetChange}
                  value={selectedAssetPreset}
                />
                {assetValue && assetValue?.type === "image" ? (
                  <div className="ms-3 mt-lg-0 mt-2">
                    <p className="mb-1 small">png</p>
                    {/* <p className="m-0 small"> */}
                    {/*  {!isMultipleDownload && ( */}
                    {/*    <a */}
                    {/*      href="#something" */}
                    {/*      className="nowrap" */}
                    {/*      onClick={(event) => { */}
                    {/*        event.preventDefault(); */}
                    {/*        toggleEditPreset(); */}
                    {/*      }} */}
                    {/*    > */}
                    {/*      {t("edit preset")} */}
                    {/*    </a> */}
                    {/*  )} */}
                    {/* </p> */}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </td>
          </tr>
        )}
        {showEditPreset && (
          <>
            <tr>
              <td>
                <h6 className="mb-1 mt-3">{t("File Format")}</h6>
              </td>
              <td>
                <div className="d-flex justify-content-between align-items-center ">
                  <Select
                    classNamePrefix="regular-looking-dropdown"
                    className="w-100"
                    options={imageFileFormat}
                    isMulti={false}
                    onChange={handleAssetFileFormatChange}
                    value={selectedAssetFileFormat}
                    placeholder={t("Select...")}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6 className="mb-1 mt-3">{t("Resolution")}</h6>
              </td>
              <td>
                <div className="d-flex justify-content-start align-items-center ">
                  <input
                    type="text"
                    className="form-control me-2 mw-64"
                    onBlur={handleAssetResolutionChange}
                    defaultValue={assetResolution}
                  />{" "}
                  dpi
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6 className="mb-1 mt-3">{t("Dimensions")}</h6>
              </td>
              <td>
                <div className="d-flex justify-content-start align-items-center ">
                  <input
                    type="text"
                    className={`form-control me-2 ${
                      selectedDimensionOption &&
                      selectedDimensionOption.value === "height"
                        ? "bg-h"
                        : "bg-w"
                    }`}
                    onBlur={handleAssetDimensionChange}
                    defaultValue={
                      selectedDimensionOption &&
                      selectedDimensionOption.value === "height"
                        ? assetHeight
                        : assetWidth
                    }
                  />
                  {" px "}
                  {/* <img src={IconLockIcon} alt="lock" />{" "} */}
                  <Select
                    classNamePrefix="regular-looking-dropdown"
                    className="w-100"
                    options={imageDimensionOptions}
                    isMulti={false}
                    onChange={handleDimensionOptionChange}
                    value={selectedDimensionOption}
                  />
                  {/* <input */}
                  {/*  type="text" */}
                  {/*  className="form-control mx-2 bg-h" */}
                  {/*  onBlur={handleAssetHeightChange} */}
                  {/*  defaultValue={assetHeight} */}
                  {/* /> */}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6 className="mb-1 mt-3">{t("Color Space")}</h6>
              </td>
              <td>
                <div className="d-flex justify-content-between align-items-center ">
                  <Select
                    classNamePrefix="regular-looking-dropdown"
                    className="w-100"
                    options={imageColorSpace}
                    isMulti={false}
                    onChange={handleAssetColorSpaceChange}
                    value={selectedAssetColorSpace}
                    placeholder={t("Select...")}
                  />
                </div>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );

  const ImageAssetPreset = () => (
    <table className="table table-borderless body-header ">
      <thead />
      <tbody>
        <tr>
          <td>
            <h6 className="mb-1 mt-3">{t("Desired Preset")}</h6>
          </td>
          <td>
            <div className="d-flex justify-content-between align-items-center ">
              <Select
                classNamePrefix="regular-looking-dropdown"
                className="w-100"
                options={imageDownloadPreset}
                isMulti={false}
                onChange={handleAssetPresetChange}
                value={selectedAssetPreset}
              />
              {assetValue && assetValue?.type === "image" ? (
                <div className="ms-3 mt-lg-0 mt-2">
                  <p className="mb-1 small">{fileExtension}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </td>
        </tr>
        {showEditPreset && (
          <>
            <tr>
              <td>
                <h6 className="mb-1 mt-3">{t("File Format")}</h6>
              </td>
              <td>
                <div className="d-flex justify-content-between align-items-center ">
                  <Select
                    classNamePrefix="regular-looking-dropdown"
                    className="w-100"
                    options={imageFileFormat}
                    isMulti={false}
                    onChange={handleAssetFileFormatChange}
                    value={selectedAssetFileFormat}
                    placeholder={t("Select...")}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6 className="mb-1 mt-3">{t("Resolution")}</h6>
              </td>
              <td>
                <div className="d-flex justify-content-start align-items-center ">
                  <input
                    type="text"
                    className="form-control me-2 mw-64"
                    onBlur={handleAssetResolutionChange}
                    defaultValue={assetResolution}
                  />{" "}
                  dpi
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6 className="mb-1 mt-3">{t("Dimensions")}</h6>
              </td>
              <td>
                <div className="d-flex justify-content-start align-items-center ">
                  <input
                    type="text"
                    className="form-control me-2 mw-64"
                    onBlur={handleAssetDimensionChange}
                    defaultValue={
                      selectedDimensionOption &&
                      selectedDimensionOption.value === "height"
                        ? assetHeight
                        : assetWidth
                    }
                  />
                  {/* <img src={IconLockIcon} alt="lock" />{" "} */}
                  <div className="pe-2 ps-1">px</div>
                  <Select
                    classNamePrefix="regular-looking-dropdown"
                    className="w-100"
                    options={imageDimensionOptions}
                    isMulti={false}
                    onChange={handleDimensionOptionChange}
                    value={selectedDimensionOption}
                  />
                  <div className="p-4"> </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h6 className="mb-1 mt-3">{t("Color Space")}</h6>
              </td>
              <td>
                <div className="d-flex justify-content-between align-items-center ">
                  <Select
                    classNamePrefix="regular-looking-dropdown"
                    className="w-100"
                    options={imageColorSpace}
                    isMulti={false}
                    onChange={handleAssetColorSpaceChange}
                    value={selectedAssetColorSpace}
                    placeholder={t("Select...")}
                  />
                </div>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );

  const VideoAssetPreset = () => (
    <table className="table table-borderless body-header ">
      <thead />
      <tbody>
        <tr>
          <td>
            <h6 className="mb-1 mt-3">{t("Desired Preset")}</h6>
          </td>
          <td>
            <div className="d-flex justify-content-between align-items-center ">
              <Select
                classNamePrefix="regular-looking-dropdown"
                className="w-100"
                options={videoDownloadPreset}
                isMulti={false}
                onChange={handleVideoAssetPresetChange}
                value={selectedVideoAssetPreset}
              />
              {assetValue && assetValue?.type === "video" ? (
                <div className="ms-3 mt-lg-0 mt-2">
                  <p className="mb-1 small">{fileExtension}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );

  const ImagesPreview = () => (
    <>
      <h5>{t("Images")}</h5>
      <ImageAssetPreset />
      <ImageDownloadPreview />
    </>
  );
  const VideosPreview = () => (
    <>
      <h5>{t("Videos")}</h5>
      <VideoAssetPreset />
      <VideoDownloadPreview />
    </>
  );
  const OthersPreview = () => (
    <>
      <h5>{t("Others")}</h5>
      <DocumentDownloadPreview />
    </>
  );

  // Just For one asset.
  const PreviewComponent = () => (
    <>
      {/* <EditAssetPreset /> */}
      {downloadChoice === 1 && assetValue && assetValue.type === "image" ? (
        <ImageAssetPreset />
      ) : null}
      {assetValue && assetValue.type === "video" ? <VideoAssetPreset /> : null}
      {downloadChoice === 0 && assetValue && assetValue.type === "image" ? (
        <ImageAssetPreset />
      ) : null}
      <MultipleDownloadPresetsCount />
      <DownloadPreview />
    </>
  );

  return (
    <Modal
      show={isOpen}
      onHide={toggle}
      className={`${modalClass}`}
      backdrop={backdrop}
      centered={centered}
      keyboard={keyboard}
    >
      <Modal.Header closeButton>
        <h4 className="modal-title">{t("Download")}</h4>
      </Modal.Header>
      <Modal.Body>
        {/* <form className="">
            <EditAssetPreset />
          </form> */}
        {/* <MultipleDownloadPresetsCount /> */}
        {/* <DownloadPreview /> */}
        {!isMultipleDownload ? <PreviewComponent /> : null}
        {imageAssetList.length >= 1 && isMultipleDownload ? (
          <ImagesPreview />
        ) : null}
        {videoAssetList.length >= 1 && isMultipleDownload ? (
          <VideosPreview />
        ) : null}
        {documentAssetList.length >= 1 && isMultipleDownload ? (
          <OthersPreview />
        ) : null}

        <button
          className="btn btn-lg btn-blue mb-3"
          type="button"
          onClick={() => {
            downloadAsset();
            toggle();
          }}
        >
          {t("Download")}
        </button>
      </Modal.Body>
    </Modal>
  );
};
export default AssetDownloadModal;
