import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { RootState } from "../../rootReducer";
import { ModalProps, FavoriteAsset, FavoriteType } from "../../types/common";
import { CHANGE_USER_INFO } from "../../services/graphql/migrations";
import {
  removeProcessById,
  removeProcesses,
  setCancelAllProcesses,
} from "../../pages/multipleAssetDownloadSlice";
import { useAppDispatch, useAppSelector } from "../../store";

const CancelDownloadConfirmationModal = ({
  isOpen,
  toggle,
  modalClass,
  keyboard = true,
  backdrop = "static",
  centered = true,
}: ModalProps) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");

  const dispatch = useAppDispatch();

  const zipIdToCancel = useSelector(
    (state: RootState) => state.multipleAssetsDownload.zipIdToCancel
  );
  const MultipleDownloadStatus = useSelector(
    (state: RootState) => state.multipleAssetsDownload
  );

  const UserInfoSlice = useSelector((state: RootState) => state.user);

  // const StopDownload = () => {
  //   console.log("download stopped");
  // };

  return (
    <Modal
      show={isOpen}
      onHide={toggle}
      className={`${modalClass}`}
      backdrop={backdrop}
      centered={centered}
      keyboard={keyboard}
    >
      <Modal.Body>
        <h2>{t("Confirmation")}</h2>
        <p style={{ textWrap: "nowrap" }}>
          {t("Are you sure that you would like to stop your download?")}
        </p>
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="btn btn-red w-100 me-1"
            type="button"
            onClick={() => {
              // dispatch(removeProcesses());
              // dispatch(setDownloadStatusIsOpen());
              if (zipIdToCancel) {
                dispatch(removeProcessById(zipIdToCancel));
              }
              toggle();
            }}
          >
            {t("Stop")}
          </button>
          <button
            className="btn btn-reversed w-100 ms-1"
            type="button"
            onClick={toggle}
          >
            {t("Cancel")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelDownloadConfirmationModal;
