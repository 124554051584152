import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CatalogInterface } from "../models/interfaces/common";

interface CatalogSliceInterface {
  catalogs: CatalogInterface[];
}

const initialState: CatalogSliceInterface = {
  catalogs: [],
};

const catalog = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    setCatalogs(state, { payload }: PayloadAction<CatalogInterface[]>) {
      state.catalogs = payload;
    },
  },
});

export const { setCatalogs } = catalog.actions;

export default catalog.reducer;
