/* eslint-disable no-plusplus */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FilterCatalog,
  FilterMainAttributesInterface,
  FilterVariableCategory,
  FilterVariableData,
} from "../models/interfaces/common";

interface FilterInterface {
  variables: FilterVariables;
  assetAttributes: FilterVariableCategory | null;
  mainAttributes: FilterMainAttributesInterface | null;
  filterCatalog: FilterCatalog | null;
  showFilter: boolean;
}

interface FilterVariables {
  [details: string]: ValueObject[];
}

const initialState: FilterInterface = {
  variables: {},
  assetAttributes: null,
  mainAttributes: null,
  filterCatalog: null,
  showFilter: false,
};

interface PayloadInterface {
  label: string;
  valueObj: ValueObject[];
}

interface ValueObject {
  id: string;
  label: string;
  value: string;
}

const filter = createSlice({
  name: "filter",
  initialState,
  reducers: {
    addFilterVariable(state, { payload }: PayloadAction<PayloadInterface>) {
      if (payload) {
        delete state.variables[payload.label];
        state.variables[payload.label] = payload.valueObj;
      }
    },
    removeFilterVariable(state, { payload }: PayloadAction<ValueObject>) {
      if (payload) {
        for (let i = 0; i < state.variables[payload.label].length; i++) {
          if (state.variables[payload.label][i].value === payload.value) {
            state.variables[payload.label].splice(i, 1);
          }
        }
      }
    },
    removeAllFilters(state) {
      state.variables = {};
    },
    addFilterVariableData(
      state,
      { payload }: PayloadAction<FilterVariableCategory>
    ) {
      if (payload) {
        state.assetAttributes = payload;
      }
    },
    addFilterMainAttributesData(
      state,
      { payload }: PayloadAction<FilterMainAttributesInterface>
    ) {
      if (payload) {
        state.mainAttributes = payload;
      }
    },
    addCatalogAttributes(state, { payload }: PayloadAction<FilterCatalog>) {
      if (payload) {
        state.filterCatalog = payload;
      }
    },
    showFilterDropdown(state) {
      state.showFilter = !state.showFilter;
    },
    setFilterDropdownStatus(state, { payload }: PayloadAction<boolean>) {
      state.showFilter = payload;
    },
  },
});

export const {
  addFilterVariable,
  removeFilterVariable,
  removeAllFilters,
  addFilterVariableData,
  addFilterMainAttributesData,
  addCatalogAttributes,
  showFilterDropdown,
  setFilterDropdownStatus,
} = filter.actions;

export default filter.reducer;
