/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import { IUserProfile } from "../../models/interfaces/common";
import SecureLS from "../../utils/SecureStorage";
import { CHANGE_USER_INFO } from "../../services/graphql/migrations";
import { setUser } from "../../pages/auth/userSlice";
import { useAppDispatch } from "../../store";
import { RootState } from "../../rootReducer";
import useDebounce from "../../hooks/useDebounce";
import http from "../../services/api";
import { showAlert, showGreenAlert } from "../../util";
import PasswordResetErrorModal from "../modals/PasswordResetErrorModal";
import "bootstrap-icons/font/bootstrap-icons.css";
import Tooltip from "../tooltip/Tooltip";

// NOTE: Why is user password not being updated here, if the client adds to UI

export interface userProfile {
  Session: boolean;
}

// perform user profile update to server
export default function UserProfile() {
  const { t, i18n } = useTranslation("common");
  const UserInfoSlice = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  // set up usestate hook for value
  const [userName, setUsername] = useState<any>("");
  const [firstName, setFirstname] = useState<any>("");
  const [lastName, setLastname] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [id, setID] = useState<number | null>(null);
  const [passwordUpdate, setPasswordUpdate] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  const toggleErrorModal = () => setShowErrorModal(!showErrorModal);

  const UserProfileAccountValidationSchema = Yup.object({
    firstName: Yup.string()
      .notOneOf([null])
      .required(t("Please enter your first name"))
      .matches(/^[A-Za-z ]*$/, t("Please enter a valid first name")),
    lastName: Yup.string()
      .notOneOf([null])
      .required(t("Please enter your last name"))
      .matches(/^[A-Za-z ]*$/, t("Please enter a valid last name")),
    userName: Yup.string()
      .notOneOf([null])
      .matches(/^\w+$/, t("Please enter a valid username"))
      .required(t("Please enter your username")),
    email: Yup.string()
      .email(t("Please enter a valid email address"))
      .required(t("Please enter your email address")),
    password: Yup.string()
      .notOneOf([null])
      .min(8, t("Password must contain at least 8 characters"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_])[A-Za-z\d!@#$%^&*()_]{8,20}$/,
        t(
          "Password must contain at least one uppercase and lowercase letters, one number and one special character."
        )
      ),
    confirmPassword: Yup.string().when("password", {
      is: (value: any) => value,
      then: Yup.string()
        .required(t("Please re-enter your password"))
        .test(
          "password-match",
          t("Password must match"),
          function mustMatch(value) {
            return this.parent.password === value;
          }
        ),
    }),
  });

  const secureLS = SecureLS.getItem("user");
  // retrieve data from session storage to populate the userprofile information
  if (secureLS) {
    const dataValue = JSON.parse(secureLS) || {};
    const { data } = dataValue;

    useEffect(() => {
      if (data) {
        setUsername(data.username);
        setFirstname(data.firstName);
        setLastname(data.lastName);
        setEmail(data.email);
        setID(data.id);
      }
    }, []);
  }

  // useAppSelector is for the state of all file
  // const user = useAppSelector((state) => state.user);
  const [updateUserInfo] = useMutation(CHANGE_USER_INFO);

  const initialValues: IUserProfile = {
    firstName,
    lastName,
    userName,
    email,
    password: "",
    confirmPassword: "",
  };

  const handleUpdate = (
    data: IUserProfile,
    formikHelpers: FormikHelpers<any>
  ) => {
    updateUserInfo({
      variables: {
        // eslint-disable-next-line object-shorthand
        id: Number(id),
        userInfo: {
          username: data.userName,
          // eslint-disable-next-lgfine object-shorthand
          firstName: data.firstName,
          // eslint-disable-next-line object-shorthand
          lastName: data.lastName,
          // eslint-disable-next-line object-shorthand
          email: data.email,
        },
      },
    })
      .then((response) => {
        if (!response.data.updateUser.success) {
          throw Error;
        }
        if (data.password === "") {
          showGreenAlert(t("User Updated"));
        }
      })
      .catch((err) => {
        showAlert("error", "Error. Could not update user");
        // console.log(err);
      });
    setPasswordUpdate(data.password);
    const userInfo = {
      ...UserInfoSlice,
      username: data.userName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    };
    dispatch(setUser(userInfo));
    formikHelpers.setSubmitting(false);
    formikHelpers.setTouched({});
  };

  interface updatePasswordInterface {
    email: string;
    password: string;
  }
  interface updatePasswordResponse {
    success: boolean;
    message: string;
    code: number;
  }

  useEffect(() => {
    if (passwordUpdate !== "") {
      const path = "/api/overwritepassword";
      const dataUpdate = {
        email,
        password: passwordUpdate,
      };
      http
        .post<updatePasswordInterface, updatePasswordResponse>(path, dataUpdate)
        .then((response) => {
          if (response.code !== 200) {
            throw Error;
          }
          showGreenAlert(t("Password updated successfully"));
        })
        .catch((err) => {
          toggleErrorModal();
          // console.log(err);
        });
    }
  }, [passwordUpdate]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={UserProfileAccountValidationSchema}
      onSubmit={(
        values: IUserProfile,
        formikHelpers: FormikHelpers<IUserProfile>
      ) => {
        handleUpdate(values, formikHelpers);
      }}
    >
      {(props: FormikProps<IUserProfile>) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
        } = props;

        const delay = 500;
        const debouncedUserName = useDebounce(values.userName, delay);
        const [isUsernameExists, setIsUsernameExists] = useState<boolean>(true);
        const [showPassword, setShowPassword] = useState(false);
        const [showConfirmPassword, setShowConfirmPassword] = useState(false);
        const [usernameErrorMessage, setUsernameErrorMessage] = useState<
          string | null
        >(null);
        const [
          lastModifiedForUsernameOrEmail,
          setLastModifiedForUsernameOrEmail,
        ] = useState<string | null>(null);
        const renderUserNameError = () => {
          if (isUsernameExists && usernameErrorMessage && touched.userName) {
            return (
              <div className="text-danger mt-1 fs-13 fw-bold">
                {usernameErrorMessage}
              </div>
            );
          }
          if (touched.userName && errors.userName) {
            return (
              <div className="text-danger mt-1 fs-13 fw-bold">
                {errors.userName}
              </div>
            );
          }
          return null;
        };

        useEffect(() => {
          const path = "/api/isusernameexist";
          if (debouncedUserName && debouncedUserName !== userName) {
            http
              .post(path, {
                username: debouncedUserName,
              })
              .then((res: any) => {
                // if username exists
                if (res) {
                  // ignore if value entered is  username got from the query --> values.userName
                  setIsUsernameExists(!res.success);
                  setUsernameErrorMessage(res.message);
                }
              })
              .catch((err) => {
                showAlert("error", "Error. Username Exists");
                // console.error(err.message);
              });
          }
        }, [debouncedUserName]);

        const focusHandler = async (
          event: React.FocusEvent<HTMLInputElement>
        ) => {
          if (["userName", "email"].includes(event.target.name))
            setLastModifiedForUsernameOrEmail(event.target.name);
        };

        return (
          <Form>
            <div>
              <div className="row form-row">
                <div className="col-md-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="firstName" className="form-label">
                      {t("First Name")}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        touched.firstName && errors.firstName
                          ? "is-invalid"
                          : ""
                      }`}
                      id="firstName"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    {touched.firstName && errors.firstName ? (
                      <div className="text-danger mt-1 fs-13 fw-bold">
                        {errors.firstName}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="lastName" className="form-label">
                      {t("Last Name")}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        touched.lastName && errors.lastName ? "is-invalid" : ""
                      }`}
                      id="lastName"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    {touched.lastName && errors.lastName ? (
                      <div className="text-danger mt-1 fs-13 fw-bold">
                        {errors.lastName}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="userName" className="form-label">
                      {t("Username")}
                    </label>
                    <input
                      onFocus={focusHandler}
                      type="text"
                      className={`form-control ${
                        touched.userName && errors.userName ? "is-invalid" : ""
                      }`}
                      id="userName"
                      name="userName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.userName}
                    />
                    {renderUserNameError()}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="email" className="form-label">
                      {t("Email")}
                    </label>
                    <input
                      readOnly
                      type="text"
                      className={`form-control ${
                        touched.email && errors.email ? "is-invalid" : ""
                      }`}
                      id="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </div>
                </div>
              </div>

              <div className="row form-row">
                <div>
                  <h3 className="reset-password-heading">
                    {t("Change Password")}
                  </h3>
                </div>
                <div className="col-md-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="password" className="form-label">
                      {t("New Password")}
                    </label>
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${
                          touched.password && errors.password
                            ? "is-invalid"
                            : ""
                        }`}
                        id="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      <Tooltip
                        content={
                          showPassword ? "Hide Password" : "Show Password"
                        }
                        direction="bottom"
                        delay={0}
                      >
                        <button
                          type="button"
                          className="btn showHide btn-outline-secondary"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <i className="bi bi-eye-slash" />
                          ) : (
                            <i className="bi bi-eye" />
                          )}
                        </button>
                      </Tooltip>
                    </div>
                    {touched.password && errors.password ? (
                      <div className="text-danger mt-1 fs-13 fw-bold">
                        {errors.password}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="confirmPassword" className="form-label">
                      {t("Verify New Password")}
                    </label>
                    <div className="input-group">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className={`form-control ${
                          touched.confirmPassword && errors.confirmPassword
                            ? "is-invalid"
                            : ""
                        }`}
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                      />
                      <Tooltip
                        content={
                          showConfirmPassword
                            ? "Hide Password"
                            : "Show Password"
                        }
                        direction="bottom"
                        delay={0}
                      >
                        <button
                          type="button"
                          className="btn showHide btn-outline-secondary"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <i className="bi bi-eye-slash" />
                          ) : (
                            <i className="bi bi-eye" />
                          )}
                        </button>
                      </Tooltip>
                    </div>
                    {touched.confirmPassword && errors.confirmPassword ? (
                      <div className="text-danger mt-1 fs-13 fw-bold">
                        {errors.confirmPassword}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="my-account-buttons">
                <button
                  className="btn btn-lg btn-blue"
                  type="submit"
                  disabled={
                    isSubmitting ||
                    Object.keys(touched).length === 0 ||
                    !!(errors.firstName && touched.firstName) ||
                    !!(errors.lastName && touched.lastName) ||
                    !!(errors.userName && touched.userName) ||
                    (isUsernameExists && touched.userName) ||
                    !!(errors.confirmPassword && touched.confirmPassword) ||
                    !!(errors.password && touched.password)
                  }
                >
                  {t("Update")}
                </button>
              </div>
            </div>
            <PasswordResetErrorModal
              isOpen={showErrorModal}
              toggle={toggleErrorModal}
              modalClass="passwordModal"
              centered
              backdrop="static"
              keyboard
            />
          </Form>
        );
      }}
    </Formik>
  );
}
