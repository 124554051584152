import React, { useEffect, useState } from "react";
import "reactjs-popup/dist/index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import Swal from "sweetalert2";
import { decodeToken } from "react-jwt";
import { useSelector } from "react-redux";
import { RootState } from "../../rootReducer";
import ResetPasswordSchema from "./ResetPassword.schema";
import { ILocale } from "../../models/interfaces/locale";
import { useAppDispatch } from "../../store";
import http from "../../services/api";
import SecureLS from "../../utils/SecureStorage";

type ResetPasswordType = {
  email: string;
};
type ResetPasswordTokenType = {
  resetPasswordToken: string;
};

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const secureLS = SecureLS.getItem("user");
  const dataValue = secureLS ? JSON.parse(secureLS) : null;

  const resetPasswordToken = useSelector(
    (state: RootState) => state.resetToken
  );
  const [isExpired, setIsExpired] = useState<Boolean>(false);

  useEffect(() => {
    const decodeResetPasswordToken = decodeToken(
      resetPasswordToken.token as string
    ) as any;
    if (decodeResetPasswordToken) {
      if (
        resetPasswordToken &&
        new Date() > new Date(Number(`${decodeResetPasswordToken.expires}000`))
      ) {
        setIsExpired(true);
      }
    }
  }, [resetPasswordToken]);

  interface apiResponse {
    code: number;
    message: string;
    success: boolean;
  }

  const locale: ILocale = {
    region: "76",
    language: "en_US",
  };

  const initialValues: ResetPasswordType = {
    email: "",
  };

  const handleLogin = (
    data: ResetPasswordType,
    _setSubmitting: any,
    _resetForm: any
  ) => {
    const path = "/api/forgotpasswordlink";
    http
      .post<ResetPasswordType, apiResponse>(path, data)
      .then((res) => {
        const { success, message, code } = res;
        if (success === true) {
          Swal.fire({
            position: "bottom",
            // icon: 'success',
            background: "#00ba4d",
            // text: "Email has been sent",
            html: `<strong style=color:white>Email has been sent</strong>`,
            width: 300,
            showConfirmButton: false,
            timer: 5000,
            heightAuto: false,
          });
        } else {
          Swal.fire({
            position: "bottom",
            icon: "error",
            // background: "#00ba4d",
            text: message,
            // width: 170,
            showConfirmButton: false,
            timer: 5000,
            heightAuto: false,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          position: "bottom",
          icon: "error",
          // background: "#00ba4d",
          html: `<h6 style=color:white>Unable to make API call</h6>`,
          // width: 170,
          showConfirmButton: false,
          timer: 5000,
          heightAuto: false,
        });
      })
      .finally(() => {
        _setSubmitting(false);
        _resetForm();
      });
  };

  const LoginForm = () => (
    <Formik
      initialValues={initialValues}
      validationSchema={ResetPasswordSchema}
      onSubmit={(
        values: ResetPasswordType,
        formikHelpers: FormikHelpers<ResetPasswordType>
      ) => {
        handleLogin(
          values,
          formikHelpers.setSubmitting,
          formikHelpers.resetForm
        );
      }}
    >
      {(props: FormikProps<ResetPasswordType>) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
        } = props;
        return (
          <Form>
            <div className="mb-4">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="text"
                className={`form-control ${
                  touched.email && errors.email ? "is-invalid" : ""
                }`}
                id="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {touched.email && errors.email ? (
                <div className="text-danger mt-1 fs-13 fw-bold">
                  {errors.email}
                </div>
              ) : null}
            </div>
            <div className="mb-3">
              <button
                className="btn btn-lg btn-blue"
                type="submit"
                disabled={isSubmitting || !!(errors.email && touched.email)}
              >
                Email reset link
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  return (
    <div className="registration registration-sm">
      <div className="container-fluid">
        <div className="row">
          <div className="px-5 py-3 pb-1 single-col h-auto col-12 overflow-hidden">
            <h2>Email Password Reset Link</h2>
            <p className="fs-13">
              Change your mind? <Link to="/login">Login here.</Link>
              {isExpired ? (
                <p className="text-danger mt-1 fs-13 fw-bold">
                  Sorry, your password reset link has expired. Please submit
                  your request again
                </p>
              ) : null}
            </p>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
}
