import Swal, { SweetAlertIcon } from "sweetalert2";

export const showAlert = (
  alertType?: SweetAlertIcon,
  titleText = "Something happened."
): void => {
  Swal.fire({
    titleText,
    position: "top-end",
    timer: 3000,
    timerProgressBar: true,
    toast: true,
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: "Dismiss",
    icon: alertType,
    showClass: {
      popup: "swal2-noanimation",
      backdrop: "swal2-noanimation",
    },
    hideClass: {
      popup: "",
      backdrop: "",
    },
  });
};

export const showGreenAlert = (titleText = "Something happened."): void => {
  Swal.fire({
    titleText,
    position: "bottom",
    background: "#00BB59",
    showConfirmButton: false,
    timer: 5000,
    heightAuto: false,
    customClass: {
      popup: "green-alert",
    },
  });
};

export const scrollToTop = () => {
  const content = document.querySelector(".portal-content-white");
  if (content) content.scrollTo(0, 0);
};
