import * as React from "react";

interface DynamicButtonProps {
  icon: any;
  onClick: () => void;
  isSmall?: boolean;
}

export default function DynamicButton({
  icon,
  onClick,
  isSmall,
}: DynamicButtonProps) {
  return (
    <button
      className={`${isSmall ? "downloadDynamicButtonSM" : "downloadDynamicButton"}`}
      type="button"
      onClick={onClick}
    >
      <img src={icon} alt="downloadIcon" className="w-100" />
    </button>
  );
}

DynamicButton.defaultProps = {
  isSmall: false,
};
