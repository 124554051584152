import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AssetExclusionInterface {
  assets: string[];
}

const initialState: AssetExclusionInterface = {
  assets: [],
};

const assetExclusion = createSlice({
  name: "assetExclusion",
  initialState,
  reducers: {
    addExcludedAsset(state, { payload }: PayloadAction<string>) {
      if (payload) {
        state.assets = [payload, ...state.assets];
      }
    },
    addExcludedAssets(state, { payload }: PayloadAction<string[]>) {
      if (payload) {
        state.assets = [...payload, ...state.assets];
      }
    },
    emptyExcludedAssets(state) {
      state.assets = [];
    },
  },
});

export const { addExcludedAsset, addExcludedAssets, emptyExcludedAssets } =
  assetExclusion.actions;

export default assetExclusion.reducer;
