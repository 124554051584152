import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./pages/auth/authSlice";
import userReducer from "./pages/auth/userSlice";
import localeReducer from "./pages/localeSlice";
import inPageNavReducer from "./components/inPageNavSlice";
import assetReducer from "./pages/assetSlice";
import navBarSlice from "./pages/navBarSlice";
import assetCardSlice from "./pages/assetCardSlice";
import assetDownloadSlice from "./pages/assetDownloadSlice";
import sidebarSlice from "./pages/sidebarSlice";
import breadcrumbSlice from "./components/breadcrumbs/breadcrumbSlice";
import sortSlice from "./pages/sortSlice";
import filterSlice from "./pages/filterSlice";
import customMetadataSlice from "./pages/customMetadataSlice";
import favoriteSlice from "./pages/favoriteSlice";
import useMultiSelectionSlice from "./hooks/useMultiSelectionSlice";
import shareSlice from "./pages/shareSlice";
import downloadSlice from "./pages/downloadSlice";
import oktaSlice from "./pages/auth/idForOktaSlice";
import regionSlice from "./pages/regionSlice";
import resetPasswordSlice from "./pages/auth/resetPasswordSlice";
import multipleAssetDownloadSlice from "./pages/multipleAssetDownloadSlice";
import assetsSlice from "./pages/assetsSlice";
import assetSliceNavBar from "./pages/assetSliceNavBar";
import inShare from "./pages/inShareSlice";
import catalogSlice from "./pages/catalogSlice";
import filterOptionsSlice from "./pages/filterOptionsSlice";
import assetExclusionSlice from "./pages/assetExclusionSlice";
import newFavoriteSlice from "./pages/newFavoriteSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  locale: localeReducer,
  inPageNav: inPageNavReducer,
  asset: assetReducer,
  navBar: navBarSlice,
  assetCard: assetCardSlice,
  assetDownload: assetDownloadSlice,
  breadcrumb: breadcrumbSlice,
  sidebar: sidebarSlice,
  sort: sortSlice,
  filter: filterSlice,
  region: regionSlice,
  customeMetadata: customMetadataSlice,
  favorite: favoriteSlice,
  useSelections: useMultiSelectionSlice,
  share: shareSlice,
  download: downloadSlice,
  okta: oktaSlice,
  resetToken: resetPasswordSlice,
  multipleAssetsDownload: multipleAssetDownloadSlice,
  assets: assetsSlice,
  assetNav: assetSliceNavBar,
  inShareCheck: inShare,
  catalog: catalogSlice,
  filterOptions: filterOptionsSlice,
  assetExclusion: assetExclusionSlice,
  newFavorite: newFavoriteSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
