import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Regioninterface {
  regionId: string;
}

const initialState: Regioninterface = {
  regionId: "",
};

const region = createSlice({
  name: "region",
  initialState,
  reducers: {
    setRegion(state, { payload }: PayloadAction<string>) {
      if (payload) state.regionId = payload;
    },
  },
});

export const { setRegion } = region.actions;

export default region.reducer;
