/* eslint-disable no-plusplus */
import { useMutation } from "@apollo/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CHANGE_USER_INFO } from "../services/graphql/migrations";

export type UseMultiSelectionState = {
  allSections: any[];
  possibleSelections: any[];
  possibleSelectionsListener: boolean;
};

const initialState: UseMultiSelectionState = {
  allSections: [],
  possibleSelections: [],
  possibleSelectionsListener: true,
};

const favorite = createSlice({
  name: "useSelections",
  initialState,
  reducers: {
    setUseMultiSelectionState(state, { payload }: PayloadAction<any[]>) {
      state.allSections = payload;
    },
    setUseMultiSelectionPossibleState(
      state,
      { payload }: PayloadAction<any[]>
    ) {
      state.possibleSelections = payload;
    },
    selectAllPossibleMultiSelectionState(state) {
      state.allSections = [...state.possibleSelections];
      state.possibleSelectionsListener = !state.possibleSelectionsListener;
    },
    clearAllPossibleMultiSelectionState(state) {
      state.allSections = [];
    },
    togglePossibleSelectionsListener(state) {
      state.possibleSelectionsListener = !state.possibleSelectionsListener;
    },
  },
});

export const {
  setUseMultiSelectionState,
  setUseMultiSelectionPossibleState,
  selectAllPossibleMultiSelectionState,
  clearAllPossibleMultiSelectionState,
  togglePossibleSelectionsListener,
} = favorite.actions;

export default favorite.reducer;
