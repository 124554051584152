import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { REGION_LANGUAGES } from "../services/graphql/pimcoreDam";
import { GraphqlApi } from "../models/interfaces/regionLanguage";
import { RootState } from "../rootReducer";
import { languageWithNames, IObj } from "./languages";

export type IRegionLanguageResponse = {
  userID: any;
  allRegionList: any[];
  allLanguageList: any[];
  defaultRegion: any;
  defaultLanguage: any;
  getUpdatedLanguageOptions?: () => any[];
};

const languageList = Object.keys(languageWithNames).map((key: string) => ({
  value: key,
  label: languageWithNames[key],
}));

export const getRegionAndLanguageData = (languageOptions: any[]) => {
  const UserInfoSlice = useSelector((state: RootState) => state.user);
  const { loading, error, data } = useQuery(REGION_LANGUAGES, {
    fetchPolicy: "cache-first",
    // pollInterval: 500,
  });

  const userID = UserInfoSlice?.id;

  const response: IRegionLanguageResponse = {
    userID,
    allRegionList: [],
    allLanguageList: [],
    defaultRegion: null,
    defaultLanguage: null,
  };
  if (loading) return { ...response, loading, error, data };
  if (error) return { ...response, loading, error, data };

  response.allRegionList = data.getRegionListing.edges.map(
    (region: GraphqlApi, regionIndex: number) => ({
      value: region.node.id,
      label: region.node.name,
    })
  );

  const selectedRegionValue = UserInfoSlice?.primaryRegion;
  if (selectedRegionValue) {
    response.defaultRegion = response.allRegionList.find(
      (option: any) => option.value.toString() === UserInfoSlice?.primaryRegion
    );
    // get selected languages list if languageOptions is empty
    let languageOptionsList = languageOptions || [];
    if (languageOptionsList.length === 0 && response.defaultRegion) {
      const selectedNode = data.getRegionListing.edges.find(
        (x: { node: { id: number } }) =>
          x.node.id === response.defaultRegion.value
      );
      languageOptionsList = selectedNode.node.languages;
    }
    response.allLanguageList = languageList.filter((opt: any) =>
      languageOptionsList.includes(opt.value)
    );
  } else {
    response.allLanguageList = languageList;
  }
  const selectedLanguageValue = UserInfoSlice?.preferredLanguage;
  if (selectedLanguageValue) {
    // response.defaultLanguage = response.allLanguageList[0].value.toString();
    response.defaultLanguage = response.allLanguageList.find(
      (option) => option.value.toString() === UserInfoSlice?.preferredLanguage
    );
  }

  const getUpdatedLanguageOptions = (selectedRegionOption: any): any[] => {
    if (selectedRegionOption) {
      // get selected languages list if languageOptions is empty
      let languageOptionsList = languageOptions;
      if (languageOptionsList.length === 0) {
        const selectedNode = data.getRegionListing.edges.find(
          (x: { node: { id: number } }) =>
            x.node.id === selectedRegionOption.value
        );
        languageOptionsList = selectedNode.node.languages;
      }
      response.allLanguageList = languageList.filter((opt: any) =>
        languageOptionsList.includes(opt.value)
      );
    }
    return response.allLanguageList;
  };

  return { getUpdatedLanguageOptions, loading, error, data, ...response };
};
