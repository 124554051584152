import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
// Components
import { Formik, FormikProps, FormikValues } from "formik";
import RegistrationNavigation from "../../components/registrationSteps/RegistrationNavigation";
// Utils
import {
  generateSteps,
  generateInitialValues,
  getStepSchema,
} from "../../components/registrationSteps";
import completeImg from "../../assets/img/check.svg";
// import instances required to establish a connection to api
import http from "../../services/api";
import { ISignupForm } from "../../models/interfaces/signup";
import { RegResponse } from "../../services/mirage/routes/user";

export default function Register() {
  const [steps] = useState(generateSteps());
  const [initialValues] = useState(generateInitialValues(steps));
  const [currentIndex, setCurrentIndex] = useState(0);
  const [allowProceed, setAllowProceed] = useState(true);
  const navigate = useNavigate();

  const goNext = () => {
    setCurrentIndex((oldIndex) => oldIndex + 1);
  };

  const goBack = () => {
    setCurrentIndex((oldIndex) => oldIndex - 1);
  };

  const updateAllowProceed = (value: boolean) => {
    // console.log("value -falling", value);
    setAllowProceed(value);
  };

  const renderCurrentStep = (form: FormikProps<FormikValues>) => {
    const step = steps[currentIndex];

    // opportunity to extend commonProps here with other relevant information
    const commonProps = {
      name: step.name,
      form,
    };

    const StepComponent = step.component;

    return (
      <StepComponent {...commonProps} updateAllowProceed={updateAllowProceed} />
    );
  };

  const handleSubmit = (values: FormikValues) => {
    // Opportunity to perform API call here
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 2000);
    }) // beginning api call:
      .then(() => {
        // variable that contain the path where the sign up data be sent to
        const path = "/api/register";
        const valuesToSubmit = {
          ...values,
          primaryRegion: values?.primaryRegion?.value,
          language: values?.language?.value,
          companyType: values?.companyType?.value,
          country: values?.country?.value,
          termsAndConditionsId: values?.termsAndConditionsId,
        };
        // console.log("valuesToSubmit - ", valuesToSubmit);
        http
          .post<ISignupForm, RegResponse>(path, valuesToSubmit)
          .catch((error) => {
            // console.log(error);
          });
      })
      .then(() => {
        // console.log("current values from formik: ", values);
        navigate(`/`, values);
      });
  };

  const registrationStepsHeader = [
    {
      step: 1,
      label: (
        <>
          User <br /> Profile
        </>
      ),
    },
    {
      step: 2,
      label: <>Company Information</>,
    },
    {
      step: 3,
      label: <>Region &#38; Time Selection</>,
    },
    {
      step: 4,
      label: <>Terms and Conditions</>,
    },
  ];

  const RegistrationStep = () => {
    const currentStep = currentIndex + 1;
    return (
      <div className="step-children">
        {registrationStepsHeader.map((registrationStep) => {
          let stepClass = "";
          let isCompleted = false;
          if (currentStep > registrationStep.step) {
            stepClass = "complete";
            isCompleted = true;
          } else if (registrationStep.step === currentStep) {
            stepClass = "active";
          }
          return (
            <div
              key={`step-index-${registrationStep.step}`}
              className={`step-child step-${registrationStep.step} ${stepClass}`}
            >
              <div className="step-circle">
                {isCompleted ? (
                  <img src={completeImg} alt="Complete" />
                ) : (
                  registrationStep.step
                )}
              </div>
              <div className="step-text">{registrationStep.label}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const RegistrationHeader = () => (
    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-5">
      <div>
        <div className="page-title">
          <h2>Register</h2>
          <p className="fs-13">
            Already have an account? <Link to="/login">Login here.</Link>
          </p>
        </div>
      </div>
      <div className="the-steps">
        <div className="or-line">&nbsp;</div>
        <RegistrationStep />
      </div>
    </div>
  );

  return (
    <div className="registration">
      <div className="container-fluid">
        <div className="row">
          <div className="single-col col-12 registration-wrapper-height">
            <RegistrationHeader />
            <Formik
              initialValues={initialValues}
              validationSchema={getStepSchema(currentIndex, steps)}
              onSubmit={handleSubmit}
              validateOnMount
            >
              {(form) => (
                <>
                  {renderCurrentStep(form)}

                  <RegistrationNavigation
                    maxSteps={steps.length}
                    currentIndex={currentIndex}
                    onClickNext={goNext}
                    onClickBack={goBack}
                    handleSubmit={handleSubmit}
                    allowProceed={allowProceed}
                  />
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
