/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import "./Tooltip.scss";

interface TooltipProps {
  content: string;
  direction?: "top" | "bottom" | "left" | "right";
  delay?: number;
  children: React.ReactNode;
}

export default function Tooltip(props: TooltipProps) {
  const { content, direction, delay, children } = props;
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {children}
      {active && (
        <div className={`Tooltip-Tip ${direction || "top"}`}>
          {/* Content */}
          {content}
        </div>
      )}
    </div>
  );
}
