/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
import { HttpResponse } from "aws-sdk";
import { CancelTokenSource } from "axios";
import { filter } from "graphql-anywhere";
import {
  AssetAvailableInDamPortalResponse,
  AssetCategoryDataIds,
  AssetCategoryDataResponse,
  AssetCustomMetadataInfo,
  AssetNameDataResponse,
  AssetNameInterface,
  AssetPermission,
  AssetPermissionRequest,
  AssetPermissionResponseData,
  AssetSizeDataResponse,
  AssetSizeInfo,
  AssetTypeDataResponse,
  FilterVariableData,
  FilterVariablesRequest,
  FilterVariablesResponse,
  Hash,
  Options,
  SearchRequestInterface,
  SearchResponseInterface,
  VideoFilePathDataResponse,
  VideoPreviewFilePathDataResponse,
  VideoFilePathInterface,
  VideoThumbnail,
  VideoThumbnailDataResponse,
  VideoThumbnailInterface,
  DocumentFilePathDataResponse,
  DocumentFilePathInterface,
  DocumentThumbnail,
  DocumentThumbnailDataResponse,
  DocumentThumbnailInterface,
  ViewAllData,
  AssetAvailableInDamPortalRequest,
  TotalModelOptions,
  TotalModelDataResponse,
  AssetEmbeddedDataResponse,
  AssetEmbeddedDataRequest,
  AssetInfo,
  AssetData,
  AssetCountResponse,
  AssetCount,
  FilterAttributesResponse,
  FilterAttributesResponseData,
  FilterMainAttributesInterface,
  FilterMainAttributesData,
  FilterMainAttributesDataMapping,
  FilterVariableCategory,
  KeyIdInterface,
  AssetFileTypesResponse,
  AssetData2,
  FilterCatalogResponse,
  FilterCatalog,
  FilterCatalogCopy,
  SearchCountResponseInterface,
  SearchCountData,
  AssetTypeByCustomMetaIdData,
  AssetTypeByCustomMetaIdInterface,
  AssetTypeByCustomMetaIdResponse,
  AssetByIdsRequest,
  AssetByIdsResponse,
  CatalogInterface,
  AssetResolutionData,
  SearchFilterRequestInterface,
  SearchFilterResponseInterface,
  DownloadsPayloadInterface,
  DownloadResponseInterface,
  DownloadEditRequestInterface,
  DownloadEditResponseInterface,
  MultiAssetDownloadData,
  SingleAssetDownloadResponseInterface,
  MultipleAssetDownloadResponseInterface,
} from "../models/interfaces/common";
import thumbnailZIP from "../assets/img/icons-svg/zip-1127889.svg";
import thumbnailPPTX from "../assets/img/icons-svg/pptx-extension-775413.svg";
import thumbnailPPT from "../assets/img/icons-svg/ppt-775405.svg";
import thumbnailDOCX from "../assets/img/icons-svg/docx-1127984.svg";
import thumbnailDOC from "../assets/img/icons-svg/doc-1127985.svg";
import thumbnailXLSX from "../assets/img/icons-svg/xlsx-file-775412.svg";
import thumbnailXLS from "../assets/img/icons-svg/xls-775407.svg";
import thumbnailINDD from "../assets/img/icons-svg/ini-1127950.svg";
import thumbnailTXT from "../assets/img/icons-svg/txt-1127979.svg";
import thumbnailCSV from "../assets/img/icons-svg/csv-1127906.svg";
import thumbnailPDF from "../assets/img/icons-svg/pdf-1127982.svg";
import thumbnailAI from "../assets/img/icons-svg/ai-1127929.svg";
import thumbnailOTF from "../assets/img/icons-svg/otf-775404.svg";
import thumbnailTTF from "../assets/img/icons-svg/ttf-775410.svg";
import http from "../services/api";
import { getKey } from "./assetDetail";
import { getSortDirection } from "./InPageNav";
import { GraphqlApi } from "../models/interfaces/graphApi";
import { FavoriteInfoInterface } from "../pages/newFavoriteSlice";

interface ValueObject {
  id: string;
  label: string;
  value: string;
}

interface FilterVariables {
  [details: string]: ValueObject[];
}

export const partitionDate = (
  array: AssetCustomMetadataInfo[] | ViewAllData[] | AssetInfo[],
  left: number,
  right: number
) => {
  const pivot = array[Math.floor((right + left) / 2)].creationDate;
  let i = left;
  let j = right;

  while (i <= j) {
    while (array[i].creationDate < pivot) i++;
    while (array[j].creationDate > pivot) j--;

    if (i <= j) {
      [array[i], array[j]] = [array[j], array[i]];
      i++;
      j--;
    }
  }

  return i;
};

export const partitionOptions = (
  array: Options[],
  left: number,
  right: number
) => {
  const pivot = array[Math.floor((right + left) / 2)].value;
  let a = left;
  let b = right;

  while (a <= b) {
    while (array[a].value < pivot) a++;
    while (array[b].value > pivot) b--;

    if (a <= b) {
      [array[a], array[b]] = [array[b], array[a]];
      a++;
      b--;
    }
  }

  return a;
};

export const quickSortDate = (
  array: AssetCustomMetadataInfo[] | ViewAllData[] | AssetInfo[],
  left: number,
  right: number
) => {
  let index;

  if (array.length > 1) {
    index = partitionDate(array, left, right);
    if (left < index - 1) quickSortDate(array, left, index - 1);
    if (index < right) quickSortDate(array, index, right);
  }
  return array;
};

export const quickSortOptions = (
  array: Options[],
  left: number,
  right: number
) => {
  let index;

  if (array.length > 1) {
    index = partitionOptions(array, left, right);
    if (left < index - 1) quickSortOptions(array, left, index - 1);
    if (index < right) quickSortOptions(array, index, right);
  }
  return array;
};

const getSortedArrayDates = (
  array: AssetCustomMetadataInfo[] | ViewAllData[] | AssetInfo[],
  sortingDirection: boolean
) => {
  switch (getSortDirection(sortingDirection)) {
    case "DESC":
      return quickSortDate(array, 0, array.length - 1).reverse();
    case "ASC":
      return quickSortDate(array, 0, array.length - 1);
    default:
      return quickSortDate(array, 0, array.length - 1);
  }
};

export const getAssetLists = (
  categoryId: AssetCategoryDataIds,
  sortingOrder: string,
  sortingDirection: boolean,
  setAssetIds: (arg0: string) => void,
  setCustomMetaDataIds: (arg0: Hash) => void,
  source: CancelTokenSource
) => {
  const path = "/api/assets";

  http
    .post<AssetCategoryDataIds, AssetCategoryDataResponse>(path, categoryId, {
      cancelToken: source.token,
    })
    .then((res) => {
      const responseData = res ? Object.values(res.data) : [];
      const data =
        responseData.length > 0 && sortingOrder === "Date Uploaded"
          ? getSortedArrayDates(responseData, sortingDirection)
          : responseData;
      const temp: Hash = {};
      let value = "";
      for (let i = 0; i < data.length; i++) {
        if (data[i].assetId) {
          if (value === "") value = data[i].assetId;
          else value += `,${data[i].assetId}`;
          temp[data[i].assetId] = (
            data[i] as AssetCustomMetadataInfo
          ).customMetadataId;
        }
      }
      setCustomMetaDataIds(temp);
      setAssetIds(value);
    })
    .catch((err) => {});
};

export const getAssetLists2 = async (
  categoryId: AssetCategoryDataIds,
  sortingOrder: string,
  sortingDirection: boolean,
  assetFetchCount: number,
  pageNumber: number,
  setAssetIds: (arg0: string) => void,
  setRestApiAssets: (arg0: AssetInfo[]) => void,
  setCustomMetaDataIds: (arg0: Hash) => void,
  setLoading: (arg0: boolean) => void,
  source: CancelTokenSource
) => {
  const path = "/api/getassets";

  const requestData: AssetData = {
    categoryId,
    sortingDirection: sortingDirection ? "ASC" : "DESC",
    sortingOrder,
    assetFetchCount,
    pageNumber,
  };

  setLoading(true);

  await http
    .post<AssetData, AssetCategoryDataResponse>(path, requestData, {
      cancelToken: source.token,
    })
    .then((res) => {
      const responseData = res ? Object.values(res.data) : [];
      const data: AssetInfo[] =
        responseData.length > 0 && sortingOrder === "Date Uploaded"
          ? getSortedArrayDates(responseData, sortingDirection)
          : responseData;
      setRestApiAssets(data);
      const temp: Hash = {};
      let value = "";
      for (let i = 0; i < data.length; i++) {
        if (data[i].id) {
          if (value === "") value = data[i].id;
          else value += `,${data[i].id}`;
          temp[data[i].id] = (data[i] as AssetInfo).customMetadataId;
        }
      }
      setAssetIds(value);
      setCustomMetaDataIds(temp);
    })
    .catch((err) => {})
    .finally(() => {
      setLoading(false);
    });
};

export const getAssetLists4 = async (
  categoryId: AssetCategoryDataIds,
  sortingOrder: string,
  sortingDirection: boolean,
  assetFetchCount: number,
  pageNumber: number,
  assetIds: Set<string>,
  restApiAssets: AssetInfo[] | null,
  setAssetIds: (arg0: Set<string>) => void,
  setRestApiAssets: (arg0: AssetInfo[]) => void,
  setCustomMetaDataIds: (arg0: Hash) => void,
  setLoading: (arg0: boolean) => void,
  source: CancelTokenSource
) => {
  const path = "/api/getassets";

  const requestData: AssetData = {
    categoryId,
    sortingDirection: sortingDirection ? "ASC" : "DESC",
    sortingOrder,
    assetFetchCount,
    pageNumber,
  };

  setLoading(true);

  await http
    .post<AssetData, AssetCategoryDataResponse>(path, requestData, {
      cancelToken: source.token,
    })
    .then((res) => {
      const responseData: AssetInfo[] = res
        ? (res.data as unknown as AssetInfo[])
        : [];
      const tempData = responseData
        .filter((asset) => !assetIds.has(asset.id))
        .map((asset) => {
          assetIds.add(asset.id);
          return asset;
        });
      if (restApiAssets) setRestApiAssets([...restApiAssets, ...tempData]);
      else setRestApiAssets(responseData);
      setAssetIds(assetIds);
      const temp: Hash = {};
      for (let i = 0; i < responseData.length; i++) {
        if (responseData[i].id) {
          // value.push(data[i].id);
          temp[responseData[i].id] = (
            responseData[i] as AssetInfo
          ).customMetadataId;
        }
      }
      setCustomMetaDataIds(temp);
    })
    .catch((err) => {})
    .finally(() => {
      setLoading(false);
    });
};

export const isFilterVariablesEmpty = (
  filterVariables: FilterVariables
): boolean => {
  let tempVal = true;
  Object.keys(filterVariables).every((key) => {
    if (filterVariables[key].length > 0) {
      tempVal = false;
      return false;
    }
    return true;
  });
  return tempVal;
};

export const checkKey = (sampleValue: string) => {
  switch (sampleValue) {
    case "region":
      return true;
    case "fileType":
      return true;
    case "assetType":
      return true;
    case "6":
      return true;
    case "11":
      return true;
    case "8":
      return true;
    case "13":
      return true;
    case "12":
      return true;
    case "14":
      return true;
    case "7":
      return true;
    case "1":
      return true;
    case "3":
      return true;
    case "15":
      return true;
    case "4":
      return true;
    case "brand":
      return true;
    default:
      return false;
  }
};

export const getFilterRecentlyAddedAttributes = (
  catalogId: AssetCategoryDataIds,
  setRecentlyAddedAttributes: (arg0: FilterCatalog) => void,
  source: CancelTokenSource
) => {
  const path = "/api/recentlyaddedattributes";

  http
    .post<AssetCategoryDataIds, FilterCatalogResponse>(path, catalogId, {
      cancelToken: source.token,
    })
    .then((res) => {
      const responseData = res
        ? (res.data as unknown as FilterAttributesResponseData[])
        : [];
      const tempRecentlyAddedAttributes: FilterCatalogCopy = {};
      tempRecentlyAddedAttributes.assetTypes = new Set<string>();
      tempRecentlyAddedAttributes.fileTypes = new Set<string>();
      responseData.forEach((item) => {
        if (!(item.key in tempRecentlyAddedAttributes) && item.id) {
          tempRecentlyAddedAttributes[item.key] = new Set<string>();
        }
        if (item.id) {
          tempRecentlyAddedAttributes[item.key].add(item.id);
          if (item.groupId)
            tempRecentlyAddedAttributes.assetTypes.add(item.groupId);
          if (item.fileType)
            tempRecentlyAddedAttributes.fileTypes.add(item.fileType);
        }
      });
      const temp: FilterCatalog = {};
      Object.keys(tempRecentlyAddedAttributes).forEach((key) => {
        temp[key] = Array.from(tempRecentlyAddedAttributes[key]);
      });
      setRecentlyAddedAttributes(temp);
    });
};

export const getRecentlyAdded = async (
  userId: number | null,
  defaultRegion: string,
  selectedRegion: string,
  filterVariables: FilterVariables,
  assetIds: string[] | null,
  setRestApiAssets: (arg0: AssetInfo[]) => void,
  setAssetIds: (arg0: string[]) => void,
  setRecentlyAddedAttributes: (arg0: FilterCatalog) => void,
  setLoading: (arg0: boolean) => void,
  source: CancelTokenSource
) => {
  const path = "/api/getrecentlyaddedassests";
  setLoading(true);
  const requestParameters: Hash = {};
  if (typeof userId === "number") {
    const isFilterEmpty: boolean = isFilterVariablesEmpty(filterVariables);
    if (isFilterEmpty && defaultRegion !== "") {
      if (selectedRegion !== "") requestParameters.region = [selectedRegion];
      else if (defaultRegion !== "") requestParameters.region = [defaultRegion];
    } else if (!isFilterEmpty) {
      Object.keys(filterVariables).forEach((key) => {
        delete requestParameters[getKey(key)];
        // if (key === "AssetType")
        //   requestParameters.assetType = filterVariables[key][0]?.id;
        if (checkKey(getKey(key))) {
          if (filterVariables[key].length > 0) {
            const temp: string[] = [];
            for (let i = 0; i < filterVariables[key].length; i++) {
              if (key === "FileType")
                temp[i] = filterVariables[key][i].value.toLowerCase();
              else if (
                key === "FabricName" ||
                key === "TotalModels" ||
                key === "LabelNames"
              ) {
                temp[i] = filterVariables[key][i].value;
              } else temp[i] = filterVariables[key][i].id;
            }
            requestParameters[getKey(key)] = temp;
          }
        } else {
          if (filterVariables[key].length > 0) {
            requestParameters[getKey(key)] = filterVariables[key][0].id;
          }
        }
      });
    }
    if (!requestParameters.region) {
      if (selectedRegion !== "") requestParameters.region = [selectedRegion];
      else if (defaultRegion !== "") requestParameters.region = [defaultRegion];
    }
    requestParameters.userId = String(userId);
    requestParameters.assetIds = assetIds || [];
  }

  // let sample: string = "";
  // (requestParameters.assetIds as string[]).forEach((item: string, index) => {
  //   if (index === 0) sample = `${item},`;
  //   else if (index === (requestParameters.assetIds as string[]).length - 1) {
  //     sample = `${sample},${item}`;
  //   } else if (index !== 0) sample = `${sample},${item},`;
  // });

  // const sample = (requestParameters.assetIds as string[]).join(",");

  getFilterRecentlyAddedAttributes(
    requestParameters,
    setRecentlyAddedAttributes,
    source
  );

  await http
    .post<AssetCategoryDataIds, AssetCategoryDataResponse>(
      path,
      requestParameters,
      {
        cancelToken: source.token,
      }
    )
    .then((res) => {
      const response: AssetInfo[] = res
        ? (res.data as unknown as AssetInfo[])
        : [];
      if (!assetIds) {
        const tempAssetIds = response.map((asset) => asset.id);
        setAssetIds(tempAssetIds);
      }
      setRestApiAssets(response);
    })
    .catch((err) => {})
    .finally(() => {
      setLoading(false);
    });
};

export const getAssetsByIds = (
  ids: number[],
  setRestApiAssets: (arg0: AssetInfo[]) => void,
  source: CancelTokenSource
) => {
  const path = "/api/getassetsbyids";

  http
    .post<
      AssetByIdsRequest,
      AssetByIdsResponse
    >(path, { ids }, { cancelToken: source.token })
    .then((res) => {
      const response = res.data ? (res.data as unknown as AssetInfo[]) : [];
      setRestApiAssets(response);
    });
};

export const getResolutionsByAssetIds = (
  ids: number[] | string[],
  setAssetResolutions: (arg0: AssetResolutionData) => void,
  source: CancelTokenSource
) => {
  const path = "/api/assetresolutionbyids";

  interface AssetResolutionRequest {
    assetIds: number[] | string[];
  }

  interface AssetResolutionResponse {
    success: boolean;
    message: string;
    data: AssetResolutionData;
    code: number;
  }

  http
    .post<AssetResolutionRequest, AssetResolutionResponse>(
      path,
      {
        assetIds: ids,
      },
      { cancelToken: source.token }
    )
    .then((res) => {
      const response = res ? res.data : {};
      setAssetResolutions(response);
    });
};

export const getAssetLists3 = (
  categoryId: AssetCategoryDataIds,
  sortingOrder: string,
  sortingDirection: boolean,
  // assetFetchCount: number,
  // pageNumber: number,
  setAssetIds: (arg0: string) => void,
  // setRestApiAssets: (arg0: AssetInfo[]) => void,
  setCustomMetaDataIds: (arg0: Hash) => void,
  // setLoading: (arg0: boolean) => void,
  source: CancelTokenSource
) => {
  const path = "/api/getassets3";

  const requestData: AssetData2 = {
    categoryId,
    sortingDirection: sortingDirection ? "ASC" : "DESC",
    sortingOrder,
    // assetFetchCount,
    // pageNumber,
  };

  // setLoading(true);

  http
    .post<AssetData2, AssetCategoryDataResponse>(path, requestData, {
      cancelToken: source.token,
    })
    .then((res) => {
      const responseData = res
        ? (res.data as unknown as AssetCustomMetadataInfo[])
        : [];

      // const data: AssetCustomMetadataInfo[] =
      //   responseData.length > 0 && sortingOrder === "Date Uploaded"
      //     ? getSortedArrayDates(responseData, sortingDirection)
      //     : responseData;
      // setRestApiAssets(data);
      // setLoading(false);
      const temp: Hash = {};
      let value = "";
      for (let i = 0; i < responseData.length; i++) {
        if (responseData[i].assetId) {
          if (value === "") value = responseData[i].assetId;
          else value += `,${responseData[i].assetId}`;
          temp[responseData[i].assetId] = (
            responseData[i] as AssetCustomMetadataInfo
          ).customMetadataId;
        }
      }
      setAssetIds(value);
      setCustomMetaDataIds(temp);
    })
    .catch((err) => {});
};

export const getMetaDataAttributes = (
  userId: number | null,
  brandPortalCategoryId: string,
  filterVariables: FilterVariables,
  defaultRegion: string,
  selectedRegion: string,
  sortingOrder: string,
  sortingDirection: boolean,
  setAssetIds: (arg0: string) => void,
  setCustomMetaDataIds: (arg0: Hash) => void,
  source: CancelTokenSource
) => {
  if (typeof userId === "number") {
    const requestParameters: Hash = {};
    const isFilterEmpty: boolean = isFilterVariablesEmpty(filterVariables);
    if (isFilterEmpty && defaultRegion !== "") {
      if (selectedRegion !== "") requestParameters.region = [selectedRegion];
      else if (defaultRegion !== "") requestParameters.region = [defaultRegion];
    } else if (!isFilterEmpty) {
      Object.keys(filterVariables).forEach((key) => {
        delete requestParameters[getKey(key)];
        // if (key === "AssetType")
        //   requestParameters.assetType = filterVariables[key][0]?.id;
        if (checkKey(getKey(key))) {
          if (filterVariables[key].length > 0) {
            const temp: string[] = [];
            for (let i = 0; i < filterVariables[key].length; i++) {
              if (key === "FileType")
                temp[i] = filterVariables[key][i].value.toLowerCase();
              else if (
                key === "FabricName" ||
                key === "TotalModels" ||
                key === "LabelNames"
              ) {
                temp[i] = filterVariables[key][i].value;
              } else temp[i] = filterVariables[key][i].id;
            }
            requestParameters[getKey(key)] = temp;
          }
        } else {
          if (filterVariables[key].length > 0) {
            requestParameters[getKey(key)] = filterVariables[key][0].id;
          }
        }
      });
    }
    if (!requestParameters.region) {
      if (selectedRegion !== "") requestParameters.region = [selectedRegion];
      else if (defaultRegion !== "") requestParameters.region = [defaultRegion];
    }
    requestParameters.userId = String(userId);
    requestParameters.brandPortalCategoryId = brandPortalCategoryId;
    getAssetLists(
      requestParameters,
      sortingOrder,
      sortingDirection,
      setAssetIds,
      setCustomMetaDataIds,
      source
    );
  }
};

export const getAssetsCount = async (
  categoryId: AssetCategoryDataIds,
  setAssetLength: (arg0: number) => void,
  setLoading: (arg0: boolean) => void,
  source: CancelTokenSource
) => {
  const path = "/api/assetscount";

  setLoading(true);

  await http
    .post<AssetCountResponse>(path, categoryId, {
      cancelToken: source.token,
    })
    .then((res) => {
      const assetCount: AssetCount[] = res
        ? (res.data as unknown as AssetCount[])
        : [];
      if (assetCount.length > 0)
        setAssetLength(Number(assetCount[0].assetCount));
      if (Number(assetCount[0].assetCount) === 0) setLoading(false);
    })
    .catch((err) => {})
    .finally(() => {
      setLoading(false);
    });
};

export const getAssetTypeByCustomMetaDataId = (
  customMetaDataId: string,
  setAssetTypeName: (arg0: string | null) => void
) => {
  const path = "/api/assettypesbycustommetadataid";

  http
    .post<
      AssetTypeByCustomMetaIdInterface,
      AssetTypeByCustomMetaIdResponse
    >(path, { customMetaDataId })
    .then((res) => {
      const response = res
        ? (res.data as unknown as AssetTypeByCustomMetaIdData[])
        : null;
      if (response) setAssetTypeName(response[0].assetType);
    });
};

export const getFilterCatalogAttributes = (
  catalogId: AssetCategoryDataIds,
  setCatalogAttributes: (arg0: FilterCatalog) => void,
  source: CancelTokenSource
) => {
  const path = "/api/catalogattributes";

  http
    .post<AssetCategoryDataIds, FilterCatalogResponse>(path, catalogId, {
      cancelToken: source.token,
    })
    .then((res) => {
      const responseData = res
        ? (res.data as unknown as FilterAttributesResponseData[])
        : [];
      const tempCatalogAttributes: FilterCatalogCopy = {};
      tempCatalogAttributes.assetTypes = new Set<string>();
      tempCatalogAttributes.fileTypes = new Set<string>();
      responseData.forEach((item) => {
        if (!(item.key in tempCatalogAttributes) && item.id) {
          tempCatalogAttributes[item.key] = new Set<string>();
        }
        if (item.id) {
          tempCatalogAttributes[item.key].add(item.id);
          if (item.groupId) tempCatalogAttributes.assetTypes.add(item.groupId);
          if (item.fileType) tempCatalogAttributes.fileTypes.add(item.fileType);
        }
      });
      const temp: FilterCatalog = {};
      Object.keys(tempCatalogAttributes).forEach((key) => {
        temp[key] = Array.from(tempCatalogAttributes[key]);
      });
      setCatalogAttributes(temp);
    });
};

// export const getMetaDataAttributes2 = (
//   userId: number | null,
//   brandPortalCategoryId: string,
//   filterVariables: FilterVariables,
//   defaultRegion: string,
//   selectedRegion: string,
//   sortingOrder: string,
//   sortingDirection: boolean,
//   assetFetchCount: number,
//   pageNumber: number,
//   setAssetIds: (arg0: string) => void,
//   setRestApiAssets: (arg0: AssetInfo[]) => void,
//   // setTempVisibleAssets: (arg0: Set<string>) => void,
//   setCustomMetaDataIds: (arg0: Hash) => void,
//   setLoading: (arg0: boolean) => void,
//   setAssetLength: (arg0: number) => void,
//   setCatalogAttributes: (arg0: FilterCatalog) => void,
//   source: CancelTokenSource
// ) => {
//   if (typeof userId === "number") {
//     const requestParameters: Hash = {};
//     const isFilterEmpty: boolean = isFilterVariablesEmpty(filterVariables);
//     if (isFilterEmpty && defaultRegion !== "") {
//       if (selectedRegion !== "") requestParameters.region = [selectedRegion];
//       else if (defaultRegion !== "") requestParameters.region = [defaultRegion];
//     } else if (!isFilterEmpty) {
//       Object.keys(filterVariables).forEach((key) => {
//         delete requestParameters[getKey(key)];
//         // if (key === "AssetType")
//         //   requestParameters.assetType = filterVariables[key][0]?.id;
//         if (checkKey(getKey(key))) {
//           if (filterVariables[key].length > 0) {
//             const temp: string[] = [];
//             for (let i = 0; i < filterVariables[key].length; i++) {
//               if (key === "FileType")
//                 temp[i] = filterVariables[key][i].value.toLowerCase();
//               else if (
//                 key === "FabricName" ||
//                 key === "TotalModels" ||
//                 key === "LabelNames"
//               ) {
//                 temp[i] = filterVariables[key][i].value;
//               } else temp[i] = filterVariables[key][i].id;
//             }
//             requestParameters[getKey(key)] = temp;
//           }
//         } else {
//           if (filterVariables[key].length > 0) {
//             requestParameters[getKey(key)] = filterVariables[key][0].id;
//           }
//         }
//       });
//     }
//     if (!requestParameters.region) {
//       if (selectedRegion !== "") requestParameters.region = [selectedRegion];
//       else if (defaultRegion !== "") requestParameters.region = [defaultRegion];
//     }
//     requestParameters.userId = String(userId);
//     requestParameters.brandPortalCategoryId = brandPortalCategoryId;
//     getFilterCatalogAttributes(requestParameters, setCatalogAttributes, source);
//     getAssetLists2(
//       requestParameters,
//       sortingOrder,
//       sortingDirection,
//       assetFetchCount,
//       pageNumber,
//       setAssetIds,
//       setRestApiAssets,
//       setCustomMetaDataIds,
//       setLoading,
//       source
//     );
//     getAssetsCount(requestParameters, setAssetLength, source);
//   }
// };

export const getAssetMetaData = async (
  userId: string,
  region: string,
  brandPortalCategoryId: string,
  sortingOrder: string,
  sortingDirection: string,
  assetFetchCount: number,
  pageNumber: number,
  restApiAssets: AssetInfo[] | null,
  assetIds: string[],
  excludedAssetIds: string[],
  setExcludedAssetIds: (arg0: string[]) => void,
  setRestApiAssets: (arg0: AssetInfo[]) => void,
  setCustomMetaDataIds: (arg0: Hash) => void,
  setLoading: (arg0: boolean) => void,
  setAssetLength: (arg0: number) => void,
  setGlobalLoader: (arg0: boolean) => void,
  setPageNumberFlag: (arg0: boolean) => void,
  source: CancelTokenSource,
  isCancelled: (arg0: any) => boolean
) => {
  const path = "/api/assetsbyassetids";

  if (region === "") return;

  interface AssetMetadataInterface {
    assetIds: string[];
    excludedAssetIds: string[];
    brandPortalCategoryId: string;
    userId: string;
    region: string;
    sortingOrder: string;
    sortingDirection: string;
    assetFetchCount: number;
    pageNumber: number;
  }

  interface AssetMetadataResponse {
    success: boolean;
    message: string;
    data: AssetInfo[];
    code: number;
  }

  const assetMetadataRequest: AssetMetadataInterface = {
    assetIds,
    excludedAssetIds,
    brandPortalCategoryId,
    userId,
    region,
    sortingOrder,
    sortingDirection,
    assetFetchCount,
    pageNumber,
  };

  setLoading(true);

  if (assetIds.length === 0) return;

  await http
    .post<AssetMetadataInterface, AssetMetadataResponse>(
      path,
      assetMetadataRequest,
      { cancelToken: source.token }
    )
    .then((res) => {
      const response = res ? (res.data as unknown as AssetInfo[]) : [];
      setExcludedAssetIds([
        ...excludedAssetIds,
        ...response.map((asset) => asset.id),
      ]);
      if (restApiAssets) setRestApiAssets([...restApiAssets, ...response]);
      else setRestApiAssets(response);
      setPageNumberFlag(true);
      setAssetLength(assetIds.length);
      const temp: Hash = {};

      for (let i = 0; i < response.length; i++) {
        if (response[i].id) {
          temp[response[i].id] = (response[i] as AssetInfo).customMetadataId;
        }
      }
      setCustomMetaDataIds(temp);
    })
    .catch((error) => {
      if (typeof isCancelled === "function") {
        // eslint-disable-next-line no-useless-return
        if (isCancelled(error)) return;
      }
    })
    .finally(() => {
      setLoading(false);
      setGlobalLoader(false);
    });
};

export const getCatalogs = async (
  setCatalogs: (data: CatalogInterface[]) => void,
  source: CancelTokenSource
) => {
  const path = "/api/catalogs";

  interface CatalogResponse {
    success: boolean;
    message: string;
    data: CatalogInterface[];
    code: number;
  }

  await http
    .post<CatalogResponse>(path, { cancelToken: source.token })
    .then((res) => {
      const response = res ? (res.data as unknown as CatalogInterface[]) : [];
      setCatalogs(response);
    })
    .catch((err) => {});
};

/**
 * @param previousArray string[]
 * @param currentArray string[]
 */
export const isArrayEqual = (
  previousArray: string[],
  currentArray: string[]
): boolean => {
  let result: boolean = true;

  interface ArrayHash {
    [details: string]: null;
  }

  const previousArrayHash: ArrayHash = {};

  previousArray.forEach((value) => {
    previousArrayHash[value] = null;
  });

  currentArray.forEach((item) => {
    if (!(item in previousArrayHash)) result = false;
  });

  if (currentArray.length !== previousArray.length) result = false;

  return result;
};

const getLowestValue = (arr: string[]): string | null => {
  if (arr.length > 0) {
    let value: number = Number(arr[0]);
    arr.forEach((item) => {
      const tempVal = Number(item);
      if (tempVal < value) value = tempVal;
    });
    return value.toString();
  }
  return null;
};

export const getFavoritesApiCall = (
  userId: number | null,
  defaultFavoriteFolderId: string | null,
  currentFavoriteFolderId: string | null,
  setFavorites: (args0: FavoriteInfoInterface) => void,
  setDefaultFavoriteFolderId: (args0: string) => void,
  setCurrentFavoriteFolderId: (args0: string) => void
) => {
  if (userId) {
    const path = "api/getfavoriteassets";

    interface FavoriteRequestInterface {
      userId: number;
    }

    interface FavoriteResponseInterface {
      success: boolean;
      message: string;
      data: FavoriteInfoInterface;
      code: number;
    }

    http
      .post<FavoriteRequestInterface, FavoriteResponseInterface>(path, {
        userId,
      })
      .then((res) => {
        const response = res
          ? (res.data as unknown as FavoriteInfoInterface)
          : {};
        const lowestId = getLowestValue(Object.keys(response));
        if (!defaultFavoriteFolderId && lowestId)
          setDefaultFavoriteFolderId(lowestId);
        if (!currentFavoriteFolderId && lowestId) {
          setCurrentFavoriteFolderId(lowestId);
        }
        setFavorites(response);
      })
      .catch((err) => {
        // console.log(err);
      });
  }
};

export const addAssetsToFavorite = async (
  userId: number | null,
  assetIds: string[],
  defaultFavoriteFolderId: string | null,
  currentFavoriteFolderId: string | null,
  setFavorites: (args0: FavoriteInfoInterface) => void,
  closeModal: () => void = () => {}
) => {
  const path = "api/addfavoriteassets";

  interface AddFavoriteAssetsRequestInterface {
    userId: number;
    folderId: string;
    assetIds: string[];
  }

  interface AddFavoriteAssetsResponseInterface {
    success: boolean;
    message: string;
    code: number;
  }

  if (userId && defaultFavoriteFolderId) {
    const request: AddFavoriteAssetsRequestInterface = {
      userId,
      folderId: currentFavoriteFolderId || defaultFavoriteFolderId,
      assetIds,
    };

    await http
      .post<
        AddFavoriteAssetsRequestInterface,
        AddFavoriteAssetsResponseInterface
      >(path, request)
      .then((res) => {
        const response = res ? res.success : false;
        if (response) {
          getFavoritesApiCall(
            userId,
            defaultFavoriteFolderId,
            currentFavoriteFolderId,
            setFavorites,
            (val1: string) => {},
            (val2: string) => {}
          );
          closeModal();
        }
      });
  }
};

export const removeAssetsFromFavorite = async (
  userId: number | null,
  assetIds: string[],
  defaultFavoriteFolderId: string | null,
  currentFavoriteFolderId: string | null,
  setFavorites: (args0: FavoriteInfoInterface) => void
) => {
  const path = "api/removefavoriteassets";

  interface RemoveFavoriteAssetsRequestInterface {
    userId: number;
    folderId: string;
    assetIds: string[];
  }

  interface RemoveFavoriteAssetsResponseInterface {
    success: boolean;
    message: string;
    code: number;
  }

  if (userId && defaultFavoriteFolderId) {
    const request: RemoveFavoriteAssetsRequestInterface = {
      userId,
      folderId: currentFavoriteFolderId || defaultFavoriteFolderId,
      assetIds,
    };

    await http
      .post<
        RemoveFavoriteAssetsRequestInterface,
        RemoveFavoriteAssetsResponseInterface
      >(path, request)
      .then((res) => {
        const response = res ? res.success : false;
        if (response)
          getFavoritesApiCall(
            userId,
            defaultFavoriteFolderId,
            currentFavoriteFolderId,
            setFavorites,
            (val1: string) => {},
            (val2: string) => {}
          );
      });
  }
};

export const removeDownloads = async (
  userId: number | null,
  zipIdsToRemove: number[],
  removeAll: boolean
) => {
  const path = "api/removeziptasks";

  interface RemoveFavoriteAssetsRequestInterface {
    userId: number;
    zipIdsToRemove: number[];
    zipIdsRemoveAll: string;
  }

  interface RemoveFavoriteAssetsResponseInterface {
    success: boolean;
    message: string;
    code: number;
  }

  if (userId) {
    const request: RemoveFavoriteAssetsRequestInterface = {
      userId,
      zipIdsToRemove,
      zipIdsRemoveAll: removeAll ? "yes" : "",
    };

    const result = await http
      .post<
        RemoveFavoriteAssetsRequestInterface,
        RemoveFavoriteAssetsResponseInterface
      >(path, request)
      .then((res) => {
        const response = res ? res.success : false;

        return response;
      });

    return result;
  }
  return false;
};

export const createFavoriteFolder = (
  userId: number | null,
  folderName: string,
  defaultFavoriteFolderId: string | null,
  currentFavoriteFolderId: string | null,
  setFavorites: (args0: FavoriteInfoInterface) => void
) => {
  const path = "api/createfavoritefolder";

  interface CreateFavoriteFolderRequestInterface {
    userId: number;
    folderName: string;
  }

  interface CreateFavoriteFolderResponseInterface {
    success: boolean;
    message: string;
    code: number;
  }

  if (userId && defaultFavoriteFolderId) {
    const request: CreateFavoriteFolderRequestInterface = {
      userId,
      folderName,
    };

    http
      .post<
        CreateFavoriteFolderRequestInterface,
        CreateFavoriteFolderResponseInterface
      >(path, request)
      .then((res) => {
        const response = res ? res.success : false;
        if (response)
          getFavoritesApiCall(
            userId,
            defaultFavoriteFolderId,
            currentFavoriteFolderId,
            setFavorites,
            (val1: string) => {},
            (val2: string) => {}
          );
      });
  }
};

export const updateFavoriteFolder = (
  userId: number | null,
  folderId: string,
  folderName: string,
  defaultFavoriteFolderId: string | null,
  setFavorites: (args0: FavoriteInfoInterface) => void
) => {
  const path = "api/updatefavoritefolder";

  interface UpdateFavoriteFolderRequestInterface {
    userId: number;
    folderId: string;
    folderName: string;
  }

  interface UpdateFavoriteFolderResponseInterface {
    success: boolean;
    message: string;
    code: number;
  }

  if (userId && defaultFavoriteFolderId) {
    const request: UpdateFavoriteFolderRequestInterface = {
      userId,
      folderId,
      folderName,
    };

    http
      .post<
        UpdateFavoriteFolderRequestInterface,
        UpdateFavoriteFolderResponseInterface
      >(path, request)
      .then((res) => {
        const response = res ? res.success : false;
        if (response)
          getFavoritesApiCall(
            userId,
            defaultFavoriteFolderId,
            folderId,
            setFavorites,
            (val1: string) => {},
            (val2: string) => {}
          );
      });
  }
};

export const deleteFavoriteFolder = (
  userId: number | null,
  defaultFavoriteFolderId: string | null,
  currentFavoriteFolderId: string | null,
  setFavorites: (args0: FavoriteInfoInterface) => void
) => {
  const path = "api/removefavoritefolder";

  interface DeleteFavoriteFolderRequestInterface {
    userId: number;
    folderId: string;
  }

  interface DeleteFavoriteFolderResponseInterface {
    success: boolean;
    message: string;
    code: number;
  }

  if (userId && defaultFavoriteFolderId) {
    const request: DeleteFavoriteFolderRequestInterface = {
      userId,
      folderId: currentFavoriteFolderId || defaultFavoriteFolderId,
    };

    http
      .post<
        DeleteFavoriteFolderRequestInterface,
        DeleteFavoriteFolderResponseInterface
      >(path, request)
      .then((res) => {
        const response = res ? res.success : false;
        if (response)
          getFavoritesApiCall(
            userId,
            defaultFavoriteFolderId,
            currentFavoriteFolderId,
            setFavorites,
            (val1: string) => {},
            (val2: string) => {}
          );
      });
  }
};

export const getMetaDataAttributes4 = async (
  userId: number | null,
  brandPortalCategoryId: string,
  filterVariables: FilterVariables,
  defaultRegion: string,
  selectedRegion: string,
  sortingOrder: string,
  sortingDirection: boolean,
  assetFetchCount: number,
  pageNumber: number,
  restApiAssets: AssetInfo[] | null,
  assetIds: Set<string>,
  setAssetIds: (arg0: Set<string>) => void,
  setRestApiAssets: (arg0: AssetInfo[]) => void,
  // setTempVisibleAssets: (arg0: Set<string>) => void,
  setCustomMetaDataIds: (arg0: Hash) => void,
  setLoading: (arg0: boolean) => void,
  setCatalogAttributes: (arg0: FilterCatalog) => void,
  source: CancelTokenSource
) => {
  if (typeof userId === "number") {
    const requestParameters: Hash = {};
    const isFilterEmpty: boolean = isFilterVariablesEmpty(filterVariables);
    if (isFilterEmpty && defaultRegion !== "") {
      if (selectedRegion !== "") requestParameters.region = [selectedRegion];
      else if (defaultRegion !== "") requestParameters.region = [defaultRegion];
    } else if (!isFilterEmpty) {
      Object.keys(filterVariables).forEach((key) => {
        delete requestParameters[getKey(key)];
        // if (key === "AssetType")
        //   requestParameters.assetType = filterVariables[key][0]?.id;
        if (checkKey(getKey(key))) {
          if (filterVariables[key].length > 0) {
            const temp: string[] = [];
            for (let i = 0; i < filterVariables[key].length; i++) {
              if (key === "FileType")
                temp[i] = filterVariables[key][i].value.toLowerCase();
              else if (
                key === "FabricName" ||
                key === "TotalModels" ||
                key === "LabelNames"
              ) {
                temp[i] = filterVariables[key][i].value;
              } else temp[i] = filterVariables[key][i].id;
            }
            requestParameters[getKey(key)] = temp;
          }
        } else {
          if (filterVariables[key].length > 0) {
            requestParameters[getKey(key)] = filterVariables[key][0].id;
          }
        }
      });
    }
    if (!requestParameters.region) {
      if (selectedRegion !== "") requestParameters.region = [selectedRegion];
      else if (defaultRegion !== "") requestParameters.region = [defaultRegion];
    }
    requestParameters.userId = String(userId);
    requestParameters.brandPortalCategoryId = brandPortalCategoryId;
    getFilterCatalogAttributes(requestParameters, setCatalogAttributes, source);
    await getAssetLists4(
      requestParameters,
      sortingOrder,
      sortingDirection,
      assetFetchCount,
      pageNumber,
      assetIds,
      restApiAssets,
      setAssetIds,
      setRestApiAssets,
      setCustomMetaDataIds,
      setLoading,
      source
    );
  }
};

export const getAssetLength = async (
  userId: number | null,
  brandPortalCategoryId: string,
  filterVariables: FilterVariables,
  defaultRegion: string,
  selectedRegion: string,
  sortingOrder: string,
  sortingDirection: boolean,
  loading: boolean,
  setLoading: (arg0: boolean) => void,
  setAssetLength: (arg0: number) => void,
  source: CancelTokenSource
) => {
  if (typeof userId === "number") {
    const requestParameters: Hash = {};
    const isFilterEmpty: boolean = isFilterVariablesEmpty(filterVariables);
    if (isFilterEmpty && defaultRegion !== "") {
      if (selectedRegion !== "") requestParameters.region = [selectedRegion];
      else if (defaultRegion !== "") requestParameters.region = [defaultRegion];
    } else if (!isFilterEmpty) {
      Object.keys(filterVariables).forEach((key) => {
        delete requestParameters[getKey(key)];
        // if (key === "AssetType")
        //   requestParameters.assetType = filterVariables[key][0]?.id;
        if (checkKey(getKey(key))) {
          if (filterVariables[key].length > 0) {
            const temp: string[] = [];
            for (let i = 0; i < filterVariables[key].length; i++) {
              if (key === "FileType")
                temp[i] = filterVariables[key][i].value.toLowerCase();
              else if (
                key === "FabricName" ||
                key === "TotalModels" ||
                key === "LabelNames"
              ) {
                temp[i] = filterVariables[key][i].value;
              } else temp[i] = filterVariables[key][i].id;
            }
            requestParameters[getKey(key)] = temp;
          }
        } else {
          if (filterVariables[key].length > 0) {
            requestParameters[getKey(key)] = filterVariables[key][0].id;
          }
        }
      });
    }
    if (!requestParameters.region) {
      if (selectedRegion !== "") requestParameters.region = [selectedRegion];
      else if (defaultRegion !== "") requestParameters.region = [defaultRegion];
    }
    requestParameters.userId = String(userId);
    requestParameters.brandPortalCategoryId = brandPortalCategoryId;
    await getAssetsCount(requestParameters, setAssetLength, setLoading, source);
  }
};

export const getCatalogSliderAssets = (
  defaultRegion: string,
  selectedRegion: string,
  setRestApiAssets: (arg0: AssetInfo[]) => void
) => {
  const path = "/api/catalogsliderrecentlyaddedattributes";

  let region = "";

  if (selectedRegion !== "") region = selectedRegion;
  else if (defaultRegion !== "") region = defaultRegion;

  interface CatalogSliderRequestInterface {
    region: string;
  }

  if (region === "") return;

  http
    .post<CatalogSliderRequestInterface, AssetCategoryDataResponse>(path, {
      region,
    })
    .then((res) => {
      const response = res ? (res.data as unknown as AssetInfo[]) : [];
      setRestApiAssets(response);
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const getMetaDataAttributes3 = (
  userId: number | null,
  brandPortalCategoryId: string,
  filterVariables: FilterVariables,
  defaultRegion: string,
  selectedRegion: string,
  sortingOrder: string,
  sortingDirection: boolean,
  // assetFetchCount: number,
  // pageNumber: number,
  setAssetIds: (arg0: string) => void,
  // setRestApiAssets: (arg0: AssetInfo[]) => void,
  setCatalogAttributes: (arg0: FilterCatalog) => void,
  setCustomMetaDataIds: (arg0: Hash) => void,
  // setLoading: (arg0: boolean) => void,
  // setAssetLength: (arg0: number) => void,
  source: CancelTokenSource
) => {
  if (typeof userId === "number") {
    const requestParameters: Hash = {};
    const isFilterEmpty: boolean = isFilterVariablesEmpty(filterVariables);
    if (isFilterEmpty && defaultRegion !== "") {
      if (selectedRegion !== "") requestParameters.region = [selectedRegion];
      else if (defaultRegion !== "") requestParameters.region = [defaultRegion];
    } else if (!isFilterEmpty) {
      Object.keys(filterVariables).forEach((key) => {
        delete requestParameters[getKey(key)];
        // if (key === "AssetType")
        //   requestParameters.assetType = filterVariables[key][0]?.id;
        if (checkKey(getKey(key))) {
          if (filterVariables[key].length > 0) {
            const temp: string[] = [];
            for (let i = 0; i < filterVariables[key].length; i++) {
              if (key === "FileType")
                temp[i] = filterVariables[key][i].value.toLowerCase();
              else if (
                key === "FabricName" ||
                key === "TotalModels" ||
                key === "LabelNames"
              ) {
                temp[i] = filterVariables[key][i].value;
              } else temp[i] = filterVariables[key][i].id;
            }
            requestParameters[getKey(key)] = temp;
          }
        } else {
          if (filterVariables[key].length > 0) {
            requestParameters[getKey(key)] = filterVariables[key][0].id;
          }
        }
      });
    }
    if (!requestParameters.region) {
      if (selectedRegion !== "") requestParameters.region = [selectedRegion];
      else if (defaultRegion !== "") requestParameters.region = [defaultRegion];
    }
    requestParameters.userId = String(userId);
    requestParameters.brandPortalCategoryId = brandPortalCategoryId;
    getFilterCatalogAttributes(requestParameters, setCatalogAttributes, source);
    getAssetLists3(
      requestParameters,
      sortingOrder,
      sortingDirection,
      // assetFetchCount,
      // pageNumber,
      setAssetIds,
      // setRestApiAssets,
      setCustomMetaDataIds,
      // setLoading,
      source
    );
    // getAssetsCount(requestParameters, setAssetLength, source);
  }
};

export const getFilterVariables = (
  brandPortalCategoryId: string,
  setFilterVariableData: (data: FilterVariableData) => {
    payload: FilterVariableData;
    type: string;
  },
  source: CancelTokenSource
) => {
  const path = "/api/assetsCustomMetadataInfo";

  http
    .post<FilterVariablesRequest, FilterVariablesResponse>(
      path,
      { brandPortalCategoryId },
      {
        cancelToken: source.token,
      }
    )
    .then((res) => {
      const responseData = res ? res.data : {};
      setFilterVariableData(responseData);
    })
    .catch((err) => {});
};

export const getFileTypes = (
  setFileTypes: (arg0: Options[]) => void,
  source: CancelTokenSource
) => {
  const path = "/api/filetypes";

  http
    .post<AssetFileTypesResponse>(path, { cancelToken: source.token })
    .then((res) => {
      const response: Options[] = res ? (res.data as unknown as Options[]) : [];
      setFileTypes(response);
    });
};

export const getFilterAttributes = (
  setFilterMainAttributes: (arg0: FilterMainAttributesInterface) => void,
  setFilterVariableData: (arg0: FilterVariableCategory) => void,
  source: CancelTokenSource
) => {
  const path = "/api/filterattributes";

  http
    .post<FilterAttributesResponse>(path, {
      cancelToken: source.token,
    })
    .then((res) => {
      const response = res
        ? (res.data as unknown as FilterAttributesResponseData[])
        : [];
      const tempFilterAttributes: FilterMainAttributesInterface = {};
      const tempFilterVariables: FilterVariableCategory = {};
      tempFilterAttributes["File Type"] = {};
      response.forEach((item) => {
        if (item.key in tempFilterAttributes) {
          if (
            !(item.id in tempFilterAttributes[item.key]) &&
            item.id !== null
          ) {
            tempFilterAttributes[item.key][item.id] = {
              id: item.id,
              key: item.key,
              keyId: item.keyId,
              value: item.value,
            };
            tempFilterAttributes["File Type"][item.fileType] = {
              id: item.fileType,
              key: item.fileType,
              keyId: item.fileType,
              value: item.fileType,
            };
          }
        } else {
          if (item.id !== null) {
            tempFilterAttributes[item.key] = {};
            tempFilterAttributes[item.key][item.id] = {
              id: item.id,
              key: item.key,
              keyId: item.keyId,
              value: item.value,
            };
            tempFilterAttributes["File Type"][item.fileType] = {
              id: item.fileType,
              key: item.fileType,
              keyId: item.fileType,
              value: item.fileType,
            };
          }
        }
        if (
          item.brandPortalCategoryId &&
          item.brandPortalCategoryId in tempFilterVariables
        ) {
          if (item.assetId in tempFilterVariables[item.brandPortalCategoryId]) {
            if (
              item.key === "Brand" &&
              !(
                "brand" in
                tempFilterVariables[item.brandPortalCategoryId][item.assetId]
              )
            ) {
              tempFilterVariables[item.brandPortalCategoryId][
                item.assetId
              ].brand = item.id;
            }
            if (
              item.key === "AssetType" &&
              !(
                "groupId" in
                tempFilterVariables[item.brandPortalCategoryId][item.assetId]
              )
            ) {
              tempFilterVariables[item.brandPortalCategoryId][
                item.assetId
              ].groupId = item.id;
            }
            if (
              "keyId" in
              tempFilterVariables[item.brandPortalCategoryId][item.assetId]
            ) {
              const val: KeyIdInterface = tempFilterVariables[
                item.brandPortalCategoryId
              ][item.assetId].keyId as KeyIdInterface;
              if (!(item.keyId in val) && item.keyId !== "-99" && item.id) {
                (
                  tempFilterVariables[item.brandPortalCategoryId][item.assetId]
                    .keyId as KeyIdInterface
                )[item.keyId] = [item.id];
              }
            }
          } else {
            tempFilterVariables[item.brandPortalCategoryId][item.assetId] = {
              assetId: item.assetId,
              fileType: item.fileType,
            };
            tempFilterVariables[item.brandPortalCategoryId][
              item.assetId
            ].keyId = {};
            if (item.keyId !== "-99" && item.id)
              (
                tempFilterVariables[item.brandPortalCategoryId][item.assetId]
                  .keyId as KeyIdInterface
              )[item.keyId] = [item.id];
            if (item.key === "Brand") {
              tempFilterVariables[item.brandPortalCategoryId][
                item.assetId
              ].brand = item.id;
            }
            if (item.key === "AssetType") {
              tempFilterVariables[item.brandPortalCategoryId][
                item.assetId
              ].groupId = item.id;
            }
          }
        } else if (
          item.brandPortalCategoryId &&
          !(item.brandPortalCategoryId in tempFilterVariables)
        ) {
          tempFilterVariables[item.brandPortalCategoryId] = {};
          tempFilterVariables[item.brandPortalCategoryId][item.assetId] = {
            assetId: item.assetId,
            fileType: item.fileType,
          };
          tempFilterVariables[item.brandPortalCategoryId][item.assetId].keyId =
            {};
          if (item.keyId !== "-99" && item.id)
            (
              tempFilterVariables[item.brandPortalCategoryId][item.assetId]
                .keyId as KeyIdInterface
            )[item.keyId] = [item.id];
          if (item.key === "Brand") {
            tempFilterVariables[item.brandPortalCategoryId][
              item.assetId
            ].brand = item.id;
          }
          if (item.key === "AssetType") {
            tempFilterVariables[item.brandPortalCategoryId][
              item.assetId
            ].groupId = item.id;
          }
        }
      });
      setFilterMainAttributes(tempFilterAttributes);
      setFilterVariableData(tempFilterVariables);
    });
};

export const partitionFileSize = (
  array: AssetSizeInfo[],
  left: number,
  right: number
) => {
  const pivot = array[Math.floor((right + left) / 2)].fileSize;
  let i = left;
  let j = right;

  while (i <= j) {
    while (array[i].fileSize < pivot) i++;
    while (array[j].fileSize > pivot) j--;

    if (i <= j) {
      [array[i], array[j]] = [array[j], array[i]];
      i++;
      j--;
    }
  }

  return i;
};

export const quickSortFileSize = (
  array: AssetSizeInfo[],
  left: number,
  right: number
) => {
  let index;

  if (array.length > 1) {
    index = partitionFileSize(array, left, right);
    if (left < index - 1) quickSortFileSize(array, left, index - 1);
    if (index < right) quickSortFileSize(array, index, right);
  }
  return array;
};

const getSortedArraySizes = (
  array: AssetSizeInfo[],
  sortDirection: boolean
) => {
  switch (getSortDirection(sortDirection)) {
    case "DESC":
      return quickSortFileSize(array, 0, array.length - 1).reverse();
    case "ASC":
      return quickSortFileSize(array, 0, array.length - 1);
    default:
      return quickSortFileSize(array, 0, array.length - 1);
  }
};

export const getAssetSizes = (
  userId: number | null,
  brandPortalCategoryId: string,
  filterVariables: FilterVariables,
  defaultRegion: string,
  selectedRegion: string,
  sortDirection: boolean,
  setAssetIds: (arg0: string) => void,
  setCustomMetaDataIds: (arg0: Hash) => void,
  source: CancelTokenSource
) => {
  const path = "/api/filesizes";
  const requestParameters: Hash = {};

  const isFilterEmpty: boolean = isFilterVariablesEmpty(filterVariables);
  if (typeof userId === "number") {
    if (isFilterEmpty && defaultRegion !== "") {
      if (selectedRegion !== "") requestParameters.region = selectedRegion;
      else if (defaultRegion !== "") requestParameters.region = defaultRegion;
      else if (!isFilterEmpty) {
        Object.keys(filterVariables).forEach((key) => {
          delete requestParameters[getKey(key)];
          if (key === "AssetType")
            requestParameters.assetType = filterVariables[key][0].id;
          if (checkKey(getKey(key))) {
            if (filterVariables[key].length > 0) {
              const temp: string[] = [];
              for (let i = 0; i < filterVariables[key].length; i++) {
                temp[i] = filterVariables[key][i].id;
              }
              requestParameters[getKey(key)] = temp;
            }
          } else {
            if (filterVariables[key].length > 0) {
              requestParameters[getKey(key)] = filterVariables[key][0].id;
            }
          }
        });
      }
    }
    requestParameters.userId = String(userId);
    requestParameters.brandPortalCategoryId = brandPortalCategoryId;

    http
      .post<AssetCategoryDataIds, AssetSizeDataResponse>(
        path,
        requestParameters,
        { cancelToken: source.token }
      )
      .then((res) => {
        const responseData = res ? Object.values(res.data) : [];
        const temp: Hash = {};
        const sortedAssetSizes = getSortedArraySizes(
          responseData,
          sortDirection
        );
        let value = "";
        for (
          let i = 0;
          i < (sortedAssetSizes.length < 24 ? sortedAssetSizes.length : 24);
          i++
        ) {
          if (sortedAssetSizes[i].assetId) {
            if (value === "") value = sortedAssetSizes[i].assetId;
            else value += `,${sortedAssetSizes[i].assetId}`;
            temp[responseData[i].assetId] = responseData[i].customMetadataId;
          }
        }
        setCustomMetaDataIds(temp);
        setAssetIds(value);
      })
      .catch((err) => {});
  }
};

export const getAssetResolutionScale = (
  assetId: AssetNameInterface,
  setAssetResolutionScale: (arg0: Number | null) => void
) => {
  const path = "/api/assetresolution";

  http
    .post<AssetNameInterface, AssetNameDataResponse>(path, assetId)
    .then((res) => {
      const responseData = res ? res.data : null;
      if (
        responseData &&
        responseData.assetOriginalHeight &&
        responseData.assetOriginalWidth
      ) {
        setAssetResolutionScale(
          Number(responseData.assetOriginalHeight) /
            Number(responseData.assetOriginalWidth)
        );
      } else setAssetResolutionScale(null);
    })
    .catch((err) => {});
};

export const getAssetResolution = (
  assetId: AssetNameInterface,
  setAssetResolution: (arg0: string | null) => void
) => {
  const path = "/api/assetresolution";

  http
    .post<AssetNameInterface, AssetNameDataResponse>(path, assetId)
    .then((res) => {
      const responseData = res ? res.data : null;
      if (
        responseData &&
        responseData.assetOriginalHeight &&
        responseData.assetOriginalWidth
      ) {
        setAssetResolution(
          `${responseData.assetOriginalHeight.toString()} x ${responseData.assetOriginalWidth.toString()}`
        );
      } else setAssetResolution(null);
    })
    .catch((err) => {});
};

export const getAssetColorSpace = (
  id: string,
  setColorSpace: (arg0: string) => void,
  source: CancelTokenSource
) => {
  const path = "/api/assetcolorspace";

  http
    .post<AssetEmbeddedDataRequest, AssetEmbeddedDataResponse>(
      path,
      {
        id,
      },
      { cancelToken: source.token }
    )
    .then((res) => {
      const responseData = res ? res.data : null;
      if (responseData) setColorSpace(responseData);
    })
    .catch((err) => {});
};

export const getAssetTypes = (
  setTempAssetTypeOptions: (arg0: Options[]) => void,
  source: CancelTokenSource
) => {
  const path = "/api/assettypes";

  http
    .post<AssetTypeDataResponse>(path, { cancelToken: source.token })
    .then((res) => {
      const responseData = res ? (res.data as any as Options[]) : [];
      if (responseData) setTempAssetTypeOptions(responseData);
    })
    .catch((err) => {});
};

export const getVideoThumbnails = (
  assetIds: string,
  setVideoThumbnails: (arg0: VideoThumbnail) => void
) => {
  const path = "/api/videothumbnails";
  const requestData: VideoThumbnailInterface = {
    assetIds,
  };

  http
    .post<string, VideoThumbnailDataResponse>(path, requestData)
    .then((res) => {
      const response: VideoThumbnail = res
        ? (res.data as unknown as VideoThumbnail)
        : {};
      if (Object.keys(response).length > 0) setVideoThumbnails({ ...response });
    })
    .catch((err) => {});
};

export const getVideoPreviewThumbnails = (
  assetIds: string,
  setVideoThumbnail: (arg0: string) => void
) => {
  const path = "/api/videothumbnails";
  const requestData: VideoThumbnailInterface = {
    assetIds,
  };

  http
    .post<string, VideoThumbnailDataResponse>(path, requestData)
    .then((res) => {
      const response: VideoThumbnail = res
        ? (res.data as unknown as VideoThumbnail)
        : {};
      if (Object.values(response)[0] as string) {
        setVideoThumbnail(Object.values(response)[0] as string);
      }
    })
    .catch((err) => {});
};

export const getVideoThumbnail = (
  assetIds: string,
  thumbnailType: string = "thumbnail"
): string => {
  const path = "/api/videothumbnails";
  const requestData: VideoThumbnailInterface = {
    assetIds,
  };
  http
    .post<string, VideoThumbnailDataResponse>(path, requestData)
    .then((res) => {
      const response: VideoThumbnail = res
        ? (res.data as unknown as VideoThumbnail)
        : {};
      return Object.values(response)
        ? (Object.values(response)[0] as string)
        : "";
    })
    .catch((err) => err);
  return "";
};

export const getVideoFilePath = (
  assetId: string,
  setVideoFilePath: (arg0: string) => void
) => {
  const path = "/api/assetfilepath";

  const requestData: VideoFilePathInterface = {
    assetId,
  };

  http
    .post<string, VideoFilePathDataResponse>(path, requestData)
    .then((res) => {
      const response = res ? (res.data as unknown as string) : null;
      if (response) {
        setVideoFilePath(response);
      }
    })
    .catch((err) => {});
};

export const getVideoPreviewFilePath = (
  assetId: string,
  setVideoFilePath: (arg0: string) => void
) => {
  const path = "/api/assetvideopath";
  const requestData: VideoFilePathInterface = {
    assetId,
  };
  http
    .post<string, VideoPreviewFilePathDataResponse>(path, requestData)
    .then((res) => {
      const response = res ? (res.url as unknown as string) : null;
      if (response) {
        setVideoFilePath(response);
      }
    })
    .catch((err) => {});
};

export const getDocumentThumbnails = (
  assetIds: string,
  setDocumentThumbnails: (arg0: DocumentThumbnail) => void
) => {
  const path = "/api/documentthumbnails";
  const requestData: DocumentThumbnailInterface = {
    assetIds,
  };

  http
    .post<string, DocumentThumbnailDataResponse>(path, requestData)
    .then((res) => {
      const response: DocumentThumbnail = res
        ? (res.data as unknown as DocumentThumbnail)
        : {};
      if (Object.keys(response).length > 0)
        setDocumentThumbnails({ ...response });
    })
    .catch((err) => {});
  return "";
};

export const getDocumentThumbnailUrl = (
  assetId: string,
  setDocumentThumbnail: (arg0: string) => void
) => {
  const path = "/api/documentthumbnail";

  const requestData: DocumentFilePathInterface = {
    assetId,
  };

  http
    .post<string, DocumentFilePathDataResponse>(path, requestData)
    .then((res) => {
      const response = res ? (res.data as unknown as string) : null;
      if (response) {
        setDocumentThumbnail(response);
      }
    })
    .catch((err) => {});
};

export const checkForLandscapeOrPortrait = (
  resolutionRatio: number,
  portrait: string,
  landscape: string
) => {
  if (resolutionRatio < 1) return landscape;
  return portrait;
};

// The function evaluate the asset's extension and returns the name of the appropriate thumbnail
export const getAssetThumbnailUrl = (
  mainAsset: GraphqlApi | any,
  thumbnailType: string = "thumbnail",
  pathname: string = ""
): string => {
  let node = {
    id: "",
    filename: "",
    fullpath: "",
    fullpathThumbnailPortraitImage: "",
    fullpathThumbnailFont: "",
  };
  let extension = "";
  // let assetId = "";
  let asset = null;
  if ("node" in mainAsset) asset = mainAsset.node;
  else asset = mainAsset;
  if (asset) {
    node = asset || null;
    const filename = node ? node.filename : "";
    // assetId = node ? node.id : "";
    const filenameParts = filename ? filename.split(".") : [];
    if (filenameParts.length > 0)
      extension = filenameParts[filenameParts.length - 1]
        ? filenameParts[filenameParts.length - 1].toUpperCase()
        : "";
  } else {
    const filename = asset ? asset.filename : "";
    // assetId = asset ? asset.id : "";
    const filenameParts = filename ? filename.split(".") : ["", ""];
    extension = filenameParts[filenameParts.length - 1]
      ? filenameParts[filenameParts.length - 1].toUpperCase()
      : "";
  }
  let assetThumbnailUrl: string | undefined = "";
  const imageExtensions = [
    "JPEG",
    "JPG",
    "PNG",
    "EPS",
    "PSD",
    "TIFF",
    "TIF",
    "BMP",
    "SVG",
    "GIF",
  ];
  const fontExtensions = ["OTF", "TTF"];
  const videoExtensions = ["MP4"];
  const otherExtensions = [
    "ZIP",
    "PPTX",
    "PPT",
    "DOCX",
    "DOC",
    "XLSX",
    "XLS",
    "INDD",
    "TXT",
    "CSV",
    "AI",
  ];

  if (imageExtensions.includes(extension)) {
    if (thumbnailType === "extend") {
      if (extension === "SVG") {
        // assetThumbnailUrl =
        //   asset?.fullpathThumbnailFont?.slice(0, 5) !== "https"
        //     ? `https://gildan.blob.core.windows.net/gildan/tmp/image-thumbnails${asset.fullpathThumbnailFont}`
        //     : asset.fullpathThumbnailFont;
        assetThumbnailUrl =
          pathname === "logosguidelines"
            ? asset.fullpathExtandLandscapeImage
            : asset.fullpathThumbnailFont;
      } else {
        // assetThumbnailUrl = asset ? asset.fullpathExtandPortraitImage : "";
        // assetThumbnailUrl =
        //   asset?.fullpathExtandPortraitImage?.slice(0, 5) !== "https"
        //     ? `https://gildan.blob.core.windows.net/gildan/tmp/image-thumbnails${asset.fullpathExtandPortraitImage}`
        //     : asset.fullpathExtandPortraitImage;
        assetThumbnailUrl =
          pathname === "logosguidelines"
            ? asset.fullpathExtandLandscapeImage
            : asset.fullpathExtandPortraitImage;
      }
    } else {
      if (asset) {
        if (extension === "SVG") {
          // if (pathname === "logosguidelines")
          //   assetThumbnailUrl = asset.fullpathThumbnailLandscapeImage;
          // else assetThumbnailUrl = asset.fullpathThumbnailFont;
          assetThumbnailUrl =
            pathname === "logosguidelines"
              ? asset.fullpathThumbnailLandscapeImage
              : asset.fullpathThumbnailFont;
        } else {
          const path =
            pathname === "logosguidelines"
              ? asset.fullpathThumbnailLandscapeImage
              : asset.fullpathThumbnailPortraitImage;
          // if (pathname === "logosguidelines")
          //   path = asset ? asset.fullpathThumbnailLandscapeImage : "";
          // else
          //   path = asset.fullpathThumbnailPortraitImage
          //     ? asset.fullpathThumbnailPortraitImage
          //     : "";
          // if (
          //   asset?.fullpathThumbnailPortraitImage?.slice(0, 5) !== "https" &&
          //   thumbnailType === "thumbnail"
          // ) {
          // path = `https://gildan.blob.core.windows.net/gildan/tmp/image-thumbnails${asset.fullpathThumbnailPortraitImage.replace(
          //   "%20",
          //   " "
          // )}`;
          // path =
          //   asset?.fullpathThumbnailFont?.slice(0, 5) !== "https"
          //     ? `https://gildan.blob.core.windows.net/gildan/tmp/image-thumbnails${asset.fullpathThumbnailFont}`
          //     : asset.fullpathThumbnailFont;
          // }
          assetThumbnailUrl = asset ? path : "";
        }
      } else {
        if (extension === "SVG") {
          assetThumbnailUrl = asset ? asset.fullpathThumbnailFont : "";
        } else {
          assetThumbnailUrl = asset ? asset.fullpathThumbnailPortraitImage : "";
        }
      }
    }
  } else if (fontExtensions.includes(extension)) {
    if (extension === "OTF") {
      assetThumbnailUrl = thumbnailOTF;
    } else if (extension === "TTF") {
      assetThumbnailUrl = thumbnailTTF;
    }
  } else if (videoExtensions.includes(extension)) {
    assetThumbnailUrl = node ? node.fullpath : "";
  } else if (otherExtensions.includes(extension)) {
    if (extension === "ZIP") {
      assetThumbnailUrl = thumbnailZIP;
    } else if (extension === "PPTX") {
      assetThumbnailUrl = thumbnailPPTX;
    } else if (extension === "PPT") {
      assetThumbnailUrl = thumbnailPPT;
    } else if (extension === "DOCX") {
      assetThumbnailUrl = thumbnailDOCX;
    } else if (extension === "DOC") {
      assetThumbnailUrl = thumbnailDOC;
    } else if (extension === "XLSX") {
      assetThumbnailUrl = thumbnailXLSX;
    } else if (extension === "XLS") {
      assetThumbnailUrl = thumbnailXLS;
    } else if (extension === "INDD") {
      assetThumbnailUrl = thumbnailINDD;
    } else if (extension === "TXT") {
      assetThumbnailUrl = thumbnailTXT;
    } else if (extension === "CSV") {
      assetThumbnailUrl = thumbnailCSV;
    } else if (extension === "AI") {
      assetThumbnailUrl = thumbnailAI;
    }
  } else {
    assetThumbnailUrl = node ? node.fullpath : "";
  }
  return assetThumbnailUrl || "";
};

export const getAssetsAvailableInDamPortal = (
  setAssetIds: (arg0: string) => void,
  setSortedAssetInfo: (arg0: ViewAllData[]) => void,
  regionId: Number,
  source: CancelTokenSource
) => {
  const path: string = "/api/assetsavailableindamportal";

  http
    .post<
      AssetAvailableInDamPortalRequest,
      AssetAvailableInDamPortalResponse
    >(path, { regionId }, { cancelToken: source.token })
    .then((res) => {
      const response = res ? (res.data as unknown as ViewAllData[]) : null;
      if (response) {
        const sortedAssetByDate: ViewAllData[] = [];
        (getSortedArrayDates(response, true) as ViewAllData[]).every(
          (asset, index) => {
            sortedAssetByDate.push(asset);
            if (index <= 200) return true;
            return false;
          }
        );

        let assetIds: string = "";
        sortedAssetByDate.forEach((data, index) => {
          if (index === 0) assetIds = data.assetId;
          else assetIds += `,${data.assetId}`;
        });
        setAssetIds(assetIds);
        setSortedAssetInfo(sortedAssetByDate);
      }
    });
};

export const getSearchWithFilter = async (
  assetIds: Set<string>,
  userId: number,
  regionId: number,
  filtersData: { [details: string]: string[] },
  loading: boolean,
  setLoading: (arg0: boolean) => void,
  setFilteredIds: (arg0: Array<string>) => void,
  source: CancelTokenSource
) => {
  const path = "/api/searchfilter";

  if (!loading) setLoading(true);

  const searchQuery: SearchFilterRequestInterface = {
    assetIds: Array.from(assetIds),
    userId,
    region: regionId,
    filtersData,
  };

  await http
    .post<SearchFilterRequestInterface, SearchFilterResponseInterface>(
      path,
      searchQuery,
      {
        cancelToken: source.token,
      }
    )
    .then((res) => {
      const response = res ? res.data : [];

      if (response.asset) {
        setFilteredIds(response.asset);
      }
    })
    .catch((err) => {
      // console.log(err);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getDownloads = async (
  userId: number,
  sortBy: string,
  sortDirection: string,
  loading: boolean,
  setLoading: (arg0: boolean) => void,
  setDownloadsArray: (arg0: Array<string>) => void,
  setCounter: (arg0: number) => void,
  source: CancelTokenSource
) => {
  const path = "/api/userdownloadlist";

  if (!loading) setLoading(true);

  const downloadsPayload: DownloadsPayloadInterface = {
    userId,
    sortBy,
    sortDirection,
  };

  await http
    .post<SearchFilterRequestInterface, DownloadResponseInterface>(
      path,
      downloadsPayload,
      {
        cancelToken: source.token,
      }
    )
    .then((res) => {
      const response = res ? res.data : [];
      setDownloadsArray(response);
      setCounter(res.unreadMessage);
    })
    .catch((err) => {
      // console.log(err);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getDownloadsCounter = async (
  userId: number,
  sortBy: string,
  sortDirection: string,
  source: CancelTokenSource
) => {
  const path = "/api/userdownloadlist";

  const downloadsPayload: DownloadsPayloadInterface = {
    userId,
    sortBy,
    sortDirection,
  };

  const result = await http
    .post<SearchFilterRequestInterface, DownloadResponseInterface>(
      path,
      downloadsPayload,
      {
        cancelToken: source.token,
      }
    )
    .then((res) => {
      if (!res) return 0;
      return res.unreadMessage;
    })
    .catch((err) => {
      // console.log(err);
    });

  return result;
};

export function downloadFileOnBrowser(url: string, fileName?: string) {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = fileName ?? "";
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
}

const downloadPreviewSingleAsset = async (
  singleAssetDownloadData: MultiAssetDownloadData
) => {
  const path = "/api/singledownloadasset";

  const mimeTypeMapping: { [key: string]: string } = {
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    bmp: "image/bmp",
    pdf: "application/pdf",
    eps: "application/postscript",
    tiff: "image/tiff",
  };

  const result = await http({
    url: path,
    method: "POST",
    responseType: "blob",
    data: singleAssetDownloadData,
  })
    .then((res: any) => {
      // access content type from response headers
      const format = res.headers["content-type"];
      const mimeType = mimeTypeMapping[format];
      const blob = new Blob([res.data], { type: mimeType });
      const url = URL.createObjectURL(blob);

      const contentDisposition = res.headers["content-disposition"];
      let filename = "downloaded_file";

      if (contentDisposition) {
        const regex = /filename="?([^"]+)"?/;
        const match = contentDisposition.match(regex);
        if (match && match[1]) {
          filename = match[1];
        }
      }
      downloadFileOnBrowser(url, filename);
    })
    .catch((err) => {
      console.log(err);
    });

  return result;
};

const downloadCustomSingleAsset = async (
  singleAssetDownloadData: MultiAssetDownloadData
) => {
  const path = "/api/singledownloadasset";

  const mimeTypeMapping: { [key: string]: string } = {
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    bmp: "image/bmp",
    pdf: "application/pdf",
    eps: "application/postscript",
    tiff: "image/tiff",
  };

  const result = await http({
    url: path,
    method: "POST",
    responseType: "blob",
    data: singleAssetDownloadData,
  })
    .then((res: any) => {
      const format = singleAssetDownloadData.format ?? "";
      const mimeType = mimeTypeMapping[format];
      if (mimeType) {
        const blob = new Blob([res.data], { type: mimeType });
        const url = URL.createObjectURL(blob);

        const contentDisposition = res.headers["content-disposition"];
        let filename = "downloaded_file";

        if (contentDisposition) {
          const regex = /filename="?([^"]+)"?/;
          const match = contentDisposition.match(regex);
          if (match && match[1]) {
            filename = match[1];
          }
        }

        downloadFileOnBrowser(url, filename);
      } else {
        console.error("Unsupported format:", singleAssetDownloadData.format);
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return result;
};

export const downloadSingleAsset = async (
  singleAssetDownloadData: MultiAssetDownloadData
) => {
  const path = "/api/singledownloadasset";

  let result;

  if (
    singleAssetDownloadData.imageDownloadType === "custom" ||
    (singleAssetDownloadData.videoDownloadType === "custom" &&
      singleAssetDownloadData.format !== undefined)
  ) {
    // Custom
    result = downloadCustomSingleAsset(singleAssetDownloadData);
  } else if (singleAssetDownloadData.imageDownloadType === "preview") {
    // Preview
    result = downloadPreviewSingleAsset(singleAssetDownloadData);
  } else {
    // Original
    result = await http
      .post<DownloadEditRequestInterface, SingleAssetDownloadResponseInterface>(
        path,
        singleAssetDownloadData
      )
      .then((res) => {
        if (res) {
          downloadFileOnBrowser(res.path);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return result;
};

export const downloadZip = async (
  multiAssetDownloadData: MultiAssetDownloadData
) => {
  const path = "/api/downloadprocesszip";

  const result = await http
    .post<DownloadEditRequestInterface, MultipleAssetDownloadResponseInterface>(
      path,
      multiAssetDownloadData
    )
    .then((res) => {
      if (res) return res;
      return null;
    })
    .catch((err) => {
      console.log(err);
    });

  return result;
};

export const postDownloadedOnce = async (zipID: number) => {
  const path = "/api/downloadupdate";

  const downloadOncePayload: DownloadEditRequestInterface = {
    zipTaskId: zipID,
  };

  await http
    .post<DownloadEditRequestInterface, DownloadEditResponseInterface>(
      path,
      downloadOncePayload
    )
    .then((res) => {
      const response = res ? res.data : null;
      // console.log(response);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const markDownloadAsRead = async (zipTaskId: number) => {
  const path = "/api/notificationack";

  const downloadOncePayload: DownloadEditRequestInterface = {
    zipTaskId,
  };

  await http
    .post<DownloadEditRequestInterface, DownloadEditResponseInterface>(
      path,
      downloadOncePayload
    )
    .then((res) => {
      const response = res ? res.data : null;
      // console.log(response);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSearchResultAssetIds = async (
  searchParameter: string,
  regionId: number
) => {
  const path = "/api/search";

  const searchQuery: SearchRequestInterface = {
    regionId,
    searchParameter,
  };

  const result = await http
    .post<SearchRequestInterface, SearchResponseInterface>(path, searchQuery)
    .then((res) => {
      const response = res ? (res.data as unknown as string[]) : [];

      return response;
    })
    .catch((err) => {});

  return result;
};

export const getSearchResult = async (
  searchParameter: string,
  regionId: number,
  setAssetIds: (ids: string[]) => void,
  setLoading: (status: boolean) => void
  // source: CancelTokenSource
) => {
  const path = "/api/search";

  setLoading(true);

  const searchQuery: SearchRequestInterface = {
    regionId,
    searchParameter,
  };

  await http
    .post<SearchRequestInterface, SearchResponseInterface>(path, searchQuery)
    .then((res) => {
      const response = res ? (res.data as unknown as string[]) : [];

      setAssetIds(response);
    })
    .catch((err) => {})
    .finally(() => {
      setLoading(false);
    });
};

export const getTotalModels = (
  setTempTotalModelOptions: (arg0: TotalModelOptions[]) => void,
  source: CancelTokenSource
) => {
  const path = "/api/totalmodels";

  http
    .post<TotalModelDataResponse>(path, { cancelToken: source.token })
    .then((res) => {
      const response = res ? (res.data as any as TotalModelOptions[]) : [];
      setTempTotalModelOptions(response);
    })
    .catch((err) => {});
};

export const getLabelNames = (
  setTempLabelNameOptions: (arg0: TotalModelOptions[]) => void,
  source: CancelTokenSource
) => {
  const path = "/api/labelnames";

  http
    .post<AssetTypeDataResponse>(path, { cancelToken: source.token })
    .then((res) => {
      const response = res ? (res.data as any as TotalModelOptions[]) : [];
      setTempLabelNameOptions(response);
    })
    .catch((err) => {});
};

export const checkIfHasNext = (
  dataLength: number,
  nextPageNumber: number
): boolean => {
  const currentPageNumber = nextPageNumber - 1;
  if (nextPageNumber * 16 < dataLength) return true;
  if (dataLength > currentPageNumber * 16 && nextPageNumber * 16 > dataLength)
    return true;
  return false;
};

export const getBrandPortalCategoryId = (path: string): string => {
  switch (path) {
    case "/catalogs/imagery":
      return "64";
    case "/catalogs/videos":
      return "65";
    case "/catalogs/emails":
      return "69";
    case "/catalogs/swatch-cards-catalogs":
      return "67";
    case "/catalogs/webbanners":
      return "70";
    case "/catalogs/sellsheets":
      return "71";
    case "/catalogs/logosguidelines":
      return "66";
    case "/catalogs/socialmedia":
      return "68";
    case "/catalogs/posterssignage":
      return "115971";
    case "/catalogs/hangtags":
      return "115972";
    default:
      return "64";
  }
};
