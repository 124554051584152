import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
const ASSETS_CATALOG = gql`
  query {
    getAssetListing {
      ... on AssetConnection {
        edges {
          ... on AssetEdge {
            node {
              ... on asset {
                id
                filename
                fullpath
                fullpathThumbnailPortraitImage: fullpath(
                  thumbnail: "DAMPortalThumbnailPortraitImage"
                )
                fullpathThumbnailLandscapeImage: fullpath(
                  thumbnail: "DAMPortalThumbnailLandscapeImage"
                )
                fullpathExtandPortraitImage: fullpath(
                  thumbnail: "DAMPortalExtandPortraitImage"
                )
                fullpathExtandLandscapeImage: fullpath(
                  thumbnail: "DAMPortalExtandLandscapeImage"
                )
                fullpathThumbnailFont: fullpath(
                  thumbnail: "DAMPortalThumbnailFont"
                )
                fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
                type
                filesize
                creationDate
              }
            }
          }
        }
      }
    }
  }
`;

export const ASSETS_CATALOG_FILTERED = gql`
  query (
    $assetFilters: String
    $metadataFilters: String
    $fabricContentFilters: String
    $limit: Int
  ) {
    getAssetListing(filter: $assetFilters, first: $limit) {
      ... on AssetConnection {
        edges {
          ... on AssetEdge {
            node {
              ... on asset {
                id
                filename
                fullpath
                fullpathThumbnailPortraitImage: fullpath(
                  thumbnail: "DAMPortalThumbnailPortraitImage"
                )
                fullpathThumbnailLandscapeImage: fullpath(
                  thumbnail: "DAMPortalThumbnailLandscapeImage"
                )
                fullpathExtandPortraitImage: fullpath(
                  thumbnail: "DAMPortalExtandPortraitImage"
                )
                fullpathExtandLandscapeImage: fullpath(
                  thumbnail: "DAMPortalExtandLandscapeImage"
                )
                fullpathThumbnailFont: fullpath(
                  thumbnail: "DAMPortalThumbnailFont"
                )
                fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
                type
                filesize
                creationDate
              }
            }
          }
        }
      }
    }
    getAssetTypeListing(filter: $metadataFilters) {
      ... on AssetTypeConnection {
        edges {
          ... on AssetTypeEdge {
            node {
              ... on object_AssetType {
                id
                label: name(language: "en_US")
                value: name(language: "en_US")
              }
            }
          }
        }
      }
    }
    getRegionListing(filter: $metadataFilters) {
      ... on RegionConnection {
        edges {
          ... on RegionEdge {
            node {
              ... on object_Region {
                id
                label: name(language: "en_US")
                value: name(language: "en_US")
              }
            }
          }
        }
      }
    }
    getBrandListing(filter: $metadataFilters) {
      ... on BrandConnection {
        edges {
          ... on BrandEdge {
            node {
              ... on object_Brand {
                id
                label: name(language: "en_US")
                value: name(language: "en_US")
              }
            }
          }
        }
      }
    }
    getProductYearListing(filter: $metadataFilters) {
      ... on ProductYearConnection {
        edges {
          ... on ProductYearEdge {
            node {
              ... on object_ProductYear {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getStyleListing(filter: $metadataFilters) {
      ... on StyleConnection {
        edges {
          ... on StyleEdge {
            node {
              ... on object_Style {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getFileTypeListing(filter: $metadataFilters) {
      ... on FileTypeConnection {
        edges {
          ... on FileTypeEdge {
            node {
              ... on object_FileType {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getColorListing(filter: $metadataFilters) {
      ... on ColorConnection {
        edges {
          ... on ColorEdge {
            node {
              ... on object_Color {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getColorCodeListing(filter: $metadataFilters) {
      ... on ColorCodeConnection {
        edges {
          ... on ColorCodeEdge {
            node {
              ... on object_ColorCode {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getGenderListing(filter: $metadataFilters) {
      ... on GenderConnection {
        edges {
          ... on GenderEdge {
            node {
              ... on object_Gender {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getSilhouetteListing(filter: $metadataFilters) {
      ... on SilhouetteConnection {
        edges {
          ... on SilhouetteEdge {
            node {
              ... on object_Silhouette {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getAgeListing(filter: $metadataFilters) {
      ... on AgeConnection {
        edges {
          ... on AgeEdge {
            node {
              ... on object_Age {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getDateMonthListing(filter: $metadataFilters) {
      ... on DateMonthConnection {
        edges {
          ... on DateMonthEdge {
            node {
              ... on object_DateMonth {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getVariantFabricContentListing(filter: $fabricContentFilters) {
      ... on VariantFabricContentConnection {
        edges {
          ... on VariantFabricContentEdge {
            node {
              ... on object_VariantFabricContent {
                id
                label: fabricContent
                value: fabricContent
              }
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_QUERY = gql`
  query ($ids: String!, $sortBy: [String], $sortOrder: [String], $limit: Int) {
    getAssetListing(
      ids: $ids
      sortBy: $sortBy
      sortOrder: $sortOrder
      first: $limit
    ) {
      ... on AssetConnection {
        edges {
          ... on AssetEdge {
            node {
              ... on asset {
                id
                filename
                fullpath
                fullpathThumbnailPortraitImage: fullpath(
                  thumbnail: "DAMPortalThumbnailPortraitImage"
                )
                fullpathThumbnailLandscapeImage: fullpath(
                  thumbnail: "DAMPortalThumbnailLandscapeImage"
                )
                fullpathExtandPortraitImage: fullpath(
                  thumbnail: "DAMPortalExtandPortraitImage"
                )
                fullpathExtandLandscapeImage: fullpath(
                  thumbnail: "DAMPortalExtandLandscapeImage"
                )
                fullpathThumbnailFont: fullpath(
                  thumbnail: "DAMPortalThumbnailFont"
                )
                fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
                type
                filesize
                creationDate
                metadata(language: "en") {
                  ... on asset_metadata_item {
                    name
                    type
                    data
                    language
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const BRAND_PORTAL_CATEGORY = gql`
  query ($language: String!) {
    getBrandPortalCategoryListing {
      ... on BrandPortalCategoryConnection {
        edges {
          ... on BrandPortalCategoryEdge {
            node {
              ... on object_BrandPortalCategory {
                id
                name(language: "en_US")
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;

export const ASSETS_CATEGORIES = gql`
  query ($language: String!) {
    getAssetListing {
      ... on AssetConnection {
        edges {
          ... on AssetEdge {
            node {
              ... on asset {
                id
                filename
                fullpath
                fullpathThumbnailPortraitImage: fullpath(
                  thumbnail: "DAMPortalThumbnailPortraitImage"
                )
                fullpathThumbnailLandscapeImage: fullpath(
                  thumbnail: "DAMPortalThumbnailLandscapeImage"
                )
                fullpathExtandPortraitImage: fullpath(
                  thumbnail: "DAMPortalExtandPortraitImage"
                )
                fullpathExtandLandscapeImage: fullpath(
                  thumbnail: "DAMPortalExtandLandscapeImage"
                )
                fullpathThumbnailFont: fullpath(
                  thumbnail: "DAMPortalThumbnailFont"
                )
                fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
                type
                filesize
                creationDate
                metadata(language: "en") {
                  ... on asset_metadata_item {
                    name
                    type
                    data
                    language
                  }
                }
              }
            }
          }
        }
      }
    }
    getBrandPortalCategoryListing {
      ... on BrandPortalCategoryConnection {
        edges {
          ... on BrandPortalCategoryEdge {
            node {
              ... on object_BrandPortalCategory {
                id
                name(language: $language)
              }
            }
          }
        }
      }
    }
    getCustomMetadataImageListing {
      ... on CustomMetadataImageConnection {
        edges {
          ... on CustomMetadataImageEdge {
            node {
              ... on object_CustomMetadataImage {
                id
                internalCode
                name
                labelName
                description
                expirationDate
                brand {
                  ... on object_Brand {
                    name(language: $language)
                  }
                }
                silhouette {
                  ... on object_Silhouette {
                    id
                    name
                  }
                }
                gender {
                  ... on object_Gender {
                    id
                    key
                    name(language: $language)
                    creationDate
                  }
                }
                age {
                  ... on object_Age {
                    id
                    key
                    name(language: $language)
                    creationDate
                    modificationDate
                  }
                }
                style {
                  ... on object_Style {
                    id
                    name
                  }
                }
                fabricName
                fabricContent {
                  ... on object_VariantFabricContent {
                    id
                    fabricContent(language: $language)
                  }
                }
                colorCode {
                  ... on object_ColorCode {
                    name(language: $language)
                  }
                }
                colorName(language: $language) {
                  ... on object_Color {
                    id
                    name(language: $language)
                  }
                }
                expirationDate
                region {
                  ... on object_Region {
                    id
                    filename
                    name(language: $language)
                  }
                }
                brandPortalCategory {
                  ... on object_BrandPortalCategory {
                    id
                    name(language: $language)
                  }
                }
                releaseMonth {
                  ... on object_DateMonth {
                    id
                    name(language: $language)
                  }
                }
                productYear {
                  ... on object_ProductYear {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const RECENTLY_ADDED_ASSETS = gql`
  query (
    $assetIds: String
    $sortBy: [String]
    $sortOrder: [String]
    $limit: Int
  ) {
    getAssetListing(
      ids: $assetIds
      sortBy: $sortBy
      sortOrder: $sortOrder
      first: $limit
    ) {
      ... on AssetConnection {
        edges {
          ... on AssetEdge {
            node {
              ... on asset {
                id
                filename
                fullpath
                fullpathThumbnailPortraitImage: fullpath(
                  thumbnail: "DAMPortalThumbnailPortraitImage"
                )
                fullpathThumbnailLandscapeImage: fullpath(
                  thumbnail: "DAMPortalThumbnailLandscapeImage"
                )
                fullpathExtandPortraitImage: fullpath(
                  thumbnail: "DAMPortalExtandPortraitImage"
                )
                fullpathExtandLandscapeImage: fullpath(
                  thumbnail: "DAMPortalExtandLandscapeImage"
                )
                fullpathThumbnailFont: fullpath(
                  thumbnail: "DAMPortalThumbnailFont"
                )
                fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
                type
                filesize
                creationDate
                metadata(language: "en") {
                  ... on asset_metadata_item {
                    name
                    type
                    data
                    language
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ALL_ASSETS = gql`
  query {
    getAssetListing {
      ... on AssetConnection {
        edges {
          ... on AssetEdge {
            node {
              ... on asset {
                id
                filename
                fullpath
                fullpathThumbnailPortraitImage: fullpath(
                  thumbnail: "DAMPortalThumbnailPortraitImage"
                )
                fullpathThumbnailLandscapeImage: fullpath(
                  thumbnail: "DAMPortalThumbnailLandscapeImage"
                )
                fullpathExtandPortraitImage: fullpath(
                  thumbnail: "DAMPortalExtandPortraitImage"
                )
                fullpathExtandLandscapeImage: fullpath(
                  thumbnail: "DAMPortalExtandLandscapeImage"
                )
                fullpathThumbnailFont: fullpath(
                  thumbnail: "DAMPortalThumbnailFont"
                )
                fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
                type
                filesize
                creationDate
                metadata(language: "en") {
                  ... on asset_metadata_item {
                    name
                    type
                    data
                    language
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ASSETS_METADATA = gql`
  query ($language: String!) {
    getAssetListing {
      ... on AssetConnection {
        edges {
          ... on AssetEdge {
            node {
              ... on asset {
                id
                filename
                fullpath
                fullpathThumbnailPortraitImage: fullpath(
                  thumbnail: "DAMPortalThumbnailPortraitImage"
                )
                fullpathThumbnailLandscapeImage: fullpath(
                  thumbnail: "DAMPortalThumbnailLandscapeImage"
                )
                fullpathExtandPortraitImage: fullpath(
                  thumbnail: "DAMPortalExtandPortraitImage"
                )
                fullpathExtandLandscapeImage: fullpath(
                  thumbnail: "DAMPortalExtandLandscapeImage"
                )
                fullpathThumbnailFont: fullpath(
                  thumbnail: "DAMPortalThumbnailFont"
                )
                fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
                type
                filesize
                creationDate
                metadata(language: "en") {
                  ... on asset_metadata_item {
                    name
                    type
                    data
                    language
                  }
                }
              }
            }
          }
        }
      }
    }
    getCustomMetadataImageListing {
      ... on CustomMetadataImageConnection {
        edges {
          ... on CustomMetadataImageEdge {
            node {
              ... on object_CustomMetadataImage {
                id
                internalCode
                name
                labelName
                description
                expirationDate
                brand {
                  ... on object_Brand {
                    name(language: $language)
                  }
                }
                silhouette {
                  ... on object_Silhouette {
                    id
                    name
                  }
                }
                gender {
                  ... on object_Gender {
                    id
                    key
                    name(language: $language)
                    creationDate
                  }
                }
                age {
                  ... on object_Age {
                    id
                    key
                    name(language: $language)
                    creationDate
                    modificationDate
                  }
                }
                style {
                  ... on object_Style {
                    id
                    name
                  }
                }
                fabricName
                fabricContent {
                  ... on object_VariantFabricContent {
                    id
                    fabricContent(language: $language)
                  }
                }
                colorCode {
                  ... on object_ColorCode {
                    name(language: $language)
                  }
                }
                colorName(language: $language) {
                  ... on object_Color {
                    id
                    name(language: $language)
                  }
                }
                expirationDate
                region {
                  ... on object_Region {
                    id
                    filename
                    name(language: $language)
                  }
                }
                brandPortalCategory {
                  ... on object_BrandPortalCategory {
                    id
                    name(language: $language)
                  }
                }
                releaseMonth {
                  ... on object_DateMonth {
                    id
                    name(language: $language)
                  }
                }
                productYear {
                  ... on object_ProductYear {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ASSETS_SORT = gql`
  query (
    $language: String!
    $sortBy: [String]
    $sortOrder: [String]
    $ids: String!
  ) {
    getAssetListing(ids: $ids, sortBy: $sortBy, sortOrder: $sortOrder) {
      ... on AssetConnection {
        edges {
          ... on AssetEdge {
            node {
              ... on asset {
                id
                filename
                fullpath
                fullpathThumbnailPortraitImage: fullpath(
                  thumbnail: "DAMPortalThumbnailPortraitImage"
                )
                fullpathThumbnailLandscapeImage: fullpath(
                  thumbnail: "DAMPortalThumbnailLandscapeImage"
                )
                fullpathExtandPortraitImage: fullpath(
                  thumbnail: "DAMPortalExtandPortraitImage"
                )
                fullpathExtandLandscapeImage: fullpath(
                  thumbnail: "DAMPortalExtandLandscapeImage"
                )
                fullpathThumbnailFont: fullpath(
                  thumbnail: "DAMPortalThumbnailFont"
                )
                fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
                type
                filesize
                creationDate
                metadata(language: $language) {
                  ... on asset_metadata_item {
                    name
                    type
                    data
                    language
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ASSETS_METADATA_SORT = gql`
  query ($sortBy: [String], $sortOrder: [String], $ids: String!, $limit: Int) {
    getAssetListing(
      ids: $ids
      sortBy: $sortBy
      sortOrder: $sortOrder
      first: $limit
    ) {
      ... on AssetConnection {
        edges {
          ... on AssetEdge {
            node {
              ... on asset {
                id
                filename
                fullpath
                fullpathThumbnailPortraitImage: fullpath(
                  thumbnail: "DAMPortalThumbnailPortraitImage"
                )
                fullpathThumbnailImageDownload: fullpath(
                  thumbnail: "DamPortalThumbnailImageDownload"
                )
                fullpathThumbnailLandscapeImage: fullpath(
                  thumbnail: "DAMPortalThumbnailLandscapeImage"
                )
                fullpathExtandPortraitImage: fullpath(
                  thumbnail: "DAMPortalExtandPortraitImage"
                )
                fullpathExtandLandscapeImage: fullpath(
                  thumbnail: "DAMPortalExtandLandscapeImage"
                )
                fullpathThumbnailFont: fullpath(
                  thumbnail: "DAMPortalThumbnailFont"
                )
                fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
                type
                filesize
                creationDate
                metadata(language: "en") {
                  ... on asset_metadata_item {
                    name
                    type
                    data
                    language
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ASSETS_FILESIZE_SORT = gql`
  query ($ids: String!, $limit: Int) {
    getAssetListing(ids: $ids, first: $limit) {
      ... on AssetConnection {
        edges {
          ... on AssetEdge {
            node {
              ... on asset {
                id
                filename
                fullpath
                fullpathThumbnailPortraitImage: fullpath(
                  thumbnail: "DAMPortalThumbnailPortraitImage"
                )
                fullpathThumbnailImageDownload: fullpath(
                  thumbnail: "DamPortalThumbnailImageDownload"
                )
                fullpathThumbnailLandscapeImage: fullpath(
                  thumbnail: "DAMPortalThumbnailLandscapeImage"
                )
                fullpathExtandPortraitImage: fullpath(
                  thumbnail: "DAMPortalExtandPortraitImage"
                )
                fullpathExtandLandscapeImage: fullpath(
                  thumbnail: "DAMPortalExtandLandscapeImage"
                )
                fullpathThumbnailFont: fullpath(
                  thumbnail: "DAMPortalThumbnailFont"
                )
                fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
                type
                filesize
                creationDate
                metadata(language: "en") {
                  ... on asset_metadata_item {
                    name
                    type
                    data
                    language
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ASSET_DETAIL = gql`
  query ($id: Int!) {
    getAsset(id: $id) {
      ... on asset {
        id
        filename
        fullpath
        fullpathThumbnailPortraitImage: fullpath(
          thumbnail: "DAMPortalThumbnailPortraitImage"
        )
        fullpathThumbnailLandscapeImage: fullpath(
          thumbnail: "DAMPortalThumbnailLandscapeImage"
        )
        fullpathExtandPortraitImage: fullpath(
          thumbnail: "DAMPortalExtandPortraitImage"
        )
        fullpathExtandLandscapeImage: fullpath(
          thumbnail: "DAMPortalExtandLandscapeImage"
        )
        fullpathThumbnailFont: fullpath(thumbnail: "DAMPortalThumbnailFont")
        fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
        type
        filesize
        creationDate
        metadata(language: "en") {
          name
          type
          data
          language
        }
      }
    }
  }
`;

export const SHARED_ASSETS = gql`
  query ($queryIds: String!, $sortBy: [String], $sortOrder: [String]) {
    getAssetListing(ids: $queryIds, sortBy: $sortBy, sortOrder: $sortOrder) {
      ... on AssetConnection {
        edges {
          ... on AssetEdge {
            node {
              ... on asset {
                id
                filename
                fullpath
                fullpathThumbnailPortraitImage: fullpath(
                  thumbnail: "DAMPortalThumbnailPortraitImage"
                )
                fullpathThumbnailLandscapeImage: fullpath(
                  thumbnail: "DAMPortalThumbnailLandscapeImage"
                )
                fullpathExtandPortraitImage: fullpath(
                  thumbnail: "DAMPortalExtandPortraitImage"
                )
                fullpathExtandLandscapeImage: fullpath(
                  thumbnail: "DAMPortalExtandLandscapeImage"
                )
                fullpathThumbnailFont: fullpath(
                  thumbnail: "DAMPortalThumbnailFont"
                )
                fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
                type
                filesize
                creationDate
                metadata(language: "en") {
                  name
                  data
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const BRAND_CATEGORIES = gql`
  query {
    getBrandPortalCategoryListing {
      ... on BrandPortalCategoryConnection {
        edges {
          ... on BrandPortalCategoryEdge {
            node {
              ... on object_BrandPortalCategory {
                id
                name(language: "en")
              }
            }
          }
        }
      }
    }
  }
`;

export const CUSTOM_METADATA = gql`
  query Sample($customId: Int!) {
    getCustomMetadataImage(id: $customId) {
      ... on object_CustomMetadataImage {
        id
        name
        brand
        age
        silhouette
        gender {
          ... on object_Gender {
            id
            key
            name(language: "en")
            creationDate
          }
        }
        style
        fabricName
        fabricContent
        colorCode
        colorName
        expirationDate
        region {
          ... on object_Region {
            id
            filename
            name(language: "en")
          }
        }
      }
    }
  }
`;

export const ALL_CUSTOM_METADATA = gql`
  query ($language: String!) {
    getCustomMetadataImageListing {
      ... on CustomMetadataImageConnection {
        edges {
          ... on CustomMetadataImageEdge {
            node {
              ... on object_CustomMetadataImage {
                id
                internalCode
                name
                labelName
                description
                brand {
                  ... on object_Brand {
                    name(language: $language)
                  }
                }
                silhouette {
                  ... on object_Silhouette {
                    id
                    name
                  }
                }
                gender {
                  ... on object_Gender {
                    id
                    key
                    name(language: $language)
                    creationDate
                  }
                }
                age {
                  ... on object_Age {
                    id
                    key
                    name(language: $language)
                    creationDate
                    modificationDate
                  }
                }
                style {
                  ... on object_Style {
                    id
                    name
                  }
                }
                fabricName
                fabricContent {
                  ... on object_VariantFabricContent {
                    id
                    fabricContent(language: $language)
                  }
                }
                colorCode {
                  ... on object_ColorCode {
                    name(language: $language)
                  }
                }
                colorName(language: $language) {
                  ... on object_Color {
                    id
                    name(language: $language)
                  }
                }
                expirationDate
                region {
                  ... on object_Region {
                    id
                    filename
                    name(language: $language)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const REGION_LANGUAGES = gql`
  query {
    getRegionListing {
      ... on RegionConnection {
        edges {
          ... on RegionEdge {
            node {
              ... on object_Region {
                id
                name
                languages
              }
            }
          }
        }
      }
    }
  }
`;

const COMPANYTYPE_LIST = gql`
  {
    getCompanyTypeListing {
      ... on CompanyTypeConnection {
        edges {
          ... on CompanyTypeEdge {
            node {
              ... on object_CompanyType {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

const LATEST_TERMS_AND_CONDITIONS = gql`
  query {
    getTermsConditionsListing(
      sortBy: "creationDate"
      sortOrder: "desc"
      first: 1
    ) {
      edges {
        node {
          ... on object_TermsConditions {
            id
            termsConditionsContent
          }
        }
      }
    }
  }
`;

export const USER_TERMS_AND_CONDITIONS = gql`
  query ($id: Int!) {
    getUser(id: $id) {
      termsAndConditions {
        ... on object_TermsConditions {
          termsConditionsContent
        }
      }
    }
  }
`;

export const FILTER_LIST = gql`
  {
    getAssetTypeListing {
      ... on AssetTypeConnection {
        edges {
          ... on AssetTypeEdge {
            node {
              ... on object_AssetType {
                id
                label: name(language: "en_US")
                value: name(language: "en_US")
              }
            }
          }
        }
      }
    }
    getRegionListing {
      ... on RegionConnection {
        edges {
          ... on RegionEdge {
            node {
              ... on object_Region {
                id
                label: name(language: "en_US")
                value: name(language: "en_US")
              }
            }
          }
        }
      }
    }
    getBrandListing {
      ... on BrandConnection {
        edges {
          ... on BrandEdge {
            node {
              ... on object_Brand {
                id
                label: name(language: "en_US")
                value: name(language: "en_US")
              }
            }
          }
        }
      }
    }
    getProductYearListing {
      ... on ProductYearConnection {
        edges {
          ... on ProductYearEdge {
            node {
              ... on object_ProductYear {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getStyleListing {
      ... on StyleConnection {
        edges {
          ... on StyleEdge {
            node {
              ... on object_Style {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getFileTypeListing {
      ... on FileTypeConnection {
        edges {
          ... on FileTypeEdge {
            node {
              ... on object_FileType {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getColorListing {
      ... on ColorConnection {
        edges {
          ... on ColorEdge {
            node {
              ... on object_Color {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getColorCodeListing {
      ... on ColorCodeConnection {
        edges {
          ... on ColorCodeEdge {
            node {
              ... on object_ColorCode {
                id
                label: key
                value: key
              }
            }
          }
        }
      }
    }
    getGenderListing {
      ... on GenderConnection {
        edges {
          ... on GenderEdge {
            node {
              ... on object_Gender {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getSilhouetteListing {
      ... on SilhouetteConnection {
        edges {
          ... on SilhouetteEdge {
            node {
              ... on object_Silhouette {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getAgeListing {
      ... on AgeConnection {
        edges {
          ... on AgeEdge {
            node {
              ... on object_Age {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getDateMonthListing {
      ... on DateMonthConnection {
        edges {
          ... on DateMonthEdge {
            node {
              ... on object_DateMonth {
                id
                label: name
                value: name
              }
            }
          }
        }
      }
    }
    getVariantFabricContentListing {
      ... on VariantFabricContentConnection {
        edges {
          ... on VariantFabricContentEdge {
            node {
              ... on object_VariantFabricContent {
                id
                label: fabricContent
                value: fabricContent
              }
            }
          }
        }
      }
    }
  }
`;

const FAV_COLLECTION_LIST = gql`
  query ($id: Int!) {
    getUser(id: $id) {
      collectionFolderFavAsset {
        ... on fieldcollection_collectionFolder {
          name
          assetsListFav {
            ... on asset {
              id
              filename
              fullpath
              fullpathThumbnailPortraitImage: fullpath(
                thumbnail: "DAMPortalThumbnailPortraitImage"
              )
              fullpathThumbnailLandscapeImage: fullpath(
                thumbnail: "DAMPortalThumbnailLandscapeImage"
              )
              fullpathThumbnailImageDownload: fullpath(
                thumbnail: "DamPortalThumbnailImageDownload"
              )
              fullpathExtandPortraitImage: fullpath(
                thumbnail: "DAMPortalExtandPortraitImage"
              )
              fullpathExtandLandscapeImage: fullpath(
                thumbnail: "DAMPortalExtandLandscapeImage"
              )
              fullpathThumbnailFont: fullpath(
                thumbnail: "DAMPortalThumbnailFont"
              )
              fullpathExtandFont: fullpath(thumbnail: "DAMPortalExtandFont")
              type
              filesize
              creationDate
              metadata(language: "en") {
                name
                data
              }
            }
          }
        }
      }
    }
  }
`;

const FAV_COLLECTION_LIST_ID_TYPE = gql`
  query ($id: Int!) {
    getUser(id: $id) {
      collectionFolderFavAsset {
        ... on fieldcollection_collectionFolder {
          name
          assetsListFav {
            ... on asset {
              id
              type
            }
          }
        }
      }
    }
  }
`;

export const NEW_CUSTOMMETADATA = gql`
  query {
    getCustomMetadataListing {
      ... on CustomMetadataConnection {
        edges {
          ... on CustomMetadataEdge {
            node {
              ... on object_CustomMetadata {
                id
                brand {
                  ... on object_Brand {
                    name
                    code
                  }
                }
                customAttributes {
                  features {
                    ... on csFeatureSelect {
                      id
                      name
                      selection
                    }
                    ... on csFeatureNumeric {
                      id
                      name
                      number
                    }
                    ... on csFeatureMultiselect {
                      id
                      name
                      selections
                    }
                    ... on csFeatureInput {
                      id
                      name
                      text
                    }
                    ... on csFeatureDate {
                      id
                      name
                      date
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const NEW_CUSTOM_METADATA = gql`
  query ($id: Int) {
    getCustomMetadata(id: $id) {
      ... on object_CustomMetadata {
        id
        brand {
          ... on object_Brand {
            name
            code
          }
        }
        description
        region {
          ... on object_Region {
            id
            name(language: "en_US")
          }
        }
        customAttributes {
          features {
            ... on csFeatureSelect {
              id
              name
              selection
            }
            ... on csFeatureNumeric {
              id
              name
              number
            }
            ... on csFeatureMultiselect {
              id
              name
              selections
            }
            ... on csFeatureInput {
              id
              name
              text
            }
            ... on csFeatureDate {
              id
              name
              date
            }
          }
        }
      }
    }
  }
`;

export {
  ASSETS_CATALOG,
  REGION_LANGUAGES,
  COMPANYTYPE_LIST,
  LATEST_TERMS_AND_CONDITIONS,
  ASSET_DETAIL,
  FAV_COLLECTION_LIST,
  FAV_COLLECTION_LIST_ID_TYPE,
};
