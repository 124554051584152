export interface IObj {
  [index: string]: string;
}

export const languageWithNames: IObj = {
  en: "English",
  en_AU: "English",
  en_CA: "English",
  en_MY: "English",
  en_NZ: "English",
  en_GB: "English",
  en_US: "English",
  zh: "中文",
  zh_Hans_CN: "中文",
  fr_CA: "Français",
  fr_FR: "Français",
  de_DE: "Deutsch",
  it_IT: "Italiano",
  es_CO: "Español",
  es_MX: "Español",
  es_ES: "Español",
  es_US: "Español",
};

// export const languageWithNames: IObj = {
//   zh: "Chinese",
//   zh_Hans_CN: "Chinese (China)",
//   en: "English",
//   en_AU: "English (Australia)",
//   en_CA: "English (Canada)",
//   en_MY: "English (Malaysia)",
//   en_NZ: "English (New Zealand)",
//   en_GB: "English (United Kingdom)",
//   en_US: "English (United States)",
//   fr_CA: "French (Canada)",
//   fr_FR: "French (France)",
//   de_DE: "German (Germany)",
//   it_IT: "Italian (Italy)",
//   es_CO: "Spanish (Colombia)",
//   es_MX: "Spanish (Mexico)",
//   es_ES: "Spanish (Spain)",
//   es_US: "Spanish (United States)",
// };
