import * as Yup from "yup";

export default Yup.object({
  companyName: Yup.string()
    .notOneOf([null])
    .required("Please enter company name")
    .matches(
      /^[0-9A-Za-zÀ-ÿ\s,._+;()*~'#@!?&-]+$/,
      "Please enter a valid company name"
    ),
  companyUrl: Yup.string()
    .nullable()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Please enter a valid url"
    ),
  companyType: Yup.object().required(
    "Please select a company type from the dropdown field"
  ),
  country: Yup.object().required(
    "Please select a country from the dropdown field"
  ),
  purpose: Yup.string()
    .nullable(false)
    .max(255, "Purpose of use must be no longer than 255 characters")
    .required("Please enter the purpose of use"),
});
