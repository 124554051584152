/* eslint-disable import/prefer-default-export */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TrashIcon from "../../assets/img/trash.svg";
import { RootState } from "../../rootReducer";
import { useAppDispatch, useAppSelector } from "../../store";
import { deleteFavoriteFolder } from "../../utils/catalog";
import {
  FavoriteInfoInterface,
  setFavorite,
} from "../../pages/newFavoriteSlice";

interface getUser {
  getUser: collectionFolderFavAsset[];
}
interface collectionFolderFavAsset {
  name: string; // folder name
  assetListFav: assetListFav[]; // use for folder.length
}
interface assetListFav {
  id: string;
  filename: string;
  fullpath: string;
  type: string;
  filesize: number;
  creationDate: number;
}

interface PropValueForFolder {
  selectedFolder: string | null;
}

type FavoriteFolderType = {
  setCheckedFavoriteFolder: (value: string | null) => void;
};

// retreive a list of favorite asset folder from the server
// define interface
// retrieve list of collection
export const FavoriteFolder = ({
  setCheckedFavoriteFolder,
}: FavoriteFolderType) => {
  const { t, i18n } = useTranslation("common");
  const userId = useSelector((state: RootState) => state.user?.id);
  const [checkedList, setCheckedList] = useState<string[]>([]);

  const Favorite = useAppSelector((state) => state.newFavorite.favorite);

  // retrieve the state that track the object with useSelector hook
  const favArr = useSelector((state: RootState) => state.favorite);
  // set up dispatch to send state
  const dispatch = useAppDispatch();

  const FavFolderList = () => (
    <>
      {Object.keys(Favorite).map((key: string, index: number) => (
        <tr key={`${Favorite[key].name}`}>
          <td>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id={Favorite[key].name}
                  name={Favorite[key].name}
                  checked={checkedList.includes(Favorite[key].name)}
                  disabled={
                    checkedList.length > 0 &&
                    !checkedList.includes(Favorite[key].name)
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      setCheckedList([Favorite[key].name]);
                      setCheckedFavoriteFolder(key);
                    } else {
                      setCheckedList([]);
                      setCheckedFavoriteFolder(null);
                    }
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor={Favorite[key].name}
                >
                  {t(Favorite[key].name)}
                </label>
              </div>
              {index !== 0 && (
                <a
                  className="trash-link"
                  href="#asdfa"
                  onClick={() => {
                    if (userId) {
                      deleteFavoriteFolder(
                        userId as number,
                        key,
                        key,
                        (value: FavoriteInfoInterface) =>
                          dispatch(setFavorite(value))
                      );
                    }
                  }}
                >
                  <img src={TrashIcon} alt="Remove" />
                </a>
              )}
            </div>
          </td>
        </tr>
      ))}
    </>
  );

  return <FavFolderList />;
};
