import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssetInfo } from "../models/interfaces/common";

interface newFavoriteInterface {
  favorite: FavoriteInfoInterface;
  defaultFolder: string;
  defaultFolderId: string;
  currentFolderId: string | null;
}

export interface FavoriteInfoInterface {
  [details: string]: FavoriteCategoryInterface;
}

interface FavoriteCategoryInterface {
  name: string;
  assets: FavoriteAssetInfo[];
}

interface FavoriteAssetInfo extends AssetInfo {
  folderName: string;
  folderId: string;
}

const initialState: newFavoriteInterface = {
  favorite: {},
  defaultFolder: "My Favorites",
  defaultFolderId: "",
  currentFolderId: null,
};

const newFavoriteSlice = createSlice({
  name: "newFavorite",
  initialState,
  reducers: {
    setFavorite(state, { payload }: PayloadAction<FavoriteInfoInterface>) {
      if (payload) {
        state.favorite = payload;
      }
    },
    setCurrentFolderId(state, { payload }: PayloadAction<string>) {
      if (payload) {
        state.currentFolderId = payload;
      }
    },
    setDefaultFolderId(state, { payload }: PayloadAction<string>) {
      if (payload) {
        state.defaultFolderId = payload;
      }
    },
  },
});

export const { setFavorite, setCurrentFolderId, setDefaultFolderId } =
  newFavoriteSlice.actions;

export default newFavoriteSlice.reducer;
