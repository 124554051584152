/* eslint-disable no-shadow */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ShareInterface {
  isOpen: boolean;
  choice: number;
}

enum Choice {
  fromCard = 1,
  fromNav = 0,
}

/*
 * From Card = 1
 * From Navbar = 0
 */

const initialState: ShareInterface = {
  isOpen: false,
  choice: Choice.fromNav,
};

const share = createSlice({
  name: "share",
  initialState,
  reducers: {
    toggleIsOpen(state) {
      state.isOpen = !state.isOpen;
    },
    toggleChoice(state, { payload }: PayloadAction<number>) {
      state.choice = payload;
    },
  },
});

export const { toggleIsOpen, toggleChoice } = share.actions;

export default share.reducer;
