import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILocale } from "../models/interfaces/locale";

const locale = createSlice({
  name: "locale",
  initialState: null as ILocale | null,
  reducers: {
    setLocale(state, { payload }: PayloadAction<ILocale>) {
      // eslint-disable-next-line no-param-reassign
      state = payload;
      return state;
    },
  },
});

export const { setLocale } = locale.actions;

export default locale.reducer;
