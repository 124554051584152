import { useQuery } from "@apollo/client";
import React, { useState, useRef } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import type { ReactI18NextChild } from "react-i18next";
import { getRegionAndLanguageData } from "../../../utils/regionAndLanguagesGraphQL";
import { IRegionSelection } from "../../../models/interfaces/common";
// Types
import { RegistrationFormProps } from "../../../types/registration";
// Util
import { getInputProps } from "../../../utils/forms";

export default function RegionRegistration({ form }: RegistrationFormProps) {
  const [languageOptions, setLanguageOptions] = useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<any>(null);

  const {
    getUpdatedLanguageOptions,
    allRegionList,
    allLanguageList,
    loading,
    data,
  } = getRegionAndLanguageData(languageOptions);

  const changeSelectOptionHandler = (selectedLang: any) => {
    // setSelectedRegion(selectedLang);
    const selectedNode = data.getRegionListing.edges.find(
      (x: { node: { id: number } }) => x.node.id === selectedLang.value
    );
    setLanguageOptions(selectedNode.node.languages);
  };

  const { t, i18n } = useTranslation("common");
  const initialValues: IRegionSelection = {
    primaryRegion: null,
    language: null,
  };

  // get list of regions
  const RegionLanguages = () => {
    const { id, name, onBlur, value, className, error } = getInputProps(
      "primaryRegion",
      form,
      "form-control",
      true
    );
    return (
      <Select
        classNamePrefix="regular-looking-dropdown"
        className="w-100"
        options={allRegionList}
        isMulti={false}
        placeholder="- Select Region -"
        id={id}
        name={name}
        onChange={async (option: any, e: any) => {
          setSelectedRegion(option);
          setSelectedLanguage(null);
          await form.setFieldValue("primaryRegion", option);
          form.setFieldValue("language", undefined, true);
          form.setFieldTouched("language", false, true);
          changeSelectOptionHandler(option);
        }}
        onBlur={onBlur}
        value={value}
        aria-errormessage={error}
      />
    );
  };
  // get list of language
  const LanguagesList = () => {
    const { id, name, onBlur, value, className, error } = getInputProps(
      "language",
      form,
      "form-control",
      true
    );
    return (
      <Select
        classNamePrefix="regular-looking-dropdown"
        className="w-100"
        options={
          getUpdatedLanguageOptions && selectedRegion
            ? getUpdatedLanguageOptions(selectedRegion)
            : allLanguageList
        }
        isMulti={false}
        placeholder="- Select Language -"
        id={id}
        name={name}
        onChange={(option: any, e: any) => {
          form.setFieldValue("language", option);
        }}
        onBlur={onBlur}
        value={selectedLanguage || value}
        aria-errormessage={error}
      />
    );
  };

  return (
    <>
      <div>
        <h1>Region Selection &#38; Time</h1>
      </div>
      <div className="row form-row mb-3">
        <div className="col-md-6 col-12">
          <div className="mb-4">
            <label htmlFor="primaryRegion" className="form-label">
              Primary Region
            </label>
            <RegionLanguages />
            {form.touched.primaryRegion && form.errors.primaryRegion ? (
              <div className="text-danger mt-1 fs-13 fw-bold">
                {form.errors.primaryRegion as ReactI18NextChild}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="mb-4">
            <label htmlFor="language" className="form-label">
              Language
            </label>
            <LanguagesList />
            {form.touched.language && form.errors.language ? (
              <div className="text-danger mt-1 fs-13 fw-bold">
                {form.errors.language as ReactI18NextChild}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
