import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { type TFunction, useTranslation } from "react-i18next";

// Redux
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../store";
import { setAssetIdList, setLoaderValue } from "../assetsSlice";
import { toggleDownloadIsOpen } from "../downloadSlice";
import { RootState } from "../../rootReducer";

// API
import { getSearchResult, getAssetMetaData } from "../../utils/catalog";

// Types
import { AssetInfo, Hash } from "../../models/interfaces/common";

import {
  initialState,
  setInPageNavState,
} from "../../components/inPageNavSlice";

// Custom Hooks
import useMultiSelection from "../../hooks/useMultiSelection";
import usePrevious from "../../hooks/usePrevious";

// Components
import CatalogCards from "../../components/CatalogCards";
import AssetDetailModal from "../../components/modals/AssetDetailModal";
import AssetDownloadModal from "../../components/modals/AssetDownloadModal";
import ManageFavoriteModal from "../../components/modals/ManageFavoriteModal";

// Utils
import { scrollToTop } from "../../util";

const BigLoader = () => (
  <div className="loaderContainer">
    <BeatLoader size={50} color="#0C2658" />
    <h1>Loading...</h1>
  </div>
);

const NoResultContent = ({ t }: { t: TFunction<"common", undefined> }) => (
  <div className="nr-wrap">
    <div className="no-results">
      <h1>We’re sorry. Nothing matches your search.</h1>
      <p>Don’t give up! Check the spelling, or try less specific terms.</p>
    </div>
  </div>
);

export default function Search() {
  // Translation
  const { t } = useTranslation("common");

  // Redux
  const dispatch = useAppDispatch();

  // Get the search value from the URL
  const location = useLocation();
  const searchValue = new URLSearchParams(location.search).get("search");

  // API Parameters
  const primaryRegion = useAppSelector(
    (state) => state.user?.primaryRegion
  ) as string;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const assetIds = useAppSelector((state) => state.assets.assetIdList);
  const [restApiAssets, setRestApiAssets] = useState<AssetInfo[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const source = axios.CancelToken.source();

  // Function to set the assetIds in the Redux store
  const setAssetIds = (ids: string[]) => dispatch(setAssetIdList(ids));

  // Function to set the loader value in the Redux store
  const setLoader = (loader: boolean) => dispatch(setLoaderValue(loader));
  const [smallLoading, setSmallLoading] = useState<boolean>(false);

  // Sorting Direction
  const sortingOrder = useAppSelector((state) => state.sort.order);
  const sortingDirection = useAppSelector((state) => state.sort.direction);

  // Modal States
  const [showAssetDetail, setShowAssetDetail] = useState(false);
  const [showManageFavorite, setShowManageFavorite] = useState(false);
  const [isAddToFavoriteModalTrigger, setIsAddToFavoriteModalTrigger] =
    useState(false);
  const downloadModalState = useAppSelector((state) => state.download.isOpen);
  const toggleDownloadModal = () => dispatch(toggleDownloadIsOpen());
  const toggleAssetDetail = () => setShowAssetDetail(!showAssetDetail);
  const toggleManageFavoriteModal = () => {
    setIsAddToFavoriteModalTrigger(true);
    setShowManageFavorite(!showManageFavorite);
  };

  // Selected Assets
  const initialSelection: string[] = [];
  const { selectedList } = useMultiSelection(
    "getAssetListing",
    "image",
    initialSelection
  );

  // GetAssetMetaData Parameters
  const userId = useSelector((state: RootState) => state.user?.id);
  const [assetLength, setAssetLength] = useState<number | null>(null);
  const [excludedAssetIds, setExcludedAssetIds] = useState<string[]>([]);
  const [customMetaDataIds, setCustomMetaDataIds] = useState<Hash>({});
  const [pageNumberFlag, setPageNumberFlag] = useState<boolean>(true);

  // Pagination
  const [hasNext, setHasNext] = useState<boolean>(false);
  const previousAssetLength = usePrevious(assetLength);

  // Function to get all assets from the API based on the search value
  async function getFirstSearch() {
    if (!primaryRegion) return;

    if (pageNumber !== 1) {
      setPageNumber(1);
    }

    await getSearchResult(
      (searchValue as string).toLowerCase(),
      Number(primaryRegion),
      setAssetIds,
      setLoading
    );
  }

  // Function to get all assets without setting the assetIds in the Redux store
  async function getPaginationAssets() {
    if (!primaryRegion && pageNumber !== 1) return;

    const assetLengthVar = assetIds.length;
    setAssetLength(assetLengthVar);

    // This one is for pagination
    if (
      assetLengthVar &&
      (hasNext || (assetLengthVar > 0 && pageNumber === 1))
    ) {
      if (assetLengthVar === previousAssetLength && pageNumber !== 1) {
        await getAssetMetaData(
          userId as string,
          primaryRegion,
          "",
          sortingOrder,
          sortingDirection ? "ASC" : "DESC",
          16,
          pageNumber,
          restApiAssets,
          assetIds,
          excludedAssetIds,
          setExcludedAssetIds,
          setRestApiAssets,
          setCustomMetaDataIds,
          setSmallLoading,
          setAssetLength,
          setLoader,
          setPageNumberFlag,
          source,
          (value: any) => axios.isCancel(value)
        );
      }
      // this one is for the first page
      else if (pageNumber === 1) {
        setRestApiAssets(null);
        await getAssetMetaData(
          userId as string,
          primaryRegion,
          "",
          sortingOrder,
          sortingDirection ? "ASC" : "DESC",
          16,
          pageNumber,
          [],
          assetIds,
          [],
          setExcludedAssetIds,
          setRestApiAssets,
          setCustomMetaDataIds,
          setLoading,
          setAssetLength,
          setLoader,
          setPageNumberFlag,
          source,
          (value: any) => axios.isCancel(value)
        );
      }
    }
  }

  async function getAssetInfo() {
    if (!assetIds) return;

    await getAssetMetaData(
      userId as string,
      primaryRegion,
      "",
      sortingOrder,
      sortingDirection ? "ASC" : "DESC",
      16,
      pageNumber,
      [],
      assetIds,
      [],
      setExcludedAssetIds,
      setRestApiAssets,
      setCustomMetaDataIds,
      setLoading,
      setAssetLength,
      setLoader,
      setPageNumberFlag,
      source,
      (value: any) => axios.isCancel(value)
    );
  }

  useEffect(() => {
    setRestApiAssets(null);
    setAssetIds([]);
    getFirstSearch();
  }, [searchValue, primaryRegion]);

  useEffect(() => {
    getPaginationAssets();
    return () => {
      source.cancel("Component unmounted");
    };
  }, [assetIds, assetLength, pageNumber, sortingDirection, sortingOrder]);

  useEffect(() => {
    setPageNumber(1);
    scrollToTop();
  }, [assetIds, sortingDirection, sortingOrder]);

  useEffect(() => {
    dispatch(setInPageNavState(initialState));
  }, []);

  useEffect(() => {
    getAssetInfo();
    return () => {
      source.cancel("");
    };
  }, []);

  // Todo add a not found message
  if (!loading && (!assetIds || assetIds.length === 0)) {
    return <NoResultContent t={t} />;
  }

  return (
    <div className="catalog-page ">
      <div className="cp container-fluid">
        <div className="cards">
          <div className="row">
            {loading ? (
              <BigLoader />
            ) : (
              <CatalogCards
                assetLength={assetIds.length}
                loading={smallLoading}
                hasNext={hasNext}
                setHasNext={setHasNext}
                restApiAssets={restApiAssets}
                pageNumber={pageNumber}
                toggleDownloadModal={toggleDownloadModal}
                toggleAssetDetail={toggleAssetDetail}
                toggleManageFavoriteModal={toggleManageFavoriteModal}
                setPageNumber={setPageNumber}
              />
            )}
            <AssetDownloadModal
              isOpen={downloadModalState}
              toggle={toggleDownloadModal}
              selectedList={selectedList}
              modalClass={
                selectedList.length > 1
                  ? "downloadMultipleModal"
                  : "downloadModal"
              }
              centered
              backdrop="static"
              keyboard
              isMultipleDownload={selectedList.length > 1}
              useMultiSelectionData={false}
            />
            <AssetDetailModal
              isOpen={showAssetDetail}
              toggle={toggleAssetDetail}
              modalClass="assetDetailsModal"
              centered
              backdrop="static"
              keyboard
              fullscreen
            />
            <ManageFavoriteModal
              isOpen={showManageFavorite}
              toggle={toggleManageFavoriteModal}
              modalClass="favoritesModal"
              centered
              backdrop="static"
              keyboard
              isAddToFavorite={isAddToFavoriteModalTrigger}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
