import React, { useState, useEffect } from "react";
import { useQuery, DocumentNode } from "@apollo/client";
import { useSelector } from "react-redux";
import {
  GraphqlApi,
  GraphqlDataInterface,
} from "../models/interfaces/graphApi";
import { useAppDispatch } from "../store";
import { RootState } from "../rootReducer";
import { setUseMultiSelectionState } from "./useMultiSelectionSlice";
import { initialState, setInPageNavState } from "../components/inPageNavSlice";
import { AssetInfo } from "../models/interfaces/common";

/*
This is a Custom React Hook that handles card selections
*/

const useMultiSelection = <T>(
  DOCUMENT_NODE_METHOD: string,
  ASSET_TYPE: string = "image",
  initialStatePassed: T[] = [],
  enableNavBarState: boolean = false,
  isFavoritePage: boolean = false
) => {
  const dispatch = useAppDispatch();
  const [selectedList, setSelectedList] = useState<T[]>(initialStatePassed);
  const [enableNavBar, setEnableNavBar] = useState<boolean>(enableNavBarState);
  const [lastSelectedItem, setLastSelectedItem] = useState<T | null>(null);
  const [assetData, setAssetData] = useState<any>();
  const SelectionSlice = useSelector((state: RootState) => state.useSelections);

  const clearMultiSelections = () => {
    dispatch(setUseMultiSelectionState([]));
    setSelectedList([]);
  };

  const handleCardClick = (
    id: T,
    event: React.MouseEvent<HTMLDivElement>,
    callBackEvent?: () => void
  ) => {
    const toggleSelectedItem = () => {
      // if control or command key is pressed along with click
      // add/remove the currently select item, while keeping the previous list
      if (selectedList.includes(id)) {
        const newsSelections = selectedList.filter((val) => val !== id);
        setSelectedList(newsSelections);
        dispatch(setUseMultiSelectionState(newsSelections));
      } else {
        const newsSelections = [...selectedList, id];
        setSelectedList(newsSelections);
        dispatch(setUseMultiSelectionState(newsSelections));
      }
    };
    if (event.ctrlKey || event.metaKey) {
      toggleSelectedItem();
    } else if (event.shiftKey) {
      let rawData = [];
      let rawDataIDs = [];
      if (isFavoritePage) {
        rawData = assetData;
        rawDataIDs = rawData.map((catalog: any) => catalog.id);
      } else {
        // rawData = assetData[DOCUMENT_NODE_METHOD].edges.filter(
        //   (asset: GraphqlApi) => asset.node.type === ASSET_TYPE
        // );
        rawData = assetData;
        // retrieve the main key [in this case it's TITLE], so we can easily retrieve indexes
        rawDataIDs = rawData.map((catalog: AssetInfo) => catalog.id);
      }
      // if shift key is pressed along with click
      // get the items between the 2 selected items and auto-select them

      if (lastSelectedItem !== null && lastSelectedItem !== id) {
        // if statement ensures first is clicked, and not clicked twice

        // get the index of the last selected item
        const lastSelectedItemIndex = rawDataIDs.indexOf(lastSelectedItem);

        // get the index of the current selected item
        const currentSelectItemIndex = rawDataIDs.indexOf(id);

        // make sure index of currently selected item is greater than the last selected item
        if (currentSelectItemIndex > lastSelectedItemIndex) {
          // get list of new items to be selected && remove already selected items
          const moreSelectedItems = rawDataIDs
            .slice(lastSelectedItemIndex, currentSelectItemIndex + 1)
            .filter((itemID: T) => !selectedList.includes(itemID));
          const newsSelections = [...selectedList, ...moreSelectedItems];
          setSelectedList(newsSelections);
          dispatch(setUseMultiSelectionState(newsSelections));
        } else if (lastSelectedItemIndex > currentSelectItemIndex) {
          // this is when user decides to perform multi select backward
          // NOT SURE if this needed for now,
          const moreSelectedItems: T[] = [];
          for (
            let iterator = rawDataIDs.length - 1;
            iterator >= 0;
            iterator -= 1
          ) {
            if (
              iterator >= currentSelectItemIndex &&
              iterator <= lastSelectedItemIndex
            ) {
              moreSelectedItems.push(rawDataIDs[iterator]);
            }
          }
          const newsSelections = [
            ...selectedList,
            ...moreSelectedItems.filter((item) => !selectedList.includes(item)),
          ];
          setSelectedList(newsSelections);
          dispatch(setUseMultiSelectionState(newsSelections));
        }
      } else {
        toggleSelectedItem();
      }
    } else {
      // NOT SURE how the client wants to handle this click scenerio but in the meantime
      // this handles a single click and toggle select state as needed
      if (
        !selectedList.includes(id) ||
        (selectedList.includes(id) && selectedList.length > 1)
      ) {
        setSelectedList([id]);
        dispatch(setUseMultiSelectionState([id]));
      } else {
        setSelectedList([]);
        dispatch(setUseMultiSelectionState([]));
      }
    }
    setLastSelectedItem(id);
    if (callBackEvent) callBackEvent();
  };

  const updateNavbarVisibility = () => {
    dispatch(
      setInPageNavState({
        ...initialState,
        showDownload: true,
        enableDownload: true,
        showShare: true,
        enableShare: true,
        showFavorite: true,
        enableFavorite: true,
        showSpacerLine: false,
        showRemoveFromFavorite: false,
        enableRemoveFromFavorite: false,
        showRightDownloadAll: false,
        enableRightDownloadAll: false,
        showRightShareAll: false,
        enableRightShareAll: false,
        showRightClearAll: false,
        enableRightClearAll: false,
        showFilter: true,
        showName: true,
        showSort: true,
      })
    );
  };

  const resetNavBarVisibility = () => {
    dispatch(
      setInPageNavState({
        ...initialState,
        showDownload: true,
        enableDownload: false,
        showShare: true,
        enableShare: false,
        showFavorite: true,
        enableFavorite: false,
        showSpacerLine: false,
        showRemoveFromFavorite: false,
        enableRemoveFromFavorite: false,
        showRightDownloadAll: false,
        enableRightDownloadAll: false,
        showRightShareAll: false,
        enableRightShareAll: false,
        showRightClearAll: false,
        enableRightClearAll: false,
      })
    );
  };

  useEffect(() => {
    if (enableNavBar) {
      resetNavBarVisibility();
    }
    dispatch(setUseMultiSelectionState(initialStatePassed));
  }, []);

  const updateSelectedListFromSlice = () => {
    const currentHookSelections = JSON.stringify([...selectedList].sort());
    const currentSliceSelections = JSON.stringify(
      [...SelectionSlice.allSections].sort()
    );
    if (currentHookSelections !== currentSliceSelections) {
      const newSelections = [
        ...selectedList,
        ...SelectionSlice.allSections,
      ].filter((v, i, a) => a.indexOf(v) === i);
      setSelectedList(newSelections);
      dispatch(setUseMultiSelectionState(newSelections));
    }
  };

  useEffect(() => {
    if (enableNavBar) {
      if (selectedList.length > 0) {
        updateNavbarVisibility();
      } else {
        resetNavBarVisibility();
      }
    }
  }, [selectedList]);

  return {
    setAssetData,
    handleCardClick,
    selectedList,
    clearMultiSelections,
    resetNavBarVisibility,
    updateNavbarVisibility,
    updateSelectedListFromSlice,
  };
};

export default useMultiSelection;
