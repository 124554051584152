import React, { useEffect, useState } from "react";
import "reactjs-popup/dist/index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import Swal from "sweetalert2";
import { RootState } from "../../rootReducer";
import ResetPasswordConfirmationSchema from "./ResetPasswordConfirmation.schema";
import { ILoginForm } from "../../models/interfaces/login";
import { ILocale } from "../../models/interfaces/locale";
import { useAppDispatch } from "../../store";
import http from "../../services/api";
import SecureLS from "../../utils/SecureStorage";
import { saveResetToken } from "./resetPasswordSlice";

type ResetPasswordConfirmationType = {
  password: string;
  confirmPassword: string;
};

type ResetPasswordTokenType = {
  resetPasswordToken: string;
};

export default function ResetPasswordConfirmation() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const secureLS = SecureLS.getItem("user");
  const dataValue = secureLS ? JSON.parse(secureLS) : null;
  const { resetPasswordToken } = useParams<ResetPasswordTokenType>();

  // save the token into slice
  useEffect(() => {
    if (resetPasswordToken) {
      dispatch(saveResetToken(resetPasswordToken));
    }
  }, []);

  interface apiResponse {
    code: number;
    message: string;
    success: boolean;
  }

  const initialValues: ResetPasswordConfirmationType = {
    password: "",
    confirmPassword: "",
  };

  useEffect(() => {
    // console.log("resetPasswordToken", decodeToken(resetPasswordToken));
    let decodeResetPasswordToken;
    if (resetPasswordToken) {
      decodeResetPasswordToken = decodeToken(resetPasswordToken) as any;
    }
    // console.log(new Date(Number(`${decodeResetPasswordToken.expires}000`)));
    if (
      resetPasswordToken &&
      new Date() > new Date(Number(`${decodeResetPasswordToken.expires}000`))
    ) {
      const delayTime = 2000;
      // Swal.fire({
      //   allowOutsideClick: false,
      //   position: "center",
      //   // icon: 'success',
      //   background: "#F7665E",
      //   // text: "Token has expired!",
      //   html: `<strong style=color:white>Token has expired!</strong>`,
      //   width: 170,
      //   showConfirmButton: false,
      //   timer: delayTime,
      //   heightAuto: false,
      // });
      // setTimeout(() => {
      //   navigate("/reset-password");
      // }, delayTime + 100);
      navigate("/reset-password");
    }
  }, [resetPasswordToken]);

  const handleLogin = (
    data: ResetPasswordConfirmationType,
    _setSubmitting: any,
    _resetForm: any
  ) => {
    const path = "/api/recoverylinkpasswordreset";
    const requestData = {
      ...data,
      token: resetPasswordToken,
    };
    http
      .post<ResetPasswordConfirmationType, apiResponse>(path, requestData)
      .then((res) => {
        const { success, message, code } = res;
        const delayTime = 5000;

        if (success === true) {
          Swal.fire({
            position: "bottom",
            // icon: 'success',
            background: "#00ba4d",
            // text: message,
            html: `<strong style=color:white>${message}</strong>`,
            width: 350,
            showConfirmButton: false,
            timer: 5000,
            heightAuto: false,
          });
          setTimeout(() => {
            _resetForm();
            navigate("/login");
          }, delayTime + 500);
        } else {
          Swal.fire({
            position: "bottom",
            icon: "error",
            // background: "#00ba4d",
            text: message,
            // width: 170,
            showConfirmButton: false,
            timer: 5000,
            heightAuto: false,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          position: "bottom",
          icon: "error",
          // background: "#00ba4d",
          html: `<h6 style=color:white>Unable to make API call</h6>`,
          // width: 170,
          showConfirmButton: false,
          timer: 5000,
          heightAuto: false,
        });
      })
      .finally(() => {
        _setSubmitting(false);
      });
  };

  const LoginForm = () => (
    <Formik
      initialValues={initialValues}
      validationSchema={ResetPasswordConfirmationSchema}
      onSubmit={(
        values: ResetPasswordConfirmationType,
        formikHelpers: FormikHelpers<ResetPasswordConfirmationType>
      ) => {
        handleLogin(
          values,
          formikHelpers.setSubmitting,
          formikHelpers.resetForm
        );
      }}
    >
      {(props: FormikProps<ResetPasswordConfirmationType>) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
        } = props;
        return (
          <Form>
            <div className="mb-2">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className={`form-control ${
                  touched.password && errors.password ? "is-invalid" : ""
                }`}
                id="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {touched.password && errors.password ? (
                <div className="text-danger mt-1 fs-13 fw-bold">
                  {errors.password}
                </div>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">
                Verify Password
              </label>
              <input
                type="password"
                className={`form-control ${
                  touched.confirmPassword && errors.confirmPassword
                    ? "is-invalid"
                    : ""
                }`}
                id="confirmPassword"
                name="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
              />
              {touched.confirmPassword && errors.confirmPassword ? (
                <div className="text-danger mt-1 fs-13 fw-bold">
                  {errors.confirmPassword}
                </div>
              ) : null}
            </div>
            <div className="mb-3">
              <button
                className="btn btn-lg btn-blue"
                type="submit"
                disabled={
                  isSubmitting ||
                  !!(errors.password && touched.password) ||
                  !!(errors.confirmPassword && touched.confirmPassword)
                }
              >
                Reset Password
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );

  return (
    <div className="registration registration-sm">
      <div className="container-fluid">
        <div className="row">
          <div className="px-5 py-3 pb-1 single-col h-auto col-12 overflow-hidden">
            <h2>Reset Password</h2>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
}
