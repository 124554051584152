import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { RootState } from "../../rootReducer";
import { ModalProps, FavoriteAsset, FavoriteType } from "../../types/common";
import { CHANGE_USER_INFO } from "../../services/graphql/migrations";
import favoriteSlice, {
  setCurrentFavoriteFolder,
  setFavoriteState,
} from "../../pages/favoriteSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import { updateFavoriteFolder } from "../../utils/catalog";
import {
  FavoriteInfoInterface,
  setFavorite,
} from "../../pages/newFavoriteSlice";

type EditFavoriteModalType = ModalProps & {
  selectedFolder: string | null;
};

const EditFavoriteFolderModal = ({
  isOpen,
  toggle,
  modalClass,
  selectedFolder,
  keyboard = true,
  backdrop = "static",
  centered = true,
}: EditFavoriteModalType) => {
  const [newFavFolderName, setNewFavFolderName] = useState<string | null>();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");
  const userId = useSelector((state: RootState) => state.user?.id);
  const FavoriteSlice = useSelector((state: RootState) => state.favorite);
  const currentFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.currentFolderId
  );
  const defaultFavoriteFolderId = useAppSelector(
    (state) => state.newFavorite.defaultFolderId
  );

  const Favorite = useAppSelector((state) => state.newFavorite.favorite);
  const [fieldValue, setFieldValue] = useState<any>(
    FavoriteSlice.currentFavFolder
  );

  const dispatch = useAppDispatch();

  const handleNewFavFolderNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event?.target?.value;
    if (value) setNewFavFolderName(value.trim());
  };

  // update user favorite folder pimcore
  useEffect(() => {
    const changedFolderName =
      Favorite[currentFavoriteFolderId || defaultFavoriteFolderId]?.name;
    if (changedFolderName) {
      navigate({
        pathname: "/favorites",
        search: `?favoriteFolder=${encodeURIComponent(changedFolderName)}`,
      });
    }
  }, [Favorite[currentFavoriteFolderId || defaultFavoriteFolderId]?.name]);

  const handleUpdate = () => {
    toggle();
    if (userId && defaultFavoriteFolderId && newFavFolderName) {
      updateFavoriteFolder(
        userId,
        currentFavoriteFolderId || defaultFavoriteFolderId,
        newFavFolderName,
        defaultFavoriteFolderId,
        (value: FavoriteInfoInterface) => dispatch(setFavorite(value))
      );
    }
  };
  const handleBlur = (e: { target: { value: any } }) =>
    setFieldValue(e.target.value);

  return (
    <Modal
      show={isOpen}
      onHide={toggle}
      className={`${modalClass}`}
      backdrop={backdrop}
      centered={centered}
      keyboard={keyboard}
    >
      <Modal.Body>
        <h2>{t("Edit Folder Name")}</h2>
        <div className="input-group">
          <input
            type="text"
            className="form-control mb-3"
            aria-label="Text input with segmented dropdown button"
            placeholder={`${t("Enter Favorite Folder")}`}
            defaultValue={
              Favorite[currentFavoriteFolderId || defaultFavoriteFolderId]
                ?.name || ""
            }
            onChange={handleNewFavFolderNameChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <button
            type="button"
            className="btn btn-blue w-100 me-1"
            onClick={handleUpdate}
          >
            {t("Confirm")}
          </button>
          <button
            type="button"
            className="btn btn-reversed w-100 ms-1"
            onClick={toggle}
          >
            {t("Cancel")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditFavoriteFolderModal;
