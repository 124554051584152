import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssetInfo } from "../models/interfaces/common";

interface AssetsInterface {
  assetList: AssetInfo[];
  assetIdList: string[];
  loading: boolean;
}

const initialState: AssetsInterface = {
  assetList: [],
  assetIdList: [],
  loading: false,
};

const assets = createSlice({
  name: "assets",
  initialState,
  reducers: {
    setAssets(state, { payload }: PayloadAction<AssetInfo[]>) {
      if (payload) {
        state.assetList = payload;
      }
    },
    setAssetIdList(state, { payload }: PayloadAction<string[]>) {
      if (payload) {
        state.assetIdList = payload;
      }
    },
    setLoaderValue(state, { payload }: PayloadAction<boolean>) {
      if (payload) {
        state.loading = payload;
      }
    },
  },
});

export const { setAssets, setAssetIdList, setLoaderValue } = assets.actions;

export default assets.reducer;
