import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { ModalProps } from "../../types/common";

type PasswordResetErrorModalType = ModalProps;

const PasswordResetErrorModal = ({
  isOpen,
  toggle,
  modalClass,
  keyboard = true,
  backdrop = "static",
  centered = true,
}: PasswordResetErrorModalType) => {
  const { t } = useTranslation("common");

  return (
    <Modal
      show={isOpen}
      onHide={toggle}
      className={`${modalClass}`}
      backdrop={backdrop}
      centered={centered}
      keyboard={keyboard}
    >
      <Modal.Body>
        <h2>{t("Password Error")}</h2>
        <p>{t("Password update was unsuccessful")}.</p>
        <div className="retry-button-container">
          <button
            className="retry-button btn btn-reversed ms-1"
            type="button"
            onClick={toggle}
          >
            {t("Retry")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordResetErrorModal;
