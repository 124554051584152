import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface oktaState {
  id: number | string | null;
}

const initialState: oktaState = {
  id: null,
};

const okta = createSlice({
  name: "okta",
  initialState,
  reducers: {
    setOktaId(state: oktaState, { payload }: PayloadAction<number | string>) {
      if (payload) {
        // eslint-disable-next-line no-param-reassign
        state.id = payload;
      }
    },
  },
});

export const { setOktaId } = okta.actions;

export default okta.reducer;
